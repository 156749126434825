import React from 'react';
import {Link} from "react-router-dom";
import career2 from '../assets/career2.svg'

const ErrorPage = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h1>
                This page <span className={'span-invert'}>does not exist</span>
            </h1>

            <div>
                <img src={career2} height={'300px'} width={'300px'}  style={{paddingTop: '30px'}}/>
            </div>
            <Link to={'/'} className={'btn security-btn'} style={{marginTop: '30px'}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'} style={{transform: 'rotate(180deg)', marginLeft: '0', marginRight: '8px'}}>
                    <path
                        fill={'#fff'}
                        fillRule="evenodd"
                        d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                    ></path>
                </svg>
                Home
            </Link>

        </div>
    );
};

export default ErrorPage;