import React, {useEffect, useRef, useState} from 'react';
import './CloneTrading.css'
import clone_trading from "../assets/clone_trading.svg";
import {Link} from "react-router-dom";
import MobileApp from "./components/MobileApp";
import MightBeInteresting from "../MightBeInteresting";
import JoinCommunity from "./components/JoinCommunity";
import {clone_sort_options, dataCoins, logos, marketplace_data, mybots_data, sort_options} from "./TradingBotsData";
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";
import grid_bot from "../assets/grid_bot.svg";
import add_dark from "../assets/add_dark.svg";
import dca_bot from "../assets/dca_bot.svg";
import add_primary2 from "../assets/add_primary2.svg";
import {myClonesData} from "./CloneTradingData";
import Select from "react-select";
import abstract_2_primary from "../assets/abstract_2_primary.svg";
import {select_styles_light} from "./TradingBots";
import abstract_header from "../assets/abstract_header.svg";

const CloneTrading = () => {

    useEffect(() => {
        document.body.scrollTo({
            top: 0,
        })
    }, [])

    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }



    const select_styles_black2 = {
        input: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        noOptionsMessage: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),

        container: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),

        control: (baseStyles, state) => ({
            border: '1px solid #fafafa',
            background: '#fafafa',
            "&:hover": {
                border: '1px solid #fafafa',
                transition: '0.2s ease-in-out',
            },
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 12,
            height: '48px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            color: '#0e0e12',
            paddingLeft: '5px',
            zIndex: '100',
            transition: '0.2s ease-in-out',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 0,
            height: '42px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            color: '#0e0e12',
            background: (state.isFocused || state.isSelected) ? '#f1f1f1' : '#fafafa',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            border: '1px solid #f1f1f1',
            paddingBottom: '0px',
            marginTop: '5px',
            width: '90%',
            height: 'auto',
            boxShadow: 'none',
            zIndex: '100',
            overflow: 'hidden',
            background: '#ffffff',
            color: '#ffffff',
        }),
        menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            width: '',
            height: 'auto',
            boxShadow: 'none',
            color: '#0e0e12',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            height: 'auto',
            boxShadow: 'none',
            borderRadius: 10,
            color: '#0e0e12',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.isFocused) ? '#0e0e12' : '#292a33',
            "&:hover": {
                color: '#0e0e12'
            }
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
        }),
    }



    const botMarketplaceRef = useRef()

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }


    const now = Date.now()


    const [time, setTime] = useState(Date.now());


    const totalPnl = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].pnl;
        }
        return total / arr.length;
    }

    const totalPnlUsdt = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + (arr[i].aum * arr[i].pnl / 100);
        }
        return total;
    }

    const totalAum = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].aum;
        }
        return total;
    }










    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())

            let ticker = Math.round(getRandomArbitrary(1, 2))

            if (ticker === 1) {
                mybots_data[0].pnl = mybots_data[0].pnl * getRandomArbitrary(0.998, 1.002)
            }
            if (ticker === 2) {
                mybots_data[2].pnl = mybots_data[2].pnl * getRandomArbitrary(0.998, 1.002)
            }

            for (let i = Math.round(getRandomArbitrary(0, 1)); i < marketplace_data.length; i = i + Math.round(getRandomArbitrary(2, 3))) {
                marketplace_data[i].pnl = marketplace_data[i].pnl * getRandomArbitrary(0.997, 1.003)
            }



        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);













    const [marketplaceSort, setMarketplaceSort] = useState({value: 'Top Rated'})
    const [marketplaceCoin, setMarketplaceCoin] = useState('')

    const [marketplaceTypes, setMarketplaceTypes] = useState(1)
    const [marketplace_data_filtered, set_marketplace_data_filtered] = useState(marketplace_data)



    const [marketplaceIsSorting, setMarketplaceIsSorting] = useState(false)


    useEffect(() => {

        setMarketplaceIsSorting(true)

        setTimeout(() => {
            marketplace_data_filtered.sort((a, b) => (a.followers < b.followers) ? 1 : -1)
            const filtered = marketplace_data.filter((item) => item.currency !== 0)
            set_marketplace_data_filtered(filtered)
        }, 310)



        setTimeout(() => {
            setMarketplaceIsSorting(false)
        }, 3000)

    }, [])


    const handleMarketplaceSortChange = (selectedSort) => {

        setMarketplaceSort(selectedSort)

        setMarketplaceIsSorting(true)

        if (selectedSort.value === 'Top Rated') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.followers < b.followers) ? 1 : -1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        } else if (selectedSort.value === 'Highest PnL') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.pnl < b.pnl) ? 1 : -1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        } else if (selectedSort.value === 'Lowest Fee') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.share < b.share) ? -1 : 1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        }
    }


    const handleMarketplaceCoinChange = (selectedCoin) => {

        setMarketplaceIsSorting(true)

        setTimeout(() => {


            setMarketplaceCoin(selectedCoin)

            let marketplace_data_filtered_123 = marketplace_data.filter((item) => item.currency.includes(selectedCoin.value))


            if (marketplaceSort.value === 'Top Rated') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.followers < b.followers) ? 1 : -1))

            } else if (marketplaceSort.value === 'Highest PnL') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.pnl < b.pnl) ? 1 : -1))

            } else if (marketplaceSort.value === 'Lowest Fee') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.share < b.share) ? -1 : 1))

            }

            set_marketplace_data_filtered(marketplace_data_filtered_123)


            /*alert(marketplaceSort.value)*/

        }, 310)

        setTimeout(() => {
            setMarketplaceIsSorting(false)
        }, 2500)
    }




    const [marketplace_data_output, set_marketplace_data_output] = useState(marketplace_data_filtered)

    useEffect(() => {


        if (marketplaceTypes === 1) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered)
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)


        } else if (marketplaceTypes === 2) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered.filter(item => {
                    return item.type === 'Spot Grid' || item.type === 'Futures Grid';
                }))
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)


        } else if (marketplaceTypes === 3) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered.filter(item => {
                    return item.type === 'DCA';
                }))
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)

        }

    }, [marketplaceTypes, marketplaceSort, marketplaceCoin])

















    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header-container black2'}>
                    <div className={'filled info-page-header'}>


                        <div className={'academy-header-h1'}>

                            <h1 className={''}>
                                Clone Trading
                            </h1>

                            <h5 className={''}>
                                {/*Зарабатывайте, иcпoльзуя oпыт и знaния пpoфeccиoнaлoв*/}
                                Earn money using the experience and knowledge of professionals
                            </h5>


                            <button onClick={() => featureNotAvailableActiveHandler()} className={'letf-header-btn earn-box-header-btn btn primary'}>
                                Clone
                            </button>

                        </div>


                        <img className={'info-page-header-img'} src={clone_trading} />

                    </div>

                    <img className={'aimonitor-header-img-abstract info-page-header-img-abstract'} src={abstract_header} />

                </div>




                <div className={'info-page-container'}>


                    <p>

                    </p>


                    <h3 className={'clone mybots-h3'}>
                        Spotlight
                    </h3>

                    <div className={'clone mybots-wrapper'}>

                        <div className={'mybots-header'}>
                            <div className={'mybots-header-left'}>
                                <h2 className={'mybots-h2'}>
                                    My Clones
                                </h2>
                            </div>

                            <div className={'mybots-header-right'}>
                                <button onClick={() => featureNotAvailableActiveHandler()} className={'btn primary'}>
                                    Manage
                                </button>
                            </div>

                        </div>


                        <p className={'mybots-p'}>
                            {/*Клонирование дeйcтвий тpeйдepa пpoизвoдитcя автоматически и oтpaжaтьcя нa вaшeм тopгoвoм cчeтe. Для кaждoгo клонируемого игpoкa oн будeт cвoй.*/}
                            Cloning of a trader's actions is performed automatically and is reflected on your trading account. For each cloned player it will be different.
                        </p>


                        <div className={'clone mybots-container-big'}>
                            <div className={'mybots-container'}>

                                {mybots_data.map((item, i) =>
                                    <div className={'clone mybots-bot'}>


                                        {/*<div className={'clone mybots-bot-user-info-container'}>

                                            <img src={myClonesData[i].userImg} />

                                            <div>
                                                <h5 className={'bold'}>
                                                    {myClonesData[i].userName}
                                                </h5>

                                                <p>
                                                    Profit Share: {myClonesData[i].userProfitShare}%
                                                </p>
                                            </div>

                                        </div>*/}

                                        {/*<div className={`light ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                            <div className={`light ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                <img
                                                    className={'light mybots-bot-img'}
                                                    src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                />
                                            </div>

                                            {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                <div className={`light scd mybots-bot-img-container`}>
                                                    <img
                                                        className={'light mybots-bot-img'}
                                                        src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                    />
                                                </div>
                                                :
                                                ''
                                            }

                                        </div>*/}


                                        <div className={'clone mybots-bot-top'}>

                                            <div className={'clone-user'}>
                                                <img className={'clone-pfp'} src={myClonesData[i].userImg} />

                                                <div>
                                                    <h3 className={'bold'}>
                                                        {myClonesData[i].userName}
                                                    </h3>
                                                    <p>
                                                        Profit Share: {myClonesData[i].userProfitShare}%
                                                    </p>
                                                </div>
                                            </div>




                                            <div className={'mybots-bot-top-type-container'}>
                                                <p className={'clone mybots-bot-top-type'}>
                                                    {item.type}
                                                </p>
                                                <br/>
                                                <p className={'clone2 mybots-bot-top-type long'} style={item.type === 'DCA' ? {} : {display: 'none'}}>
                                                    {item.strategy}
                                                </p>
                                                <p className={'clone2 mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                    {item.long ? 'Long' : 'Short'}
                                                </p>
                                                <p className={'clone2 mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                    {item.leverage.toFixed(2)}x
                                                </p>
                                            </div>



                                        </div>

                                        <div className={'mybots-bot-mid-row'}>


                                            <div className={'mybots-bot-mid-row-left'}>
                                                <p className={'mybots-bot-pnl-p'}>
                                                    PnL
                                                </p>

                                                <h3 className={'clone mybots-bot-pnl-h2'}>
                                                    +{item.pnl.toLocaleString(undefined, {maximumFractionDigits: 2})}%
                                                </h3>
                                            </div>

                                            <div className={'mybots-bot-sparkline-container'}>
                                                <div className={'mybots-bot-sparkline'}>
                                                    <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d}>
                                                        <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.6'}} color={'#1b78ff'}/>
                                                    </Sparklines>
                                                </div>
                                                <div className={'mybots-bot-sparkline-bott'}>
                                                    <Sparklines data={item.sparkline_in_7d}>
                                                        <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#1b78ff'}/>
                                                    </Sparklines>
                                                </div>
                                            </div>


                                        </div>

                                        <div className={'mybots-bot-mid-row'}>

                                            <div className={'mybots-bot-mid-row-left'}>
                                                <p className={'mybots-bot-aum-p '}>
                                                    AuM
                                                </p>

                                                <h3 className={'mybots-bot-aum-h3 '}>
                                                    {item.aum.toLocaleString()} USDT
                                                </h3>
                                            </div>

                                            <div className={'mybots-bot-mid-row-right'}>
                                                <p className={'mybots-bot-aum-p '}>
                                                    Runtime
                                                </p>

                                                <h3 className={'mybots-bot-aum-h3 '}>
                                                    {item.runtime}D {((now - item.date) / (1000*60*60) % 24).toFixed(0)}H {((now - item.date) / (1000*60) % 60).toFixed(0)}M
                                                </h3>
                                            </div>

                                        </div>


                                    </div>
                                )}

                            </div>

                            <div className={'clone mybots-stats'}>

                                <div className={'clone mybots-stats-item'}>
                                    <p className={'whitewhite'}>
                                        Total AuM
                                    </p>
                                    <h2 className={'white'}>
                                        {totalAum(mybots_data).toLocaleString()} USDT
                                    </h2>
                                    <h3 className={'white'}>
                                        12.92% of Total Net Assets
                                    </h3>
                                </div>

                                <div className={'clone mybots-stats-item'}>
                                    <p className={'whitewhite'}>
                                        Total Bots
                                    </p>
                                    <h2 className={'white'}>
                                        {mybots_data.length} Bots
                                    </h2>
                                    <h3 className={'white'}>
                                        2 Active
                                    </h3>
                                </div>

                                <div className={'clone mybots-stats-item'}>
                                    <p className={'whitewhite'}>
                                        Total PnL
                                    </p>
                                    <h2 className={'white'}>
                                        +{totalPnl(mybots_data).toFixed(2)}%
                                    </h2>
                                    <h3 className={'white'}>
                                        +{totalPnlUsdt(mybots_data).toLocaleString(undefined, {maximumFractionDigits: 2})} USDT
                                    </h3>
                                </div>

                            </div>
                        </div>




                        {/*<div className={'spotlight-add-wrapper'}>

                            <div className={'mybots-header'}>
                                <div className={'mybots-header-left'}>
                                    <h2 className={'mybots-h2'}>
                                        Add More
                                    </h2>
                                </div>

                                <div className={'mybots-header-right'}>
                                    <button onClick={() => {botMarketplaceRef.current?.scrollIntoView({block: "center"})}} className={'btn primary'}>
                                        Marketplace
                                    </button>
                                </div>

                            </div>

                            <p className={'mybots-p'}>
                                Liqui Global offers several template bots that are designed to generate profits in different market conditions and your investment goals.
                            </p>

                        </div>*/}




                    </div>























                    <div className={'trading-bots-marketplace-wrapper'}>

                        <h1 className={''}>
                            Public Portfolios
                        </h1>

                        <p className={'trading-bots-marketplace-p'}>
                            {/*Во всех сферах бизнеса есть таланты, обладающие особыми навыками и знаниями. Трейдинг не исключение.
                        Новичкам больше не обязательно торговать самостоятельно. Достаточно использовать автоматизированный Clone Trading и пoлучить шaнc зapaбoтaть, иcпoльзуя oпыт и знaния пpoфeccиoнaльных трейдеров.*/}

                            In all areas of business, there are talents with specific skills and knowledge. Trading is no exception.
                            Beginners no longer have to trade on their own. It is enough to use automated Clone Trading and get a chance to make money using the experience and knowledge of professional traders.
                        </p>

                        <div ref={botMarketplaceRef} className={'trading-bots-marketplace-types'}>
                            <div className={'trading-bots-marketplace-types-left'}>
                                <p
                                    onClick={() => setMarketplaceTypes(1)}
                                    className={`clone trading-bots-marketplace-types-item bold ${marketplaceTypes === 1 ? 'active' : ''}`}
                                >
                                    All
                                </p>
                                <p
                                    onClick={() => setMarketplaceTypes(2)}
                                    className={`clone trading-bots-marketplace-types-item bold ${marketplaceTypes === 2 ? 'active' : ''}`}
                                >
                                    Manual
                                </p>
                                <p
                                    onClick={() => setMarketplaceTypes(3)}
                                    className={`clone trading-bots-marketplace-types-item bold ${marketplaceTypes === 3 ? 'active' : ''}`}
                                >
                                    API
                                </p>
                            </div>

                            <div className={'trading-bots-marketplace-types-right'}>
                                <Select
                                    className={'trading-bots-marketplace-types-right-select'}
                                    options={clone_sort_options}
                                    onChange={handleMarketplaceSortChange}
                                    placeholder={'Top Rated'}
                                    styles={select_styles_black2}
                                    isSearchable={false}
                                />

                                <Select
                                    className={'trading-bots-marketplace-types-right-select'}
                                    options={dataCoins}
                                    onChange={handleMarketplaceCoinChange}
                                    placeholder={'All Crypto'}
                                    styles={select_styles_black2}
                                />
                            </div>

                        </div>

                        <div className={`trading-bots-marketplace-container ${marketplace_data_output.length === 0 ? 'nothing' : ''}`}>


                            {marketplace_data_output.length === 0 ?
                                <div className={`earnproducts-nothing`}>
                                    <h3 className={''}>
                                        Nothing Found Under Selected Filters
                                    </h3>

                                    {/*<button
                                            className={'btn light'}
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            Reset
                                        </button>*/}
                                </div>
                                :
                                ''
                            }


                            {
                                marketplace_data_output.slice(0, 6).map((item, i) =>
                                    <div className={`clone ${marketplaceIsSorting ? 'sorting' : ''} marketplace-bot mybots-bot`}>

                                        {/*<div className={`light mb ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                            <div className={`light ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                <img
                                                    className={'light mybots-bot-img'}
                                                    src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                />
                                            </div>

                                            {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                <div className={`light scd mybots-bot-img-container`}>
                                                    <img
                                                        className={'light mybots-bot-img'}
                                                        src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                    />
                                                </div>
                                                :
                                                ''
                                            }

                                        </div>*/}


                                        <div className={'clone marketplace-row mybots-bot-top'}>

                                            <div className={'clone-user'}>
                                                <img className={'clone-pfp'} src={myClonesData[i%myClonesData.length].userImg} />

                                                <div>
                                                    <h3 className={'bold'}>
                                                        {myClonesData[i%myClonesData.length].userName}
                                                    </h3>
                                                    <p>
                                                        Profit Share: {myClonesData[i%myClonesData.length].userProfitShare}%
                                                    </p>
                                                </div>
                                            </div>



                                            <div className={'mybots-bot-top-type-container'}>
                                                <p className={'clone mybots-bot-top-type'}>
                                                    {item.type === 'DCA' ? 'API' : 'Manual'}
                                                </p>
                                                <br/>
                                                <p className={'clone3 mybots-bot-top-type long'} style={(item.type === 'DCA') ? {display: 'none'} : {}}>
                                                    Expert
                                                </p>
                                                <p className={'clone3 mybots-bot-top-type long'} style={(item.type !== 'DCA' && item.long) ? {display: 'none'} : {}}>
                                                    Top Performer
                                                </p>
                                            </div>


                                        </div>




                                        <div className={'marketplace-row mybots-bot-mid-row'}>


                                            <div className={'mybots-bot-mid-row-left'}>
                                                <p className={'mybots-bot-pnl-p'}>
                                                    PnL
                                                </p>

                                                <h2 className={'mybots-bot-pnl-h2'}>
                                                    +{item.pnl.toLocaleString(undefined, {maximumFractionDigits: 2})}%
                                                </h2>
                                            </div>

                                            <div className={'mybots-bot-sparkline-container'}>
                                                <div className={'marketplace-bot-sparkline mybots-bot-sparkline'}>
                                                    <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d}>
                                                        <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.6'}} color={'#1b78ff'}/>
                                                    </Sparklines>
                                                </div>
                                                <div className={'marketplace-bot-sparkline-bott mybots-bot-sparkline-bott'}>
                                                    <Sparklines data={item.sparkline_in_7d}>
                                                        <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#1b78ff'}/>
                                                    </Sparklines>
                                                </div>
                                            </div>


                                        </div>

                                        <div className={'marketplace-row mybots-bot-mid-row'}>

                                            <div className={'mybots-bot-mid-row-left'}>
                                                <p className={'mybots-bot-aum-p'}>
                                                    Clones
                                                </p>

                                                <h3 className={'mybots-bot-aum-h3'}>
                                                    {myClonesData[i%myClonesData.length].userCurrentClones}/{myClonesData[i%myClonesData.length].userMaximumClones}
                                                </h3>
                                            </div>

                                            <div className={'mybots-bot-mid-row-right'}>
                                                <p className={'mybots-bot-aum-p'}>
                                                    Runtime
                                                </p>

                                                <h3 className={'mybots-bot-aum-h3'}>
                                                    {item.runtime}D {((now - item.date) / (1000*60*60) % 24).toFixed(0)}H {((now - item.date) / (1000*60) % 60).toFixed(0)}M
                                                </h3>
                                            </div>

                                        </div>


                                        <button onClick={() => featureNotAvailableActiveHandler()} className={'marketplace-row btn primary'}>
                                            Clone
                                        </button>


                                    </div>
                                )

                            }

                        </div>

                        <div className={'clone trading-bots-marketplace-place'}>

                            <h3 className={'white'}>
                                Become Copy Trader on Liqui Global
                            </h3>

                            <img className={'verify-widget-abstract2'} src={abstract_2_primary} />

                            <button onClick={() => featureNotAvailableActiveHandler()} className={'btn light'}>
                                Apply
                            </button>

                        </div>

                    </div>
















                    <div className={'lpfield-howto-container'}>

                        <h3>
                            {/*Как использовать Clone Trading?*/}
                            How to use Clone Trading?
                        </h3>

                        <div className={'lpfield-howto-item'}>
                            <div className={'lpfield-howto-step-container'}>
                                <h2 style={{marginTop: '3px', marginRight: '3px'}} className={'white lpfield-howto-step'}>
                                    1
                                </h2>
                            </div>
                            <h3 className={'lpfield-howto-desc'}>
                                {/*Нaчните c выбopa опытного инвестара, анализа его рейтинга и торговой статистики . Для клонирования сделок вам необходимо осуществить подписку c учeтoм тpeбoвaний . Paccмoтpитe статистику и характеристику его торговых стратегий— cумму eгo влoжeний, кoличecтвo пoдпиcчикoв, oзвучeнныe дoxoды и пpибыль. Cтaтиcтикa aктивнocти и oцeнки pиcкoв пoмoжeт cдeлaть пpaвильный выбop.*/}
                                Start by choosing an experienced investor, analyzing his rating and trading statistics. To clone trades, you need to subscribe according to the requirements. Review the statistics and characteristics of his trading strategies - the amount of his investments, the number of subscribers, reported income and profit. Activity statistics and risk assessments will help you make the right choice.
                            </h3>
                        </div>

                        <div className={'lpfield-howto-item'}>
                            <div className={'lpfield-howto-step-container'}>
                                <h2 style={{marginTop: '1px', marginRight: '1px'}} className={'white lpfield-howto-step'}>
                                    2
                                </h2>
                            </div>
                            <h3 className={'lpfield-howto-desc'}>
                                {/*Oпpeдeлитe cумму инвecтиpoвaния. Лучшe paздeлить финaнcы мeжду нecкoлькими трейдерами , что поможет уменьшить риски. Пocлe пoдтвepждeния выбopa сумма будет pacпpeдeлeна автоматически.*/}
                                Determine the investment amount. It is better to divide finances between several traders, which will help reduce risks. After confirming your choice, the amount will be distributed automatically.
                            </h3>
                        </div>

                        <div className={'lpfield-howto-item'}>
                            <div className={'lpfield-howto-step-container'}>
                                <h2 style={{marginTop: '3px', marginRight: '1px'}} className={'white lpfield-howto-step'}>
                                    3
                                </h2>
                            </div>
                            <h3 className={'lpfield-howto-desc'}>
                                {/*Клонирование дeйcтвий тpeйдepa пpoизвoдитcя автоматически и oтpaжaтьcя нa вaшeм тopгoвoм cчeтe. Для кaждoгo клонируемого игpoкa oн будeт cвoй.*/}
                                Cloning of a trader's actions is performed automatically and is reflected on your trading account. For each cloned player it will be different.
                            </h3>
                        </div>

                        <div className={'lpfield-howto-item'}>
                            <div className={'lpfield-howto-step-container'}>
                                <h2 style={{marginTop: '2px', marginRight: '1px'}} className={'white lpfield-howto-step'}>
                                    4
                                </h2>
                            </div>
                            <h3 className={'lpfield-howto-desc'}>
                                {/*Возможно как увeличeниe вклaдoв так cмeнa тpeйдepoв для клонирования. Moжнo клонировать aбcoлютнo вce oпepaции или пocтaвить oгpaничeниe, нaпpимep, пo кoличecтву днeвныx cдeлoк.*/}
                                It is possible to either increase deposits or change traders for cloning. You can clone absolutely all operations or set a limit, for example, on the number of daily transactions.
                            </h3>
                        </div>

                        <div className={'lpfield-howto-item'}>
                            <div className={'lpfield-howto-step-container'}>
                                <h2 style={{marginTop: '2px', marginRight: '0px'}} className={'white lpfield-howto-step'}>
                                    5
                                </h2>
                            </div>
                            <h3 className={'lpfield-howto-desc'}>
                                {/*Koмиccия зa использование сервиса формируется из комиссии Liqui Global и комисии трейдера. Плaтфopмa имeeт пpoцeнт oт цeны пoдпиcки, a трейдер— дoлю oт вашей пpибыли.*/}
                                The commission for using the service is formed from the Liqui Global commission and the trader’s commission. The platform has a percentage of the subscription price, and the trader has a share of your profit.
                            </h3>
                        </div>

                    </div>



















                    <Link to={'/'} className={'info-page-home'}>
                        {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                        <button className={'btn black2'} style={{minWidth: '150px'}}>
                            Go Home
                        </button>
                    </Link>




                    <MobileApp />



                </div>

            </div>

            <MightBeInteresting current={5} mt={true} fixed/>

            <JoinCommunity  mt0={true} />

        </div>
    );
};

export default CloneTrading;