import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createConfig, http, WagmiProvider} from "wagmi";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {bsc, mainnet} from "wagmi/chains";
import {injected, metaMask} from "wagmi/connectors";

const queryClient = new QueryClient()


export const wagmiConfig = createConfig({
    chains: [mainnet, bsc],
    connectors: [
        injected(),
        metaMask(),
    ],
    transports: {
        [mainnet.id]: http(),
        [bsc.id]: http(),
    },
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WagmiProvider config={wagmiConfig}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </React.StrictMode>
    </WagmiProvider>
);
