import React from 'react';
import {Link} from "react-router-dom";
import liquidation_shield from "../assets/liquidation_shield.svg";
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import MightBeInteresting from "../MightBeInteresting";

const LiquidationShield = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '20px', marginTop: '-5px'}} className={'info-page-header-img'} src={liquidation_shield}/>

                    <h1>
                        Liquidation Shield
                        <h5 style={{margin: '0'}}>
                            Защитите свои маржинальные позиции от ликвидации
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>

                        <div className={'info-page-1-container'}>

                            <div className={'info-page-1-left'}>

                                <h2 className={'info-page-h2'}>Используйте инструмент взаимного страхования</h2>

                                <div className={'info-page-bsect'}>
                                    <p>
                                        Создавайте p2p заявки, оказывайте помощь другим участникам рынка и зарабатывйте вместе.
                                    </p>
                                </div>



                                <h3 className={'info-page-h3'}>
                                    Защитите свои маржинальные позиции от ликвидации
                                </h3>

                                <p className={'info-page-p'}>
                                    Появилась вероятность ликвидации позиции?
                                </p>

                                <ul className={'ul-dot'}>
                                    <li>
                                        Создайте заявку на помощь, участники рынка моментально увидят её в списке
                                    </li>
                                    <li>
                                        После отклика на заявку, вся информация о позиции будет обновлена автоматически
                                    </li>
                                    <li>
                                        Вы сможете зафиксировать позиции в любой момент при достижении положительного PnL
                                    </li>
                                </ul>

                                <h3 className={'info-page-h3'}>
                                    Помогайте другим участникам рынка
                                </h3>

                                <ul className={'ul-dot'}>
                                    <li>
                                        Выбирайте заявки из списка
                                    </li>
                                    <li>
                                        Добавляйте обеспечение маржой, обезопасив чужую позицию от ликвидации
                                    </li>
                                    <li>
                                        При положительном PnL, вы автоматически будете получать прибыль от своей позициию и часть прибыли пользователя, что позицию вы обезопасили
                                    </li>
                                </ul>


                                <div className={'info-page-bsect'} style={{textAlign: 'center'}}>
                                    <p>
                                        Обезопасьте себя от ликвидации.
                                    </p>
                                </div>

                            </div>

                            <div className={'info-page-1-right'}>

                                <div className={'info-page-lrsect'}>

                                    <h3>Правила использования:</h3>

                                    <ul className={'ul-dot'} style={{paddingBottom: '0'}}>
                                        <li>
                                            Вы можете добавить обеспечение маржой на 1 заявку только 1 раз
                                        </li>
                                        <li>
                                            Максимальная сумма обеспечения не может быть больше суммы маржинальной позиции пользователя
                                        </li>
                                        <li style={{paddingBottom: '0'}}>
                                            При сильной волатильности рынка вы так же подвержены риску ликвидации
                                        </li>
                                    </ul>

                                </div>

                            </div>

                        </div>



                    </div>



                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>

            <MightBeInteresting current={4} />

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default LiquidationShield;