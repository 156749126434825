import React from 'react';
import BlogNav from "./BlogNav";
import giftbox from "../../assets/posts/giftbox.png";
import exclamation from "../../assets/exclamation.svg";
import JoinCommunity from "../components/JoinCommunity";
import {Link} from "react-router-dom";
import '../InfoPage.css'
import arrowrightblue from "../../assets/arrowrightblue.svg";

const BlogArt1 = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (

        <div className={'blog-container-wrapper'}>
            <div className={'blog-container'}>
                <BlogNav active={1}/>

                <div className={'blog-content'}>

                    <span className={'blog-content-date'}>
                        22.10.23
                    </span>

                    <h2 className={'blog-content-h2'}>
                        Exclusive NFT GiftBox for every LGT contributor
                    </h2>

                    <img className={'blog-content-img'} src={giftbox} />

                    <p className={'blog-content-p-first'}>
                        Liqui Global is one of the crypto exchanges in the volatile world of cryptocurrencies with cutting-edge tools and services. This distinguished platform is about to enter into a new phase as it unveils the Liqui Global Token (LGT) and its Exclusive NFT Giftboxes.
                    </p>


                    <h3 className={'blog-content-h3'}>
                        NFT Giftboxes: Unveiling Digital Treasures
                    </h3>

                    <p className={'blog-content-p'}>
                        As we gear up for the Liqui Global Token pre-sale, let's talk about the Exclusive NFT Giftboxes. These are not your everyday digital collectibles; they range in value from $3 to an impressive $3000. Each Giftbox is like a unique piece of art, quietly adding value to your digital collection.
                    </p>


                    <div className={'blog-content-frame'}>
                        <img src={exclamation}  className={'blog-content-frame-img'} style={{height: '38px', marginLeft: '5px', marginRight: '16px'}} />
                        <p>
                            <strong>Limited Offer.</strong> NFT Giftboxes available exclusively during the LGT <Link className={'info-page-underlined-a'} to={'/token'}>Pre-Sale round</Link>. Act promptly before this special offer ends!
                        </p>
                    </div>


                    <h3 className={'blog-content-h3'}>
                        Liqui Global Token: Empowering Your Crypto Journey
                    </h3>

                    <p className={'blog-content-p'}>
                        Liqui Global Token (LGT) is not just a token: it takes you through an enhanced experience in cryptocurrency . With features like reduced trading fees, participating in launchpads, voting rights for LGT holders and private tools access – LGT becomes a bridgehead for flawless and elevated crypto travel.
                    </p>


                    <h3 className={'blog-content-h3'}>
                        Acquiring LGT: A Simple Process
                    </h3>

                    <p className={'blog-content-p'}>
                        Getting Liqui Global Token is quite simple. As soon as the smart contract is activated, LGT will be instantly transferred into your wallet following Cliff/Vesting rules. Our commitment to creating a user-friendly and secure investment experience is demonstrated by this simplicity. To participate in Pre-sale round all you have to do is...
                    </p>

                    <ul className={'ul-decimal'}>
                        <li>
                            Fill out <Link className={'info-page-underlined-a'} to={'/token'}>Contribution form</Link>
                        </li>

                        <li>
                            Wait for the information email with details
                        </li>

                        <li>
                            Make the payment to your personal address from information email
                        </li>

                        <li>
                            Confirmation email will be sent once transaction done
                        </li>
                    </ul>

                    <div className={'blog-wide-btn-container'}>
                        <Link to={'/token'} className={'blog-wide-btn'}>
                            Receive NFT GiftBox
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                                <path
                                    fill={'#fff'}
                                    fillRule="evenodd"
                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                ></path>
                            </svg>
                        </Link>
                    </div>

                    <p className={'blog-content-p-first'}>
                        Participating in the Liqui Global Token Pre-sale not only secures your stake in LGT but also grants you ownership of an Exclusive NFT Giftbox. These digital treasures hold surprises, exclusive LGT Utility NFTs, and a touch of scarcity, elevating the value and uniqueness of your investment.
                    </p>


                    <h3 className={'blog-content-h3'}>
                        Join the Evolution of Finance
                    </h3>

                    <p className={'blog-content-p'}>
                        Liqui Global Token and the Exclusive NFT Giftboxes mark a step towards the evolution of decentralized finance. Join the Liqui Global community as we embark on this serious journey, where the focus is on substance, reliability, and the seamless integration of blockchain technology.
                    </p>

                </div>
            </div>
            <JoinCommunity />
        </div>

    );
};

export default BlogArt1;