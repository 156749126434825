import React, {useEffect, useRef, useState} from 'react';
import './Token.css'
import NavbarBlack from "../NavbarBlack";
import Footer from "../pages/components/Footer";
import {Link} from "react-router-dom";
import Timeline from "./Timeline";
import Roadmap from "./Roadmap";
import lgt_grandfeature1 from '../assets/lgt_grandfeature1.svg'
import lgt_grandfeature2 from '../assets/lgt_grandfeature2.svg'

import lgt_feature4 from '../assets/lgt_feature4.svg'
import lgt_feature1 from '../assets/lgt_feature1.svg'
import lgt_feature2 from '../assets/lgt_feature2.svg'
import lgt_feature3 from '../assets/lgt_feature3.svg'

import lgt_overview1 from '../assets/lgt_overview1.svg'
import lgt_overview2 from '../assets/lgt_overview2.svg'
import lgt_overview3 from '../assets/lgt_overview3.svg'

import lgt from '../assets/lgt.svg'
import PresaleForm from "./PresaleForm";
import Tokenomics from "./Tokenomics";
import BurningMechanism from "./BurningMechanism";
import NftGiftBoxes from "./NFTGiftBoxes";
import VerificationWidget from "../pages/components/VerificationWidget";
import CookieConsent from "../pages/components/CookieConsent";
import FaqAccordion from "../pages/components/FaqAccordion";
import MyMarquee from "../pages/components/MyMarquee";
import lgt_vesting from "../assets/lgt_vesting.svg";
import lgt_specification from "../assets/lgt_specification.svg";

import lgt_overview from '../assets/lgt_overview.svg'
import lgt_roadmap from '../assets/lgt_roadmap.svg'
import lgt_buy from '../assets/lgt_buy.svg'
import accessibility from "../assets/nft_workshop_assets/accessibility.svg";
import youdecide from "../assets/nft_workshop_assets/youdecide.svg";
import fixedprice from "../assets/nft_workshop_assets/fixedprice.svg";
import Navbar from "../pages/components/Navbar";


const Token = ({scrollTo}) => {

    /*useEffect(() => {

        document.body.scrollTo({
            top: 0,
        })

    }, [])*/

    const overviewRef = useRef()
    const buyRef = useRef()
    const giftboxRef = useRef()
    const roadmapRef = useRef()

    useEffect(() => {

        if (scrollTo === 'overview') {
            overviewRef.current?.scrollIntoView({block: "center"})
        }
        else if (scrollTo === 'buy') {
            buyRef.current?.scrollIntoView({block: "start"})
        }
        else if (scrollTo === 'giftbox') {
            giftboxRef.current?.scrollIntoView({block: "center"})
        }
        else if (scrollTo === 'roadmap') {
            roadmapRef.current?.scrollIntoView({block: "center"})
        }
        else {
            document.body.scrollTo({
                top: 0,
            })
        }

    }, [])


    const [featureInfo, setFeatureInfo] = useState(1)
    const [featureInfoText, setFeatureInfoText] = useState('Hold LGT and become VIP User. VIP Status reduces commissions and provides special privileges for Liqui Global Users')

    useEffect(() => {

        switch (featureInfoText) {
            case 1:
                setFeatureInfoText('Hold LGT and become VIP User. VIP Status reduces commissions and provides special privileges for Liqui Global Users')
                break
            case 2:
                setFeatureInfoText('Liqui Global provides assistance to new promising cryptocurrency projects that need support at the initial stage of development. Join and become an investor in crypto Launchpads')
                break
        }

    }, [featureInfo])




    return (
        <>
            <div className={'token-page'}>

                <Navbar isBlack={true} />

                <div className={'token-block1-wrapper'}>

                    {/*<img className={'token-page-img-abstract'} src={abstract1} />*/}

                    <div className={'token-block1-container'}>

                        <div className={'token-top'}>
                            <div className={'token-headers'}>
                                <h3 className={'token-h3'}>
                                    INTRODUCING
                                </h3>
                                <h1 className={'token-h1'}>
                                    Liqui Global Token
                                </h1>
                            </div>

                            <div
                                className={'token-top-round-container'}
                                onClick={() => {buyRef.current?.scrollIntoView({block: "start"})}}
                            >

                                <h3 className={'white'}>
                                    Current state
                                </h3>

                                <h2 className={'primary2'}>
                                    Pre-Sale Round
                                </h2>

                            </div>

                        </div>


                        <div className={'token-nav-container'}>

                            <div
                                className={`primary2 features-plate`}
                                onClick={() => {overviewRef.current?.scrollIntoView({block: "center"})}}
                            >

                                <img src={lgt_overview} className={'small features-plate-img'} />

                                <div className={'features-plate-text'}>
                                    <h3 className={'primary2 features-plate-h3'}>
                                        Overview
                                    </h3>

                                    <p className={''}>
                                        Tokenomics, utility, features and value of LGT
                                    </p>
                                </div>

                            </div>


                            <div
                                className={`primary2 features-plate`}
                                onClick={() => {buyRef.current?.scrollIntoView({block: "start"})}}
                            >

                                <img src={lgt_buy} className={'small features-plate-img'} />

                                <div className={'features-plate-text'}>
                                    <h3 className={'primary2 features-plate-h3'}>
                                        Buy LGT
                                    </h3>

                                    <p className={''}>
                                        Participate in LGT Pre-sale round and become one of the first investors
                                    </p>
                                </div>

                            </div>


                            <div
                                className={`primary2 features-plate`}
                                onClick={() => {roadmapRef.current?.scrollIntoView({block: "center"})}}
                            >

                                <img src={lgt_roadmap} className={'small features-plate-img'} />

                                <div className={'features-plate-text'}>
                                    <h3 className={'primary2 features-plate-h3'}>
                                        Roadmap
                                    </h3>

                                    <p className={''}>
                                        Find out about LGT development plans for the next 4 annual quarters
                                    </p>
                                </div>

                            </div>

                        </div>


                        <img className={'token-page-img-lgt'} src={lgt} />

                    </div>

                </div>



                <div className={'overview-wrapper'}>

                    <div className={'overview-container'}>

                        <div className={'token-info'}>

                            <h1 className={'token-info-h1'}>
                                Overview
                            </h1>

                            <p className={'token-feature-h5 white'}>
                                {/*LGT - официальный токен, выпущенный  Liqui Global. Используется в различных биржевых механиках и является центром всей экосистемы.*/}
                                {/*<br/>Всего будет выпущенно 550.000.000 токенов LGT.*/}
                                LGT is an official token issued by Liqui Global. It is used in various Exchange mechanisms and is the center of our Ecosystem.
                            </p>




                            <div className={'nft-marketplace-features'} ref={overviewRef} id={'overview'}>

                                <div className={'dark nft-marketplace-features-item'}>
                                    <div className={'nft-marketplace-features-item-text'}>

                                        <div className={'nft-marketplace-features-item-text-top'}>
                                            <h3 className={'white nft-marketplace-features-item-h3'}>
                                                Ecosystem
                                            </h3>
                                            <p className={'white nft-marketplace-features-item-p'}>
                                                LGT is used in the mechanisms of the Liqui Global Ecosystem and is necessary to utilize some functions of the Exchange
                                            </p>
                                        </div>

                                        <img className={'nft-marketplace-features-item-img-in'} src={lgt_grandfeature2} />

                                        <div className={'nft-marketplace-features-item-text-bot'}>
                                            <h2 className={'nft-marketplace-features-item-text-bot-h2'} style={{color: 'var(--primary4lightlight)'}}>
                                                <span style={{color: '#ccdeff'}}>Multiple</span> Use Cases
                                            </h2>
                                        </div>

                                    </div>

                                    <img className={'nft-marketplace-features-item-img'} src={lgt_grandfeature2} />
                                </div>

                                <div className={'dark nft-marketplace-features-item'}>
                                    <div className={'nft-marketplace-features-item-text'}>

                                        <div className={'nft-marketplace-features-item-text-top'}>
                                            <h3 className={'white nft-marketplace-features-item-h3'}>
                                                Liquim Blockchain
                                            </h3>
                                            <p className={'white nft-marketplace-features-item-p'}>
                                                Liquim is a high-performance blockchain developed by Liqui Global with an emphasis on smart contract programmability
                                            </p>
                                        </div>

                                        <img className={'nft-marketplace-features-item-img-in'} src={lgt_grandfeature1} />

                                        <div className={'nft-marketplace-features-item-text-bot'}>

                                            <h2 className={'primary2'}>
                                                Coming Soon
                                            </h2>

                                        </div>

                                    </div>

                                    <img className={'nft-marketplace-features-item-img'} src={lgt_grandfeature1} />

                                </div>

                            </div>




                            <Tokenomics />


                            <div className={'token-block1-plates-container'}>

                                <h2 className={'white'}>
                                    Why Liqui Global Token?
                                </h2>

                                <div className={'token-block1-plates-bg'}>

                                </div>

                                <div className={'token-block1-plates'}>

                                    <div className={'token-block1-plate'}>

                                        <img className={'token-block1-plate-img'} src={lgt_overview2} />

                                        <h3 className={'token-block1-plate-h3 white'}>
                                            {/*Применимость*/}
                                            Utility
                                        </h3>

                                        <p className={'token-block1-plate-p white'}>
                                            {/*LGT используется в механиках экосистемы Liqui Global и необходим для активации некоторых функций биржи*/}
                                            LGT is used in the mechanics of the Liqui Global Ecosystem and is necessary to activate some functions of the Exchange
                                        </p>

                                    </div>

                                    <div className={'token-block1-plate'}>

                                        <img className={'token-block1-plate-img'} src={lgt_overview1} />

                                        <h3 className={'token-block1-plate-h3 white'}>
                                            {/*Ценность*/}
                                            Value
                                        </h3>

                                        <p className={'token-block1-plate-p white'}>
                                            {/*Ценность LGT постоянно увеличивается с развитием и расширением компании Liqui Global*/}
                                            The value of LGT is constantly increasing with the development and expansion of Liqui Global Exchange
                                        </p>

                                    </div>

                                    <div className={'token-block1-plate'}>

                                        <img className={'token-block1-plate-img'} src={lgt_overview3} />

                                        <h3 className={'token-block1-plate-h3 white'}>
                                            {/*Надежность*/}
                                            Reliability
                                        </h3>

                                        <p className={'token-block1-plate-p white'}>
                                            {/*Мы обеспечили LGT высокой ликвидностью и заботимся о цене токена, используя различные механизмы ценовой поддержки*/}
                                            We've provided LGT with high liquidity and take care of the price of the token using various price support mechanisms
                                        </p>

                                    </div>

                                </div>




                                <div className={'features-wrapper'}>

                                    {/*<h2 className={'features-info-h2'}>
                                        Features
                                    </h2>*/}

                                    <div className={'features-container'}>

                                        <div className={'features-info'}>

                                            <div className={'features-plates'}>

                                                <div
                                                    className={`${featureInfo === 1 ? 'active' : ''} bold features-plate`}
                                                    onMouseEnter={() => setFeatureInfo(1)}
                                                >

                                                    <div className={'features-plate-bar'}></div>


                                                    <div className={'bold features-plate-text'}>

                                                        <h3 className={'features-plate-h3'}>
                                                            {/*Снижение торговых комиссий*/}
                                                            Reduced trading commissions

                                                        </h3>

                                                        <p className={'white features-plate-p'}>
                                                            Hold Liqui Global Token and become VIP User. VIP Status reduces commissions and provides special privileges for Liqui Global Users. The more LGT the less the comission!
                                                        </p>

                                                    </div>

                                                    <img src={lgt_feature1} className={'bold features-plate-img'} />

                                                    {/*<p className={'features-plate-p'}>
                                            Голосуйте за будущие решения на платформе и за листинг новых монет. Участвуйте в жизни Liqui Global.
                                        </p>*/}

                                                </div>

                                                <div
                                                    className={`${featureInfo === 2 ? 'active' : ''} bold features-plate`}
                                                    onMouseEnter={() => setFeatureInfo(2)}
                                                >

                                                    <div className={'features-plate-bar'}></div>


                                                    <div className={'bold features-plate-text'}>

                                                        <h3 className={'features-plate-h3'}>
                                                            {/*Участие в лаунчпадах*/}
                                                            Contribution In Launchpads
                                                        </h3>

                                                        <p className={'white features-plate-p'}>
                                                            Liqui Global provides assistance to new promising cryptocurrency projects that need support at the initial stage of development. Join and become an investor in crypto Launchpads
                                                        </p>

                                                    </div>

                                                    <img src={lgt_feature2} className={'bold features-plate-img'} />

                                                    {/*<p className={'features-plate-p'}>
                                            Голосуйте за будущие решения на платформе и за листинг новых монет. Участвуйте в жизни Liqui Global.
                                        </p>*/}

                                                </div>


                                                <div
                                                    className={`${featureInfo === 4 ? 'active' : ''} bold features-plate`}
                                                    onMouseEnter={() => setFeatureInfo(4)}
                                                >

                                                    <div className={'features-plate-bar'}></div>

                                                    <div className={'bold features-plate-text'}>

                                                        <h3 className={'features-plate-h3'}>
                                                            {/*Доступ к приватным инструментам и функциям биржи*/}
                                                            Access To Private Tools
                                                        </h3>

                                                        <p className={'white features-plate-p'}>
                                                            LGT is such a big part of Liqui Global Ecosystem that there are some tools that you can use only with Liqui Global Token. Discover the wonderful world of crypto trading and investing with LGT!
                                                        </p>

                                                    </div>

                                                    <img src={lgt_feature3} className={'bold features-plate-img'}/>

                                                    {/*<p className={'features-plate-p'}>
                                            Голосуйте за будущие решения на платформе и за листинг новых монет. Участвуйте в жизни Liqui Global.
                                        </p>*/}

                                                </div>



                                                <div
                                                    className={`${featureInfo === 3 ? 'active' : ''} bold features-plate`}
                                                    onMouseEnter={() => setFeatureInfo(3)}
                                                >

                                                    <div className={'features-plate-bar'}></div>

                                                    <div className={'bold features-plate-text'}>

                                                        <h3 className={'features-plate-h3'}>
                                                            {/*Участие в голосованиях*/}
                                                            Participation In Voting
                                                        </h3>

                                                        <p className={'white features-plate-p'}>
                                                            Take part in voting for new coin listings to support your favourite project and help them become part of Liqui Global Family.
                                                            Vote with LGT!
                                                        </p>

                                                    </div>

                                                    <img src={lgt_feature4} className={'bold features-plate-img'} />

                                                    {/*<p className={'features-plate-p'}>
                                            Голосуйте за будущие решения на платформе и за листинг новых монет. Участвуйте в жизни Liqui Global.
                                        </p>*/}

                                                </div>


                                            </div>


                                        </div>

                                    </div>

                                </div>







                            </div>


                        </div>

                    </div>

                </div>



                <div className={'presale-page-wrapper'}>

                    <div className={'presale-page-container'}>

                        <h1 className={'presale-page-h1'}>
                            Pre-sale
                        </h1>

                        <Timeline />

                        <div ref={buyRef}>
                            <PresaleForm />
                        </div>

                        <div ref={giftboxRef}>
                            <NftGiftBoxes />
                        </div>

                        <div ref={roadmapRef}>
                            <Roadmap />
                        </div>

                        <FaqAccordion isBlack={true} />

                        {/*<div style={{marginTop: 'calc(0px - var(--blockmargin))', marginBottom: 'var(--blockmargin)'}}>
                            <VerificationWidget />
                        </div>*/}

                    </div>
                </div>

                {/*<CookieConsent light={false} />*/}

            </div>

            <Footer dark={true} />
        </>

    );
};

export default Token;