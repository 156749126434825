import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './InfoPage.css'
import './FeesLimits.css'
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import fees from '../assets/fees.svg'
import one from '../assets/feeslimits/1.svg'
import two from '../assets/feeslimits/2.svg'
import three from '../assets/feeslimits/3.svg'
import mystatus from '../assets/feeslimits/mystatus.svg'
import prime from '../assets/feeslimits/prime.svg'
import VerificationWidget from "./components/VerificationWidget";
import crypto_academy from "../assets/crypto_academy_dark.svg";

const Fees = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })


    const [futuresToggle, setFuturesToggle] = useState(1)


    const spot = 4721495.1893
    const futures = 1905701.5871
    const options = 83037.1449
    const spreads = 21854.3911
    const assets = 150682.7601





    const [why, setWhy] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [nameDirty, setNameDirty] = useState(false)
    const [contactDirty, setContactDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [contactError, setContactError] = useState('')
    const [formValid, setFormValid] = useState(false)


    const whyHandler = (e) => {
        setWhy(e.target.value)
    }

    const nameHandler = (e) => {
        setName(e.target.value)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
    }

    const contactHandler = (e) => {
        setContact(e.target.value)
    }


    const emailErrorHandler = (e) => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Please enter a valid email address')
        } else {
            setEmailError('')
        }

        if (e.target.value === '') {
            setEmailError('')
        }

    }

    const nameErrorHandler = (e) => {
        if (nameDirty && e.target.value.trim().length === 0) {
            setNameError('Please enter your name')
        } else {
            setNameError('')
        }
    }

    const contactErrorHandler = (e) => {
        if (contactDirty && e.target.value.trim().length === 0) {
            setContactError('Please enter contact method')
        } else {
            setContactError('')
        }
    }




    const [copyState, setCopyState] = useState(0)


    const [less, setLess] = useState(true)



    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }



    return (
        <div className={'info-page'}>

            <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
            </div>

            <div className={'info-page-wrapper'}>

                <div className={'feeslimits-header info-page-header'}>

                    <h1>
                        Fees and Limits
                        <h5 style={{opacity: 1}}>
                            Upgrade Status, Reduce Fees and Extend Limits
                        </h5>


                        <div className={'feeslimits-header-btns-container'}>
                            <button className={'btn flexible primary'}>
                                Apply For Prime VIP
                            </button>

                            <button className={'btn flexible dark'}>
                                VIP+1
                            </button>
                        </div>


                    </h1>


                    <img className={'feeslimits-header-img'} src={fees}/>


                </div>

                <div className={'info-page-container'}>


                    <div className={'feeslimits-mystatus'}>

                        <div className={'feeslimits-mystatus-left'}>

                            <img className={'feeslimits-mystatus-img'} src={mystatus}/>

                            <div className={'feeslimits-mystatus-left-text'}>

                                <div className={'feeslimits-mystatus-left-text-top'}>
                                    <p className={'white'}>
                                        My Status
                                    </p>

                                    <h2 className={'white'}>
                                        VIP3
                                    </h2>
                                </div>

                                <div className={'feeslimits-mystatus-left-text-top'}>
                                    <p className={'white'}>
                                        Verification
                                    </p>

                                    <h3 className={'white'}>
                                        Extended
                                    </h3>
                                </div>

                            </div>

                        </div>



                        <div className={'feeslimits-mystatus-center'}>

                            <p className={'white feeslimits-mystatus-center-top'}>
                                30-Day Trading Volume (as of Yesterday)
                            </p>

                            <div className={`feeslimits-mystatus-center-bott ${less === true ? 'less' : ''}`}>

                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Spot
                                    </p>

                                    <h3 className={'white'}>
                                        {spot.toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>

                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Futures
                                    </p>

                                    <h3 className={'white'}>
                                        {futures.toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>




                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Total Volume
                                    </p>

                                    <h3 className={'white'}>
                                        {(spot + futures + options + spreads).toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>




                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Options
                                    </p>

                                    <h3 className={'white'}>
                                        {options.toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>

                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Spreads
                                    </p>

                                    <h3 className={'white'}>
                                        {spreads.toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>






                                <div className={'feeslimits-mystatus-center-bott-item'}>
                                    <p className={'white'}>
                                        Assets
                                    </p>

                                    <h3 className={'white'}>
                                        {assets.toLocaleString(undefined, {
                                            minimumFractionDigits: 4,
                                            maximumFractionDigits: 4
                                        })} USD
                                    </h3>
                                </div>

                            </div>


                            <button onClick={() => setLess(!less)} className={'btn light feeslimits-mystatus-btn'}>
                                {less ? 'Show More...' : 'Show Less'}
                            </button>



                        </div>

                        <div className={'feeslimits-mystatus-right'}>

                        </div>



                    </div>




                    <div className={'info-page-text feeslimits-text'}>

                        <h2 className={'info-page-h2'}>
                            Trading Fees
                        </h2>

                        <p className={'info-page-p light'}>
                            Before 2:00 (UTC+0) every day, the system will automatically calculate the last 30 days trading volume and 24 hours average holding volume, while updating the user's VIP level and rates. After the update, you can enjoy the corresponding rates in the rate table based on the new level and keep it for 30 days without downgrading.
                        </p>


                        <div className={'coins-market-gainer-toggle'}>

                            <div onClick={() => setFuturesToggle(1)} className={'coins-market-gainer-toggle-item'}>
                                <h3 className={`coins-market-gainer-h3 ${futuresToggle === 1 ? 'active' : ''}`}>
                                    Spot
                                </h3>
                                <div className={`coins-market-gainer-toggle-underline ${futuresToggle === 1 ? 'active' : ''}`}></div>
                            </div>

                            <div onClick={() => setFuturesToggle(2)} className={'coins-market-gainer-toggle-item'}>
                                <h3 className={`coins-market-gainer-h3 ${futuresToggle === 2 ? 'active' : ''}`}>
                                    Futures
                                </h3>
                                <div className={`coins-market-gainer-toggle-underline ${futuresToggle === 2 ? 'active' : ''}`}></div>
                            </div>

                        </div>


                        <div className={'fees-table-container'}>

                            <div className={'fees-table'}>
                                <div>
                                    <p>Level</p>
                                    <p>30D Total Trading Vol. (USDT)</p>
                                    <p>Or</p>
                                    <p>Yesterday's Asset Value (USDT)</p>
                                    <p>Maker Fee</p>
                                    <p>Taker Fee</p>
                                </div>

                                <div>
                                    <p>VIP0</p>
                                    <p>-</p>
                                    <p>/</p>
                                    <p>-</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.1000%'
                                            :
                                            '0.0200%'
                                        }
                                    </p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.1000%'
                                            :
                                            '0.0500%'
                                        }
                                    </p>
                                </div>

                                <div>
                                    <p>VIP1</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 1,000,000'
                                            :
                                            '≥ 10,000,000'
                                        }
                                    </p>
                                    <p>/</p>
                                    <p>≥ 50,000</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0350%'
                                            :
                                            '0.0140%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0600%'
                                            :
                                            '0.0400%'
                                        }
                                    </p>
                                </div>

                                <div>
                                    <p>VIP2</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 2,000,000'
                                            :
                                            '≥ 20,000,000'
                                        }
                                    </p>
                                    <p>/</p>
                                    <p>≥ 200,000</p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0200%'
                                            :
                                            '0.0120%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0500%'
                                            :
                                            '0.0375%'
                                        }
                                    </p>

                                </div>

                                <div>
                                    <p>VIP3</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 4,000,000'
                                            :
                                            '≥ 50,000,000'
                                        }
                                    </p>
                                    <p>/</p>
                                    <p>≥ 1,000,000</p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0150%'
                                            :
                                            '0.0100%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0500%'
                                            :
                                            '0.0350%'
                                        }
                                    </p>

                                </div>

                                <div>
                                    <p>VIP4</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 6,000,000'
                                            :
                                            '≥ 100,000,000'
                                        }
                                        <br/>
                                        (API Trading Volume ≤ 20%)
                                    </p>
                                    <p>/</p>
                                    <p>≥ 2,000,000</p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0125%'
                                            :
                                            '0.0080%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0375%'
                                            :
                                            '0.0315%'
                                        }
                                    </p>

                                </div>

                                <div>
                                    <p>VIP5</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 8,000,000'
                                            :
                                            '≥ 200,000,000'
                                        }
                                        <br/>
                                        (API Trading Volume ≤ 20%)</p>
                                    <p>/</p>
                                    <p>≥ 3,000,000</p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0100%'
                                            :
                                            '0.0060%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0325%'
                                            :
                                            '0.0300%'
                                        }
                                    </p>

                                </div>

                                <div>
                                    <p>Prime VIP</p>
                                    <p>
                                        {futuresToggle === 1 ?
                                            '≥ 15,000,000'
                                            :
                                            '≥ 600,000,000'
                                        }
                                        <br/>
                                        (API Trading Volume ≤ 20%)</p>
                                    <p>/</p>
                                    <p>-</p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0050%'
                                            :
                                            '0.0000%'
                                        }
                                    </p>

                                    <p>
                                        {futuresToggle === 1 ?
                                            '0.0200%'
                                            :
                                            '0.0280%'
                                        }
                                    </p>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className={'feeslimits-limits-wrapper'}>

                    <div className={'info-page-container'}>

                        <h2 className={'feeslimits-limits-h2 white'}>
                            24-Hour Withdrawal Limits

                            <p className={'white'}>
                                Лимит на вывод средств зависит от уровня верификации и обновляется ежедневно в 2:00 (UTC+0)
                            </p>
                        </h2>

                            <div className={'limits-table'}>

                                <div className={'limits-table-item'}>
                                    <img className={'feeslimits-limits-img'} src={one}/>
                                    <h3 className={'white'}>Не верифицирован</h3>
                                    <p className={'whitewhite bold'}>&lt; 2 BTC</p>
                                </div>

                                <div className={'limits-table-item'}>
                                    <img className={'feeslimits-limits-img'} src={two}/>
                                    <h3 className={'white'}>Базовая верификация</h3>
                                    <p className={'whitewhite bold'}>&lt; 200 BTC</p>
                                </div>

                                <div className={'limits-table-item'}>
                                    <img className={'feeslimits-limits-img'} src={three}/>
                                    <h3 className={'white'}>Расширенная верификация</h3>
                                    <p className={'whitewhite bold'}>Unlimited</p>
                                </div>

                            </div>





                    </div>



                </div>




                <div className={'feeslimits-apply-wrapper preliminary-listing-wrapper'}>
                    <div className={'end preliminary-listing-container'}>

                        <img src={prime} className={'preliminary-listing-img'} style={{height: '420px'}}/>


                        <div className={'preliminary-listing-text'}>

                            <div>
                                <h2 className={'preliminary-listing-h2'}>
                                    Apply For <span style={{color: '#1b78ff'}}>Prime VIP</span> Status
                                </h2>

                                <h5 className={'preliminary-listing-h5'}>
                                    Our Service Team will reach out to you if you're qualified
                                </h5>

                            </div>

                            <img src={prime} className={'preliminary-listing-img-in'} />

                            <div className={'preliminary-listing-block'}>

                                <div className={'feeslimits-input-container'}>
                                    <div className={'double-input-container form-page-text-input-container'} style={{marginTop: '0', width: '100%'}}>
                                        <p className={'input-p'}>
                                            Your full name<span className={'form-page-important-star'}>*</span>
                                        </p>
                                        <input
                                            onClick={() => setNameDirty(true)}
                                            onChange={e => {nameHandler(e); nameErrorHandler(e)}}
                                            className={'double-input input lightlight form-page-text-input1'}
                                            value={name}
                                            name={'name'}
                                            type={'text'}
                                            placeholder={'Satoshi Nakamoto'}
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className={'double-input-container form-page-text-input-container'} style={{marginTop: '0', width: '100%'}}>
                                        <p className={'input-p'}>
                                            Email<span className={'form-page-important-star'}>*</span>
                                        </p>
                                        <input
                                            onClick={() => setEmailDirty(true)}
                                            onChange={e => {emailHandler(e); emailErrorHandler(e)}}
                                            className={'double-input input lightlight form-page-text-input1'}
                                            value={email}
                                            name={'email'}
                                            type={'email'}
                                            placeholder={'satoshi@nakamoto.com'}
                                            autoComplete={'off'}
                                        />
                                    </div>
                                </div>


                                <div className={'double-input-container form-page-text-input-container'} style={{width: '100%'}}>
                                    <p className={'input-p'}>
                                        How to contact you?<span className={'form-page-important-star'}>*</span>
                                    </p>
                                    <input
                                        onClick={() => setContactDirty(true)}
                                        onChange={e => {contactHandler(e); contactErrorHandler(e)}}
                                        className={'input lightlight form-page-text-input1'}
                                        style={{width: '100%', maxWidth: '100%'}}
                                        value={contact}
                                        name={'contact'}
                                        type={'email'}
                                        placeholder={'Contact method'}
                                        autoComplete={'off'}
                                    />
                                </div>


                                <div className={'form-page-text-input-container'} style={{width: '100%'}}>
                                    <p className={'input-p'}>
                                        Tell us why you want to get Prime VIP status?
                                    </p>
                                    <textarea
                                        onChange={e => whyHandler(e)}
                                        className={'input lightlight form-page-text-input1 form-page-text-textarea'}
                                        style={{width: '100%', maxWidth: '100%'}}
                                        value={why}
                                        name={'why'}
                                        type={'text'}
                                        rows={2}
                                        placeholder={'I am qualified to obtain Prime VIP status because..'}
                                        autoComplete={'off'}
                                    />
                                </div>

                                <div className={'feeslimits-form-btn-wrapper'}>
                                    <div className={'feeslimits-form-btn-container'}>
                                        <p style={{marginBottom: '5px'}} className={'error input-p'}>{emailError || nameError || contactError}&nbsp;</p>

                                        <button onClick={() => featureNotAvailableActiveHandler()} className={'btn primary apply-btn'} style={{marginTop: '0px'}}>
                                            Apply
                                        </button>
                                    </div>


                                    <div className={'feeslimits-form-btn-container'}>
                                        <p style={{marginBottom: '5px'}} className={'input-p'}>Service Team Official Channel</p>

                                        <div className={'feeslimits-form-btn-container-little'}>

                                            <a href={'mailto:service@liqui.global'}>
                                                <button onClick={() => featureNotAvailableActiveHandler()} className={'btn flexible light nohover'}>
                                                    service@liqui.global
                                                </button>
                                            </a>

                                            {/*<button
                                                onClick={() => {
                                                    navigator.clipboard.writeText('service@liqui.global')
                                                    setCopyState(1)
                                                    }}
                                                className={`btn flexible ${copyState === 0 ? 'dark' : 'light'} nohover copy-btn`}
                                            >
                                                {copyState === 0 ? 'Copy' : 'Copied'}
                                            </button>*/}

                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>

                    </div>
                </div>




                <div className={'info-page-container'} style={{marginTop: '0', marginBottom: '80px'}}>

                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>


            </div>

        </div>
    );
};

export default Fees;