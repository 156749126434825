import React from 'react';
import {Link} from "react-router-dom";
import './VerificationWidget.css'
import abstract_verify from "../../assets/abstract_verify.svg";

const VerificationWidget = ({mt = 60}) => {
    return (
        <div className={'verify-widget-wrapper'} style={{marginTop: `${mt}px`}}>

            <div className={'verify-widget-container'}>

                <h2 className={'verify-widget-header'}>
                    Media Official Verification
                </h2>

                <p>
                    Check if contact is authenticated source
                </p>

                <Link
                    className={'btn light verify-button'}
                    to={'/verify'}
                    style={{width: '120px', marginTop: '20px', zIndex: '1'}}
                >
                    <div>
                        Check
                    </div>
                </Link>
                <img className={'verify-widget-abstract1'} src={abstract_verify} />
            </div>



        </div>
    );
};

export default VerificationWidget;