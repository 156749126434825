import React from 'react';
import './FeesLimitsHome.css'
import fees from '../../assets/fees.svg'
import {Link} from "react-router-dom";
import arrowRightBlue from "../../assets/arrowrightblue.svg";

const FeesLimitsHome = () => {
    return (
        <div className={'feeslimitshome-wrapper'}>

            <div className={'feeslimitshome-container'}>

                <div className={'feeslimitshome-text'}>

                    <div className={'feeslimitshome-text-top'}>
                        <h3>
                            Learn about Trading Fees and Withdrawal Limits
                        </h3>

                        <p>
                            Upgrade status, reduce fees and extend limits
                        </p>
                    </div>


                    {/*<Link to={'/fees-and-limits'}>
                        <button className={'btn primary feeslimitshome-btn'}>
                            Discover
                        </button>
                    </Link>*/}

                    {/*<img src={arrowRightBlue} className={'feeslimitshome influencer-arrow'} />*/}

                </div>

                {/*<img src={fees} className={'feeslimitshome-img'} />*/}

                <Link to={'/fees-and-limits'}>
                    <button className={'btn primary'}>
                        Learn More
                    </button>
                </Link>

            </div>

        </div>
    );
};

export default FeesLimitsHome;