import React from 'react';
import './BurningMechanism.css'
import lgt_burning_mechanism from "../assets/lgt_burning_mechanism.svg";
import help_icon from "../assets/wallets/help.svg";
import {Link} from "react-router-dom";

const BurningMechanism = () => {
    return (
        <div className={'preliminary-listing-wrapper'}>
            <div className={'preliminary-listing-container'}>

                <img src={lgt_burning_mechanism} className={'preliminary-listing-img'} />

                <div className={'preliminary-listing-text'}>

                    <div>
                        <h2 className={'preliminary-listing-h2 white'}>
                            Burning Mechanism
                        </h2>

                        <h5 className={'preliminary-listing-h5 white'}>
                            {/*При резких падениях цены и сильной волатильности рынка, будет произведен автоматический выкуп токенов LGT. Токены в последствии будут сожжены*/}
                            {/*In case of sharp price drops and strong market volatility, LGT tokens will be automatically repurchased. Tokens will subsequently be burned*/}
                        </h5>
                    </div>

                    <img src={lgt_burning_mechanism} className={'burning-mechanism-img preliminary-listing-img-in'} />

                    <div className={'burning-mechanism-block preliminary-listing-block'}>

                        <div className={'preliminary-listing-block-text'}>

                            <div className={'preliminary-listing-block-item'}>
                                <h2 className={'burning-mechanism-h3 white'}>
                                    15%
                                </h2>

                                <div className={'burning-mechanism-bar fifteen'}>
                                    <div></div>
                                </div>

                                <p className={'burning-mechanism-p white bold'}>
                                    {/*Выручки Liqui Global будет использовано для ежеквартаильного выкупа и сжигания LGT*/}
                                    of Liqui Global's revenue will be used for quarterly repurchase and burning of LGT
                                </p>
                            </div>

                            <div className={'preliminary-listing-block-item'}>
                                <h2 className={'burning-mechanism-h3 white'}>
                                    25%
                                    <div className={'help-window-container'}>
                                        <img src={help_icon} />
                                        <div className={'help-window-in'}>
                                            <p className={'white'}>
                                                In case of sharp price drops and strong market volatility, LGT tokens will be automatically repurchased. Tokens will subsequently be burned.
                                            </p>
                                        </div>
                                    </div>
                                </h2>

                                <div className={'burning-mechanism-bar twentyfive'}>
                                    <div></div>
                                    <div></div>
                                </div>

                                <p className={'burning-mechanism-p white bold'}>
                                    {/*Выручки Liqui Global будут ежеквартально добавлены в пулл поддержки цены LGT.*/}
                                    of Liqui Global's revenue will be added quarterly to the LGT price support pool.
                                </p>
                            </div>



                        </div>

                    </div>

                    {/*<div className={'burning-mechanism-block preliminary-listing-block'}>*/}

                        <button className={'btn primary2filled2 full'} style={{marginTop: '12px'}}>
                            Buy Merchandise And Burn LGT
                        </button>

                    {/*</div>*/}

                </div>

            </div>
        </div>
    );
};

export default BurningMechanism;