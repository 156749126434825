import React, {useEffect, useRef, useState} from 'react';
import './FaqAccordion.css'
import plus from '../../assets/plus.svg'
import minus from '../../assets/minus.svg'
import LgtPresale from "./LgtPresale";
import {Link} from "react-router-dom";

const FaqAccordion = ({isBlack}) => {

    const [selected, setSelected] = useState(null)
    const [heightEl1, setHeightEl1] = useState()
    const [heightEl2, setHeightEl2] = useState()
    const [heightEl3, setHeightEl3] = useState()
    const [heightEl4, setHeightEl4] = useState()
    const [heightEl5, setHeightEl5] = useState()

    const toggle = i => {
        if (selected === i) {
            setSelected(null)
        } else {
            setSelected(i)
        }
    }

    const refHeight1 = useRef()
    const refHeight2 = useRef()
    const refHeight3 = useRef()
    const refHeight4 = useRef()
    const refHeight5 = useRef()

    useEffect(() => {

        setHeightEl1(refHeight1.current.scrollHeight)
        setHeightEl2(refHeight2.current.scrollHeight)
        setHeightEl3(refHeight3.current.scrollHeight)
        setHeightEl4(refHeight4.current.scrollHeight)
        setHeightEl5(refHeight5.current.scrollHeight)

    }, [selected])

    return (


        <div className={'faq-accordion-wrapper'}>

            <div className={'faq-accordion-container'}>



                <div className={isBlack ? 'faq-accordion-item black' : 'faq-accordion-item'} onClick={() => toggle(1)}>

                    <div
                        className={'faq-accordion-item-header'}
                    >

                        <h3 className={'faq-accordion-item-h3'}>
                            What Is Liqui Global?
                        </h3>

                        {selected === 1
                            ?
                            <h3 className={'faq-accordion-item-minus'}>&#8722;</h3>
                            :
                            <h3 className={'faq-accordion-item-plus'}>+</h3>
                        }

                    </div>

                    <p
                        ref={refHeight1}
                        style={selected === 1 ? {height: `${heightEl1}px`} : {height: '0px'}}
                        className={selected === 1 ? 'faq-accordion-item-content show' : 'faq-accordion-item-content'}
                    >

                        <div className={isBlack ? 'faq-accordion-item-line black' : 'faq-accordion-item-line'}></div>
                        <br/>
                        {/*Криптовалютная платформа, предлагающая пользователям уникальные инструменты и сервисы высочайшего уровня.*/}
                        A cryptocurrency platform that offers users tools and services of the highest level.
                        <br/>
                        {/*Некогда популярная криптовлютная биржа Liqui.io была перезапущена, чтобы вновь стать лучшей криптовалютной платформой*/}
                        The once popular cryptocurrency exchange Liqui.io has been relaunched to once again become the world's best cryptocurrency platform.


                        {/*<Link to={'/legacy'} className={'faq-accordion-item-btn-container'}>
                            <button className={'btn primary faq-accordion-item-btn'}>
                                Legacy
                            </button>
                        </Link>*/}

                    </p>

                </div>



            <div className={isBlack ? 'faq-accordion-item black' : 'faq-accordion-item'} onClick={() => toggle(2)}>

                    <div
                        className={'faq-accordion-item-header'}
                    >

                        <h3 className={'faq-accordion-item-h3'}>
                            What Is LGT?
                        </h3>

                        {selected === 2
                            ?
                            <h3 className={'faq-accordion-item-minus'}>&#8722;</h3>
                            :
                            <h3 className={'faq-accordion-item-plus'}>+</h3>
                        }

                    </div>

                    <p
                        ref={refHeight2}
                        style={selected === 2 ? {height: `${heightEl2}px`} : {height: '0px'}}
                        className={selected === 2 ? 'faq-accordion-item-content show' : 'faq-accordion-item-content'}
                    >

                        <div className={isBlack ? 'faq-accordion-item-line black' : 'faq-accordion-item-line'}></div>
                        <br/>
                        {/*Liqui Global Token (LGT) - официальный токен, выпущенный Liqui Global. Является центрой всей экосистемы и используется в различных биржевых механниках.*/}
                        Liqui Global Token (LGT) is an official token issued by Liqui Global. It is the center of our ecosystem and is used in various stock exchange mechanisms.
                        <br/>
                        <span className={'bold'}>
                            {/*Всего будет выпущенно 550.000.000 токенов LGT*/}
                            A total of 550,000,000 LGT tokens will be issued
                        </span>

                        {/*<Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                            <button className={'btn dark faq-accordion-item-btn'}>
                                Features
                            </button>
                        </Link>*/}
                    </p>

                </div>



            <div className={isBlack ? 'faq-accordion-item black' : 'faq-accordion-item'} onClick={() => toggle(3)}>


                <div
                    className={'faq-accordion-item-header'}
                >

                    <h3 className={'faq-accordion-item-h3'}>
                        What Is LGT Price?
                    </h3>

                    {selected === 3
                        ?
                        <h3 className={'faq-accordion-item-minus'}>&#8722;</h3>
                        :
                        <h3 className={'faq-accordion-item-plus'}>+</h3>
                    }

                </div>

                <p
                    ref={refHeight3}
                    style={selected === 3 ? {height: `${heightEl3}px`} : {height: '0px'}}
                    className={selected === 3 ? 'faq-accordion-item-content show' : 'faq-accordion-item-content'}
                >

                    <div className={isBlack ? 'faq-accordion-item-line black' : 'faq-accordion-item-line'}></div>
                    <br/>
                    {/*В настоящий момент, цена LGT является фиксированной и зависит от активного инвестиционного раунда. После проведения инвестиционных раундов, цена LGT будет определена рынком*/}
                    Currently, the price of LGT is fixed and depends on the active investment round. After investment rounds end, the price of LGT will be determined by the market.

                    {/*<Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                        <button className={'btn dark faq-accordion-item-btn'}>
                            Tokenomics
                        </button>
                    </Link>*/}
                </p>


            </div>



            <div className={isBlack ? 'faq-accordion-item black' : 'faq-accordion-item'} onClick={() => toggle(4)}>

                <div
                    className={'faq-accordion-item-header'}
                >

                    <h3 className={'faq-accordion-item-h3'}>
                        How to Buy LGT?
                    </h3>

                    {selected === 4
                        ?
                        <h3 className={'faq-accordion-item-minus'}>&#8722;</h3>
                        :
                        <h3 className={'faq-accordion-item-plus'}>+</h3>
                    }

                </div>

                <p
                    ref={refHeight4}
                    style={selected === 4 ? {height: `${heightEl4}px`} : {height: '0px'}}
                    className={selected === 4 ? 'faq-accordion-item-content show' : 'faq-accordion-item-content'}
                >

                    <div className={isBlack ? 'faq-accordion-item-line black' : 'faq-accordion-item-line'}></div>
                    <br/>
                    {/*Вы можете купить LGT в рамкам проведения инвестиционных раундов.  В настоящий момент активным является <Link className={'faq-accordion-presale-link'} to={'/token'}>Pre-sale round</Link>*/}
                    You can buy LGT as part of investment rounds. <Link className={'faq-accordion-presale-link bold'} to={'/token/buy'}>Pre-sale</Link> round is currently active.

                    {/*<Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                        <button className={'btn dark faq-accordion-item-btn'}>
                            Buy LGT
                        </button>
                    </Link>*/}
                </p>

            </div>



            <div className={isBlack ? 'faq-accordion-item black' : 'faq-accordion-item'} onClick={() => toggle(5)}>

                    <div
                        className={'faq-accordion-item-header'}
                    >

                        <h3 className={'faq-accordion-item-h3'}>
                            How Do I Get LGT?
                        </h3>

                        {selected === 5
                            ?
                            <h3 className={'faq-accordion-item-minus'}>&#8722;</h3>
                            :
                            <h3 className={'faq-accordion-item-plus'}>+</h3>
                        }

                    </div>

                    <p
                        ref={refHeight5}
                        style={selected === 5 ? {height: `${heightEl5}px`} : {height: '0px'}}
                        className={selected === 5 ? 'faq-accordion-item-content show' : 'faq-accordion-item-content'}
                    >

                        <div className={isBlack ? 'faq-accordion-item-line black' : 'faq-accordion-item-line'}></div>
                        <br/>
                        {/*После деплоя LGT смарт-контракта, клейм будет будет доступен на указанный вами кошелек в соответствии с условиями<span className={'bold'}> Claim/Vesting</span>*/}
                        After deploying the LGT smart contract, claim will be available on the wallet you specified in accordance with the terms of <Link className={'faq-accordion-presale-link bold'} to={'/token/buy'}>Claim/Vesting.</Link>

                        {/*<Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                            <button className={'btn dark faq-accordion-item-btn'}>
                                Vesting Rules
                            </button>
                        </Link>*/}

                    </p>

                </div>



            </div>

        </div>
    );
};

export default FaqAccordion;