import React from 'react';
import {useState, useEffect} from "react";
import './AiGemMonitor.css'
import {Link} from "react-router-dom";
import ai_gem_monitor from "../assets/ai_gem_monitor.svg";
import ai_gem_monitor_big from "../assets/ai_gem_monitor/ai_gem_monitor_big.svg";
import MightBeInteresting from "../MightBeInteresting";
import JoinCommunity from "./components/JoinCommunity";
import axios from "axios";
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";


import abstract_primary from "../assets/abstract_primary.svg";

import timings from "../assets/ai_gem_monitor/timings.svg";
import prospects from "../assets/ai_gem_monitor/prospects.svg";

import glass from "../assets/ai_gem_monitor/ai_gem_monitor_glass.svg";
import CryptoTable from "./components/CryptoTable";
import {dataGainerHard} from "./components/CoinDataHard";


const AiGemMonitor = () => {

        document.body.scrollTo({
            top: 0,
        })


    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }



    const url = 'http://82.146.58.251'

    const [dataGainer, setDataGainer] = useState(dataGainerHard)
    const [gainerToggle, setGainerToggle] = useState(1)

    const urlGainer = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h%2C24h%2C7d&locale=en'

    /*useEffect(() => {
        axios.get(`${url}/api/gainer_coin`)
            .then(response => {
                setDataGainer(JSON.parse(response.data))
            })
            .catch(error => console.log(error))
    }, [])*/


    dataGainer.sort((a, b) => (a.price_change_percentage_7d_in_currency < b.price_change_percentage_7d_in_currency) ? 1 : -1)

    /*useEffect(() => {
        dataGainer.shift()
    }, [])*/




    return (
        <div className={'academy-page info-page'} style={{marginTop: '0'}}>

            <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
            </div>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header-container academy-header-container'}>
                    <div className={'filled info-page-header academy-header'}>


                        <div className={'academy-header-h1'}>

                            <h1 className={'white'}>
                                AI GEM Monitor
                            </h1>

                            <h5 className={'white'}>
                                {/*Find the next Crypto Gem and create your perfect cryptocurrency portfolio*/}
                                Create your Perfect Cryptocurrency Portfolio
                            </h5>


                            <button onClick={() => featureNotAvailableActiveHandler()} className={'earn-box-header-btn btn light'}>
                                Launch GEM Monitor
                            </button>

                        </div>


                        <img className={'aimonitor-header-img info-page-header-img'} src={ai_gem_monitor_big} />

                    </div>

                    <img className={'aimonitor-header-img-abstract info-page-header-img-abstract'} src={abstract_primary} />

                </div>



                <div className={'info-page-container'}>






                    <div className={'aimonitor-content'}>

                        <div className={'h1-btn'}>

                            <h1>
                                {/*Специальные навыки использования и платные подписки на индикаторы больше не нужны*/}
                                {/*Special usage skills and paid subscriptions to indicators are no longer needed*/}
                                Find the Next Crypto GEM
                            </h1>

                            <button className={'btn primary flexible'}>
                                View All (339)
                            </button>

                        </div>

                        <p className={'aimonitor-content-p'}>
                            {/*Попробуйте созданный нами автоматизированный инструмент, позволяющий в реальном времени вести полноценное наблюдение за крипитовлютным рынком.*/}
                            Try the automated tool we created that allows you to conduct full monitoring of the cryptocurrency market in real time.
                        </p>





                        <div className={'nft-marketplace-features'}>


                            <div className={'primary nft-marketplace-features-item'}>
                                <div className={'nft-marketplace-features-item-text'}>

                                    <div className={'nft-marketplace-features-item-text-top'}>
                                        <h3 className={'white nft-marketplace-features-item-h3'}>
                                            Choose Ideal Timings
                                        </h3>
                                        <p className={'white nft-marketplace-features-item-p'}>
                                            {/*Определять идеальные тайминги входа в сделки*/}
                                            Determine ideal timings for entering trades
                                        </p>
                                    </div>

                                    <img className={'nft-marketplace-features-item-img-in'} src={timings} />


                                    <div className={'nft-marketplace-features-item-text-bot'}>
                                        <button className={`btn light`}>
                                            Discover
                                        </button>
                                    </div>


                                </div>

                                <img className={'nft-marketplace-features-item-img'} src={timings} />
                            </div>


                            <div className={'black2 nft-marketplace-features-item'}>
                                <div className={'nft-marketplace-features-item-text'}>

                                    <div className={'nft-marketplace-features-item-text-top'}>
                                        <h3 className={'nft-marketplace-features-item-h3'}>
                                            Explore the Prospects
                                        </h3>
                                        <p className={'nft-marketplace-features-item-p'}>
                                            {/*Анализируйте и оценивайте перспективность криптовалют*/}
                                            Analyze and evaluate the prospects of cryptocurrencies
                                        </p>
                                    </div>

                                    <img className={'nft-marketplace-features-item-img-in'} src={prospects} />


                                    <div className={'nft-marketplace-features-item-text-bot'}>
                                        <h2 className={'nft-marketplace-features-item-text-bot-h2'}>
                                            <span style={{color: '#1b78ff'}}>300+ Candidates</span>
                                        </h2>
                                    </div>


                                </div>

                                <img className={'nft-marketplace-features-item-img'} src={prospects} />

                            </div>


                        </div>






                        <div className={'info-page-rev-block preliminary-listing-wrapper'}>
                            <div className={'wide preliminary-listing-container'}>

                                <img src={glass} className={'preliminary-listing-img'} style={{marginTop: '0'}}/>

                                <div className={'preliminary-listing-text'}>

                                    <div>
                                        <h2 className={'preliminary-listing-h2'}>
                                            {/*Специальные навыки использования и платные подписки на индикаторы больше не нужны*/}
                                            Special usage skills and paid indicators are no longer needed
                                        </h2>

                                    </div>

                                    <div className={'preliminary-listing-img-in-container'}>
                                        <img src={glass} className={'preliminary-listing-img-in-in'} />
                                    </div>


                                    <p style={{padding: 0}} className={'preliminary-listing-block-p'}>
                                        {/*Построенный на сети тоговых индикаторов, Ai Gem Monitor позволит без труда получать глубокий анализ по криптовалютам и увеличить эффективность вашей торговли.*/}
                                        Built on a network of trading indicators, AI GEM Monitor will allow you to easily obtain in-depth analysis of cryptocurrencies and increase the efficiency of your trading.
                                    </p>



                                    <div>

                                        <div className={'academy-makeway-features-container'}>

                                            <div className={'academy-makeway-features-item'}>

                                                <div className={'round academy-makeway-features-item-bar'}></div>

                                                <h3 className={'top4'}>
                                                    {/*Вести поиск перспективных криптовалют по вашим критериям*/}
                                                    Search for promising cryptocurrencies based on your criteria
                                                </h3>

                                            </div>


                                            <div className={'academy-makeway-features-item'}>

                                                <div className={'round academy-makeway-features-item-bar'}></div>

                                                <h3 className={'coin-block-h3'}>
                                                    {/*Получать оповещания по аномалиям рынка*/}
                                                    Receive alerts on market anomalies
                                                </h3>

                                            </div>


                                            <div className={'academy-makeway-features-item'}>

                                                <div className={'round academy-makeway-features-item-bar'}></div>

                                                <h3 className={'coin-block-h3'}>
                                                    {/*Видеть рыночные манипуляции*/}
                                                    See market manipulation
                                                </h3>

                                            </div>


                                        </div>
                                    </div>



                                    {/*<Link to={'/listing-application'} className={'btn primary apply-btn'}>
                                        Launch App
                                    </Link>*/}

                                </div>

                            </div>
                        </div>






                        <div className={'aimonitor-suggested-container'}>

                            {
                                dataGainer.length < 1 ?
                                ''
                                :

                                <h3 className={'aimonitor-suggested-h3'}>
                                    Suggested Crypto
                                </h3>

                            }

                            {(dataGainer.slice(1,2)).map((data, i) =>

                            <div className={`coins-market-gainer ${gainerToggle === 1 ? '' : 'loser'}`} style={{background: '#fafafa', position: 'relative'}}>

                                {/*<div className={'aimonitor-suggested-overlay'}>
                                    <h2 className={''}>
                                        {data.name}
                                    </h2>
                                    <button className={'btn primary'}>
                                        Monitor
                                    </button>
                                </div>*/}

                                <div className={'coins-market-gainer-container-left'}>

                                    <div className={'coins-market-gainer-container-left-img-container'}>
                                        <img className={`coins-market-gainer-container-left-img ${gainerToggle === 1 ? '' : 'none'}`} src={data.image} />
                                    </div>


                                    <div className={'coins-market-gainer-container-left-text'}>
                                        <div className={'coins-market-gainer-container-left-text-top'}>
                                            <p className={'coins-market-gainer-name'}>{data.name}</p>
                                            <h2 style={{color: 'var(--black100)'}}>{data.symbol.toUpperCase()}</h2>
                                        </div>



                                        <div className={'coins-market-gainer-container-left-text-mc'}>
                                            <p>Market Cap</p>
                                            <h3>
                                                ${data.market_cap?.toLocaleString()}
                                            </h3>
                                        </div>

                                        <div className={'coins-market-gainer-container-left-text-mc cp'}>
                                            <p className={''}>
                                                Current Price
                                            </p>
                                            <h2 className={''}>
                                                ${data.current_price}
                                            </h2>
                                        </div>

                                    </div>

                                </div>

                                <div className={'coins-market-gainer-container-right'}>
                                    <div
                                        className={`coins-market-gainer-container-right-time ${data.price_change_percentage_7d_in_currency ? '' : 'twocol'}`}
                                    >

                                        {data.price_change_percentage_7d_in_currency ?
                                            null
                                            :
                                            <div className={'coins-market-gainer-container-right-time-dummie'}></div>
                                        }

                                        <div className={'coins-market-gainer-container-right-time-item'}>
                                            <p>1H</p>
                                            {data.price_change_percentage_1h_in_currency >= 0 ?
                                                <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_1h_in_currency?.toFixed(1)}%</h3> :
                                                <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_1h_in_currency?.toFixed(2)}%</h3>
                                            }
                                        </div>

                                        <div className={'coins-market-gainer-container-right-time-item'}>
                                            <p>24H</p>
                                            {data.price_change_percentage_24h >= 0 ?
                                                <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_24h?.toFixed(1)}%</h3> :
                                                <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_24h?.toFixed(1)}%</h3>
                                            }
                                        </div>

                                        {data.price_change_percentage_7d_in_currency ?
                                            <div className={'coins-market-gainer-container-right-time-item'}>
                                                <p>7D</p>
                                                {data.price_change_percentage_7d_in_currency >= 0 ?
                                                    <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_7d_in_currency?.toFixed(1)}%</h3> :
                                                    <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_7d_in_currency?.toFixed(1)}%</h3>
                                                }
                                            </div>
                                            :
                                            null
                                        }


                                    </div>

                                    <div>
                                        <p className={'coins-market-gainer-container-right-price'}>Current Price</p>
                                        <h2 className={'coins-market-gainer-container-right-h2'}>${data.current_price}</h2>
                                    </div>
                                </div>

                                <div className={'gainer-sparkline-container'}>
                                    <p>7D</p>
                                    <div className={'gainer-sparkline'}>
                                        <Sparklines className={'gainer-sparkline'} data={data.sparkline_in_7d.price}>
                                            <SparklinesCurve className={'gainer-sparkline-top'} style={{fill: 'none', strokeWidth: '0.6'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                        </Sparklines>
                                    </div>
                                    <div className={`${gainerToggle === 1 ? '' : 'red'} gainer-sparkline-bott`}>
                                        <Sparklines data={data.sparkline_in_7d.price}>
                                            <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                        </Sparklines>
                                    </div>
                                </div>


                            </div>
                            )}


                            <CryptoTable page={'Ai Gem Monitor'} array={dataGainer} minusFirst={true} length={10} action={"Monitor"} monitorToggle={true}/>


                        </div>




                    </div>






                    <Link to={'/'} className={'info-page-home'}>
                        {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                        <button className={'btn black2'} style={{minWidth: '150px'}}>
                            Go Home
                        </button>
                    </Link>

                </div>

            </div>

            <MightBeInteresting current={3}/>

            <JoinCommunity  mt0={true} />

        </div>
    );
};

export default AiGemMonitor;