import React from 'react';
import {Link} from "react-router-dom";
import './PreliminaryListing.css';
import preliminary_listing from "../../assets/preliminary_listing.svg";

const PreliminaryListing = () => {
    return (
        <div className={'preliminary-listing-wrapper'}>
            <div className={'preliminary-listing-container'}>

                <img src={preliminary_listing} className={'preliminary-listing-img'}/>

                <div className={'preliminary-listing-text'}>

                    <div>
                        <h2 className={'preliminary-listing-h2'}>
                            Preliminary Listing Opened
                        </h2>
                    </div>

                    <img src={preliminary_listing} className={'preliminary-listing-img-in'} />

                    <p style={{padding: 0}} className={'preliminary-listing-block-p'}>
                        List your project on Liqui Global under special terms
                    </p>


                    {/*<div className={'preliminary-listing-block'}>*/}

                        {/*<p style={{padding: 0}} className={'input-p'}>
                            Project Name
                        </p>

                        <input
                            className={'input light double-input'}
                            type={'text'}
                            placeholder={'Your Project Name'}

                        />*/}

                        <Link to={'/listing-application'} className={'flexible btn primary'} style={{marginTop: '20px', width: '200px'}}>
                            Listing Application
                            {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                                <path
                                    fill={'#ffffff'}
                                    fillRule="evenodd"
                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                ></path>
                            </svg>*/}
                        </Link>

                        {/*<div className={'preliminary-listing-block-text'}>

                        </div>*/}


                    {/*</div>*/}

                </div>

            </div>
        </div>
    );
};

export default PreliminaryListing;