import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import LgtPresale from "./components/LgtPresale";
import arrowrightblue from "../assets/arrowrightblue.svg";
import messages from "../assets/messages.svg";
import './InfoPage.css'
import './SociadMediaBounty.css'
import axios from "axios";

const SocialMediaBounty = () => {
    /*window.scrollTo({
        top: 0,
        behavior: "smooth"
    })*/

    const url = 'http://82.146.58.251'

    const [ twitter, setTwitter ] = useState('@')
    const [ telegram, setTelegram ] = useState('@')

    const [formValidTwitter, setFormValidTwitter] = useState(false)
    const [formValidTelegram, setFormValidTelegram] = useState(false)

    const twitterHandler = (e) => {
        setTwitter(e.target.value)

        if (e.target.value.trim().length === 0) {
            setTwitter('@')
        }

        if (e.target.value.trim().length <= 1) {
            setFormValidTwitter(false)
        } else {
            setFormValidTwitter(true)
            if (e.target.value[0] === '@') {
                setTwitter(e.target.value)
            } else {
                setTwitter('@' + e.target.value)
            }

        }
    }

    const telegramHandler = (e) => {
        setTelegram(e.target.value)

        if (e.target.value.trim().length === 0) {
            setTelegram('@')
        }

        if (e.target.value.trim().length <= 1) {
            setFormValidTelegram(false)
        } else {
            setFormValidTelegram(true)
            if (e.target.value[0] === '@') {
                setTelegram(e.target.value)
            } else {
                setTelegram('@' + e.target.value)
            }

        }
    }



    const inputRef = useRef()

    const handleKeypressTwitter = e => {
        if (e.keyCode === 13 && formValidTwitter) {
            sendDataTwitter();
            inputRef.current.blur()
        }
    };

    const handleKeypressTelegram = e => {
        if (e.keyCode === 13 && formValidTelegram) {
            sendDataTelegram();
            inputRef.current.blur()
        }
    };

    const [successNotificationShowingTwitter, setSuccessNotificationShowingTwitter] = useState(false)
    const [containsNotificationShowingTwitter, setContainsNotificationShowingTwitter] = useState(false)
    const [successNotificationShowingTelegram, setSuccessNotificationShowingTelegram] = useState(false)
    const [containsNotificationShowingTelegram, setContainsNotificationShowingTelegram] = useState(false)
    const [errorNotificationShowing, setErrorNotificationShowing] = useState(false)

    const successNotificationTwitter = () => {
        setSuccessNotificationShowingTwitter(true)
        setTimeout(() => {
            setSuccessNotificationShowingTwitter(false)
            setFormValidTwitter(true)
        }, 5000)
    }

    const containsNotificationTwitter = () => {
        setContainsNotificationShowingTwitter(true)
        setTimeout(() => {
            setContainsNotificationShowingTwitter(false)
            setFormValidTwitter(true)
        }, 5000)
    }

    const successNotificationTelegram = () => {
        setSuccessNotificationShowingTelegram(true)
        setTimeout(() => {
            setSuccessNotificationShowingTelegram(false)
            setFormValidTelegram(true)
        }, 5000)
    }

    const containsNotificationTelegram = () => {
        setContainsNotificationShowingTelegram(true)
        setTimeout(() => {
            setContainsNotificationShowingTelegram(false)
            setFormValidTelegram(true)
        }, 5000)
    }

    const errorNotification = () => {
        setErrorNotificationShowing(true)
        setTimeout(() => {
            setErrorNotificationShowing(false)
            setFormValidTwitter(true)
            setFormValidTelegram(true)
        }, 5000)
    }

    const sendDataTwitter = () => {

        setFormValidTwitter(false)

        const options = {
            method: 'GET',
            url: `${url}/api/twitter_bounty`,
            params: {
                twitter: twitter
            }
        }

        axios.request(options)
            .then(function (response) {

                // alert(response.data)

                // if ((response.status > 199) && (response.status < 300)) {
                if (response.data === 200) {
                    successNotificationTwitter()
                }
                if (response.data === 500) {
                    errorNotification()
                }
                if (response.data === 100) {
                    containsNotificationTwitter()
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }

    const sendDataTelegram = () => {

        setFormValidTelegram(false)

        const options = {
            method: 'GET',
            url: `${url}/api/telegram_bounty`,
            params: {
                telegram: telegram
            }
        }

        axios.request(options)
            .then(function (response) {

                // alert(response.data)

                // if ((response.status > 199) && (response.status < 300)) {
                if (response.data === 200) {
                    successNotificationTelegram()
                }
                if (response.data === 500) {
                    errorNotification()
                }
                if (response.data === 100) {
                    containsNotificationTelegram()
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }


    return (
        <div className={'info-page'}>

            <div style={{background: '#1b78ff'}} className={successNotificationShowingTwitter ? 'email-notification email-notification-active' : 'email-notification'}>
                <div className={'email-notification-text'}>
                    <h3 style={{color: '#fff'}} className={'email-notification-h3'}>
                        Hey!
                    </h3>

                    <p style={{color: '#fff'}}>
                        Thank you for joining Twitter bounty program.
                    </p>
                </div>
            </div>
            <div style={{background: '#fff', border: '1px solid #f1f1f1'}} className={containsNotificationShowingTwitter ? 'email-notification email-notification-active' : 'email-notification'}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3'}>
                        Hey!
                    </h3>

                    <p>
                        You're already joined Twitter bounty program.
                    </p>
                </div>
            </div>
            <div className={errorNotificationShowing ? 'email-notification email-notification-active' : 'email-notification'} style={{background: 'var(--errorBG)'}}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3'}>
                        Sorry, something went wrong.
                    </h3>

                    <p>
                        Try again later.
                    </p>
                </div>
            </div>



            <div style={{background: '#1b78ff'}} className={successNotificationShowingTelegram ? 'email-notification email-notification-active' : 'email-notification'}>
                <div className={'email-notification-text'}>
                    <h3 style={{color: '#fff'}} className={'email-notification-h3'}>
                        Hey!
                    </h3>

                    <p style={{color: '#fff'}}>
                        Thank you for joining Telegram bounty program.
                    </p>
                </div>
            </div>
            <div style={{background: '#fff', border: '1px solid #f1f1f1'}} className={containsNotificationShowingTelegram ? 'email-notification email-notification-active' : 'email-notification'}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3'}>
                        Hey!
                    </h3>

                    <p>
                        You're already joined Telegram bounty program.
                    </p>
                </div>
            </div>



            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{height: '115px', marginRight: '18px'}} className={'info-page-header-img'} src={messages}/>

                    <h1>
                        Social Media Bounty
                        <h5 style={{margin: '0', paddingTop: '10px'}}>
                            Старания и поддержка комьюнити должны быть щедро вознаграждены
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>

                        <div className={'info-page-bsect'}>
                            <p>
                                Мы сформировали пуллы вознаграждений за помощь в продвижении Liqui Global в социальных сетях.
                                <br/>
                                Любой пользователь может поучаствовать в баунти программе и разделить награду за помощь в продвижении нашей платформы.
                            </p>
                        </div>

                        <div className={'x-bounty-container'}>

                            <div className={'x-bounty-left'}>

                                <h2 className={'info-page-h2'}>
                                    Twitter Bounty
                                </h2>

                                <ul className={'ul-dot'} style={{marginTop: '10px'}}>
                                    <li>
                                        Зарабатывайте очки, выполняя баунти задания баунти программы
                                    </li>
                                    <li>
                                        Расчет будет произведен автомачтиечки после окончания баунти программы
                                    </li>
                                    <li>
                                        Награда будет распределена пропорционально заработаным очкам вне зависимости от количества участников
                                    </li>
                                </ul>


                                <div className={'info-page-bsect info-page-bsect-x-in'}>
                                    <h3 style={{marginBottom: '30px'}}>
                                        Join Twitter bounty
                                    </h3>

                                    <p>Twitter username</p>

                                    <div className={'info-page-text-input1-container'}>
                                        {/*<span>@</span>*/}
                                        <input
                                            onKeyDown={handleKeypressTwitter}
                                            onChange={e => twitterHandler(e)}
                                            className={'info-page-text-input1'}
                                            ref={inputRef}
                                            value={twitter}
                                            name={'twitter'}
                                            type={'text'}
                                            /*placeholder={'satoshinakamoto'}*/
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className={'body-team-text-button-container'}>

                                        <button
                                            onClick={() => {sendDataTwitter()}}
                                            disabled={!formValidTwitter}
                                            onKeyDown={handleKeypressTwitter}
                                            className={'btn info-page-text-button'}
                                            type={'submit'}
                                        >
                                            Join
                                        </button>
                                    </div>
                                </div>


                                <div className={'info-page-invert-div'}>

                                    <h3>
                                        Задания:
                                    </h3>

                                    <ul className={'ul-decimal'} style={{paddingBottom: '0'}}>

                                        <li>
                                            Твит с хештегом #LiquiGlobal — 2 очка
                                        </li>

                                        <li>
                                            Ретвит с официальной страницы #LiquiGlobal — 1.5 очка
                                        </li>

                                        <li>
                                            Лайк + комментарий на твит с официальной страницы #LiquiGlobal — 1 очко
                                        </li>

                                    </ul>

                                </div>


                                <div className={'info-page-stroke-div'}>

                                    <h3>
                                        Правила участия:
                                    </h3>

                                    <ul className={'ul-dot'} style={{paddingBottom: '0px'}}>

                                        <li>
                                            Минимальное количество подписчиков для 1 и 2 задания — не менее 100
                                        </li>

                                        <li style={{paddingBottom: '0px'}}>
                                            Количество твитов с 1 аккаунта - не более 1 поста за 6 часов
                                        </li>

                                    </ul>

                                </div>

                                <div className={'info-page-lbsect-x-in info-page-lbsect'}>

                                    <h3>
                                        Коэфицент очков зависит от количества подписчиков:
                                    </h3>

                                    <table className={'bounty-table'} style={{textAlign: 'start', paddingBottom: '0', marginBottom: '0'}}>
                                        <tr style={{background: 'none'}}>
                                            <th>Followers</th>
                                            <th>Rate</th>
                                        </tr>
                                        <tr style={{background: 'none'}}>
                                            <td>100-500</td>
                                            <td>1x</td>
                                        </tr>
                                        <tr>
                                            <td>501-1000</td>
                                            <td>1.2x</td>
                                        </tr>
                                        <tr>
                                            <td>1001-5000</td>
                                            <td>1.4x</td>
                                        </tr>
                                        <tr>
                                            <td>5001-10000</td>
                                            <td>1.6x</td>
                                        </tr>
                                        <tr>
                                            <td>10001-30000</td>
                                            <td>1.8x</td>
                                        </tr>
                                        <tr>
                                            <td>30001-50000</td>
                                            <td>2x</td>
                                        </tr>
                                        <tr>
                                            <td>50000+</td>
                                            {/*<td><a className={'info-page-underlined-a'} href="mailto:marketing@liqui.global">marketing@liqui.global</a></td>*/}
                                            <td><Link to={'/influencer-vip-bounty-program'} className={'info-page-underlined-a'}>Influencer VIP Bounty</Link></td>
                                        </tr>
                                    </table>

                                </div>

                                <div style={{alignSelf: 'start'}}>
                                    <h3 className={'info-page-h3'}>
                                        *10.000.000 LGT Allocated

                                    </h3>
                                </div>

                            </div>

                            <div className={'x-bounty-right'}>

                                <div className={'info-page-bsect-x info-page-bsect'} style={{textAlign: 'start', padding: '30px'}}>
                                    <h3 style={{marginBottom: '30px'}}>
                                        Join Twitter bounty
                                    </h3>

                                    <p>Twitter username</p>

                                    <div className={'info-page-text-input1-container'}>
                                        {/*<span>@</span>*/}
                                        <input
                                            onKeyDown={handleKeypressTwitter}
                                            onChange={e => twitterHandler(e)}
                                            className={'info-page-text-input1'}
                                            ref={inputRef}
                                            value={twitter}
                                            name={'twitter'}
                                            type={'text'}
                                            /*placeholder={'satoshinakamoto'}*/
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className={'body-team-text-button-container'}>

                                        <button
                                            onClick={() => {sendDataTwitter()}}
                                            disabled={!formValidTwitter}
                                            onKeyDown={handleKeypressTwitter}
                                            className={'btn info-page-text-button'}
                                            type={'submit'}
                                        >
                                            Join
                                        </button>
                                    </div>
                                </div>

                                <div className={'info-page-lbsect-x info-page-lbsect'}>

                                    <h3>
                                        Коэфицент очков зависит от количества подписчиков:
                                    </h3>

                                    <table className={'bounty-table'} style={{textAlign: 'start', paddingBottom: '0', marginBottom: '0'}}>
                                        <tr style={{background: 'none'}}>
                                            <th>Followers</th>
                                            <th>Rate</th>
                                        </tr>
                                        <tr style={{background: 'none'}}>
                                            <td>100-500</td>
                                            <td>1x</td>
                                        </tr>
                                        <tr>
                                            <td>501-1000</td>
                                            <td>1.2x</td>
                                        </tr>
                                        <tr>
                                            <td>1001-5000</td>
                                            <td>1.4x</td>
                                        </tr>
                                        <tr>
                                            <td>5001-10000</td>
                                            <td>1.6x</td>
                                        </tr>
                                        <tr>
                                            <td>10001-30000</td>
                                            <td>1.8x</td>
                                        </tr>
                                        <tr>
                                            <td>30001-50000</td>
                                            <td>2x</td>
                                        </tr>
                                        <tr>
                                            <td>50000+</td>
                                            {/*<td><a className={'info-page-underlined-a'} href="mailto:marketing@liqui.global">marketing@liqui.global</a></td>*/}
                                            <td><Link to={'/influencer-vip-bounty-program'} className={'info-page-underlined-a'}>Influencer VIP Bounty</Link></td>
                                        </tr>
                                    </table>

                                </div>

                            </div>



                        </div>







                        <div className={'tg-bounty-container'}>

                            <div className={'tg-bounty-left'}>

                                <h2 className={'info-page-h2'}>
                                    Telegram Bounty:
                                </h2>

                                <ul className={'ul-dot'} style={{marginTop: '10px'}}>
                                    <li>
                                        Зарабатывайте очки, выполняя баунти задания баунти программы
                                    </li>
                                    <li>
                                        После окончания баунти программы, прикрепите файл с таблицей с сылками на сделаные посты.
                                    </li>
                                    <li>
                                        Расчет будет произведен после проверки, в соответствии с отправленной вами таблицей.
                                    </li>
                                    <li>
                                        Награда будет распределена пропорционально заработаным очкам вне зависимости от количества участников
                                    </li>
                                </ul>

                                <div className={'info-page-bsect-tg-in info-page-bsect'} style={{textAlign: 'start', padding: '30px'}}>
                                    <h3 style={{marginBottom: '30px'}}>
                                        Join Telegram bounty
                                    </h3>

                                    <p>Telegram ID</p>

                                    <div className={'info-page-text-input1-container'}>
                                        {/*<span>@</span>*/}
                                        <input
                                            onKeyDown={handleKeypressTelegram}
                                            onChange={e => telegramHandler(e)}
                                            className={'info-page-text-input1'}
                                            ref={inputRef}
                                            value={telegram}
                                            name={'telegram'}
                                            type={'text'}
                                            /*placeholder={'satoshinakamoto'}*/
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className={'body-team-text-button-container'}>

                                        <button
                                            onClick={() => {sendDataTelegram()}}
                                            disabled={!formValidTelegram}
                                            onKeyDown={handleKeypressTelegram}
                                            className={'btn info-page-text-button'}
                                            type={'submit'}
                                        >
                                            Join
                                        </button>
                                    </div>
                                </div>

                                <div className={'info-page-invert-div'}>

                                    <h3>
                                        Задания:
                                    </h3>

                                    <ul className={'ul-decimal'} style={{paddingBottom: '0'}}>

                                        <li>
                                            Пост в чате с сылкой @LiquiGlobal_Announcments — 2 очка
                                        </li>

                                        <li>
                                            Пост в чате о Liqui Global — 1 очко
                                        </li>

                                    </ul>

                                </div>

                                <div className={'info-page-stroke-div'}>

                                    <h3>
                                        Правила участия:
                                    </h3>

                                    <ul className={'ul-dot'} style={{paddingBottom: '0'}}>

                                        <li>
                                            Количество постов с 1 аккаунта для 1 чата не более 1 поста в 3 часа
                                        </li>

                                        <li>
                                            Пост не должен быть удален администрацией и противоечить внутренним правилам чата
                                        </li>

                                        <li>
                                            Телеграм чат должен иметь криптовалютную/бизнес/инвестиционную тематику
                                        </li>

                                        <li>
                                            Минимальное количество участников чата — не менее 500
                                        </li>

                                    </ul>

                                </div>

                                <div style={{alignSelf: 'start'}}>
                                    <h3 className={'info-page-h3'}>
                                        *10.000.000 LGT Allocated

                                    </h3>
                                </div>

                            </div>

                            <div className={'tg-bounty-right'}>

                                <div className={'info-page-bsect-tg info-page-bsect'} style={{textAlign: 'start', padding: '30px'}}>
                                    <h3 style={{marginBottom: '30px'}}>
                                        Join Telegram bounty
                                    </h3>

                                    <p>Telegram ID</p>

                                    <div className={'info-page-text-input1-container'}>
                                        {/*<span>@</span>*/}
                                        <input
                                            onKeyDown={handleKeypressTelegram}
                                            onChange={e => telegramHandler(e)}
                                            className={'info-page-text-input1'}
                                            ref={inputRef}
                                            value={telegram}
                                            name={'telegram'}
                                            type={'text'}
                                            /*placeholder={'satoshinakamoto'}*/
                                            autoComplete={'off'}
                                        />
                                    </div>

                                    <div className={'body-team-text-button-container'}>

                                        <button
                                            onClick={() => {sendDataTelegram()}}
                                            disabled={!formValidTelegram}
                                            onKeyDown={handleKeypressTelegram}
                                            className={'btn info-page-text-button'}
                                            type={'submit'}
                                        >
                                            Join
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                    </div>

                </div>

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default SocialMediaBounty;