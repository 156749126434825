import React, {useState, useEffect} from 'react';
import './Home.css'
import CoinsMarket from "./CoinsMarket";
import MyMarquee from "./MyMarquee";
import {Link} from "react-router-dom";
import FirstBlock from "./FirstBlock";
import InfluencerBounty from "./InfluencerBounty";
import JoinCommunity from "./JoinCommunity";
import DiscoverProducts from "./DiscoverProducts";
import SecurityPrivacy from "./SecurityPrivacy";
import MobileApp from "./MobileApp";
import Reliability from "./Reliability";
import IntroducingLgt from "./IntroducingLgt";
import LgtPresale from "./LgtPresale";
import FaqAccordion from "./FaqAccordion";
import PostsCarousel from "./PostsCarousel";
import CookieConsent from "./CookieConsent";
import GiftboxBanner from "./GiftboxBanner";
import FeesLimits from "../FeesLimits";
import FeesLimitsHome from "./FeesLimitsHome";
const Home = () => {

    useEffect(() => {

        document.body.scrollTo({
            top: 0,
        })

    }, [])


    return (

        <div className={'main-body'}>

            <FirstBlock />

            <PostsCarousel />

            <CoinsMarket />

            <InfluencerBounty />

            <DiscoverProducts />

            <SecurityPrivacy />

            <Reliability isInSecurity={false} />

            <JoinCommunity />

            <MobileApp />

            <FaqAccordion isBlack={false} />

            <div className={'marquee'}>
                <MyMarquee />
            </div>


        </div>

    );
};

export default Home;