import React, {useEffect, useState} from 'react';
import './Main.css'
import Footer from "./pages/components/Footer";
import Navbar from "./pages/components/Navbar";
import Home from "./pages/components/Home";
import {Route, Routes} from "react-router-dom";
import Legacy from "./pages/Legacy";
import InfluencerVipBounty from "./pages/InfluencerVipBounty";
import NFTWorkshop from "./pages/NFTWorkshop";
import EarnBox from "./pages/EarnBox";
import OurMission from "./pages/OurMission";
import Career from "./pages/Career";
import ErrorPage from "./pages/ErrorPage";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CryptoAcademy from "./pages/CryptoAcademy";
import TradingBots from "./pages/TradingBots";
import LiquidationShield from "./pages/LiquidationShield";
import LeveragedETFs from "./pages/LeveragedETFs";
import LaunchpadField from "./pages/LaunchpadField";
import AiGemMonitor from "./pages/AiGemMonitor";
import FeesLimits from "./pages/FeesLimits";
import Loader from "./pages/components/Loader";
import LoaderFadeout from "./pages/components/LoaderFadeout";
import Security from "./pages/Security";
import SocialMediaBounty from "./pages/SocialMediaBounty";
import Blog from "./pages/Blog/Blog";
import BlogArt4 from "./pages/Blog/BlogArt4";
import BlogArt1 from "./pages/Blog/BlogArt1";
import BlogArt2 from "./pages/Blog/BlogArt2";
import BlogArt3 from "./pages/Blog/BlogArt3";
import FAQ from "./pages/FAQ";
import FloatingGiftbox from "./pages/components/FloatingGiftbox";
import CookieConsent from "./pages/components/CookieConsent";
import GiftboxBanner from "./pages/components/GiftboxBanner";
import CloneTrading from "./pages/CloneTrading";



const Main = () => {

    useEffect(() => {
        // document.getElementById('loader-wrapper').focus();
    }, [])



    const [isLoading, setIsLoading] = useState(false)

    setInterval(() => {
        setTimeout(() => {
            window.onload = setIsLoading(false)
        }, 2500)

    }, 1300)


    return isLoading ? (
            <Loader />
                )
            : (
            <div>
                {/*<LoaderFadeout />*/}

                <Navbar />

                <Routes>

                    <Route index element={ <Home /> }/>
                    <Route path={'/'} element={ <Home /> }/>
                    <Route path={'/terms-of-use'} element={ <TermsOfUse /> }/>
                    <Route path={'/privacy-policy'} element={ <PrivacyPolicy /> }/>
                    <Route path={'/legacy'} element={ <Legacy /> }/>
                    <Route path={'/faq'} element={ <FAQ /> }/>
                    <Route path={'/our-mission'} element={ <OurMission /> }/>
                    <Route path={'/security'} element={ <Security /> }/>
                    <Route path={'/career'} element={ <Career /> }/>
                    <Route path={'/fees-and-limits'} element={ <FeesLimits /> }/>
                    <Route path={'/influencer-vip-bounty-program'} element={ <InfluencerVipBounty /> }/>
                    <Route path={'/social-media-bounty-program'} element={ <SocialMediaBounty /> }/>

                    <Route path={'/nft-workshop'} element={ <NFTWorkshop /> }/>
                    <Route path={'/nft-workshop/marketplace'} element={ <NFTWorkshop scrollTo={'marketplace'} /> }/>
                    <Route path={'/nft-workshop/builder'} element={ <NFTWorkshop scrollTo={'builder'} /> }/>

                    <Route path={'/crypto-academy'} element={ <CryptoAcademy /> }/>
                    <Route path={'/liquidation-shield'} element={ <LiquidationShield /> }/>
                    <Route path={'/leveraged-etfs'} element={ <LeveragedETFs /> }/>
                    <Route path={'/launchpad-field'} element={ <LaunchpadField /> }/>
                    <Route path={'/ai-gem-monitor'} element={ <AiGemMonitor /> }/>
                    <Route path={'/clone-trading'} element={ <CloneTrading /> }/>

                    <Route path={'/blog'} element={ <Blog /> }/>
                    <Route path={'/blog/art1'} element={ <BlogArt1 /> }/>
                    <Route path={'/blog/art2'} element={ <BlogArt2 /> }/>
                    <Route path={'/blog/art3'} element={ <BlogArt3 /> }/>
                    <Route path={'/blog/art4'} element={ <BlogArt4 /> }/>

                    <Route path={'*'} element={ <ErrorPage /> }/>

                </Routes>

                <FloatingGiftbox />

                <CookieConsent light={false} />

                <GiftboxBanner />

                <Footer />
            </div>
                )
}

export default Main;