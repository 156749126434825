import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import abstract_bots from "../assets/abstract_bots.svg";
import abstract_2 from "../assets/abstract_2.svg";
import trading_bots from "../assets/trading_bots.svg";
import arrowrightbluedark from "../assets/arrowrightbluedark.svg";
import grid_bot from "../assets/grid_bot.svg";
import dca_bot from "../assets/dca_bot.svg";
import add_dark from "../assets/add_dark.svg";
import add_primary2 from "../assets/add_primary2.svg";
import LgtPresale from "./components/LgtPresale";
import MightBeInteresting from "../MightBeInteresting";
import crypto_academy_experts from "../assets/crypto_academy_experts.svg";
import './TradingBots.css'
import NavbarBlack from "../NavbarBlack";
import Footer from "./components/Footer";
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";


import {
    mybots_data,
    marketplace_data,
    dataCoins,
    sort_options,
    sparklines,
    grid_ai_data,
    dca_ai_data,
    leverage_options, dataTopCoin, logos
} from "./TradingBotsData";
import Select from "react-select";
import axios from "axios";
import InputRange from "react-input-range";
import TradingBotsLearn from "./TradingBotsLearn";
import earnbox_pig from "../assets/earnbox-pig.svg";
import VerificationWidget from "./components/VerificationWidget";
import abstract_verify from "../assets/abstract_verify.svg";
import Navbar from "./components/Navbar";

export const select_styles_light = {
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0e0e12',
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0e0e12',
    }),
    noOptionsMessage: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0e0e12',
    }),

    container: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0e0e12',
    }),

    control: (baseStyles, state) => ({
        border: '1px solid #ffffff',
        background: '#ffffff',
        "&:hover": {
            border: '1px solid #ffffff',
            transition: '0.2s ease-in-out',
        },
        cursor: 'pointer',
        boxShadow: 'none',
        borderRadius: 12,
        height: '48px',
        width: '100%',
        margin: '0',
        fontFamily: 'var(--font)',
        fontSize: '18px',
        fontWeight: '400',
        display: 'flex',
        color: '#0e0e12',
        paddingLeft: '5px',
        zIndex: '100',
        transition: '0.2s ease-in-out',
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        boxShadow: 'none',
        borderRadius: 0,
        height: '42px',
        width: '100%',
        margin: '0',
        fontFamily: 'var(--font)',
        fontSize: '18px',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        color: '#0e0e12',
        background: (state.isFocused || state.isSelected) ? '#fafafa' : '#ffffff',
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: 12,
        border: '1px solid #f1f1f1',
        paddingBottom: '0px',
        marginTop: '5px',
        width: '90%',
        height: 'auto',
        boxShadow: 'none',
        zIndex: '100',
        overflow: 'hidden',
        background: '#ffffff',
        color: '#ffffff',
    }),
    menuPortal: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0',
        width: '',
        height: 'auto',
        boxShadow: 'none',
        color: '#0e0e12',
    }),
    menuList: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0',
        height: 'auto',
        boxShadow: 'none',
        borderRadius: 10,
        color: '#0e0e12',
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: '#0e0e12',
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: (state.isFocused) ? '#0e0e12' : '#292a33',
        "&:hover": {
            color: '#0e0e12'
        }
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: 'none',
    }),
}

const TradingBots = () => {

    useEffect(() => {

        document.body.scrollTo({
            top: 0,
        })

    }, [])


    const select_styles = {
        input: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        noOptionsMessage: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        container: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        control: (baseStyles, state) => ({
            border: '1px solid #292a33',
            background: '#0e0e12',
            "&:hover": {
                border: '1px solid #575866',
                transition: '0.2s ease-in-out',
            },
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 12,
            height: '48px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            color: '#ffffff',
            paddingLeft: '5px',
            zIndex: '100',
            transition: '0.2s ease-in-out',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 0,
            height: '42px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            background: (state.isFocused || state.isSelected) ? '#292a33' : '#1f2026',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            border: '1px solid var(--primary4)',
            paddingBottom: '0px',
            marginTop: '5px',
            width: '90%',
            height: 'auto',
            boxShadow: 'none',
            zIndex: '100',
            overflow: 'hidden',
            background: '#1f2026',
            color: '#ffffff',
        }),
        menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            width: '',
            height: 'auto',
            boxShadow: 'none',
            color: '#ffffff',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            height: 'auto',
            boxShadow: 'none',
            borderRadius: 10,
            color: '#ffffff',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.isFocused) ? '#ccdeff' : '#ffffff',
            "&:hover": {
                color: '#ccdeff'
            }
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
        }),
    }


    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }


    const now = Date.now()


    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())

            let ticker = Math.round(getRandomArbitrary(1, 2))

            if (ticker === 1) {
                mybots_data[0].pnl = mybots_data[0].pnl * getRandomArbitrary(0.998, 1.002)
            }
            if (ticker === 2) {
                mybots_data[2].pnl = mybots_data[2].pnl * getRandomArbitrary(0.998, 1.002)
            }

            for (let i = Math.round(getRandomArbitrary(0, 1)); i < marketplace_data.length; i = i + Math.round(getRandomArbitrary(2, 3))) {
                marketplace_data[i].pnl = marketplace_data[i].pnl * getRandomArbitrary(0.997, 1.003)
            }



        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);








    const totalPnl = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].pnl;
        }
        return total / arr.length;
    }

    const totalPnlUsdt = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + (arr[i].aum * arr[i].pnl / 100);
        }
        return total;
    }

    const totalAum = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].aum;
        }
        return total;
    }




    const [marketplaceSort, setMarketplaceSort] = useState({value: 'Most Followed'})
    const [marketplaceCoin, setMarketplaceCoin] = useState('')

    const [marketplaceTypes, setMarketplaceTypes] = useState(1)
    const [marketplace_data_filtered, set_marketplace_data_filtered] = useState(marketplace_data)



    const [marketplaceIsSorting, setMarketplaceIsSorting] = useState(false)


    useEffect(() => {

        setMarketplaceIsSorting(true)

        setTimeout(() => {
            marketplace_data_filtered.sort((a, b) => (a.followers < b.followers) ? 1 : -1)
            const filtered = marketplace_data.filter((item) => item.currency !== 0)
            set_marketplace_data_filtered(filtered)
        }, 310)



        setTimeout(() => {
            setMarketplaceIsSorting(false)
        }, 3000)

    }, [])


    const handleMarketplaceSortChange = (selectedSort) => {

        setMarketplaceSort(selectedSort)

        setMarketplaceIsSorting(true)

        if (selectedSort.value === 'Most Followed') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.followers < b.followers) ? 1 : -1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        } else if (selectedSort.value === 'Highest PnL') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.pnl < b.pnl) ? 1 : -1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        } else if (selectedSort.value === 'Lowest Fee') {

            setTimeout(() => {
                marketplace_data_filtered.sort((a, b) => (a.share < b.share) ? -1 : 1)
            }, 310)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 3000)

        }
    }


    const handleMarketplaceCoinChange = (selectedCoin) => {

        setMarketplaceIsSorting(true)

        setTimeout(() => {


            setMarketplaceCoin(selectedCoin)

            let marketplace_data_filtered_123 = marketplace_data.filter((item) => item.currency.includes(selectedCoin.value))


            if (marketplaceSort.value === 'Most Followed') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.followers < b.followers) ? 1 : -1))

            } else if (marketplaceSort.value === 'Highest PnL') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.pnl < b.pnl) ? 1 : -1))

            } else if (marketplaceSort.value === 'Lowest Fee') {

                set_marketplace_data_filtered(marketplace_data_filtered_123.sort((a, b) => (a.share < b.share) ? -1 : 1))

            }

            set_marketplace_data_filtered(marketplace_data_filtered_123)


            /*alert(marketplaceSort.value)*/

        }, 310)

        setTimeout(() => {
            setMarketplaceIsSorting(false)
        }, 2500)
    }




    const [marketplace_data_output, set_marketplace_data_output] = useState(marketplace_data_filtered)

    useEffect(() => {


        if (marketplaceTypes === 1) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered)
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)


        } else if (marketplaceTypes === 2) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered.filter(item => {
                    return item.type === 'Spot Grid' || item.type === 'Futures Grid';
                }))
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)


        } else if (marketplaceTypes === 3) {
            setMarketplaceIsSorting(true)

            setTimeout(() => {
                set_marketplace_data_output(marketplace_data_filtered.filter(item => {
                    return item.type === 'DCA';
                }))
            }, 1800)

            setTimeout(() => {
                setMarketplaceIsSorting(false)
            }, 2000)

        }

    }, [marketplaceTypes, marketplaceSort, marketplaceCoin])






    /*let random = [];

    for (let curr of dataCoins) {

        for (let i = 1; i < curr.bots; i = i + 1) {

            let isFutures = Math.round(getRandomArbitrary(1, 2)) === 1 ? true : false

            random.push({
                currency: curr.value,
                name: `${curr.value}/USDT`,
                type: Math.round(getRandomArbitrary(1, 3)) === 1 ? 'DCA' : `${Math.round(getRandomArbitrary(1, 2)) === 1 ? 'Futures' : 'Spot'} Grid`,
                share: Math.round(getRandomArbitrary(1, 3)) === 1 ? 25 : `${Math.round(getRandomArbitrary(1, 2)) === 1 ? 20 : 30}`,
                followers: Math.round(getRandomArbitrary(11, 1550)),
                futures: isFutures,
                long: Math.round(getRandomArbitrary(1, 2)) === 1 ? true : false,
                leverage: Math.round(getRandomArbitrary(1, 5)) * 10,
                pnl: isFutures ? getRandomArbitrary(100.37, 759.93) : getRandomArbitrary(100.37, 276.93),
                runtime: Math.round(getRandomArbitrary(1, 300)),
                sparkline_in_7d: sparklines[Math.round(getRandomArbitrary(0, sparklines.length))],
                date: Math.round(getRandomArbitrary(1709789055544, 1609789055544)),
            })
        }

    }*/

    /*console.log(random)*/


    let dataCoinsCreate = []

    for (let i = 1; i < dataCoins.length; i = i + 1) {
        dataCoinsCreate.push(dataCoins[i])
    }


    const [gridIsSorting, setGridIsSorting] = useState(false)
    const [dcaIsSorting, setDcaIsSorting] = useState(false)


    const url = 'http://82.146.58.251'

    const [dataGainer, setDataGainer] = useState([])

    // const urlGainer = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h%2C24h%2C7d&locale=en'


    const [gridBotMenuType, setGridBotMenuType] = useState(1)
    const [dcaBotMenuType, setDcaBotMenuType] = useState(1)
    const [gridBotMenuTypeVisible, setGridBotMenuTypeVisible] = useState(1)
    const [dcaBotMenuTypeVisible, setDcaBotMenuTypeVisible] = useState(1)

    const [gridBotMenuTypeFutures, setGridBotMenuTypeFutures] = useState(2)
    const [gridBotMenuTypeFuturesVisible, setGridBotMenuTypeFuturesVisible] = useState(2)

    const [gridBotMenu, setGridBotMenu] = useState(1)
    const [dcaBotMenu, setDcaBotMenu] = useState(2)

    const [gridSelectedLeverage, setGridSelectedLeverage] = useState(0)

    const [gridSelectedCoin, setGridSelectedCoin] = useState({value: 'BTC'})
    const [dcaSelectedCoin, setDcaSelectedCoin] = useState({value: 'BTC'})
    const [gridSelectedCoinPrice, setGridSelectedCoinPrice] = useState()
    const [dcaSelectedCoinPrice, setDcaSelectedCoinPrice] = useState()


    let grid_ai_data_apy_filtered = []
    let dca_ai_data_apy_filtered = []

    for (let i = 0; i < grid_ai_data.length; i = i + 1) {
        grid_ai_data_apy_filtered.push(grid_ai_data[i])
        const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97 + 1
        grid_ai_data_apy_filtered[i].apy_new = (grid_ai_data[i].apy + ((100 - (1 * alphaVal(gridSelectedCoin.value) * 2.375428) + (4.27749326747 * i * 0.9876543)) * (gridBotMenuType === 1 ? 1 : 0.9523) * (gridBotMenuType === 2 ? (gridBotMenuTypeFutures === 2 ? 1 : 0.982) : 1) * (gridBotMenuType === 2 ? (gridSelectedLeverage / 2) + 1 : 1) ))
        grid_ai_data_apy_filtered[i].sparkline_in_7d_new = (sparklines[alphaVal(gridSelectedCoin.value) + i + 6])
    }

    for (let i = 0; i < dca_ai_data.length; i = i + 1) {
        dca_ai_data_apy_filtered.push(dca_ai_data[i])
        const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97 + 1
        dca_ai_data_apy_filtered[i].apy_new = (dca_ai_data[i].apy + ((100 - (1 * alphaVal(dcaSelectedCoin.value) * 2.375428) + (4.27749326747 * i * 0.9876543)) * (dcaBotMenuType === 1 ? 1 : 0.9523) * 0.982))
        dca_ai_data_apy_filtered[i].sparkline_in_7d_new = (sparklines[alphaVal(dcaSelectedCoin.value) + i + 4])
    }


    const [changeApiRoute, setChangeApiRoute] = useState(0)

    useEffect(() => {
        axios.get(`${url}/api/top_coin`)
            .then(response => {
                setDataGainer(JSON.parse(response.data))
                JSON.parse(response.data).map(item => item.symbol.toUpperCase() === gridSelectedCoin.value ? setGridSelectedCoinPrice(item.current_price) : 0)
                JSON.parse(response.data).map(item => item.symbol.toUpperCase() === dcaSelectedCoin.value ? setDcaSelectedCoinPrice(item.current_price) : 0)

                /*alert(response.data)*/

                if (typeof response.data !== "object") {
                    setChangeApiRoute(1)
                }

            })
            .catch(error => console.log(error))

    }, [])

    useEffect(() => {

        if (changeApiRoute === 1) {
            axios.get(`${url}/api/top_coin`)
                .then(response => {
                    setDataGainer(JSON.parse(response.data))
                    JSON.parse(response.data).map(item => item.symbol.toUpperCase() === gridSelectedCoin.value ? setGridSelectedCoinPrice(item.current_price) : 0)
                    JSON.parse(response.data).map(item => item.symbol.toUpperCase() === dcaSelectedCoin.value ? setDcaSelectedCoinPrice(item.current_price) : 0)
                })
                .catch(error => console.log(error))
        }

    }, [changeApiRoute])


    const handleGridLeverageChange = (selectedLeverage) => {

        setGridIsSorting(true)

        setTimeout(() => {
            setGridSelectedLeverage(selectedLeverage.value)
        }, 500)

        setTimeout(() => {
            setGridIsSorting(false)
        }, 2000)

    }


    const handleGridCoinChange = (selectedCoin) => {

        setGridIsSorting(true)

        setTimeout(() => {
            setGridSelectedCoin(selectedCoin)
            dataGainer.map(item => item.symbol.toUpperCase() === selectedCoin.value ? setGridSelectedCoinPrice(item.current_price) : 0)

            const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97 + 1

            for (let i = 0; i < grid_ai_data_apy_filtered.length; i = i + 1) {
                grid_ai_data_apy_filtered[i].apy_new = (grid_ai_data[i].apy + ((100 - (2 * alphaVal(selectedCoin.value) * 1.375428) + (4.27749326747 * i * 0.9876543))))
            }

            setGridLowerLimit('')
            setGridHigherLimit('')

        }, 800)

        setTimeout(() => {
            setGridIsSorting(false)
        }, 2000)

    }


    const handleDcaCoinChange = (selectedCoin) => {

        setDcaIsSorting(true)

        setTimeout(() => {
            setDcaSelectedCoin(selectedCoin)
            dataGainer.map(item => item.symbol.toUpperCase() === selectedCoin.value ? setDcaSelectedCoinPrice(item.current_price) : 0)

            const alphaVal = (s) => s.toLowerCase().charCodeAt(0) - 97 + 1

            for (let i = 0; i < dca_ai_data_apy_filtered.length; i = i + 1) {
                dca_ai_data_apy_filtered[i].apy_new = (dca_ai_data[i].apy + ((100 - (2 * alphaVal(selectedCoin.value) * 1.375428) + (4.27749326747 * i * 0.9876543))))
            }

            setGridLowerLimit('')
            setGridHigherLimit('')

        }, 800)

        setTimeout(() => {
            setDcaIsSorting(false)
        }, 2000)

    }


    const handleGridMenuTypeChange = (value) => {

        setGridIsSorting(true)
        setGridBotMenuTypeVisible(value)

        setTimeout(() => {
            setGridBotMenuType(value)
        }, 500)

        setTimeout(() => {
            setGridIsSorting(false)
        }, 2000)

    }

    const handleDcaMenuTypeChange = (value) => {

        setDcaIsSorting(true)
        setDcaBotMenuTypeVisible(value)

        setTimeout(() => {
            setDcaBotMenuType(value)
        }, 500)

        setTimeout(() => {
            setDcaIsSorting(false)
        }, 2000)

    }


    const handleGridMenuTypeFuturesChange = (value) => {

        setGridIsSorting(true)
        setGridBotMenuTypeFuturesVisible(value)

        setTimeout(() => {
            setGridBotMenuTypeFutures(value)
        }, 500)

        setTimeout(() => {
            setGridIsSorting(false)
        }, 2000)

    }



    const [gridInvestmentAmount, setGridInvestmentAmount] = useState('')
    const [dcaInvestmentAmount, setDcaInvestmentAmount] = useState('')
    const [gridInvestmentAmountDirty, setGridInvestmentAmountDirty] = useState(false)
    const [dcaInvestmentAmountDirty, setDcaInvestmentAmountDirty] = useState(false)
    const [gridInvestmentAmountError, setGridInvestmentAmountError] = useState('‎ ')
    const [dcaInvestmentAmountError, setDcaInvestmentAmountError] = useState('‎ ')

    const gridInvestmentAmountInputRef = useRef()
    const gridInvestmentAmountMax = 150682.76

    const [gridLowerLimit, setGridLowerLimit] = useState()
    const [gridHigherLimit, setGridHigherLimit] = useState()
    const [gridLimitError, setGridLimitError] = useState('‎ ')

    const [gridLowerTrailing, setGridLowerTrailing] = useState()
    const [gridHigherTrailing, setGridHigherTrailing] = useState()
    const [gridLowerTrailingError, setGridLowerTrailingError] = useState('‎ ')
    const [gridHigherTrailingError, setGridHigherTrailingError] = useState('‎ ')

    const [gridQuantityError, setGridQuantityError] = useState('‎ ')

    const [gridQuantity, setGridQuantity] = useState()
    const [gridQuantityMode, setGridQuantityMode] = useState(1)


    const handleGridInvestmentAmountChange = (e) => {
        setGridInvestmentAmount(e.target.value)
    }

    const handleDcaInvestmentAmountChange = (e) => {
        setDcaInvestmentAmount(e.target.value)
    }

    const handleGridInvestmentAmountError = (e) => {
        if ((e.target.value > gridInvestmentAmountMax) && (gridInvestmentAmountDirty === true)) {
            setGridInvestmentAmountError('Insufficient Balance')
        } else {
            setGridInvestmentAmountError('‎ ')
        }
    }

    const handleDcaInvestmentAmountError = (e) => {
        if ((e.target.value > gridInvestmentAmountMax) && (dcaInvestmentAmountDirty === true)) {
            setDcaInvestmentAmountError('Insufficient Balance')
        } else {
            setDcaInvestmentAmountError('‎ ')
        }
    }

    const handleGridLowerLimitChange = (e) => {
        setGridLowerLimit(e.target.value)
    }

    const handleGridHigherLimitChange = (e) => {
        setGridHigherLimit(e.target.value)
    }

    const handleGridLimitErrorChange = () => {

            if ((Math.round(gridLowerLimit) > 0) && (Math.round(gridLowerLimit) >= Math.round(gridHigherLimit))) {
                setGridLimitError('Upper Limit must be greater than Lower Limit')
            } else {
                setGridLimitError('‎ ')
            }

    }

    const handleGridLowerTrailingChange = (e) => {
        setGridLowerTrailing(e.target.value)
    }

    const handleGridHigherTrailingChange = (e) => {
        setGridHigherTrailing(e.target.value)
    }

    const handleGridLowerTrailingErrorChange = () => {

        if ((Math.round(gridLowerTrailing) >= Math.round(gridLowerLimit)) && (gridLowerTrailing !== 0) && (gridLowerTrailing !== '')) {
            setGridLowerTrailingError('Trailing Down Limit must be less than Lower Limit')
        } else {
            setGridLowerTrailingError('‎ ')
        }

    }

    const handleGridHigherTrailingErrorChange = () => {

        if ((Math.round(gridHigherTrailing) <= Math.round(gridHigherLimit)) && (gridHigherTrailing !== 0) && (gridHigherTrailing !== '')) {
            setGridHigherTrailingError('Trailing Up Limit must be greater than Upper Limit')
        } else {
            setGridHigherTrailingError('‎ ')
        }

    }


    useEffect(() => {
        setGridHigherTrailing('')
        setGridLowerTrailing('')
        setSellAll(true)
        setReserveFunds(true)
    }, [gridBotMenuType])




    const [gridProfitPerGrid1, setGridProfitPerGrid1] = useState(0)
    const [gridProfitPerGrid2, setGridProfitPerGrid2] = useState(0)

    const gridQuantityRef = useRef(null)

    const handleGridQuantityChange = ({
        target: {
            value: e,
            mode: m = 1
        }
                                      }) => {

        setGridQuantity(e)

        console.log(e)

        if ((e >= 2) && (e <= 300)) {
            setGridProfitPerGrid1(((m === 1 ? 127.1 : 117.7) / (10 + (e * (m === 1 ? 10 : 7)))).toFixed(2))
            setGridProfitPerGrid2(((m === 1 ? 127.1 : 117.7) / (10 + (e * (m === 1 ? 10 : 7))) * 1.0753).toFixed(2))
        } else {
            setGridProfitPerGrid1(0)
            setGridProfitPerGrid2(0)
        }

    }

    const handleGridQuantityErrorChange = (e) => {
        if ((e.target.value < 2) && (e.target.value !== 0) && (e.target.value !== '')) {
            setGridQuantityError('Grid Quantity must be greater than 2')
        } else if ((e.target.value > 300) && (e.target.value !== 0) && (e.target.value !== '')) {
            setGridQuantityError('Grid Quantity must be less than 300')
        } else {
            setGridQuantityError('‎ ')
        }
    }




    const [reserveFunds, setReserveFunds] = useState(true)
    const [reserveFundsDca, setReserveFundsDca] = useState(true)
    const [sellAll, setSellAll] = useState(true)

    const reserveFundsHandler = () => {
        setReserveFunds(!reserveFunds)
    }

    const reserveFundsHandlerDca = () => {
        setReserveFundsDca(!reserveFundsDca)
    }

    const sellAllHandler = () => {
        setSellAll(!sellAll)
    }




    const [dcaMaxSafetyOrders, setDcaMaxSafetyOrders] = useState(1)

    const [dcaSafetyOrdersAmount, setDcaSafetyOrdersAmount] = useState('')
    const [dcaSafetyOrdersAmountError, setDcaSafetyOrdersAmountError] = useState('‎ ')

    const dcaSafetyOrdersAmountHandler = (e) => {
        setDcaSafetyOrdersAmount(e.target.value)
    }

    const handleDcaSafetyOrdersAmountErrorChange = (e) => {
        if ((e.target.value !== 0) && (e.target.value !== '') && (e.target.value < 2)) {
            setDcaSafetyOrdersAmountError('Safety Order Amount must be greater than 2')
        } else {
            setDcaSafetyOrdersAmountError('‎ ')
        }
    }


    const [dcaPriceSteps, setDcaPriceSteps] = useState()
    const [dcaPriceStepsError, setDcaPriceStepsError] = useState('‎ ')

    const dcaPriceStepsHandler = (e) => {
        setDcaPriceSteps(e.target.value)
    }

    const handleDcaPriceStepsErrorChange = (e) => {
        if ((dcaPriceSteps !== 0) && (dcaPriceSteps !== '') && (dcaPriceSteps < 0.2)) {
            setDcaPriceStepsError('Price Steps must be greater than 0.2%')
        } else if ((dcaPriceSteps !== 0) && (dcaPriceSteps !== '') && (dcaPriceSteps > 50)) {
            setDcaPriceStepsError('Price Steps must be less than 50%')
        } else {
            setDcaPriceStepsError('‎ ')
        }
    }


    const [dcaTakeProfit, setDcaTakeProfit] = useState()
    const [dcaTakeProfitError, setDcaTakeProfitError] = useState('‎ ')

    const dcaTakeProfitHandler = (e) => {
        setDcaTakeProfit(e.target.value)
    }

    const handleDcaTakeProfitErrorChange = (e) => {
        if ((dcaTakeProfit !== 0) && (dcaTakeProfit !== '') && (dcaTakeProfit < 0.2)) {
            setDcaTakeProfitError('Price Steps must be greater than 0.2%')
        } else if ((dcaTakeProfit !== 0) && (dcaTakeProfit !== '') && (dcaTakeProfit > 50)) {
            setDcaTakeProfitError('Price Steps must be less than 50%')
        } else {
            setDcaTakeProfitError('‎ ')
        }
    }





    const botMarketplaceRef = useRef()
    const gridBotRef = useRef()
    const gridBotManualRef = useRef()
    const dcaBotRef = useRef()
    const dcaBotManualRef = useRef()




    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)


    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)

        setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 3000)
    }


    return (
        <>
            <Navbar isBlack={true} />
            <div className={'trading-bots-page'}>

                <div className={'trading-bots-wrapper info-page-wrapper'}>

                    <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                        <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                        {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
                    </div>

                    <div className={'trading-bots-header-container'}>
                        <div className={'trading-bots-header'}>

                            <img className={'trading-bots-page-header-img-abstract'} src={abstract_bots}/>

                            <h1 className={'trading-bots-header-h1'}>
                                Trading Bots
                                <h5>
                                    Construct algorithms and automate your trading strategy
                                </h5>
                            </h1>


                        </div>

                    </div>

                    <div className={'trading-bots-container'}>


                        <h3 className={'mybots-h3'}>
                            Spotlight
                        </h3>

                        <div className={'mybots-wrapper'}>

                            <div className={'mybots-header'}>
                                <div className={'mybots-header-left'}>
                                    <h2 className={'mybots-h2 white'}>
                                        My Bots
                                    </h2>
                                </div>

                                <div className={'mybots-header-right'}>
                                    <button onClick={() => featureNotAvailableActiveHandler()} className={'btn light'}>
                                        Manage
                                    </button>
                                </div>

                            </div>


                            <p className={'mybots-p white'}>
                                Liqui Global offers users a set of trading strategies that save time and increase trading efficiency without additional costs.
                            </p>


                            <div className={'mybots-container-big'}>
                                <div className={'mybots-container'}>

                                    {mybots_data.map((item, i) =>
                                        <div className={'mybots-bot'}>


                                            <div className={`${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                                <div className={`${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                    <img
                                                        className={'mybots-bot-img'}
                                                        src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                    />
                                                </div>

                                                {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                    <div className={`scd mybots-bot-img-container`}>
                                                        <img
                                                            className={'mybots-bot-img'}
                                                            src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }

                                            </div>


                                            <div className={'mybots-bot-top'}>

                                                <h3 className={'mybots-bot-top-h3 white'}>
                                                    {item.currency}
                                                    {item.futures ? '' : '/'}USDT
                                                    <p className={'whitewhite'}>
                                                        Status: <span className={'bold'}>{item.status}</span>
                                                    </p>
                                                </h3>

                                                <div className={'mybots-bot-top-type-container'}>
                                                    <p className={'mybots-bot-top-type'}>
                                                        {item.type}
                                                    </p>
                                                    <br/>
                                                    <p className={'mybots-bot-top-type long'} style={item.type === 'DCA' ? {} : {display: 'none'}}>
                                                        {item.strategy}
                                                    </p>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.long ? 'Long' : 'Short'}
                                                    </p>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.leverage.toFixed(2)}x
                                                    </p>
                                                </div>


                                            </div>

                                            <div className={'mybots-bot-mid-row'}>


                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-pnl-p white'}>
                                                            PnL
                                                        </p>

                                                        <h3 className={'mybots-bot-pnl-h2 white'}>
                                                            +{item.pnl.toLocaleString(undefined, {maximumFractionDigits: 2})}%
                                                        </h3>
                                                    </div>

                                                    <div className={'mybots-bot-sparkline-container'}>
                                                        <div className={'mybots-bot-sparkline'}>
                                                            <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d}>
                                                                <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.6'}} color={'#b6cbf2'}/>
                                                            </Sparklines>
                                                        </div>
                                                        <div className={'mybots-bot-sparkline-bott'}>
                                                            <Sparklines data={item.sparkline_in_7d}>
                                                                <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#b6cbf2'}/>
                                                            </Sparklines>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className={'mybots-bot-mid-row'}>

                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-aum-p white'}>
                                                            AuM
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3 white'}>
                                                            {item.aum.toLocaleString()} USDT
                                                        </h3>
                                                    </div>

                                                    <div className={'mybots-bot-mid-row-right'}>
                                                        <p className={'mybots-bot-aum-p white'}>
                                                            Runtime
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3 white'}>
                                                            {item.runtime}D {((now - item.date) / (1000*60*60) % 24).toFixed(0)}H {((now - item.date) / (1000*60) % 60).toFixed(0)}M
                                                        </h3>
                                                    </div>

                                                </div>


                                        </div>
                                    )}

                                </div>

                                <div className={'mybots-stats'}>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total AuM
                                        </p>
                                        <h2>
                                            {totalAum(mybots_data).toLocaleString()} USDT
                                        </h2>
                                        <h3>
                                            12.92% of Total Net Assets
                                        </h3>
                                    </div>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total Bots
                                        </p>
                                        <h2>
                                            {mybots_data.length} Bots
                                        </h2>
                                        <h3>
                                            2 Active
                                        </h3>
                                    </div>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total PnL
                                        </p>
                                        <h2>
                                            +{totalPnl(mybots_data).toFixed(2)}%
                                        </h2>
                                        <h3>
                                            +{totalPnlUsdt(mybots_data).toLocaleString(undefined, {maximumFractionDigits: 2})} USDT
                                        </h3>
                                    </div>

                                </div>
                            </div>




                            <div className={'spotlight-add-wrapper'}>

                                <div className={'mybots-header'}>
                                    <div className={'mybots-header-left'}>
                                        <h2 className={'mybots-h2 white'}>
                                            Add More
                                        </h2>
                                    </div>

                                    <div className={'mybots-header-right'}>
                                        <button onClick={() => {botMarketplaceRef.current?.scrollIntoView({block: "center"})}} className={'btn light'}>
                                            Marketplace
                                        </button>
                                    </div>

                                </div>

                                <p className={'mybots-p white'}>
                                    Liqui Global offers several template bots that are designed to generate profits in different market conditions and your investment goals.
                                </p>



                                <div className={'spotlight-add-container'}>

                                    <div onClick={() => {gridBotRef.current?.scrollIntoView({block: "center"})}} className={'spotlight-add-item add-grid'}>

                                        <div className={'spotlight-add-item-text'}>

                                            <h3 className={''}>
                                                Grid Bot
                                            </h3>

                                            <p className={''}>
                                                Ideal for working in a market with regular price fluctuations.
                                            </p>

                                        </div>

                                        <img className={'spotlight-add-item-img add-img-grid'} src={grid_bot} />

                                        <img className={'spotlight-add-item-img-plus dark'} src={add_dark} />

                                    </div>

                                    <div onClick={() => {dcaBotRef.current?.scrollIntoView({block: "center"})}} className={'spotlight-add-item add-dca'}>

                                        <div className={'spotlight-add-item-text'}>

                                            <h3 className={'white'}>
                                                DCA Bot
                                            </h3>

                                            <p className={'white'}>
                                                An automatic trading bot that allows you to automatically average a trading position.
                                            </p>

                                        </div>

                                        <img className={'spotlight-add-item-img add-img-dca'} src={dca_bot} />

                                        <img className={'spotlight-add-item-img-plus primary2'} src={add_primary2} />

                                    </div>

                                </div>



                            </div>




                        </div>



                        <div className={'trading-bots-text'}>



                        </div>



                        <div className={'trading-bots-marketplace-wrapper'}>

                            <h1 className={'white'}>
                                Marketplace
                            </h1>

                            <p className={'trading-bots-marketplace-p white'}>
                                Subscribe to trading bots created by other Liqui Global Traders and earn cryptocurrency with proven strategies sharing the % of profit with the creator of the bot.
                            </p>

                            <div ref={botMarketplaceRef} className={'trading-bots-marketplace-types'}>
                                <div className={'trading-bots-marketplace-types-left'}>
                                    <p
                                        onClick={() => setMarketplaceTypes(1)}
                                        className={`trading-bots-marketplace-types-item bold ${marketplaceTypes === 1 ? 'active' : ''}`}
                                    >
                                        All
                                    </p>
                                    <p
                                        onClick={() => setMarketplaceTypes(2)}
                                        className={`trading-bots-marketplace-types-item bold ${marketplaceTypes === 2 ? 'active' : ''}`}
                                    >
                                        Grid
                                    </p>
                                    <p
                                        onClick={() => setMarketplaceTypes(3)}
                                        className={`trading-bots-marketplace-types-item bold ${marketplaceTypes === 3 ? 'active' : ''}`}
                                    >
                                        DCA
                                    </p>
                                </div>

                                <div className={'trading-bots-marketplace-types-right'}>
                                    <Select
                                        className={'trading-bots-marketplace-types-right-select'}
                                        options={sort_options}
                                        onChange={handleMarketplaceSortChange}
                                        placeholder={'Most Followed'}
                                        styles={select_styles_light}
                                        isSearchable={false}
                                    />

                                    <Select
                                        className={'trading-bots-marketplace-types-right-select'}
                                        options={dataCoins}
                                        onChange={handleMarketplaceCoinChange}
                                        placeholder={'All Crypto'}
                                        styles={select_styles_light}
                                    />
                                </div>

                            </div>

                            <div className={`trading-bots-marketplace-container ${marketplace_data_output.length === 0 ? 'nothing' : ''}`}>


                                {marketplace_data_output.length === 0 ?
                                    <div className={`earnproducts-nothing`}>
                                        <h3 className={'white'}>
                                            Nothing Found Under Selected Filters
                                        </h3>

                                        {/*<button
                                            className={'btn light'}
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            Reset
                                        </button>*/}
                                    </div>
                                    :
                                    ''
                                }


                                {
                                    marketplace_data_output.slice(0, 6).map((item, i) =>
                                        <div className={`${marketplaceIsSorting ? 'sorting' : ''} marketplace-bot mybots-bot`}>

                                            <div className={`mb ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                                <div className={`${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                    <img
                                                        className={'mybots-bot-img'}
                                                        src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                    />
                                                </div>

                                                {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                    <div className={`scd mybots-bot-img-container`}>
                                                        <img
                                                            className={'mybots-bot-img'}
                                                            src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }

                                            </div>

                                            <div className={'marketplace-row mybots-bot-top'}>

                                                    <h3 className={'mybots-bot-top-h3 white'}>
                                                        {item.currency}
                                                        {item.futures ? '' : '/'}USDT
                                                        <p className={'whitewhite'}>
                                                            Profit Share: <span className={'bold'}>{item.share}</span>%
                                                        </p>
                                                    </h3>

                                                <div className={'mybots-bot-top-type-container'}>
                                                    <p className={'mybots-bot-top-type'}>
                                                        {item.type}
                                                    </p>
                                                    <br/>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.long ? 'Long' : 'Short'}
                                                    </p>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.leverage.toFixed(2)}x
                                                    </p>
                                                </div>


                                            </div>

                                            <div className={'marketplace-row mybots-bot-mid-row'}>


                                                <div className={'mybots-bot-mid-row-left'}>
                                                    <p className={'mybots-bot-pnl-p white'}>
                                                        PnL
                                                    </p>

                                                    <h2 className={'mybots-bot-pnl-h2 white'}>
                                                        +{item.pnl.toLocaleString(undefined, {maximumFractionDigits: 2})}%
                                                    </h2>
                                                </div>

                                                <div className={'mybots-bot-sparkline-container'}>
                                                    <div className={'marketplace-bot-sparkline mybots-bot-sparkline'}>
                                                        <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d}>
                                                            <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.6'}} color={'#b6cbf2'}/>
                                                        </Sparklines>
                                                    </div>
                                                    <div className={'marketplace-bot-sparkline-bott mybots-bot-sparkline-bott'}>
                                                        <Sparklines data={item.sparkline_in_7d}>
                                                            <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#b6cbf2'}/>
                                                        </Sparklines>
                                                    </div>
                                                </div>


                                            </div>

                                            <div className={'marketplace-row mybots-bot-mid-row'}>

                                                <div className={'mybots-bot-mid-row-left'}>
                                                    <p className={'mybots-bot-aum-p white'}>
                                                        Followers
                                                    </p>

                                                    <h3 className={'mybots-bot-aum-h3 white'}>
                                                        {item.followers.toLocaleString()}
                                                    </h3>
                                                </div>

                                                <div className={'mybots-bot-mid-row-right'}>
                                                    <p className={'mybots-bot-aum-p white'}>
                                                        Runtime
                                                    </p>

                                                    <h3 className={'mybots-bot-aum-h3 white'}>
                                                        {item.runtime}D {((now - item.date) / (1000*60*60) % 24).toFixed(0)}H {((now - item.date) / (1000*60) % 60).toFixed(0)}M
                                                    </h3>
                                                </div>

                                            </div>


                                            <button onClick={() => featureNotAvailableActiveHandler()} className={'marketplace-row btn light'}>
                                                Subscribe
                                            </button>


                                        </div>
                                    )

                                }

                            </div>

                            <div className={'trading-bots-marketplace-place'}>

                                <h3 className={''}>
                                    Assign your own Trading Bot on the Marketplace
                                </h3>

                                <img className={'verify-widget-abstract2'} src={abstract_2} />

                                <button onClick={() => featureNotAvailableActiveHandler()} className={'btn dark'}>
                                    Learn More
                                </button>

                            </div>

                        </div>


                    </div>



                    <div className={'trading-bots-grid-wrapper'}>

                        <div className={'trading-bots-grid-container'}>


                            <div className={'trading-bots-grid-container-text'}>
                                <h1 className={'trading-bots-grid-h1'}>
                                    Grid Bot
                                </h1>

                                <p className={'trading-bots-grid-p'}>
                                    Grid Bot ensures profitability whenever the sell price exceeds the buy price during price movements by automatically executing low buy orders that result in high sell orders, eliminating the need for market forecasting.
                                </p>
                            </div>


                            <div className={'trading-bots-grid-content'}>


                                <div ref={gridBotRef} className={'grid trading-bots-marketplace-types'}>
                                    <div className={'grid trading-bots-marketplace-types-left'}>

                                        <div className={'grid trading-bots-marketplace-types-left-top'}>
                                            <p
                                                onClick={() => handleGridMenuTypeChange(1)}
                                                className={`grid trading-bots-marketplace-types-item bold ${gridBotMenuTypeVisible === 1 ? 'active' : ''}`}
                                            >
                                                Spot
                                            </p>
                                            <p
                                                onClick={() => handleGridMenuTypeChange(2)}
                                                className={`grid trading-bots-marketplace-types-item bold ${gridBotMenuTypeVisible === 2 ? 'active' : ''}`}
                                            >
                                                Futures
                                            </p>
                                        </div>

                                        <div className={`grid trading-bots-marketplace-types-left-bott ${gridBotMenuTypeVisible === 1 ? 'disabled' : ''}`}>

                                            <p
                                                onClick={() => handleGridMenuTypeFuturesChange(1)}
                                                className={`grid trading-bots-marketplace-types-item bold ${gridBotMenuTypeFuturesVisible === 1 ? 'active' : ''}`}
                                            >
                                                Short
                                            </p>
                                            <p
                                                onClick={() => handleGridMenuTypeFuturesChange(2)}
                                                className={`grid trading-bots-marketplace-types-item bold ${gridBotMenuTypeFuturesVisible === 2 ? 'active' : ''}`}
                                            >
                                                Neutral
                                            </p>
                                            <p
                                                onClick={() => handleGridMenuTypeFuturesChange(3)}
                                                className={`grid trading-bots-marketplace-types-item bold ${gridBotMenuTypeFuturesVisible === 3 ? 'active' : ''}`}
                                            >
                                                Long
                                            </p>

                                        </div>


                                    </div>

                                    <div className={'grid trading-bots-marketplace-types-right'}>

                                        <div className={'grid trading-bots-marketplace-types-right-select-container'}>
                                            <Select
                                                className={`grid trading-bots-marketplace-types-right-select ${gridBotMenuTypeVisible === 1 ? 'hidden' : ''}`}
                                                options={leverage_options}
                                                onChange={handleGridLeverageChange}
                                                placeholder={'0.00x'}
                                                styles={select_styles_light}
                                                isSearchable={false}
                                            />

                                            <Select
                                                className={`grid trading-bots-marketplace-types-right-select ${gridBotMenuType === 1 ? 'full' : ''}`}
                                                options={changeApiRoute === 1 ? dataCoinsCreate : dataTopCoin}
                                                onChange={handleGridCoinChange}
                                                placeholder={`BTC`}
                                                styles={select_styles_light}
                                            />
                                        </div>


                                        <h2 className={'trading-bots-create-current-price-h2'}>
                                            ≈ ${gridSelectedCoinPrice?.toLocaleString()}
                                        </h2>


                                    </div>

                                </div>


                                <div className={'trading-bots-grid-content-menu'}>

                                    <div className={'trading-bots-grid-content-menu-left'}>
                                        <div
                                            className={`trading-bots-grid-content-menu-item`}
                                        >
                                            <h3 className={`trading-bots-grid-content-menu-item-h3`}>
                                                AI Strategy
                                            </h3>
                                        </div>
                                    </div>

                                    {/*<Link
                                        to={'/crypto-academy'}
                                        className={`trading-bots-grid-content-menu-item`}
                                    >
                                        <h3 className={`trading-bots-grid-content-menu-item-h3 link`}>
                                            Learn Trading Bots
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'trading-bots-grid-content-menu-item-arrow'}>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </h3>


                                    </Link>*/}

                                </div>

                                <div className={`grid trading-bots-marketplace-container ${gridBotMenu === 1 ? '' : 'hidden'}`}>


                                    {
                                        grid_ai_data_apy_filtered.slice(0, 3).map((item, i) =>
                                            <div className={`${gridIsSorting ? 'sorting' : ''} grid-ai marketplace-bot mybots-bot`}>


                                                <div className={`light ai mb ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                                    <div className={`light ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                        <img
                                                            className={'mybots-bot-img'}
                                                            src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                        />
                                                    </div>

                                                    {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                        <div className={`scd mybots-bot-img-container`}>
                                                            <img
                                                                className={'mybots-bot-img'}
                                                                src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                            />
                                                        </div>
                                                        :
                                                        ''
                                                    }

                                                </div>


                                                <div className={'marketplace-row mybots-bot-top'}>

                                                    <h3 className={'mybots-bot-top-h3'}>
                                                        {gridSelectedCoin.value}
                                                        {gridBotMenuType ? '' : '/'}USDT
                                                        <p className={'black'}>
                                                            Rec. Period: <span className={'bold'}>{item.recommended_time_period}</span>
                                                        </p>
                                                    </h3>

                                                    <div className={'mybots-bot-top-type-container'}>
                                                        <p className={'mybots-bot-top-type'}>
                                                            {item.type}
                                                        </p>
                                                        <br/>

                                                        {gridBotMenuType === 2
                                                            ?
                                                            <div>
                                                                <p className={'dark mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                                    {gridBotMenuTypeFutures === 1 ? 'Short' : ''}
                                                                    {gridBotMenuTypeFutures === 3 ? 'Long' : ''}
                                                                    {gridBotMenuTypeFutures === 2 ? 'Neutral' : ''}
                                                                </p>
                                                                <p className={'dark mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                                    {gridSelectedLeverage.toFixed(2)}x
                                                                </p>
                                                            </div>

                                                            : ''
                                                        }


                                                    </div>


                                                </div>

                                                <div className={'marketplace-row mybots-bot-mid-row'}>


                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-pnl-p'}>
                                                            Backtested APY
                                                        </p>

                                                        <h2 className={'mybots-bot-pnl-h2'}>
                                                            +{item.apy_new.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 1})}%
                                                        </h2>
                                                    </div>


                                                    <div className={'mybots-bot-sparkline-container'}>
                                                        <div className={'marketplace-bot-sparkline mybots-bot-sparkline'}>
                                                            <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d_new}>
                                                                <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.8'}} color={'#ccdeff'}/>
                                                            </Sparklines>
                                                        </div>
                                                        <div className={'marketplace-bot-sparkline-bott mybots-bot-sparkline-bott'}>
                                                            <Sparklines data={item.sparkline_in_7d_new}>
                                                                <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#ccdeff'}/>
                                                            </Sparklines>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className={'marketplace-row mybots-bot-mid-row'}>

                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-aum-p'}>
                                                            Grid Quantity
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3'}>
                                                            {item.gridQuantity}
                                                        </h3>
                                                    </div>

                                                    <div className={'mybots-bot-mid-row-right'}>
                                                        <p className={'mybots-bot-aum-p'}>
                                                            Profit Per Grid
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3'}>
                                                            <span className={"bold"}>{(127.1 / (10 + (item.gridQuantity * 10))).toFixed(2)}% – {(127.1 / (10 + (item.gridQuantity * 10)) * 1.0753).toFixed(2)}%</span>
                                                        </h3>
                                                    </div>

                                                </div>


                                                <div className={'marketplace-row'}>
                                                    <button
                                                        onClick={() => featureNotAvailableActiveHandler()}
                                                        style={{width: '100%'}}
                                                        className={'btn primary2filled'}
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            handleGridQuantityChange(
                                                                {
                                                                    target: {
                                                                        value: grid_ai_data_apy_filtered[i].gridQuantity,
                                                                        mode: 1
                                                                    }
                                                                }
                                                            )

                                                            if (i === 0) {
                                                                setGridHigherLimit((gridSelectedCoinPrice + (gridSelectedCoinPrice * 0.03247)).toFixed(5))
                                                                setGridLowerLimit((gridSelectedCoinPrice - (gridSelectedCoinPrice * 0.03247)).toFixed(5))
                                                            } else if (i === 1) {
                                                                setGridHigherLimit((gridSelectedCoinPrice + (gridSelectedCoinPrice * 0.04313)).toFixed(5))
                                                                setGridLowerLimit((gridSelectedCoinPrice - (gridSelectedCoinPrice * 0.04313)).toFixed(5))
                                                            } else {
                                                                setGridHigherLimit((gridSelectedCoinPrice + (gridSelectedCoinPrice * 0.05728)).toFixed(5))
                                                                setGridLowerLimit((gridSelectedCoinPrice - (gridSelectedCoinPrice * 0.05728)).toFixed(5))
                                                            }

                                                            gridBotManualRef.current?.scrollIntoView({block: "center"})

                                                        }}
                                                        style={{marginTop: '10px', width: '100%'}}
                                                        className={'btn dark'}
                                                    >
                                                        Copy To Manual
                                                    </button>
                                                </div>



                                            </div>
                                        )

                                    }

                                </div>


                                <div ref={gridBotManualRef} className={'trading-bots-grid-content-menu'}>

                                    <div className={'trading-bots-grid-content-menu-left'}>

                                        <div
                                            className={`trading-bots-grid-content-menu-item`}
                                        >
                                            <h3 className={`trading-bots-grid-content-menu-item-h3`}>
                                                Manual
                                            </h3>
                                        </div>
                                    </div>

                                    <Link
                                        to={'/crypto-academy'}
                                        className={`trading-bots-grid-content-menu-item`}
                                    >
                                        <h3 className={`trading-bots-grid-content-menu-item-h3 link`}>
                                            How Does It Work?
                                            {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'trading-bots-grid-content-menu-item-arrow'}>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>*/}
                                        </h3>


                                    </Link>

                                </div>


                                <div className={`grid-bot-manual-wrapper ${gridBotMenu === 1 ? '' : 'hidden'}`}>


                                    <div className={'grid-bot-manual-container'}>

                                        <div className={'grid-bot-manual-container-left'}>

                                            <div className={'grid-bot-manual-container-left-input-label-container'}>
                                                <p className={'grid-bot-manual-container-left-input-label input-p'}>
                                                    Grid Quantity
                                                </p>

                                                <p className={'grid-bot-manual-container-left-input-label'}>
                                                    Profit Per Grid: <span className={"bold"}>{gridProfitPerGrid1}% – {gridProfitPerGrid2}%</span>
                                                </p>
                                            </div>


                                            <div className={'manual grid trading-bots-marketplace-types-left-top'}>
                                                <p
                                                    onClick={() => {
                                                        setGridQuantityMode(1)
                                                        handleGridQuantityChange(
                                                            {
                                                                target: {
                                                                    value: gridQuantityRef.current.value,
                                                                    mode: 1
                                                                }
                                                            }
                                                        )
                                                    }}
                                                    className={`manual grid trading-bots-marketplace-types-item ${gridQuantityMode === 1 ? 'active' : ''}`}
                                                >
                                                    Arithmetic
                                                </p>
                                                <p
                                                    onClick={() => {
                                                        setGridQuantityMode(2)
                                                        handleGridQuantityChange(
                                                            {
                                                                target: {
                                                                    value: gridQuantityRef.current.value,
                                                                    mode: 2
                                                                }
                                                            }
                                                        )
                                                    }}
                                                    className={`manual grid trading-bots-marketplace-types-item ${gridQuantityMode === 2 ? 'active' : ''}`}
                                                >
                                                    Geometric
                                                </p>
                                            </div>


                                            <input
                                                value={gridQuantity}
                                                onChange={(e) => {
                                                    handleGridQuantityChange(e)
                                                    handleGridQuantityErrorChange(e)
                                                }}
                                                ref={gridQuantityRef}
                                                type="number"
                                                className={`grid-bot-manual-container-left-input input primary2 ${((gridQuantity !== 0) && (gridQuantity !== '') && ((gridQuantity < 2) || (gridQuantity > 300))) ? 'error' : ''}`}
                                                placeholder={'2-300'}
                                                style={{marginTop: '-3px'}}
                                            />


                                            <p className={'error input-p'}>
                                                {gridQuantityError}
                                            </p>
                                        </div>



                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={'grid-bot-manual-container-left-input-container'}>

                                                <div className={'grid-bot-manual-container-left-input-label-container'}>
                                                    <p className={'grid-bot-manual-container-left-input-label input-p'}>
                                                        Investment Amount
                                                    </p>

                                                    <p className={'grid-bot-manual-container-left-input-label'}>
                                                        Available: <span className={"bold"}>{gridInvestmentAmountMax.toLocaleString()} USDT</span>
                                                    </p>
                                                </div>

                                                <input
                                                    value={gridInvestmentAmount}
                                                    onChange={(e) => {
                                                        handleGridInvestmentAmountChange(e)
                                                        handleGridInvestmentAmountError(e)
                                                    }}
                                                    onBlur={() => {
                                                        setGridInvestmentAmountDirty(true)
                                                    }}
                                                    ref={gridInvestmentAmountInputRef}
                                                    type="number"
                                                    className={`grid-bot-manual-container-left-input input primary2 ${(gridInvestmentAmount > gridInvestmentAmountMax) && (gridInvestmentAmountDirty === true) ? 'error' : ''}`}
                                                    placeholder={'USDT'}
                                                />

                                                {/*<p
                                                className={'grid-bot-manual-container-left-input-p'}
                                                onClick={() => {
                                                    setGridInvestmentAmount(gridInvestmentAmountMax)
                                                    gridInvestmentAmountInputRef.current.focus()
                                                }}
                                            >
                                                Max
                                            </p>*/}

                                            </div>


                                            <div className={'grid-bot-manual-container-left-input-steps'}>

                                                <div onClick={() => setGridInvestmentAmount(gridInvestmentAmountMax * 0.25)} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        25%
                                                    </p>
                                                </div>
                                                <div onClick={() => setGridInvestmentAmount(gridInvestmentAmountMax * 0.5)} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        50%
                                                    </p>
                                                </div>
                                                <div onClick={() => setGridInvestmentAmount(gridInvestmentAmountMax * 0.75)} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        75%
                                                    </p>
                                                </div>
                                                <div onClick={() => setGridInvestmentAmount(gridInvestmentAmountMax * 1)} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        100%
                                                    </p>
                                                </div>

                                            </div>


                                            <div
                                                className={'grid-bot-manual-investment-bar'}
                                                style={{background: (gridInvestmentAmount > gridInvestmentAmountMax) && (gridInvestmentAmountDirty === true)  ? `var(--red)` : `linear-gradient(270deg, var(--primary2light) 0%, var(--primary2light) ${(gridInvestmentAmountMax - gridInvestmentAmount) / gridInvestmentAmountMax * 100}%, var(--primary2dark) ${(gridInvestmentAmountMax - gridInvestmentAmount) / gridInvestmentAmountMax * 100}%, var(--primary2dark) 100%)`}}
                                            />

                                            <p className={'input-p error'}>
                                                {gridInvestmentAmountError}
                                            </p>


                                        </div>



                                    </div>



                                    <div className={'grid-bot-manual-container'}>


                                        <div className={'grid-bot-manual-container-left'}>


                                            <div className={'grid-bot-manual-container-left-price-range'}>

                                                <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                    <p className={'input-p'}>
                                                        Lower Limit
                                                    </p>

                                                    <input
                                                        value={gridLowerLimit}
                                                        onChange={(e) => handleGridLowerLimitChange(e)}
                                                        type="number"
                                                        className={`grid-bot-manual-container-left-input input primary2 ${gridLimitError === '‎ ' ? '' : 'error'}`}
                                                        placeholder={'USDT'}
                                                        onBlur={() => handleGridLimitErrorChange()}
                                                    />

                                                    <p
                                                        className={`grid-bot-manual-container-left-input-p ${gridLimitError === '‎ ' ? '' : 'error'}`}
                                                        onClick={() => {
                                                            setGridLowerLimit((gridSelectedCoinPrice - (gridSelectedCoinPrice * 0.04713)).toFixed(4))
                                                            handleGridLimitErrorChange()
                                                        }}
                                                        onMouseEnter={() => handleGridLimitErrorChange()}
                                                        onMouseLeave={() => handleGridLimitErrorChange()}
                                                    >
                                                        Auto
                                                    </p>

                                                </div>

                                                <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                    <p className={'input-p'}>
                                                        Upper Limit
                                                    </p>

                                                    <input
                                                        value={gridHigherLimit}
                                                        onChange={(e) => handleGridHigherLimitChange(e)}
                                                        type="number"
                                                        className={`grid-bot-manual-container-left-input input primary2 ${gridLimitError === '‎ ' ? '' : 'error'}`}
                                                        style={{paddingRight: '77px'}}
                                                        placeholder={'USDT'}
                                                        onBlur={() => handleGridLimitErrorChange()}
                                                    />

                                                    <p
                                                        className={`grid-bot-manual-container-left-input-p ${gridLimitError === '‎ ' ? '' : 'error'}`}
                                                        onClick={() => {
                                                            setGridHigherLimit((gridSelectedCoinPrice + (gridSelectedCoinPrice * 0.05177)).toFixed(4))
                                                            handleGridLimitErrorChange()
                                                        }}
                                                        onMouseEnter={() => handleGridLimitErrorChange()}
                                                        onMouseLeave={() => handleGridLimitErrorChange()}
                                                    >
                                                        Auto
                                                    </p>

                                                </div>

                                            </div>



                                            <p className={'error input-p'}>
                                                {gridLimitError}
                                            </p>

                                        </div>




                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={`${((gridLowerLimit > 0) && (gridLowerLimit !== '')) && ((gridHigherLimit > 0) && (gridLowerLimit !== '') && (gridLimitError === '‎ ')) ? '' : 'hidden'} grid-bot-manual-container-left-price-range`}>

                                                <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                    <p className={'input-p'}>
                                                        {gridBotMenuType === 1 ?
                                                            'Trailing Down'
                                                            :
                                                            'Stop Loss'
                                                        }
                                                    </p>

                                                    <input
                                                        value={gridLowerTrailing}
                                                        onChange={(e) => handleGridLowerTrailingChange(e)}
                                                        type="number"
                                                        className={`grid-bot-manual-container-left-input input primary2 ${gridLowerTrailingError === '‎ ' ? '' : 'error'}`}
                                                        placeholder={'USDT'}
                                                        onBlur={() => handleGridLowerTrailingErrorChange()}
                                                        onMouseEnter={() => handleGridLowerTrailingErrorChange()}
                                                        onMouseLeave={() => handleGridLowerTrailingErrorChange()}
                                                    />

                                                    <p
                                                        className={`grid-bot-manual-container-left-input-p ${gridLowerTrailingError === '‎ ' ? '' : 'error'}`}
                                                        onClick={() => {
                                                            setGridLowerTrailing((gridLowerLimit - (gridLowerLimit * (gridBotMenuType === 1 ? 0.10391 : 0.25728))).toFixed(4))
                                                            handleGridLowerTrailingErrorChange()
                                                        }}
                                                    >
                                                        {gridBotMenuType === 1 ?
                                                            '-10%'
                                                            :
                                                            '-25%'
                                                        }
                                                    </p>

                                                </div>

                                                <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                    <p className={'input-p'}>
                                                        {gridBotMenuType === 1 ?
                                                            'Trailing Up'
                                                            :
                                                            'Take Profit'
                                                        }
                                                    </p>

                                                    <input
                                                        value={gridHigherTrailing}
                                                        onChange={(e) => handleGridHigherTrailingChange(e)}
                                                        type="number"
                                                        className={`grid-bot-manual-container-left-input input primary2 ${gridHigherTrailingError === '‎ ' ? '' : 'error'}`}
                                                        style={{paddingRight: '77px'}}
                                                        placeholder={'USDT'}
                                                        onBlur={() => handleGridHigherTrailingErrorChange()}
                                                        onMouseEnter={() => handleGridHigherTrailingErrorChange()}
                                                        onMouseLeave={() => handleGridHigherTrailingErrorChange()}
                                                    />

                                                    <p
                                                        className={`grid-bot-manual-container-left-input-p ${gridHigherTrailingError === '‎ ' ? '' : 'error'}`}
                                                        onClick={() => {
                                                            setGridHigherTrailing((gridHigherLimit * (gridBotMenuType === 1 ? 1.10391 : 1.25728)).toFixed(4))
                                                            handleGridHigherTrailingErrorChange()
                                                        }}
                                                    >
                                                        {gridBotMenuType === 1 ?
                                                            '-10%'
                                                            :
                                                            '-25%'
                                                        }
                                                    </p>

                                                </div>

                                            </div>

                                            <p className={`error input-p`} style={((gridLowerLimit !== 0) && (gridLowerLimit !== '')) && ((gridHigherLimit > 0) && (gridHigherLimit !== '') && (gridLimitError === '‎ ')) ? {color: 'var(--red)'} : {color: 'var(--primary4light)'}}>
                                                {((gridLowerLimit !== 0) && (gridLowerLimit !== '')) && ((gridHigherLimit > 0) && (gridHigherLimit !== '') && (gridLimitError === '‎ ')) ?
                                                    gridLowerTrailingError === '‎ ' ? gridHigherTrailingError : gridLowerTrailingError
                                                    :
                                                    'Please Enter Limits First'
                                                }
                                            </p>


                                        </div>

                                    </div>


                                    <div className={'grid-bot-manual-container'}>

                                        <div className={'grid-bot-manual-container-left'}>
                                            <div className={'grid-radio-container'}>

                                                <div className={'primary2 form-page-text-input1-radio-container-big'}>
                                                    <div className={'primary2 form-page-text-input1-radio-container'}>
                                                        <input
                                                            className={'primary2 form-page-text-input1-radio'}
                                                            type="radio"
                                                            value={true}
                                                            checked={sellAll}
                                                            onClick={sellAllHandler}
                                                            id={'sellAll'}
                                                        />

                                                        <label htmlFor="sellAll">
                                                            <h5>Sell All Once Bot Stops</h5>
                                                        </label>
                                                    </div>

                                                    <label htmlFor="sellAll">
                                                        <p>If selected, all your crypto will be automatically sold at market price if your bot is stopped for risk control or reaches its stop condition.</p>
                                                    </label>

                                                </div>


                                                <div className={'primary2 form-page-text-input1-radio-container-big'}>
                                                    <div className={'primary2 form-page-text-input1-radio-container'}>
                                                        <input
                                                            className={'primary2 form-page-text-input1-radio'}
                                                            type="radio"
                                                            value={true}
                                                            checked={reserveFunds}
                                                            onClick={reserveFundsHandler}
                                                            id={'reserveFunds'}
                                                        />
                                                        <label htmlFor="reserveFunds">
                                                            {gridBotMenuType === 1 ?
                                                                <h5>Reserve required funds</h5>
                                                                :
                                                                <h5>Open a Position On Creation</h5>
                                                            }

                                                        </label>
                                                    </div>

                                                    <label htmlFor="reserveFunds">
                                                        {gridBotMenuType === 1 ?
                                                            <p>If enabled, all required funds for trailing down will be reserved.
                                                                <br/>
                                                                If disabled, the bot will only use the initial investment.</p>
                                                            :
                                                            <p>Open your first position when creating a bot.</p>
                                                        }
                                                    </label>

                                                </div>

                                            </div>
                                        </div>


                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={'grid-bot-manual-total'}>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p>
                                                        Currency
                                                    </p>

                                                    <h3>
                                                        {gridSelectedCoin.value}
                                                    </h3>

                                                </div>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p>
                                                        Current Price
                                                    </p>

                                                    <h3>
                                                        ${gridSelectedCoinPrice?.toLocaleString()}
                                                    </h3>

                                                </div>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p>
                                                        Instrument
                                                    </p>

                                                    <h3>
                                                        {gridBotMenuType === 2 ? 'Futures' : 'Spot'}
                                                    </h3>

                                                </div>

                                                <div className={`grid-bot-manual-total-item ${gridBotMenuType === 1 ? 'hidden' : ''}`}>

                                                    <p>
                                                        Strategy
                                                    </p>

                                                    <h3>
                                                        {gridBotMenuTypeFutures === 2 ? 'Neutral' : gridBotMenuTypeFutures === 1 ? 'Short' : 'Long'}
                                                    </h3>

                                                </div>

                                                <div className={`grid-bot-manual-total-item ${gridBotMenuType === 1 ? 'hidden' : ''}`}>

                                                    <p>
                                                        Leverage
                                                    </p>

                                                    <h3>
                                                        {gridSelectedLeverage}.00x
                                                    </h3>

                                                </div>

                                            </div>


                                            <button
                                                onClick={() => featureNotAvailableActiveHandler()}
                                                style={{width: '100%'}}
                                                className={'btn dark'}
                                            >
                                                Create
                                            </button>
                                        </div>

                                    </div>



                                </div>


                            </div>

                            <TradingBotsLearn dark={false} />

                        </div>


                    </div>




                    <div className={'dca trading-bots-grid-wrapper'}>

                        <div className={'trading-bots-grid-container'}>


                            <div className={'trading-bots-grid-container-text'}>
                                <h1 className={'trading-bots-grid-h1 white'}>
                                    DCA Bot
                                </h1>

                                <p className={'trading-bots-grid-p white'}>
                                    The strategy is based on gradually investing funds in a target asset, averaging its cost at regular intervals over a given period of time. DCA trading is used by investors who want to reduce the impact of volatility and gradually accumulate positions. This allows users to average out the price of their tokens by purchasing a set amount over a specified period of time.
                                </p>
                            </div>


                            <div className={'trading-bots-grid-content'}>


                                <div ref={dcaBotRef} className={'grid trading-bots-marketplace-types'}>
                                    <div className={' dca grid trading-bots-marketplace-types-left'}>

                                        <div className={' dca grid trading-bots-marketplace-types-left-top'}>
                                            <p
                                                onClick={() => handleDcaMenuTypeChange(1)}
                                                className={`dca grid trading-bots-marketplace-types-item bold ${dcaBotMenuTypeVisible === 1 ? 'active' : ''}`}
                                            >
                                                Fixed Interval
                                            </p>
                                            <p
                                                onClick={() => handleDcaMenuTypeChange(2)}
                                                className={`dca grid trading-bots-marketplace-types-item bold ${dcaBotMenuTypeVisible === 2 ? 'active' : ''}`}
                                            >
                                                Price-Based
                                            </p>
                                        </div>


                                    </div>

                                    <div className={'grid trading-bots-marketplace-types-right'}>

                                        <div className={'grid trading-bots-marketplace-types-right-select-container'}>

                                            <Select
                                                className={`grid trading-bots-marketplace-types-right-select ${gridBotMenuType === 1 ? 'full' : ''}`}
                                                options={changeApiRoute === 1 ? dataCoinsCreate : dataTopCoin}
                                                onChange={handleDcaCoinChange}
                                                placeholder={`BTC`}
                                                styles={select_styles_light}
                                            />
                                        </div>


                                        <h2 className={'trading-bots-create-current-price-h2 white'}>
                                            ≈ ${dcaSelectedCoinPrice?.toLocaleString()}
                                        </h2>


                                    </div>

                                </div>


                                <div className={'trading-bots-grid-content-menu'}>

                                    <div className={'trading-bots-grid-content-menu-left'}>
                                        <div
                                            className={`trading-bots-grid-content-menu-item`}
                                        >
                                            <h3 className={`dca trading-bots-grid-content-menu-item-h3 white`}>
                                                AI Strategy
                                            </h3>
                                        </div>
                                    </div>

                                    {/*<Link
                                        to={'/crypto-academy'}
                                        className={`trading-bots-grid-content-menu-item`}
                                    >
                                        <h3 className={`dca trading-bots-grid-content-menu-item-h3 link`}>
                                            Learn Trading Bots
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'trading-bots-grid-content-menu-item-arrow'}>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </h3>


                                    </Link>*/}

                                </div>

                                <div className={`grid trading-bots-marketplace-container ${dcaBotMenu === 1 ? 'hidden' : ''}`}>


                                    {
                                        dca_ai_data_apy_filtered.slice(0, 3).map((item, i) =>
                                            <div className={`${dcaIsSorting ? 'sorting' : ''} dca-ai marketplace-bot mybots-bot`}>


                                                <div className={`ai mb ${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                                    <div className={`${item.type === 'Futures Grid' || item.type === 'Spot Grid' ? 'fst' : ''} mybots-bot-img-container`}>
                                                        <img
                                                            className={'mybots-bot-img'}
                                                            src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                        />
                                                    </div>

                                                    {item.type === 'Futures Grid' || item.type === 'Spot Grid' ?
                                                        <div className={`scd mybots-bot-img-container`}>
                                                            <img
                                                                className={'mybots-bot-img'}
                                                                src={logos.find((logo) => logo.currency === 'USDT')?.logo}
                                                            />
                                                        </div>
                                                        :
                                                        ''
                                                    }

                                                </div>


                                                <div className={'marketplace-row mybots-bot-top'}>

                                                    <h3 className={'mybots-bot-top-h3 white'}>
                                                        {gridSelectedCoin.value}
                                                        {gridBotMenuType ? '' : '/'}USDT
                                                        <p className={' white'}>
                                                            Rec. Period: <span className={'bold'}>{item.recommended_time_period}</span>
                                                        </p>
                                                    </h3>

                                                    <div className={'mybots-bot-top-type-container'}>
                                                        <p className={'mybots-bot-top-type'}>
                                                            {item.type}
                                                        </p>
                                                        <br/>

                                                        <p className={'mybots-bot-top-type long'}>
                                                            {dcaBotMenuType === 1 ? 'Fixed Interval' : 'Price-Based'}
                                                        </p>


                                                    </div>


                                                </div>

                                                <div className={'marketplace-row mybots-bot-mid-row'}>


                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-pnl-p white'}>
                                                            Backtested APY
                                                        </p>

                                                        <h2 className={'mybots-bot-pnl-h2 white'}>
                                                            +{item.apy_new.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 1})}%
                                                        </h2>
                                                    </div>


                                                    <div className={'mybots-bot-sparkline-container'}>
                                                        <div className={'marketplace-bot-sparkline mybots-bot-sparkline'}>
                                                            <Sparklines className={'mybots-bot-sparkline'} data={item.sparkline_in_7d_new}>
                                                                <SparklinesCurve className={'mybots-bot-sparkline-top'} style={{fill: 'none', strokeWidth: '1.8'}} color={'#ccdeff'}/>
                                                            </Sparklines>
                                                        </div>
                                                        <div className={'marketplace-bot-sparkline-bott mybots-bot-sparkline-bott'}>
                                                            <Sparklines data={item.sparkline_in_7d_new}>
                                                                <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={'#ccdeff'}/>
                                                            </Sparklines>
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className={'marketplace-row mybots-bot-mid-row'}>

                                                    <div className={'mybots-bot-mid-row-left'}>
                                                        <p className={'mybots-bot-aum-p white'}>
                                                            Price Steps
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3 white'}>
                                                            {item.priceSteps}%
                                                        </h3>
                                                    </div>

                                                    <div className={'mybots-bot-mid-row-right'}>
                                                        <p className={'mybots-bot-aum-p white'}>
                                                            Take Profit Target
                                                        </p>

                                                        <h3 className={'mybots-bot-aum-h3 white'}>
                                                            {item.tpPrice}%
                                                        </h3>
                                                    </div>

                                                </div>


                                                <div className={'marketplace-row'}>
                                                    <button
                                                        onClick={() => featureNotAvailableActiveHandler()}
                                                        style={{width: '100%'}}
                                                        className={'btn light '}
                                                    >
                                                        Create
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            dcaPriceStepsHandler(
                                                                {
                                                                    target: {
                                                                        value: dca_ai_data_apy_filtered[i].priceSteps,
                                                                    }
                                                                }
                                                            )
                                                            dcaTakeProfitHandler(
                                                                {
                                                                    target: {
                                                                        value: dca_ai_data_apy_filtered[i].tpPrice,
                                                                    }
                                                                }
                                                            )

                                                            setDcaMaxSafetyOrders(dca_ai_data_apy_filtered[i].maxSafetyOrders)
                                                            setDcaSafetyOrdersAmount((gridInvestmentAmountMax * dca_ai_data_apy_filtered[i].safetyOrder).toFixed(2))

                                                            dcaBotManualRef.current?.scrollIntoView({block: "center"})

                                                        }}
                                                        style={{marginTop: '10px', width: '100%'}}
                                                        className={'btn primary4'}
                                                    >
                                                        Copy To Manual
                                                    </button>
                                                </div>



                                            </div>
                                        )

                                    }

                                </div>


                                <div ref={dcaBotManualRef} className={'trading-bots-grid-content-menu'}>

                                    <div className={'trading-bots-grid-content-menu-left'}>

                                        <div
                                            className={`trading-bots-grid-content-menu-item`}
                                        >
                                            <h3 className={`dca trading-bots-grid-content-menu-item-h3 white`}>
                                                Manual
                                            </h3>
                                        </div>
                                    </div>

                                    <Link
                                        to={'/crypto-academy'}
                                        className={`trading-bots-grid-content-menu-item`}
                                    >
                                        <h3 className={`dca trading-bots-grid-content-menu-item-h3 white link`}>
                                            How Does It Work?
                                            {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'trading-bots-grid-content-menu-item-arrow'}>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>*/}
                                        </h3>


                                    </Link>

                                </div>


                                <div className={`dca grid-bot-manual-wrapper ${gridBotMenu === 1 ? '' : 'hidden'}`}>


                                    <div className={'grid-bot-manual-container'}>

                                        <div className={'grid-bot-manual-container-left'}>

                                            <div className={'grid-bot-manual-container-left-input-label-container'}>
                                                <p className={'grid-bot-manual-container-left-input-label input-p white'}>
                                                    Max Safety Orders
                                                </p>

                                                <p className={'grid-bot-manual-container-left-input-label white'}>

                                                </p>
                                            </div>


                                            <div className={'dca-max dca grid-bot-manual-container-left-input-steps'}>

                                                <div onClick={() => setDcaMaxSafetyOrders(0)} className={`dca-max ${dcaMaxSafetyOrders === 0 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        0
                                                    </p>
                                                </div>
                                                <div onClick={() => setDcaMaxSafetyOrders(1)} className={`dca-max ${dcaMaxSafetyOrders === 1 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        10
                                                    </p>
                                                </div>
                                                <div onClick={() => setDcaMaxSafetyOrders(2)} className={`dca-max ${dcaMaxSafetyOrders === 2 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        20
                                                    </p>
                                                </div>
                                                <div onClick={() => setDcaMaxSafetyOrders(3)} className={`dca-max ${dcaMaxSafetyOrders === 3 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        30
                                                    </p>
                                                </div>
                                                <div onClick={() => setDcaMaxSafetyOrders(4)} className={`dca-max ${dcaMaxSafetyOrders === 4 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        40
                                                    </p>
                                                </div>
                                                <div onClick={() => setDcaMaxSafetyOrders(5)} className={`dca-max ${dcaMaxSafetyOrders === 5 ? 'active' : ''} dca grid-bot-manual-container-left-input-steps-item`}>
                                                    <p>
                                                        50
                                                    </p>
                                                </div>

                                            </div>





                                            <input
                                                value={dcaSafetyOrdersAmount}
                                                onChange={(e) => {
                                                    dcaSafetyOrdersAmountHandler(e)
                                                    /*handleDcaSafetyOrdersAmountErrorChange(e)*/
                                                }}
                                                type="number"
                                                className={`grid-bot-manual-container-left-input input primary4 ${dcaSafetyOrdersAmountError !== '‎ ' ? 'error-dark' : ''}`}
                                                style={{marginTop: '10px'}}
                                                placeholder={'Safety Order Price (USDT)'}
                                            />


                                            <p className={'error input-p'}>
                                                {dcaSafetyOrdersAmountError}
                                            </p>
                                        </div>



                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={'grid-bot-manual-container-left-input-container'}>

                                                <div className={'grid-bot-manual-container-left-input-label-container'}>
                                                    <p className={'grid-bot-manual-container-left-input-label input-p white'}>
                                                        Initial Order Amount
                                                    </p>

                                                    <p className={'grid-bot-manual-container-left-input-label white'}>
                                                        Available: <span className={"bold"}>{gridInvestmentAmountMax.toLocaleString()} USDT</span>
                                                    </p>
                                                </div>

                                                <input
                                                    value={dcaInvestmentAmount}
                                                    onChange={(e) => {
                                                        handleDcaInvestmentAmountChange(e)
                                                        handleDcaInvestmentAmountError(e)
                                                    }}
                                                    onBlur={() => {
                                                        setDcaInvestmentAmountDirty(true)
                                                    }}
                                                    ref={gridInvestmentAmountInputRef}
                                                    type="number"
                                                    className={`grid-bot-manual-container-left-input input primary4 ${(dcaInvestmentAmount > gridInvestmentAmountMax) && (dcaInvestmentAmountDirty === true) ? 'error-dark' : ''}`}
                                                    placeholder={'USDT'}
                                                />

                                            </div>


                                            <div className={'dca grid-bot-manual-container-left-input-steps'}>

                                                <div onClick={() => {
                                                    setDcaInvestmentAmount(gridInvestmentAmountMax * 0.25)
                                                    handleDcaInvestmentAmountError({target: {value: 0}})
                                                }} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        25%
                                                    </p>
                                                </div>
                                                <div onClick={() => {
                                                    setDcaInvestmentAmount(gridInvestmentAmountMax * 0.5)
                                                    handleDcaInvestmentAmountError({target: {value: 0}})
                                                }} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        50%
                                                    </p>
                                                </div>
                                                <div onClick={() => {
                                                    setDcaInvestmentAmount(gridInvestmentAmountMax * 0.75)
                                                    handleDcaInvestmentAmountError({target: {value: 0}})
                                                }} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        75%
                                                    </p>
                                                </div>
                                                <div onClick={() => {
                                                    setDcaInvestmentAmount(gridInvestmentAmountMax * 1)
                                                    handleDcaInvestmentAmountError({target: {value: 0}})
                                                }} className={'grid-bot-manual-container-left-input-steps-item'}>
                                                    <p>
                                                        100%
                                                    </p>
                                                </div>

                                            </div>


                                            <div
                                                className={'grid-bot-manual-investment-bar'}
                                                style={{background: (dcaInvestmentAmount > gridInvestmentAmountMax) && (dcaInvestmentAmountDirty === true)  ? `var(--red)` : `linear-gradient(270deg, var(--primary4) 0%, var(--primary4) ${(gridInvestmentAmountMax - dcaInvestmentAmount) / gridInvestmentAmountMax * 100}%, var(--primary2) ${(gridInvestmentAmountMax - dcaInvestmentAmount) / gridInvestmentAmountMax * 100}%, var(--primary2) 100%)`}}
                                            />

                                            <p className={'input-p error'}>
                                                {dcaInvestmentAmountError}
                                            </p>


                                        </div>



                                    </div>



                                    <div className={'grid-bot-manual-container'}>


                                        <div className={'grid-bot-manual-container-left'}>



                                                <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                    <p className={'input-p white'}>
                                                        Price Steps (%)
                                                    </p>

                                                    <input
                                                        value={dcaPriceSteps}
                                                        onChange={(e) => dcaPriceStepsHandler(e)}
                                                        type="number"
                                                        className={`grid-bot-manual-container-left-input input primary4 ${dcaPriceStepsError === '‎ ' ? '' : 'error-dark'}`}
                                                        placeholder={'0.2% – 50%'}
                                                        onBlur={() => handleDcaPriceStepsErrorChange()}
                                                        onMouseEnter={() => handleDcaPriceStepsErrorChange()}
                                                        onMouseLeave={() => handleDcaPriceStepsErrorChange()}
                                                    />

                                                    <p
                                                        className={`dca grid-bot-manual-container-left-input-p ${dcaPriceStepsError === '‎ ' ? '' : 'error'}`}
                                                        onClick={() => {
                                                            setDcaPriceSteps(1)
                                                            handleDcaPriceStepsErrorChange()
                                                        }}
                                                        onMouseEnter={() => handleDcaPriceStepsErrorChange()}
                                                        onMouseLeave={() => handleDcaPriceStepsErrorChange()}
                                                    >
                                                        1%
                                                    </p>

                                                </div>


                                            <p className={'error input-p'}>
                                                {dcaPriceStepsError}
                                            </p>

                                        </div>




                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={'grid-bot-manual-container-left-price-range-item'}>

                                                <p className={'input-p white'}>
                                                    Profit Target Per Cycle (%)
                                                </p>

                                                <input
                                                    value={dcaTakeProfit}
                                                    onChange={(e) => dcaTakeProfitHandler(e)}
                                                    type="number"
                                                    className={`grid-bot-manual-container-left-input input primary4 ${dcaTakeProfitError === '‎ ' ? '' : 'error-dark'}`}
                                                    placeholder={'0.2% – 50%'}
                                                    onBlur={() => handleDcaTakeProfitErrorChange()}
                                                    onMouseEnter={() => handleDcaPriceStepsErrorChange()}
                                                    onMouseLeave={() => handleDcaPriceStepsErrorChange()}
                                                />

                                                <p
                                                    className={`dca grid-bot-manual-container-left-input-p ${dcaTakeProfitError === '‎ ' ? '' : 'error'}`}
                                                    onClick={() => {
                                                        setDcaTakeProfit(1.5)
                                                        handleDcaTakeProfitErrorChange()
                                                    }}
                                                    onMouseEnter={() => handleDcaTakeProfitErrorChange()}
                                                    onMouseLeave={() => handleDcaTakeProfitErrorChange()}
                                                >
                                                    1.5%
                                                </p>

                                            </div>

                                            <p className={'input-p error'}>
                                                {dcaTakeProfitError}
                                            </p>


                                        </div>

                                    </div>


                                    <div className={'grid-bot-manual-container'}>

                                        <div className={'grid-bot-manual-container-left'}>
                                            <div className={'grid-radio-container'}>

                                                <div className={'primary2 form-page-text-input1-radio-container-big'}>
                                                    <div className={'primary2 form-page-text-input1-radio-container'}>
                                                        <input
                                                            className={'primary4 form-page-text-input1-radio'}
                                                            type="radio"
                                                            value={true}
                                                            checked={reserveFundsDca}
                                                            onClick={reserveFundsHandlerDca}
                                                            id={'reserveFundsDca'}
                                                        />
                                                        <label htmlFor="reserveFundsDca">
                                                            <h5 className={'white'}>Reserve Required Funds</h5>
                                                        </label>
                                                    </div>

                                                    <label htmlFor="reserveFundsDca">
                                                        <p className={'white'}>If you choose to reserve required funds, the total amount required for this bot will be frozen in advance. If this isn't checked, the bot only freezes the funds required for the initial order and the first safety order.</p>
                                                    </label>

                                                </div>

                                            </div>
                                        </div>


                                        <div className={'grid-bot-manual-container-right'}>


                                            <div className={'dca grid-bot-manual-total'}>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p className={'white'}>
                                                        Currency
                                                    </p>

                                                    <h3 className={'white'}>
                                                        {gridSelectedCoin.value}
                                                    </h3>

                                                </div>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p className={'white'}>
                                                        Current Price
                                                    </p>

                                                    <h3 className={'white'}>
                                                        ${dcaSelectedCoinPrice?.toLocaleString()}
                                                    </h3>

                                                </div>

                                                <div className={'grid-bot-manual-total-item'}>

                                                    <p className={'white'}>
                                                        Strategy
                                                    </p>

                                                    <h3 className={'white'}>
                                                        {dcaBotMenuType === 1 ? 'Fixed Interval' : 'Price-Based'}
                                                    </h3>

                                                </div>

                                            </div>


                                            <button
                                                onClick={() => featureNotAvailableActiveHandler()}
                                                style={{width: '100%'}}
                                                className={'btn light'}
                                            >
                                                Create
                                            </button>
                                        </div>

                                    </div>



                                </div>


                            </div>


                        </div>


                    </div>


                    <div className={'trading-bots-container'}>
                        <Link to={'/'} className={'info-page-home'}>
                            <img src={arrowrightbluedark} className={'info-paige-arrow-home dark'} />
                            <p className={'info-paige-p-home dark'}>Home</p>
                        </Link>


                        <h3 className={'white'} style={{marginTop: 'var(--blockmargin)'}}>
                            Might Be Interesting
                        </h3>

                        <Link to={'/earnbox'} className={'tb earnbox-plate plate'}>
                            <div className={'earnbox-plate-text plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h2>Earn Box</h2>
                                    <p>
                                        Инвестируйте и получайте пассивный доход без рисков
                                    </p>
                                </div>

                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Staking
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Liquidity Pools
                                    </p>
                                </div>

                            </div>

                            <img src={earnbox_pig} className={'tb earnbox-plate-img'}/>

                        </Link>

                        <VerificationWidget mt={20} />
                    </div>


                </div>

                {/*<MightBeInteresting current={8} dark={true}/>*/}

                <div className={'info-page-presale'}>
                    <LgtPresale />
                </div>

            </div>
            <Footer/>
        </>

    );
};

export default TradingBots;