import React from 'react';
import './IntroducingLgt.css'
import lgt_vidget from '../../assets/lgt_vidget_back.svg'
import {Link} from "react-router-dom";
import arrow from '../../assets/arrowright.svg'

const IntroducingLgt = () => {
    return (
        <div className={'lgt-wrapper'}>
            <Link to={'/token'} className={'lgt-container'}>
                <img src={lgt_vidget} className={'lgt-img-back'}/>
                <div className={'lgt-text'}>
                    <div className={'lgt-headers'}>
                        <h3 className={'lgt-h3'}>
                            INTRODUCING
                        </h3>
                        <h1 className={'lgt-h1'}>
                            Liqui Global Token
                        </h1>
                        <p className={'lgt-feature-h5'}>
                            LGT is an official token issued by Liqui Global. It's used in various exchange mechanisms and is the center of our ecosystem.
                        </p>
                    </div>


                    {/*<div className={'lgt-info'}>

                        <div className={'lgt-features'}>

                            <div className={'lgt-feature'}>
                                <h3 className={'lgt-feature-h3'}>
                                    Применимость
                                </h3>
                                <p className={'lgt-feature-p'}>
                                    LGT используется в механиках экосистемы Liqui Global и необходим для активации приватных функций биржи.
                                </p>
                            </div>

                            <div className={'lgt-feature'}>
                                <h3 className={'lgt-feature-h3'}>
                                    Надежность
                                </h3>
                                <p className={'lgt-feature-p'}>
                                    Мы обеспечили LGT высокой ликвидностью и заботимся о цене токена, используя различные механизмы ценовой поддержки.
                                </p>
                            </div>

                        </div>

                    </div>*/}



                </div>
                <div className={'lgt-graphics'}>
                    <Link to={'/token'} className={'lgt-btn'}>
                        Learn more
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                            <path
                                fill={'#000'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>
                    </Link>
                </div>
            </Link>
        </div>
    );
};

export default IntroducingLgt;