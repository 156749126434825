import React, {useCallback, useEffect, useRef, useState} from 'react';
import './PresaleForm.css'
import '../FormPage.css'
import './InfoPageToken.css'
import './WalletModal.css'
import axios from "axios";
import {logDOM} from "@testing-library/react";
import trouble from '../assets/trouble.svg'
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import {Link} from "react-router-dom";

import lgt_mail from '../assets/lgt_mail.svg'
import lgt_mail_loading from '../assets/lgt_mail_loading.svg'
import lgt_mail_completed from '../assets/lgt_mail_completed.svg'
import lgt_mail_error from '../assets/lgt_mail_error.svg'

import lgt_overview1 from '../assets/lgt_overview1.svg'

import mode_manual from '../assets/lgt_mode_manual.svg'
import mode_manual_light from '../assets/lgt_mode_manual_light.svg'
import mode_wallet from '../assets/lgt_mode_wallet.svg'
import mode_wallet_light from '../assets/lgt_mode_wallet_light.svg'
import metamask from '../assets/wallets/metamask.svg'
import metamask_error from '../assets/wallets/metamask_error.svg'
import coinbase from '../assets/wallets/coinbase.svg'
import coinbase_error from '../assets/wallets/coinbase_error.svg'
import trust from '../assets/wallets/trust.svg'
import trust_error from '../assets/wallets/trust_error.svg'
import exodus from '../assets/wallets/exodus.svg'
import exodus_error from '../assets/wallets/exodus_error.svg'
import autodetect from '../assets/wallets/autodetect.svg'
import autodetect_error from '../assets/wallets/autodetect_error.svg'
import loader from '../assets/wallets/loader.svg'
import searching from '../assets/wallets/searching.svg'
import help from '../assets/wallets/help.svg'
import wallet from '../assets/wallets/wallet.svg'
import phantom from '../assets/wallets/phantom.svg'
import phantom_error from '../assets/wallets/phantom.svg'
import WalletModal from "./WalletModal";
import cross from "../assets/cross.svg";
import cross_black from "../assets/cross_inv_fat.svg";
import lgt_giftboxes from "../assets/lgt_giftboxes.svg";
import {ethers} from "ethers";


import lgt_vesting from "../assets/lgt_vesting.svg";
import lgt_specification from "../assets/lgt_specification.svg";


import {wagmiConfig} from "../index";
import {http, createConfig, WagmiProvider, Connector, useConnect} from 'wagmi'
import { mainnet, bsc } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'
import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi'
import { useBalance } from 'wagmi'
import { UseBalanceParameters } from 'wagmi'
import { useSwitchChain } from 'wagmi'
import { useChainId } from 'wagmi'

import { useSendTransaction } from 'wagmi'
import { parseEther } from 'viem'


import Select from "react-select";
import VerificationInput from "react-verification-input";





const PresaleForm = () => {



    const [step, setStep] = useState(1)

    const [copyGivenWallet, setCopyGivenWallet] = useState()


    const [codeValue, setCodeValue] = useState()
    const [codeExpectedValue, setCodeExpectedValue] = useState(0)
    const [codeError, setCodeError] = useState(0)
    const [stepTwoCompleted, setStepTwoCompleted] = useState(false)

    const handleCodeValue = (e) => {
        if (!codeLoading) {
            setCodeValue(e)
            setCodeError(0)
        }
    }




    const [codeLoading, setCodeLoading] = useState(false)

    useEffect(() => {
        if (codeValue?.toString().length === 6) {
            setCodeLoading(true)

            setTimeout(() => {
                checkCode()
            }, 1600)
        } else {

        }
    }, [codeValue])

    useEffect(() => {
        if ((codeExpectedValue?.toString().length === 6) && (codeValue?.toString().length === 6)) {

            if (codeValue?.toString() === codeExpectedValue?.toString()) {
                setCodeError(3)

                setTimeout(() => {
                    setStepTwoCompleted(true)
                }, 800)

                setTimeout(() => {
                    setStep(transactionMode === 1 ? 3 : 4)
                }, 1600)

            } else {
                setCodeError(2)

                setTimeout(() => {
                    setCodeLoading(false)
                }, 2000)
            }

        }

    }, [codeExpectedValue, codeValue])




    const [countDown, setCountdown] = useState(59)
    const [resendActive, setResendActive] = useState(false)

    useEffect(() => {

        let interval = setInterval(() => {
            setCountdown(countDown - 1)
        }, 1000)

        if (step === 2) {
            if (countDown === 0) {
                clearInterval(interval)
                setResendActive(true)
            }
        }

        return () => clearInterval(interval)

    }, [step, countDown])

    useEffect(() => {

        setCountdown(59)

    }, [step])





    const select_styles = {
        input: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        noOptionsMessage: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        container: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        control: (baseStyles, state) => ({
            border: '1px solid #0e0e12',
            background: '#0e0e12',
            "&:hover": {
                border: '1px solid #0e0e12',
                transition: '0.2s ease-in-out',
            },
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 12,
            height: '48px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            color: '#ffffff',
            paddingLeft: '5px',
            zIndex: '100',
            transition: '0.2s ease-in-out',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 0,
            height: '42px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            background: '#292a33',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            border: '1px solid var(--primary4)',
            paddingBottom: '0px',
            marginTop: '5px',
            width: '90%',
            height: 'auto',
            boxShadow: 'none',
            zIndex: '100',
            overflow: 'hidden',
            background: '#1f2026',
            color: '#ffffff',
        }),
        menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            width: '',
            height: 'auto',
            boxShadow: 'none',
            color: '#ffffff',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            height: 'auto',
            boxShadow: 'none',
            borderRadius: 10,
            color: '#ffffff',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.isFocused) ? '#ccdeff' : '#ffffff',
            "&:hover": {
                color: '#ccdeff'
            }
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
        }),
    }


    const selectChainOptions = [
        {
            label: 'Binance Smart Chain (BEP20)',
            value: 'bep20',
            id: 56,
        },
        {
            label: 'Ethereum (ERC20)',
            value: 'erc20',
            id: 1,
        },
    ]












    const url = 'http://82.146.58.251'




    const [transactionMode, setTransactionMode] = useState(2)

    const [ethRate, setEthRate] =useState(0)
    const [formCurrency, setFormCurrency] = useState(true) // true = usdt, false = eth
    const [formCurrencyExact, setFormCurrencyExact] = useState('USDT') // true = usdt, false = eth

    useEffect(() => {
        if (formCurrency === true) {
            setFormCurrencyExact('USDT')
            setBalanceIsShowing(false)
            handleBalanceChange()
        } else {
            setFormCurrencyExact('ETH')
            setBalanceIsShowing(false)
            handleBalanceChange()
        }
    }, [formCurrency])

    useEffect(() => {
        axios.get(`http://82.146.58.251/api/top_coin`)
            .then(response => setEthRate(JSON.parse(response.data).find((item) => item.id === 'ethereum').current_price))
            .catch(error => {
                console.log(error)
                setEthRate(3525.29)
            })
    }, [])

    const convertToEth = (usdt) => {
        return (usdt / ethRate)?.toFixed(6)
    }

    const convertToUsd = (eth) => {
        return (eth * ethRate)?.toFixed(2)
    }

    const [name, setName] = useState('')
    const [isAgree, setIsAgree] = useState(false)
    const [email, setEmail] = useState('')
    const [telegram, setTelegram] = useState('@')
    const [receiverWallet, setReceiverWallet] = useState('')
    const [quantity, setQuantity] = useState(14990.00)
    const [ethQuantity, setEthQuantity] = useState(0)
    const [lgtQuantity, setLgtQuantity] = useState(quantity/0.03)
    const [bonus, setBonus] = useState(5)
    const [bonusLgt, setBonusLgt] = useState(0)
    const [nameDirty, setNameDirty] = useState(false)
    const [telegramDirty, setTelegramDirty] = useState(false)
    const [receiverWalletDirty, setReceiverWalletDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [quantityDirty, setQuantityDirty] = useState(false)
    const [ethQuantityDirty, setEthQuantityDirty] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [isAgreeError, setIsAgreeError] = useState('')
    const [receiverWalletError, setReceiverWalletError] = useState('')
    const [telegramError, setTelegramError] = useState('')
    const [quantityError, setQuantityError] = useState('')
    const [ethQuantityError, setEthQuantityError] = useState('')
    const [formValid, setFormValid] = useState(false)
    const [formValidError, setFormValidError] = useState(false)
    const [walletConnectionDirty, setWalletConnectionErrorDirty] = useState(false)
    const [walletConnectionError, setWalletConnectionError] = useState('')

    const [givenWallet, setGivenWallet] = useState()


    useEffect(() => {
        setStepTwoCompleted(false)
        setCodeExpectedValue('')
        setCodeValue('')
        setCodeLoading(false)
        setCodeError(0)
        setGivenWallet('')
    }, [email])



    const verifyForm = () => {
        /*setNameDirty(true)
        nameErrorHandler({
            target: {
                value: name
            }
        })

        setEmailDirty(true)
        emailErrorHandler(({
            target: {
                value: email
            }
        }))

        setQuantityDirty(true)
        quantityErrorHandler(({
            target: {
                value: quantity
            }
        }))

        setEthQuantityDirty(true)
        ethQuantityErrorHandler(({
            target: {
                value: ethQuantity
            }
        }))

        setReceiverWalletDirty(true)
        receiverWalletErrorHandler(({
            target: {
                value: receiverWallet
            }
        }))*/
    }


    const blurHandler = (e) => {
        switch (e.target.value) {
            case 'email':
                setEmailDirty(true)
                break
            case 'name':
                setNameDirty(true)
                break
            case 'quantity':
                setQuantityDirty(true)
                break
            case 'ethQuantity':
                setEthQuantityDirty(true)
                break
            case 'wallet':
                setReceiverWalletDirty(true)
                break
        }
    }

    useEffect(() => {

        setFormValidError(false)

        if (emailError ||
            nameError ||
            telegramError ||
            receiverWalletError ||
            quantityError ||
            ethQuantityError ||
            name === '' ||
            telegram === '' ||
            receiverWallet === '' ||
            email === '' ||
            quantity === 0 ||
            isAgreeError
        ) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[email, name, telegram, receiverWallet, quantity, ethQuantity, isAgree])

    const nameHandler = (e) => {
        setName(e.target.value)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
    }

    const telegramHandler = (e) => {
        setTelegram(e.target.value)

        if (e.target.value.trim().length === 0) {
            setTelegram('@')
        }

        if (e.target.value.trim().length < 2) {
            setTelegramError('Please enter your Telegram contact')
        } else {
            setTelegramError('')
            if (e.target.value[0] === '@') {
                setTelegram(e.target.value)
            } else {
                setTelegram('@' + e.target.value)
            }

        }
    }

    const receiverWalletHandler = (e) => {
        setReceiverWallet(e.target.value)
    }

    const isAgreeHandler = (e) => {
        if (e?.target.value) {
            setIsAgree(!isAgree)
        }

        if (isAgree === true) {
            setIsAgreeError('You must accept Terms of use to contribute')
        } else {
            setIsAgreeError('')
        }
    }



    const quantityHandler = (e) => {

        if (e.target === undefined) {
            setQuantity(e)

            if ((e >= 0) && (e <= 5000)) {
                setBonus(0)
            }
            if ((e >= 5000) && (e <= 10000)) {
                setBonus(3)
            }
            if ((e >= 10000) && (e <= 25000)) {
                setBonus(5)
            }
            if ((e >= 25000) && (e <= 50000)) {
                setBonus(7)
            }
            if ((e >= 50000) && (e <= 75000)) {
                setBonus(10)
            }
            if ((e >= 75000) && (e <= 100000)) {
                setBonus(15)
            }

            setLgtQuantity(e / 0.03)

        } else {
            setQuantity(e.target.value)

            if ((e.target.value >= 0) && (e.target.value <= 5000)) {
                setBonus(0)
            }
            if ((e.target.value >= 5000) && (e.target.value <= 10000)) {
                setBonus(3)
            }
            if ((e.target.value >= 10000) && (e.target.value <= 25000)) {
                setBonus(5)
            }
            if ((e.target.value >= 25000) && (e.target.value <= 50000)) {
                setBonus(7)
            }
            if ((e.target.value >= 50000) && (e.target.value <= 75000)) {
                setBonus(10)
            }
            if ((e.target.value >= 75000) && (e.target.value <= 100000)) {
                setBonus(15)
            }

            setLgtQuantity(e.target.value / 0.03)
        }
    }

    const ethQuantityHandler = (e) => {

        if (e.target === undefined) {

            setEthQuantity(e.toFixed(6))

            if ((e >= 0/ethRate) && (e <= 5000/ethRate)) {
                setBonus(0)
            }
            if ((e >= 5000/ethRate) && (e <= 10000/ethRate)) {
                setBonus(3)
            }
            if ((e >= 10000/ethRate) && (e <= 25000/ethRate)) {
                setBonus(5)
            }
            if ((e >= 25000/ethRate) && (e <= 50000/ethRate)) {
                setBonus(7)
            }
            if ((e >= 50000/ethRate) && (e <= 75000/ethRate)) {
                setBonus(10)
            }
            if ((e >= 75000/ethRate) && (e <= 100000/ethRate)) {
                setBonus(15)
            }

            setLgtQuantity(e / (0.03 / ethRate))

        } else {

            setEthQuantity(e.target.value)

            if ((e.target.value >= 0/ethRate) && (e.target.value <= 5000/ethRate)) {
                setBonus(0)
            }
            if ((e.target.value >= 5000/ethRate) && (e.target.value <= 10000/ethRate)) {
                setBonus(3)
            }
            if ((e.target.value >= 10000/ethRate) && (e.target.value <= 25000/ethRate)) {
                setBonus(5)
            }
            if ((e.target.value >= 25000/ethRate) && (e.target.value <= 50000/ethRate)) {
                setBonus(7)
            }
            if ((e.target.value >= 50000/ethRate) && (e.target.value <= 75000/ethRate)) {
                setBonus(10)
            }
            if ((e.target.value >= 75000/ethRate) && (e.target.value <= 100000/ethRate)) {
                setBonus(15)
            }

            setLgtQuantity(e.target.value / (0.03 / ethRate))
        }
    }

    const emailErrorHandler = (e) => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Please enter a valid email address')
        } else {
            setEmailError('')
        }

    }

    const nameErrorHandler = (e) => {
        if (nameDirty && e.target.value.trim().length <= 0) {
            setNameError('Please enter your name')
        } else {
            setNameError('')
        }
    }

    const telegramErrorHandler = (e) => {

        if (telegramDirty && e.target.value.trim().length <= 1) {
            setTelegramError('Please enter your Telegram contact')
        }

        if (telegramDirty && e.target.value === '') {
            setTelegramError('Please enter your Telegram contact')
        }

        if (e.target.value.startsWith('@') && e.target.value.trim().length > 1) {
            setTelegramError('')
        } else {
            setTelegramError('Please enter your Telegram contact')
        }
    }

    const receiverWalletErrorHandler = (e) => {
        if (receiverWalletDirty && e.target.value.trim().length === 0) {
            setReceiverWalletError('Please enter your wallet')
        } else {
            setReceiverWalletError('')
        }
    }

    const quantityErrorHandler = (e) => {
        if ((e.target.value > (499)) && (e.target.value < (100001))) {
            setQuantityError('')
        } else {
            setQuantityError('Please read specification closely')
        }
    }

    const ethQuantityErrorHandler = (e) => {
        if ((e.target.value > (499 / ethRate)) && (e.target.value < (100001 / ethRate))) {
            setEthQuantityError('')
        } else {
            setEthQuantityError('Please read specification closely')
        }
    }

    const inputRef = useRef()

    const handleKeypress = e => {
        if (e.keyCode === 13 && formValid) {
            sendData();
            inputRef.current.blur()
        }
    };

    const [successNotificationShowing, setSuccessNotificationShowing] = useState(false)
    const [errorNotificationShowing, setErrorNotificationShowing] = useState(false)

    const successNotification = () => {
        setSuccessNotificationShowing(true)
        setTimeout(() => {
            setSuccessNotificationShowing(false)
            setFormValid(true)
        }, 5000)
    }

    const errorNotification = () => {
        setErrorNotificationShowing(true)
        setTimeout(() => {
            setErrorNotificationShowing(false)
            setFormValid(true)
        }, 5000)
    }



    const sendData = () => {

        const options = {
            method: 'GET',
            url: `${url}/api/email_contribution_checkout`,
            params: {
                name: name,
                email: email,
                telegram: telegram,
                receiverWallet: receiverWallet,
                currency: formCurrency ? 'USDT' : 'ETH',
                quantity: formCurrency
                    ?
                    Number(quantity)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    :
                    Number(ethQuantity)?.toFixed(5).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                lgtQuantity: Number((lgtQuantity + (lgtQuantity * bonus / 100))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                bonus: bonus,
                bonusLgt: Number((lgtQuantity * bonus / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),

                transactionMode: transactionMode
            }
        }

        axios.request(options)
            .then((response) => {

                setGivenWallet(response.data)

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {

        if (stepTwoCompleted) {
            sendData()
        }

    }, [stepTwoCompleted])



    const sendCode = () => {


        const options = {
            method: 'GET',
            url: `${url}/api/email_contribution_sendcode`,
            params: {
                name: name,
                email: email,
            }
        }

        axios.request(options)
            .then((response) => {

                if (response.status === 200) {
                    console.log(200)
                } else if (response.status === 500) {
                    console.log(500)
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }


    const checkCode = () => {


            const options = {
                method: 'GET',
                url: `${url}/api/email_contribution_checkcode`,
                params: {
                    name: name,
                    email: email,
                    code: codeValue,
                }
            }

            axios.request(options)
                .then((response) => {

                    setCodeExpectedValue(response.data)

                })
                .catch((error) => {
                    console.log(error)
                    console.log('1000')
                })

    }





    const autoplayOptions = {
        delay: 7000
    }

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true , align: 'start', inViewThreshold: 1 }, [Autoplay(autoplayOptions)])
    const [emblaRefTop, emblaApiTop] = useEmblaCarousel({ loop: true , align: 'start', inViewThreshold: 1 }, [Autoplay(autoplayOptions)])
    const [emblaRefSteps, emblaApiSteps] = useEmblaCarousel({ loop: false , align: 'start', inViewThreshold: 1, draggable: false, axis: 'x' }, [Autoplay('')])

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    )
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    )
    const scrollTo = useCallback(
        () => emblaApi && emblaApi.scrollTo(0),
        [emblaApi]
    )


    useEffect(() => {
        scrollTo()
    }, [transactionMode])




    const scrollPrevStep = useCallback(
        () => emblaApiSteps && emblaApiSteps.scrollPrev(),
        [emblaApiSteps]
    )
    const scrollNextStep = useCallback(
        () => emblaApiSteps && emblaApiSteps.scrollNext(),
        [emblaApiSteps]
    )








    const [walletModal, setWalletModal] = useState(false)
    const [vestingModal, setVestingModal] = useState(false)
    const [specModal, setSpecModal] = useState(false)

    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [selectedProvider, setSelectedProvider] = useState('')
    const [selectedProviderVisible, setSelectedProviderVisible] = useState('')



    const { connectors, connect } = useConnect()


    /*connectors.map((c) => {
        console.log(Object.getOwnPropertyNames(c))
        console.log(Object.getOwnPropertyDescriptor(c))
    })*/




    let detectedWallets = []

    const [haveMM, setHaveMM] = useState()
    const [haveCB, setHaveCB] = useState()
    const [havePH, setHavePH] = useState()
    const [haveTW, setHaveTW] = useState()
    const [haveEX, setHaveEX] = useState()

    const [includeExodus, setIncludeExodus] = useState(false)
    const [includeTrust, setIncludeTrust] = useState(true)

    useEffect(() => {
        for (let i = 0; i < connectors.length; i++) {
            if (connectors[i].name !== 'Injected' && connectors[i].id !== 'metaMaskSDK') {
                detectedWallets.push(connectors[i].name)
                /*alert(connectors[i].name)*/
                if (connectors[i].name === 'MetaMask') {
                    setHaveMM(true)
                }
                if (connectors[i].name === 'Coinbase Wallet') {
                    setHaveCB(true)
                }
                if (connectors[i].name === 'Phantom') {
                    setHavePH(true)
                }
                if (connectors[i].name === 'Exodus') {
                    setIncludeExodus(true)
                    setHaveEX(true)
                }
                if (connectors[i].name === 'Trust Wallet') {
                    setIncludeTrust(true)
                    setHaveTW(true)
                }
            }
        }

    }, [])


    const isProviderExist = (wallet) => {

        setIsWalletLoading(true)

        const timeout = setTimeout(() => {

            setIsWalletLoading(false)

        }, selectedProviderVisible === 'auto' ? 3000 : 7000)

        return () => {
            clearTimeout(timeout)
        }

    }


    const [showDisconnect, setShowDisconnect] = useState(false)

    const { isConnected } = useAccount()
    const { address } = useAccount()
    const { disconnect } = useDisconnect()
    const { data: ensName } = useEnsName({ address })

    useEffect(() => {
        if (!isConnected) {
            setWalletConnectionError('Please connect wallet to continue')
        } else {
            setWalletConnectionError('')
        }
    }, [isConnected, transactionMode])


    const [balanceIsShowing, setBalanceIsShowing] = useState(false)


    const { chains, switchChain } = useSwitchChain()
    const chainId = useChainId()

    const [selectedChain, setSelectedChain] = useState('bep20')
    const [selectedChainId, setSelectedChainId] = useState(56)

    const [wrongChain, setWrongChain] = useState(false)

    const handleSelectedChainChange = async (e) => {

        setBalanceIsShowing(false)

        setSelectedChain(e.value)

        await switchChain({ chainId: e.id })
        await setSelectedChainId(e.id)

    }

    useEffect(() => {
        if (chainId === 1) {

        } else {
            switchChain({chainId: 56})
        }
    }, [address, chainId, isConnected])


    useEffect(() => {
        if (isConnected) {
            setBalanceIsShowing(false)
        }
    }, [chainId])




    const [selectedToken, setSelectedToken] = useState('0x55d398326f99059fF775485246999027B3197955')

    useEffect(() => {

        setSelectedToken(
            formCurrency === true ?
                chainId === 56 ?
                    '0x55d398326f99059fF775485246999027B3197955' // bep20 USDT
                    :
                    '0x2170ed0880ac9a755fd29b2688956bd959f933f8' // bep20 ETH
                :
                chainId === 1 ?
                    '0xdac17f958d2ee523a2206206994597c13d831ec7' // erc20 USDT
                    :
                    '0x71C7656EC7ab88b098defB751B7401B5f6d8976F' // erc20 ETH
        )

    }, [chainId, selectedChainId, formCurrency])


    const { data: balanceData, isError } = useBalance({
        address: address,
        chainId: chainId,
        token: selectedToken,
    })

    const [balance, setBalance] = useState(0)
    const [balanceSymbol, setBalanceSymbol] = useState()

    const handleBalanceChange = () => {
        if (balanceData?.symbol === 'USDT') {
            setBalance((balanceData?.formatted * 1)?.toFixed(2))
        } else {
            setBalance((balanceData?.formatted * 1)?.toFixed(6))
        }
        setBalanceSymbol(balanceData?.symbol)
    }

    useEffect(() => {
        handleBalanceChange()

        if (isConnected && (balanceData !== undefined) && (balanceData !== null)) {
            setBalanceIsShowing(true)
        }

    }, [balanceIsShowing, isConnected])


    useEffect(() => {

        setIsWalletLoading(false)
        setWalletModal(false)

    }, [address])

    useEffect(() => {

        setTimeout(() => {
            if (
                (selectedProviderVisible === 'MetaMask' && haveMM)
                ||
                (selectedProviderVisible === 'Coinbase Wallet' && haveCB)
                ||
                (selectedProviderVisible === 'Phantom' && havePH)
                ||
                (selectedProviderVisible === 'Trust Wallet' && haveTW)
                ||
                (selectedProviderVisible === 'Exodus' && haveEX)
            ) {
                if (!isConnected) {
                    setSelectedProviderVisible('')
                    setIsWalletLoading(false)
                }
            }
        }, 300)


    }, [selectedProviderVisible])








    const { sendTransaction } = useSendTransaction()

    const approveTransaction = () => {

        sendTransaction({
            data: selectedToken,
            to: `${givenWallet === '' ? '0x1F5794b2d88eD243BD8e4A17060A4501234e5e88' : givenWallet}`,
            value: 100000,
        })

    }















    const presaleFormStepOne = useRef()

    useEffect(() => {

        /*presaleFormStepOne.current.style.marginTop = `${-883 * -3}px`*/

    }, [])

    useEffect(() => {



    }, [step])



    return (
        <div className={'presale-form-wrapper'}>

            <div className={'presale-form-container'}>

                <div className={'presale-form-col1'}>

                    <div className={'how-to-buy-wrapper-top'}>

                        <h2 className={'how-to-buy-h2-top'}>
                            How to buy Liqui Global Token?
                        </h2>

                        <div className={'how-to-buy-carousel-wrapper'}>

                            <div className={'how-to-buy-btns'}>
                                <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev how-to-buy-btn'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'how-to-buy-btn how-to-buy-btn-left'}>
                                        <path
                                            fill={''}
                                            fillRule="evenodd"
                                            d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                        ></path>
                                    </svg>
                                </button>

                                <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next how-to-buy-btn'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'how-to-buy-btn how-to-buy-btn-right'}>
                                        <path
                                            fill={''}
                                            fillRule="evenodd"
                                            d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                        ></path>
                                    </svg>
                                </button>
                            </div>

                            <div className={'embla how-to-buy-container-top'} ref={emblaRefTop}>

                                <div className={'embla__container how-to-buy-slide-container-top'}>

                                    <div
                                        className={'embla__slide how-to-buy-slide-top'}
                                        onClick={() => {
                                            if (transactionMode === 2) {setWalletModal(true)}
                                        }}
                                    >

                                        <div className={'how-to-buy-circle-top'}>
                                            <h2 style={{marginTop: '2px', marginRight: '2px'}} className={'how-to-buy-circle-h3-top'}>1</h2>
                                        </div>

                                        <h3 className={'how-to-buy-slide-h3-top'}>
                                            {
                                                transactionMode === 2 ?
                                                    'Connect Wallet'
                                                    :
                                                    'Fill out Contribution Form'
                                            }
                                        </h3>
                                    </div>

                                    <div className={'embla__slide how-to-buy-slide-top'}>

                                        <div className={'how-to-buy-circle-top'}>
                                            <h2 className={'how-to-buy-circle-h3-top'}>2</h2>
                                        </div>

                                        <h3 className={'how-to-buy-slide-h3-top'}>
                                            {
                                                transactionMode === 2 ?
                                                    'Fill out Contribution Form'
                                                    :
                                                    'Wait for the information Email with details'
                                            }
                                        </h3>
                                    </div>

                                    <div className={'embla__slide how-to-buy-slide-top'}>

                                        <div className={'how-to-buy-circle-top'}>
                                            <h2 style={{marginTop: '2px'}} className={'how-to-buy-circle-h3-top'}>3</h2>
                                        </div>

                                        <h3 className={'how-to-buy-slide-h3-top'}>
                                            {
                                                transactionMode === 2 ?
                                                    'Confirm suggested transaction in Wallet'
                                                    :
                                                    'Make the payment to your personal address from information email'
                                            }
                                        </h3>
                                    </div>

                                    <div className={'embla__slide how-to-buy-slide-top'}>

                                        <div style={{backgroundColor: '#fff'}} className={'how-to-buy-circle-top'}>
                                            <h2  style={{marginTop: '1px', marginRight: '2px', color: '#000'}} className={'how-to-buy-circle-h3-top'}>
                                                4
                                            </h2>
                                        </div>

                                        <h3 className={'how-to-buy-slide-h3-top'}>
                                            {
                                                transactionMode === 2 ?
                                                    'Confirmation Email will be sent once transaction done'
                                                    :
                                                    'Confirmation Email will be sent once transaction done'
                                            }
                                        </h3>
                                    </div>

                                </div>

                            </div>

                        </div>



                    </div>

                    <div className={'contribution-form-container'}>

                        <h2 className={'presale-form-text-h2'}>
                            Contribution Form
                        </h2>



                        <div className={`wallet-modal-wrapper`}>
                            <div onClick={() => setWalletModal(false)} className={`wallet-modal-back ${walletModal ? '' : 'hidden'}`}></div>

                            <div className={`wallet-modal-container ${walletModal ? '' : 'hidden'}`}>
                                <img onClick={() => setWalletModal(false)} src={cross} className={`wallet-modal-cross ${walletModal ? '' : 'hidden'}`} />

                                <h3 className={'wallet-modal-h3 white'}>
                                    Connect Web3 Wallet
                                </h3>


                                <div className={'wallet-modal-content'}>

                                    <div className={'wallet-modal-content-left'}>

                                        {/*{
                                                connectors.map((connector) =>
                                                    <button className={'flexible btn light'}>
                                                        {connector.name}
                                                    </button>
                                                )
                                            }*/}



                                        <div
                                            className={`${selectedProviderVisible === 'MetaMask' ? 'active' : ''} wallet-modal-wallet-item`}
                                            onClick={() => {
                                                isProviderExist('metamask')
                                                setSelectedProviderVisible('MetaMask')
                                                setSelectedProvider('MetaMask')
                                                connectors.map((connector) =>
                                                    connector.name === 'MetaMask' && connector.id === 'io.metamask' ?
                                                        connect({connector})
                                                        :
                                                        ''
                                                )
                                            }}
                                        >

                                            <div className={'wallet-modal-wallet-item-img-container'}>
                                                <img className={'wallet-modal-wallet-item-img'} src={metamask} />
                                            </div>

                                            <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                MetaMask
                                            </h3>

                                            <p className={`${selectedProviderVisible === 'MetaMask' ? 'hot' : 'top'} earnproducts mybots-bot-top-type`}>
                                                Recommended
                                            </p>

                                        </div>


                                        <div
                                            className={`${selectedProviderVisible === 'Coinbase Wallet' ? 'active' : ''} wallet-modal-wallet-item`}
                                            onClick={() => {
                                                isProviderExist('coinbase')
                                                setSelectedProviderVisible('Coinbase Wallet')
                                                setSelectedProvider('Coinbase Wallet')
                                                connectors.map((connector) =>
                                                    connector.name === 'Coinbase Wallet' ?
                                                        connect({connector})
                                                        :
                                                        ''
                                                )
                                            }}
                                        >

                                            <div className={'wallet-modal-wallet-item-img-container'}>
                                                <img className={'wallet-modal-wallet-item-img'} src={coinbase} />
                                            </div>

                                            <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                CoinBase Wallet
                                            </h3>

                                        </div>


                                        <div
                                            className={`${selectedProviderVisible === 'Phantom' ? 'active' : ''} wallet-modal-wallet-item`}
                                            onClick={() => {
                                                isProviderExist('phantom')
                                                setSelectedProviderVisible('Phantom')
                                                setSelectedProvider('Phantom')
                                                connectors.map((connector) =>
                                                    connector.name === 'Phantom' ?
                                                        connect({connector})
                                                        :
                                                        ''
                                                )
                                            }}
                                        >

                                            <div className={'wallet-modal-wallet-item-img-container'}>
                                                <img className={'wallet-modal-wallet-item-img'} src={phantom} />
                                            </div>

                                            <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                Phantom
                                            </h3>

                                        </div>


                                        {
                                            includeTrust ?
                                                <div
                                                    className={`${selectedProviderVisible === 'Trust Wallet' ? 'active' : ''} wallet-modal-wallet-item`}
                                                    onClick={() => {
                                                        isProviderExist('trust')
                                                        setSelectedProviderVisible('Trust Wallet')
                                                        setSelectedProvider('Trust Wallet')
                                                        connectors.map((connector) =>
                                                            connector.name === 'Trust Wallet' ?
                                                                connect({connector})
                                                                :
                                                                ''
                                                        )
                                                    }}
                                                >

                                                    <div className={'wallet-modal-wallet-item-img-container'}>
                                                        <img className={'wallet-modal-wallet-item-img'} src={trust} />
                                                    </div>

                                                    <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                        Trust Wallet
                                                    </h3>

                                                </div>
                                                :
                                                ''
                                        }


                                        {
                                            includeExodus ?
                                                <div
                                                    className={`${selectedProviderVisible === 'Exodus' ? 'active' : ''} wallet-modal-wallet-item`}
                                                    onClick={() => {
                                                        isProviderExist('exodus')
                                                        setSelectedProviderVisible('Exodus')
                                                        setSelectedProvider('Exodus')
                                                        connectors.map((connector) =>
                                                            connector.name === 'Exodus' ?
                                                                connect({connector})
                                                                :
                                                                ''
                                                        )
                                                    }}
                                                >

                                                    <div className={'wallet-modal-wallet-item-img-container'}>
                                                        <img className={'wallet-modal-wallet-item-img'} src={exodus} />
                                                    </div>

                                                    <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                        Exodus
                                                    </h3>

                                                </div>
                                                :
                                                ''
                                        }


                                        <div
                                            className={`auto ${selectedProviderVisible === 'auto' ? 'active' : ''} wallet-modal-wallet-item`}
                                            onClick={() => {
                                                isProviderExist('autodetect')
                                                setSelectedProviderVisible('auto')
                                                setSelectedProvider('auto')
                                                connectors.map((connector) =>
                                                    connector.name === 'Injected' ?
                                                        connect({connector})
                                                        :
                                                        ''
                                                )
                                            }}
                                        >

                                            <div className={'auto wallet-modal-wallet-item-img-container'}>
                                                <img className={'wallet-modal-wallet-item-img'} src={autodetect} />
                                            </div>

                                            <h3 className={'white wallet-modal-wallet-item-h3'}>
                                                Auto Detect
                                            </h3>

                                        </div>

                                    </div>

                                    <div className={'wallet-modal-content-right'}>

                                        {
                                            (
                                                (selectedProviderVisible === 'MetaMask' && !haveMM)
                                                ||
                                                (selectedProviderVisible === 'Coinbase Wallet' && !haveCB)
                                                ||
                                                (selectedProviderVisible === 'Phantom' && !havePH)
                                                ||
                                                (selectedProviderVisible === 'Trust Wallet' && !haveTW)
                                                ||
                                                (selectedProviderVisible === 'Exodus' && includeExodus && !haveEX)
                                            )
                                            && !isWalletLoading ?
                                                <div className={'wallet-modal-content-right-error-container'}>

                                                    <div className={'wallet-modal-content-right-error-top'}>

                                                        {
                                                            selectedProviderVisible === 'auto' ?
                                                                <p className={'light'}>
                                                                    {/*If you have {selectedProviderVisible === 'MetaMask' ? 'MetaMask' : 'other Web3 Wallet'} installed on this device, browser can fail to find {selectedProviderVisible}.*/}
                                                                    *You can install any supported Web3 Wallet as browser extension (e.g. MetaMask) and try again
                                                                </p>
                                                                :
                                                                <p className={'light'}>
                                                                    {/*If you have {selectedProviderVisible === 'MetaMask' ? 'MetaMask' : 'other Web3 Wallet'} installed on this device, browser can fail to find {selectedProviderVisible}.*/}
                                                                    *Try to disable {selectedProviderVisible ? 'other Web3 Wallets' : 'MetaMask'} in extensions settings and try again
                                                                </p>
                                                        }

                                                    </div>

                                                    <div className={'wallet-modal-content-right-error-center'}>
                                                        {selectedProviderVisible === 'MetaMask' ?
                                                            <img className={`wallet-modal-content-wallet-error-img`} src={metamask_error} />
                                                            :
                                                            selectedProviderVisible === 'CoinBase Wallet' ?
                                                                <img className={`wallet-modal-content-wallet-error-img`} src={coinbase_error} />
                                                                :
                                                                selectedProviderVisible === 'Trust Wallet' ?
                                                                    <img className={`wallet-modal-content-wallet-error-img`} src={trust_error} />
                                                                    :
                                                                    selectedProviderVisible === 'Phantom' ?
                                                                        <img className={`wallet-modal-content-wallet-error-img`} src={phantom_error} />
                                                                        :
                                                                        selectedProviderVisible === 'Exodus' ?
                                                                            <img className={`wallet-modal-content-wallet-error-img`} src={exodus_error} />
                                                                            :
                                                                            <img className={`wallet-modal-content-wallet-error-img`} src={autodetect_error} />
                                                        }

                                                        {
                                                            selectedProviderVisible === 'auto' ?
                                                                <h3>
                                                                    Supported Web3 Wallets Not Found On This Device
                                                                </h3>
                                                                :
                                                                <h3>
                                                                    {selectedProviderVisible} Not Found On This Device
                                                                </h3>
                                                        }

                                                        <button
                                                            className={'btn dark'}
                                                            onClick={() => {
                                                                isProviderExist(
                                                                    selectedProviderVisible === 'MetaMask' ?
                                                                        'metamask'
                                                                        :
                                                                        selectedProviderVisible === 'CoinBase Wallet' ?
                                                                            'coinbase'
                                                                            :
                                                                            selectedProviderVisible === 'Trust Wallet' ?
                                                                                'trust'
                                                                                :
                                                                                'auto'
                                                                )
                                                            }}
                                                        >
                                                            Try Again
                                                        </button>

                                                        <a href={
                                                            selectedProviderVisible === 'MetaMask' ?
                                                                'https://metamask.io/download/'
                                                                :
                                                                selectedProviderVisible === 'CoinBase Wallet' ?
                                                                    'https://www.coinbase.com/wallet/downloads'
                                                                    :
                                                                    selectedProviderVisible === 'Trust Wallet' ?
                                                                        'https://trustwallet.com/download'
                                                                        :
                                                                        selectedProviderVisible === 'Exodus' ?
                                                                            'https://metamask.io/download/'
                                                                            :
                                                                            'https://chromewebstore.google.com/detail/exodus-web3-wallet/aholpfdialjgjfhomihkjbmgjidlcdno'
                                                        } className={'input-p'}>
                                                            <p>
                                                                Install {selectedProviderVisible === 'auto' ? 'MetaMask' : selectedProviderVisible}
                                                            </p>
                                                        </a>

                                                    </div>

                                                    <div className={'wallet-modal-content-right-error-bott'}>

                                                        <button
                                                            className={'full btn light'}
                                                            onClick={() => {
                                                                setWalletModal(false)
                                                                setTransactionMode(1)
                                                            }}
                                                        >
                                                            Proceed With Manual
                                                        </button>

                                                    </div>

                                                </div>

                                                :



                                                <div className={'wallet-modal-content-right-wait-container'}>
                                                    <img className={`${isWalletLoading && selectedProviderVisible !== 'auto' ? 'active' : ''} wallet-modal-content-right-loader`} src={loader} />
                                                    <img className={`${isWalletLoading && selectedProviderVisible === 'auto' ? 'active' : ''} searching wallet-modal-content-right-loader`} src={searching} />
                                                    <img className={`${!isWalletLoading ? 'active' : ''} wallet-modal-content-right-loader`} src={wallet} style={{animation: 'none'}} />

                                                    <h3>
                                                        {
                                                            !isWalletLoading ?
                                                                'Select Wallet From The List'
                                                                :
                                                                selectedProviderVisible === 'auto' ?
                                                                    'Searching For Available Wallets...'
                                                                    :
                                                                    `Connecting To ${selectedProviderVisible}..`
                                                        }
                                                    </h3>
                                                </div>
                                        }

                                    </div>

                                </div>


                                <div className={'wallet-modal-tip-container'}>
                                    <p className={'light'}>
                                        Tip: Use Auto Detect option if list doesn't contain wallet you're using.
                                        <br/>
                                        Try to disable other extensions if app opens wrong ones
                                    </p>

                                </div>

                            </div>

                        </div>




                        <div className={'presale-form-text'}>

                            <div className={'presale-form-steps-carousel-container'}>

                                <div className={'presale-form-steps-carousel-slide'}>

                                    <div ref={presaleFormStepOne} className={`${step === 1 ? 'active' : ''} presale-form-stepone-container`}>

                                        <div className={'contribution-form-transaction-mode-container'}>

                                            <div
                                                onClick={() => setTransactionMode(1)}
                                                className={`contribution-form-transaction-mode-item ${transactionMode === 1 ? 'active' : ''}`}
                                            >

                                                <img src={transactionMode === 1 ? mode_manual : mode_manual_light} />

                                                <h3 className={'white'}>
                                                    Manual
                                                </h3>

                                            </div>

                                            <div
                                                onClick={() => setTransactionMode(2)}
                                                className={`contribution-form-transaction-mode-item ${transactionMode === 2 ? 'active' : ''}`}
                                            >

                                                <img src={transactionMode === 2 ? mode_wallet : mode_wallet_light} />

                                                <h3 className={'white'}>
                                                    Web3 Wallet
                                                </h3>

                                            </div>

                                        </div>


                                        <button
                                            className={`${transactionMode === 2 ? 'full' : 'full'} btn light`}
                                            style={(transactionMode === 2) && (!isConnected) ? {opacity: '1', position: 'relative'} : {opacity: '0', pointerEvents: 'none', position: 'relative'}}
                                            onClick={() => setWalletModal(true)}
                                        >
                                            Connect Wallet
                                        </button>

                                        <button
                                            className={`${(transactionMode === 2) && (isConnected) ? 'full' : 'full'} btn primary2filled2`}
                                            style={(transactionMode === 2) && (isConnected) ? {opacity: '1', pointerEvents: 'all', position: 'relative', marginTop: '-48px', zIndex: '24'} : {opacity: '0', marginTop: '-48px', pointerEvents: 'none', position: 'relative', zIndex: '24'}}
                                            onClick={() => disconnect()}
                                            onMouseEnter={() => setShowDisconnect(true)}
                                            onMouseLeave={() => setShowDisconnect(false)}
                                        >
                                            Connected to: {address?.slice(0, 7)}...{address?.slice(-5)}
                                        </button>

                                        <button
                                            className={`${(transactionMode === 2) && (isConnected) ? 'full' : 'full'} btn primary2filled2`}
                                            style={(transactionMode === 2) && (isConnected) && (showDisconnect) ? {opacity: '1', pointerEvents: 'all', position: 'relative', marginTop: '-48px', zIndex: '25'} : {opacity: '0', marginTop: '-48px', pointerEvents: 'none', position: 'relative', zIndex: '25'}}
                                            onClick={() => disconnect()}
                                            onMouseEnter={() => setShowDisconnect(true)}
                                            onMouseLeave={() => setShowDisconnect(false)}
                                        >
                                            Disconnect
                                        </button>


                                        <p
                                            className={'white'}
                                            style={{minHeight: '48px', marginTop: '-48px', opacity: transactionMode === 2 ? '0' : '1'}}
                                        >
                                            You will be given a wallet to make the transaction. Once it is completed we will send you a confirmation by Email.
                                        </p>


                                        <p
                                            className={'white'}
                                            style={{minHeight: '48px', marginTop: '-48px', opacity: transactionMode === 2 ? '0' : 1}}
                                        >
                                            You will be given a wallet to make the transaction. Once it is completed we will send you a confirmation by Email.
                                        </p>





                                        <div className={'contribution-form-container-inside'}>

                                            {/*<div className={successNotificationShowing ? 'presale-form-email-notification presale-form-email-notification-active' : 'presale-form-email-notification'}>
                                        <div className={'presale-form-email-notification-text'}>
                                            <h3>
                                                Hey, {name}. Thank you for your contribution.
                                            </h3>

                                            <p className={'presale-form-email-notification-p'}>
                                                Please, check your Inbox.
                                            </p>
                                        </div>
                                        <img src={emailIllustration} className={'email-notification-img'} />
                                    </div>
                                    <div className={errorNotificationShowing ? 'presale-form-email-notification presale-form-email-notification-active' : 'presale-form-email-notification'} style={{background: 'var(--errorBG)', padding: '25px', display: 'flex', flexDirection: 'column'}}>
                                        <h3>
                                            Sorry, {name}. Something went wrong...
                                        </h3>

                                        <p>
                                            Try again later
                                        </p>
                                    </div>*/}

                                            <div className={'presale-form-text-input'}>

                                                <p className={'input-p white'} style={{marginTop: '20px'}}>
                                                    Name
                                                </p>

                                                <input
                                                    onClick={() => setNameDirty(true)}
                                                    onKeyDown={handleKeypress}
                                                    onChange={e => {nameHandler(e); nameErrorHandler(e)}}
                                                    className={`${nameError ? 'error-dark' : ''} input primary4 presale-form-text-input1`}
                                                    ref={inputRef}
                                                    value={name}
                                                    name={'name'}
                                                    type={'text'}
                                                    placeholder={'Satoshi Nakamoto'}
                                                    autoComplete={'off'}
                                                />

                                                <p className={'input-p white'}>
                                                    E-mail
                                                </p>

                                                <input
                                                    onBlur={(e) => emailErrorHandler(e)}
                                                    onKeyDown={handleKeypress}
                                                    onChange={e => {emailHandler(e); emailErrorHandler(e)}}
                                                    className={`${emailError ? 'error-dark' : ''} input primary4 presale-form-text-input1`}
                                                    ref={inputRef}
                                                    value={email}
                                                    name={'email'}
                                                    type={'email'}
                                                    placeholder={'satoshi@nakamoto.com'}
                                                    autoComplete={'off'}
                                                />

                                                <p className={'input-p white'}>
                                                    Telegram Contact
                                                </p>

                                                <input
                                                    onBlur={() => setTelegramDirty(true)}
                                                    onKeyDown={handleKeypress}
                                                    onChange={e => {telegramHandler(e); telegramErrorHandler(e)}}
                                                    className={`${telegramError ? 'error-dark' : ''} input primary4 presale-form-text-input1`}
                                                    ref={inputRef}
                                                    value={telegram}
                                                    name={'telegram'}
                                                    type={'text'}
                                                    placeholder={'@satoshi_nakamoto'}
                                                    autoComplete={'off'}
                                                />

                                                <p className={'input-p white'} style={{marginTop: '20px'}}>
                                                    1 ETH ≈ {(ethRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDT
                                                </p>

                                                <div className={'presale-form-toggle'}>
                                                    <p
                                                        onClick={() => {

                                                            !formCurrency
                                                                ?
                                                                setQuantity(convertToUsd(ethQuantity))
                                                                :
                                                                setQuantity(quantity);
                                                            setFormCurrency(true)

                                                        }}
                                                        className={`presale-form-toggle-button bold ${formCurrency ? 'active' : ''}`}
                                                    >
                                                        USDT
                                                    </p>
                                                    <p
                                                        onClick={() => {
                                                            setFormCurrency(false)
                                                            setEthQuantity(convertToEth(quantity))
                                                        }}
                                                        className={`presale-form-toggle-button bold ${formCurrency ? '' : 'active'}`}
                                                    >
                                                        ETH
                                                    </p>
                                                </div>


                                                <div className={'presale-form-select-container'}>

                                                    {
                                                        transactionMode === 2 ?
                                                            <Select
                                                                className={`presale-form-select`}
                                                                options={selectChainOptions}
                                                                onChange={handleSelectedChainChange}
                                                                value={!wrongChain ? selectChainOptions.find((e) => e.id === chainId) : 'Select Supported Chain'}
                                                                placeholder={`Binance Smart Chain (BEP20)`}
                                                                styles={select_styles}
                                                            />
                                                            :
                                                            <div
                                                                className={'primary2filled2 presale-form-select btn'}
                                                                onClick={() => setSpecModal(true)}
                                                            >
                                                                Ethereum (ERC20), BSC (BEP20)
                                                            </div>
                                                    }

                                                </div>


                                                <div className={'input-p-double'}>
                                                    <p className={'input-p white'}>
                                                        Amount {formCurrency ? '(USDT)' : '(ETH)'}
                                                    </p>
                                                    {
                                                        (transactionMode === 2) ?
                                                            balanceIsShowing && isConnected ?

                                                                balance > -1 ?
                                                                    <p className={'input-p white'}>
                                                                        Balance: {balance} {balanceSymbol}
                                                                    </p>
                                                                    :
                                                                    <p className={'input-p white'}>
                                                                        Not supported by wallet
                                                                    </p>
                                                                :
                                                                <p className={'input-p white'}>
                                                                    <a
                                                                        className={'primary2 bold'}
                                                                        onClick={() => {
                                                                            if (isConnected) {
                                                                                handleBalanceChange()
                                                                                setBalanceIsShowing(true)
                                                                            } else {
                                                                                setWalletModal(true)
                                                                            }
                                                                        }}
                                                                    >
                                                                        Load Balance
                                                                    </a>
                                                                </p>
                                                            :
                                                            <p className={'input-p white'}>
                                                                &nbsp;
                                                            </p>
                                                    }
                                                </div>

                                                {formCurrency
                                                    ?
                                                    <input
                                                        onBlur={(e) => quantityErrorHandler(e)}
                                                        onKeyDown={handleKeypress}
                                                        onChange={e => {quantityHandler(e); quantityErrorHandler(e)}}
                                                        className={`${quantityError ? 'error-dark' : ''} input primary4 presale-form-text-input1`}
                                                        ref={inputRef}
                                                        value={quantity}
                                                        name={'quantity'}
                                                        placeholder={'14990.00'}
                                                        autoComplete={'off'}
                                                    />
                                                    :
                                                    <input
                                                        onBlur={(e) => ethQuantityErrorHandler(e)}
                                                        onKeyDown={handleKeypress}
                                                        onChange={e => {ethQuantityHandler(e); ethQuantityErrorHandler(e)}}
                                                        className={`${ethQuantityError ? 'error-dark' : ''} input primary4 presale-form-text-input1`}
                                                        ref={inputRef}
                                                        value={ethQuantity}
                                                        name={'ethQuantity'}
                                                        placeholder={'14990.00'}
                                                        autoComplete={'off'}
                                                    />
                                                }

                                                <div
                                                    className={'grid-bot-manual-investment-bar'}
                                                    style={{
                                                        background:
                                                            formCurrency ?
                                                                (quantity < 500) || (quantity > 100000) ? `var(--red)` : `linear-gradient(90deg, var(--primary2) 0%, var(--primary2) ${(quantity - 500) / (100000 - 500) * 100}%, var(--primary4darkdark) ${(quantity - 500) / (100000 - 500) * 100}%, var(--primary4darkdark) 100%)`
                                                                :
                                                                (convertToUsd(ethQuantity) < 500) || (convertToUsd(ethQuantity) > 100000) ? `var(--red)` : `linear-gradient(90deg, var(--primary2) 0%, var(--primary2) ${(convertToUsd(ethQuantity) - 500) / (100000 - 500) * 100}%, var(--primary4darkdark) ${(convertToUsd(ethQuantity) - 500) / (100000 - 500) * 100}%, var(--primary4darkdark) 100%)`
                                                        ,
                                                        marginTop: '2px',
                                                        marginLeft: '3px',
                                                        marginRight: '3px',
                                                        marginBottom: '6px',
                                                    }}
                                                />



                                                <p className={'input-p white'}>
                                                    LGT tokens will be sent to wallet
                                                </p>

                                                <div className={'presale-form-input-placeholder-container'}>

                                                    <input
                                                        onBlur={() => setReceiverWalletDirty(true)}
                                                        onKeyDown={handleKeypress}
                                                        onChange={e => {receiverWalletHandler(e); receiverWalletErrorHandler(e)}}
                                                        className={`${receiverWalletError ? 'error-dark' : ''} input primary4 ${isConnected && transactionMode === 2 ? 'placeholder' : ''} presale-form-text-input1`}
                                                        ref={inputRef}
                                                        value={receiverWallet}
                                                        name={'wallet'}
                                                        type={'text'}
                                                        placeholder={'0x...'}
                                                        autoComplete={'off'}
                                                    />

                                                    <p
                                                        className={`${isConnected && transactionMode === 2 ? 'active' : ''} presale-form-input-placeholder`}
                                                        onClick={() => {
                                                            setReceiverWallet(address)
                                                        }}
                                                    >
                                                        This Wallet
                                                    </p>

                                                </div>



                                                <div className={'presale-form-text-input1-radio-container'}>
                                                    <input
                                                        className={'presale-form-text-input1-radio'}
                                                        type="radio"
                                                        id={'terms'}
                                                        value="Agree"
                                                        checked={isAgree === true}
                                                        onClick={isAgreeHandler}
                                                    />
                                                    <label htmlFor="terms">
                                                        <p className={'presale-form-text-input1-radio-label'}>By participating in LGT pre-sale, I agree to Liqui Global's <Link className={'primary2 bold'} to={'/terms-of-use'}>Terms of Use</Link> and <Link className={'primary2 bold'} to={'/privacy-policy'}>Privacy policy</Link></p>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>


                                        <div className={'presale-form-text-button-container'}>

                                            <p className={'input-p whitewhite error'}>
                                                {(
                                                    emailError ||
                                                    nameError ||
                                                    telegramError ||
                                                    quantityError ||
                                                    ethQuantityError ||
                                                    receiverWalletError ||
                                                    isAgreeError ||
                                                    (transactionMode === 2 && walletConnectionDirty ? walletConnectionError : '') ||
                                                    (formValidError ? 'Please fill out the form' : '')
                                                )}
                                                &nbsp;
                                            </p>

                                            <div className={'wallet-modal-tip-container'}>

                                                <button
                                                    onClick={() => {
                                                        setWalletConnectionErrorDirty(true)
                                                        if (!isAgree) {setIsAgreeError('You must accept Terms of Use to contribute')}
                                                        if (formValid && isAgree) {
                                                            if ((transactionMode === 1) || (transactionMode === 2 && isConnected)) {
                                                                sendCode()
                                                                if (stepTwoCompleted) {
                                                                    setStep(transactionMode === 2 ? 4 : 3)
                                                                } else {
                                                                    setStep(2)
                                                                }
                                                            }
                                                        } else {
                                                            setFormValidError(true)
                                                        }
                                                    }}
                                                    onKeyDown={handleKeypress}
                                                    className={'btn primary2filled2 presale-form-text-button'}
                                                    type={'submit'}
                                                    style={!formValid ? {opacity: '1'} : {}}
                                                >
                                                    Participate
                                                </button>

                                                {/*<button
                                        onClick={() => {}}
                                        className={'btn light presale-form-text-button'}
                                        type={'submit'}
                                    >
                                        Pay
                                    </button>*/}

                                                <Link to={'/submit-request'} className={'wallet-modal-tip-img'}>
                                                    <img src={help} className={'wallet-modal-tip-img'} />
                                                </Link>
                                            </div>

                                        </div>

                                    </div>

                                </div>








                                <div className={'presale-form-steps-carousel-slide'}>

                                    <div className={`${step === 4 ? 'active' : ''} presale-form-steptwo-container`}>


                                        <div
                                            className={'presale-form-emailverification-top'}
                                            onClick={() => {
                                                setStep(1)
                                            }}
                                        >

                                            <h3>
                                                Change Contribution Info
                                            </h3>

                                            <p>
                                                You can edit your contribution request details everytime through support
                                            </p>

                                        </div>


                                        <div className={'presale-form-emailverification-center'}>

                                            <h3 className={'white'}>
                                                Complete Transaction
                                            </h3>

                                            <p className={'white'} style={{marginTop: '4px'}}>
                                                After transaction completed we will notify you with confirmation email.
                                            </p>

                                            {/*<a className={'primary2 bold'}>{receiverWallet}</a>*/}


                                            <div className={'nomb presale-form-emailverification-img-container'}>
                                                <img src={lgt_overview1} className={`${true ? 'active' : ''} presale-form-emailverification-img`} />
                                            </div>

                                            <p
                                                className={`nohover input-p presale-form-complete-transaction-copy-address`}
                                            >
                                                Approve transaction in <a className={'primary2 bold'}>{selectedProvider}</a> and while it is processing you will receive contribution request details email.
                                            </p>




                                            <button
                                                onClick={() => approveTransaction()}
                                                className={'flexible btn primary2filled2 presale-form-text-button'}
                                                type={'submit'}
                                                style={{marginTop: '24px'}}
                                            >
                                                Approve Transaction
                                            </button>

                                        </div>





                                        <div className={'presale-form-emailverification-bott'}>

                                            <h3 className={'white'}>
                                                Important Notice
                                            </h3>

                                            <p className={'white'}>
                                                Verify the payment only after transaction completed, otherwise it may take longer for customer service team to notify you with confirmation.
                                            </p>

                                            <button
                                                onClick={() => window.open('https://etherscan.io/tokentxns', "_blank", "noreferrer")}
                                                className={'full btn light presale-form-text-button'}
                                                type={'submit'}
                                            >
                                                Track Transaction
                                            </button>

                                        </div>



                                        {/*<button
                                    onClick={() => {
                                        setStep(1)
                                    }}
                                    className={'btn light presale-form-text-button'}
                                    type={'submit'}
                                    style={{marginTop: '50px'}}
                                >
                                    Back
                                </button>

                                <button
                                    onClick={() => {
                                        checkCode()
                                    }}
                                    className={'btn light presale-form-text-button'}
                                    type={'submit'}
                                    style={{marginTop: '10px'}}
                                >
                                    Check
                                </button>*/}

                                    </div>

                                </div>





                                <div className={'presale-form-steps-carousel-slide'}>

                                    <div className={`${step === 2 ? 'active' : step === 3 ? 'left' : ''} presale-form-emailverification-container`}>


                                        <div
                                            className={'presale-form-emailverification-top'}
                                            style={codeLoading ? {opacity: '0.9', pointerEvents: 'none'} : {}}
                                            onClick={() => {
                                                if (!codeLoading) {
                                                    setStep(1)
                                                }
                                            }}
                                        >

                                            <h3>
                                                Change Contribution Info
                                            </h3>

                                            <p>
                                                You can edit your contribution request details everytime through support
                                            </p>

                                        </div>


                                        <div className={'presale-form-emailverification-center'}>

                                            <h3 className={'white'}>
                                                Email Verification
                                            </h3>

                                            <p className={'white'}>
                                                Please enter code that we send you on
                                                <br/>
                                                <a className={'primary2 bold'}>{email}</a>
                                            </p>

                                            <div className={'presale-form-emailverification-img-container'}>
                                                <img src={lgt_mail} className={`${!codeLoading && !stepTwoCompleted && codeError !== 2 ? 'active' : ''} presale-form-emailverification-img`} />
                                                <img src={lgt_mail_loading} className={`${codeLoading && !stepTwoCompleted ? 'active' : ''} loading presale-form-emailverification-img`} />
                                                <img src={lgt_mail_completed} className={`${stepTwoCompleted ? 'active' : ''} completed presale-form-emailverification-img`} />
                                                <img src={lgt_mail_error} className={`${codeError === 2 && !codeLoading ? 'active' : ''} error presale-form-emailverification-img`} />
                                            </div>


                                            <p className={'presale-form-emailverification-error-p input-p error'}>
                                                {codeError === 2 && !codeLoading ? 'Invalid code' : ' '}
                                                &nbsp;
                                            </p>


                                            <div className={''}>
                                                <VerificationInput
                                                    value={codeValue}
                                                    onChange={(e) => handleCodeValue(e)}
                                                    /*onComplete={() => checkCode()}*/
                                                    validChars="0-9"
                                                    inputProps={{ inputMode: "numeric" }}
                                                    placeholder=""
                                                    autoFocus={step === 2 ? true : false}
                                                    classNames={{
                                                        container: `${codeLoading ? 'loading' : ''} presale-form-emailverification-input-container`,
                                                        character: `${codeError === 2 && !codeLoading ? 'error' : ''} presale-form-emailverification-input-character`,
                                                        characterInactive: `${codeError === 2 && !codeLoading ? 'error' : ''} presale-form-emailverification-input-character--inactive`,
                                                        characterSelected: `${codeError === 2 && !codeLoading ? 'error' : ''} presale-form-emailverification-input-character--selected`,
                                                        characterFilled: `${codeError === 2 && !codeLoading ? 'error' : ''} presale-form-emailverification-input-character--filled`,
                                                    }}
                                                />
                                            </div>


                                            <button
                                                onClick={() => {
                                                    setCountdown(59)
                                                    setResendActive(false)
                                                    setCodeValue('')
                                                    setCodeError(0)
                                                    setCodeExpectedValue('')
                                                    sendCode()
                                                }}
                                                className={'btn primary2filled2 presale-form-text-button'}
                                                type={'submit'}
                                                style={{marginTop: '24px', opacity: resendActive ? '1' : '0', pointerEvents: resendActive ? 'all' : 'none'}}
                                            >
                                                Resend Code
                                            </button>

                                            <button
                                                onClick={() => {

                                                }}
                                                className={'btn light presale-form-text-button'}
                                                type={'submit'}
                                                style={{marginTop: '-48px', opacity: resendActive ? '0' : '1', pointerEvents: 'none'}}
                                            >
                                                0:{countDown <= 9 ? '0' : ''}{countDown}
                                            </button>

                                        </div>





                                        <div className={'presale-form-emailverification-bott'}>

                                            <h3 className={'white'}>
                                                Can't Verify Email?
                                            </h3>

                                            <p className={'white'}>
                                                If you can't proceed payment manually you can participate in LGT Pre-sale with your Web3 wallet
                                            </p>

                                            <button
                                                onClick={() => {
                                                    setStep(1)
                                                    setTransactionMode(2)
                                                    setWalletModal(true)
                                                }}
                                                className={'full btn light presale-form-text-button'}
                                                type={'submit'}
                                            >
                                                Connect Wallet
                                            </button>

                                        </div>

                                        {/*<button
                                    onClick={() => {
                                        checkCode()
                                    }}
                                    className={'btn light presale-form-text-button'}
                                    type={'submit'}
                                    style={{marginTop: '10px'}}
                                >
                                    Check
                                </button>*/}

                                    </div>

                                </div>







                                <div className={'presale-form-steps-carousel-slide'}>

                                    <div className={`${step === 3 ? 'active' : ''} presale-form-steptwo-container`}>


                                        <div
                                            className={'presale-form-emailverification-top'}
                                            style={codeLoading ? {opacity: '0.9', pointerEvents: 'none'} : {}}
                                            onClick={() => {
                                                if (!codeLoading) {
                                                    setStep(1)
                                                }
                                            }}
                                        >

                                            <h3>
                                                Change Contribution Info
                                            </h3>

                                            <p>
                                                You can edit your contribution request details everytime through support
                                            </p>

                                        </div>


                                        <div className={'presale-form-emailverification-center'}>

                                            <h3 className={'white'}>
                                                Complete Transaction
                                            </h3>

                                            <p className={'white'} style={{marginTop: '4px'}}>
                                                Make payment of <span style={{whiteSpace: 'nowrap', fontWeight: '500', color: '#ccdeff'}}>{formCurrency ? (Number(quantity)?.toFixed(2))?.toLocaleString() : (Number(ethQuantity)?.toFixed(6))?.toLocaleString()} {formCurrency ? 'USDT' : 'ETH'}</span> to the address given below.
                                                <p className={'light'}>
                                                    Supported chains: <span className={'bold'}>ERC20</span>, <span className={'bold'}>BEP20</span>.
                                                </p>
                                            </p>

                                            {/*<a className={'primary2 bold'}>{receiverWallet}</a>*/}


                                            <div className={'nomb presale-form-emailverification-img-container'}>
                                                <img src={lgt_overview1} className={`${true ? 'active' : ''} presale-form-emailverification-img`} />
                                            </div>


                                            <h5
                                                className={`presale-form-complete-transaction-given-wallet`}
                                            >
                                                {givenWallet}
                                            </h5>


                                            <p
                                                className={`${copyGivenWallet ? 'nohover' : ''} input-p bold presale-form-complete-transaction-copy-address`}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(receiverWallet)
                                                    setCopyGivenWallet(true)
                                                }}
                                            >
                                                {copyGivenWallet ? 'Copied' : 'Copy address'}
                                            </p>




                                            <button
                                                onClick={() => {

                                                }}
                                                className={'flexible btn primary2filled2 presale-form-text-button'}
                                                type={'submit'}
                                                style={{marginTop: '24px'}}
                                            >
                                                Confirm Payment
                                            </button>

                                        </div>





                                        <div className={'presale-form-emailverification-bott'}>

                                            <h3 className={'white'}>
                                                Important Notice
                                            </h3>

                                            <p className={'white'}>
                                                Verify the payment only after transaction completed, otherwise it may take longer for customer service team to notify you with confirmation.
                                            </p>

                                            <button
                                                onClick={() => window.open('https://etherscan.io/tokentxns', "_blank", "noreferrer")}
                                                className={'full btn light presale-form-text-button'}
                                                type={'submit'}
                                            >
                                                Track Transaction
                                            </button>

                                        </div>



                                        {/*<button
                                    onClick={() => {
                                        setStep(1)
                                    }}
                                    className={'btn light presale-form-text-button'}
                                    type={'submit'}
                                    style={{marginTop: '50px'}}
                                >
                                    Back
                                </button>

                                <button
                                    onClick={() => {
                                        checkCode()
                                    }}
                                    className={'btn light presale-form-text-button'}
                                    type={'submit'}
                                    style={{marginTop: '10px'}}
                                >
                                    Check
                                </button>*/}

                                    </div>

                                </div>








                            </div>

                        </div>

                        <div className={''}>


                        </div>

                    </div>

                </div>



















                <div className={'presale-form-col2'}>
                    <div className={'presale-form-col2-top'}>

                        <div className={'how-to-buy-wrapper'}>

                            <h2 className={'how-to-buy-h2-top'}>
                                How to buy Liqui Global Token?
                            </h2>

                            <div className={'how-to-buy-carousel-wrapper'}>

                                <div className={'how-to-buy-btns'}>
                                    <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev how-to-buy-btn'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'how-to-buy-btn how-to-buy-btn-left'}>
                                            <path
                                                fill={''}
                                                fillRule="evenodd"
                                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                            ></path>
                                        </svg>
                                    </button>

                                    <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next how-to-buy-btn'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'how-to-buy-btn how-to-buy-btn-right'}>
                                            <path
                                                fill={''}
                                                fillRule="evenodd"
                                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>

                                <div className={'embla how-to-buy-container-top'} ref={emblaRef}>

                                    <div className={'embla__container how-to-buy-slide-container-top'}>

                                        <div
                                            className={'embla__slide how-to-buy-slide-top'}
                                            onClick={() => {
                                                if (transactionMode === 2) {setWalletModal(true)}
                                            }}
                                        >

                                            <div className={'how-to-buy-circle-top'}>
                                                <h2 style={{marginTop: '2px', marginRight: '2px'}} className={'how-to-buy-circle-h3-top'}>1</h2>
                                            </div>

                                            <h3 className={'how-to-buy-slide-h3-top'}>
                                                {
                                                    transactionMode === 2 ?
                                                        'Connect Wallet'
                                                        :
                                                        'Fill out Contribution Form'
                                                }
                                            </h3>
                                        </div>

                                        <div className={'embla__slide how-to-buy-slide-top'}>

                                            <div className={'how-to-buy-circle-top'}>
                                                <h2 className={'how-to-buy-circle-h3-top'}>2</h2>
                                            </div>

                                            <h3 className={'how-to-buy-slide-h3-top'}>
                                                {
                                                    transactionMode === 2 ?
                                                        'Fill out Contribution Form'
                                                        :
                                                        'Wait for the information Email with details'
                                                }
                                            </h3>
                                        </div>

                                        <div className={'embla__slide how-to-buy-slide-top'}>

                                            <div className={'how-to-buy-circle-top'}>
                                                <h2 style={{marginTop: '2px'}} className={'how-to-buy-circle-h3-top'}>3</h2>
                                            </div>

                                            <h3 className={'how-to-buy-slide-h3-top'}>
                                                {
                                                    transactionMode === 2 ?
                                                        'Confirm suggested transaction in Wallet'
                                                        :
                                                        'Make the payment to your personal address from information email'
                                                }
                                            </h3>
                                        </div>

                                        <div className={'embla__slide how-to-buy-slide-top'}>

                                            <div style={{backgroundColor: '#fff'}} className={'how-to-buy-circle-top'}>
                                                <h2  style={{marginTop: '1px', marginRight: '2px', color: '#000'}} className={'how-to-buy-circle-h3-top'}>
                                                    4
                                                </h2>
                                            </div>

                                            <h3 className={'how-to-buy-slide-h3-top'}>
                                                {
                                                    transactionMode === 2 ?
                                                        'Confirmation Email will be sent once transaction done'
                                                        :
                                                        'Confirmation Email will be sent once transaction done'
                                                }
                                            </h3>
                                        </div>

                                    </div>

                                </div>

                            </div>



                        </div>






                    </div>






                    <div className={'participation-rules-container'}>


                        <h2 className={'white participation-rules-text-h2'}>
                            Details
                        </h2>

                        <div className={'particulars-container'}>


                            <div className={`wallet-modal-wrapper`}>
                                <div onClick={() => setVestingModal(false)} className={`wallet-modal-back ${vestingModal ? '' : 'hidden'}`}></div>

                                <div className={`primary2 wallet-modal-container ${vestingModal ? '' : 'hidden'}`}>
                                    <img onClick={() => setVestingModal(false)} src={cross_black} className={`wallet-modal-cross ${vestingModal ? '' : 'hidden'}`} />

                                    <h3 className={'wallet-modal-h3'}>
                                        Vesting Period
                                    </h3>


                                    <div className={'info wallet-modal-content'}>


                                        <h2>
                                            What is Cliff/Vesting?
                                        </h2>

                                        <p className={'wallet-modal-content-info-p'}>
                                            At the stage of preliminary collection of funds, the price of the token is usually very attractive for investors and increases significantly at the time of the official start of trading. There are risks of some early investors selling tokens quickly on the market in an attempt to make a quick profit, which could negatively affect the rate. To protect the price of a token from falling at the time of entering the market, the Cliff/Vesting system is used in practice.
                                        </p>

                                        <span></span>

                                        <div className={'wallet-modal-cliff-vesting-grid'}>

                                            <div className={'wallet-modal-cliff-vesting-item'}>
                                                <h3>
                                                    Cliff
                                                </h3>

                                                <p className={'wallet-modal-content-info-p'}>
                                                    a period of time during which tokens are not awarded to preliminary investors. Typically used for Pre-Seed round of investment. After the end of the Cliff period, tokens are distributed in accordance with the Vesting plan.
                                                </p>
                                            </div>

                                            <div className={'wallet-modal-cliff-vesting-item'}>
                                                <h3>
                                                    Vesting
                                                </h3>

                                                <p className={'wallet-modal-content-info-p'}>
                                                    a period of time during which tokens are transferred in equal parts to preliminary investors. This system is the most fair and balanced in relation to both preliminary investors and other market participants.
                                                </p>
                                            </div>

                                        </div>



                                        <h2>
                                            What Is Cliff/Vesting Period For Liqui Global Token?
                                        </h2>

                                        <p className={'wallet-modal-content-info-p'}>
                                            <span className={''}>Vesting</span> is a period of time during which tokens are transferred in equal parts to preliminary investors. This system is the most fair and balanced in relation to both preliminary investors and other market participants.
                                        </p>

                                        <div className={'wallet-modal-contributors-container'}>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    Pre-Seed Contributors
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Cliff - 12 months. Vesting - 12 months
                                                    <br/>daily linear vesting
                                                </p>
                                            </div>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    Pre-Sale Contributors
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Cliff - No Cliff. Vesting - 6 months
                                                    <br/>daily linear vesting
                                                </p>
                                            </div>

                                        </div>


                                    </div>

                                    <button
                                        to={'/token'}
                                        className={`btn dark full`}
                                        onClick={() => setVestingModal(false)}
                                    >
                                        Done
                                    </button>
                                </div>

                            </div>





                            <div className={`wallet-modal-wrapper`}>
                                <div onClick={() => setSpecModal(false)} className={`wallet-modal-back ${specModal ? '' : 'hidden'}`}></div>

                                <div className={`wallet-modal-container ${specModal ? '' : 'hidden'}`}>
                                    <img onClick={() => setSpecModal(false)} src={cross} className={`wallet-modal-cross ${specModal ? '' : 'hidden'}`} />

                                    <h3 className={'white wallet-modal-h3'}>
                                        Specification
                                    </h3>


                                    <div className={'info wallet-modal-content'}>


                                        <h2 className={'white'}>
                                            Pre-Sale Round Specification
                                        </h2>

                                        <p className={'white wallet-modal-content-info-p'}>
                                            Here you can find out technical details about particulars of conducting a transaction of Liqui Global Token
                                        </p>




                                        <h3 className={'white wallet-modal-content-info-h3'}>
                                            Available Amount Range
                                        </h3>

                                        <div className={'wallet-modal-contributors-container'}>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    Minimum
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    500 USDT
                                                </p>
                                            </div>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    Maximum
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    100,000 USDT
                                                </p>
                                            </div>

                                        </div>




                                        <h3 className={'white wallet-modal-content-info-h3'}>
                                            Supported Currencies
                                        </h3>

                                        <div className={'wallet-modal-contributors-container'}>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    USDT
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Tether
                                                </p>
                                            </div>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    ETH
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Ether
                                                </p>
                                            </div>

                                        </div>



                                        <h3 className={'white wallet-modal-content-info-h3'}>
                                            Supported Chains
                                        </h3>

                                        <div className={'triple wallet-modal-contributors-container'}>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    BEP20
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Binance Smart Chain
                                                </p>
                                            </div>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    ERC20
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Ethereum
                                                </p>
                                            </div>

                                            <div className={`bonus-program-item`}>
                                                <h3>
                                                    TRC20
                                                </h3>
                                                <p className={'light wallet-modal-contributors-container-p'}>
                                                    Tron
                                                </p>
                                            </div>

                                        </div>


                                    </div>

                                    <button
                                        to={'/token'}
                                        className={`btn primary2filled2 full`}
                                        onClick={() => setSpecModal(false)}
                                    >
                                        Done
                                    </button>
                                </div>

                            </div>





                            <div className={'particulars-container-top'}>
                                <div
                                    className={`primary2 features-plate`}
                                    onClick={() => setVestingModal(true)}
                                >

                                    <img src={lgt_vesting} className={'features-plate-img'} />

                                    <div className={'features-plate-text'}>
                                        <h3 className={'primary2 features-plate-h3'}>
                                            Vesting Period
                                        </h3>

                                        <p>
                                            Learn about Cliff/Vesting process of LGT
                                        </p>
                                    </div>

                                </div>

                                <div
                                    className={`primary4 features-plate`}
                                    onClick={() => setSpecModal(true)}
                                >

                                    <img src={lgt_specification} className={'features-plate-img'} />

                                    <div className={'features-plate-text'}>
                                        <h3 className={'primary4 features-plate-h3'}>
                                            Specification
                                        </h3>

                                        <p className={'white'}>
                                            Discover particulars of conducting a transaction
                                        </p>
                                    </div>

                                </div>
                            </div>


                            <div className={'particulars-container-bott'}>

                                <div className={'particulars-container-bott-item'}>

                                    <h3 className={'white'}>
                                        Facing the Issue?
                                    </h3>

                                    <p className={'white'}>
                                        Let us know and we will help you as soon as possible.
                                        <br/>
                                        Contact <a className={'primary2 bold'} href="mailto: support@liqui.global">support@liqui.global</a> or <Link className={'primary2 bold'} to={'/submit-request'}>Submit Request</Link>
                                    </p>

                                </div>

                            </div>


                        </div>

                    </div>






                    <div className={'presale-form-col2-bott'}>

                        <h2 className={'participation-rules-text-h2'}>
                            Bonus Program
                        </h2>

                        <div className={'participation-rules-block'}>

                            <div
                                className={'participation-rules-block-bonus'}
                                style={{pointerEvents: step === 1 ? '' : 'none'}}
                            >

                                <div
                                    className={`${bonus === 3 ? 'active' : ''} bonus-program-item`}
                                    onClick={() => {
                                        quantityHandler(5000)
                                        ethQuantityHandler(5000 / ethRate)
                                        setQuantityError('')
                                        setEthQuantityError('')
                                    }}
                                >
                                    <h3>
                                        +3%
                                    </h3>
                                    <p>
                                        &#8805; {formCurrency ? '5,000 USDT' : `${(5000/ethRate).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ETH`}
                                    </p>
                                </div>

                                <div
                                    className={`${bonus === 5 ? 'active' : ''} bonus-program-item`}
                                    onClick={() => {
                                        quantityHandler(10000)
                                        ethQuantityHandler(10000 / ethRate)
                                        setQuantityError('')
                                        setEthQuantityError('')
                                    }}
                                >
                                    <h3>
                                        +5%
                                    </h3>
                                    <p>
                                        &#8805; {formCurrency ? '10,000 USDT' : `${(10000/ethRate).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ETH`}
                                    </p>
                                </div>

                                <div
                                    className={`${bonus === 7 ? 'active' : ''} bonus-program-item`}
                                    onClick={() => {
                                        quantityHandler(25000)
                                        ethQuantityHandler(25000 / ethRate)
                                        setQuantityError('')
                                        setEthQuantityError('')
                                    }}
                                >
                                    <h3>
                                        +7%
                                    </h3>
                                    <p>
                                        &#8805; {formCurrency ? '25,000 USDT' : `${(25000/ethRate).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ETH`}
                                    </p>
                                </div>

                                <div
                                    className={`${bonus === 10 ? 'active' : ''} bonus-program-item`}
                                    onClick={() => {
                                        quantityHandler(50000)
                                        ethQuantityHandler(50000 / ethRate)
                                        setQuantityError('')
                                        setEthQuantityError('')
                                    }}
                                >
                                    <h3>
                                        +10%
                                    </h3>
                                    <p>
                                        &#8805; {formCurrency ? '50,000 USDT' : `${(50000/ethRate).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ETH`}
                                    </p>
                                </div>

                                <div
                                    className={`${bonus === 15 ? 'active' : ''} bonus-program-item`}
                                    onClick={() => {
                                        quantityHandler(75000)
                                        ethQuantityHandler(75000 / ethRate)
                                        setQuantityError('')
                                        setEthQuantityError('')
                                    }}
                                >
                                    <h3>
                                        +15%
                                    </h3>
                                    <p className={'light'}>
                                        &#8805; {formCurrency ? '75,000 USDT' : `${(75000/ethRate).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ETH`}
                                    </p>
                                </div>

                            </div>

                        </div>


                        <div className={'presale-receipt'}>

                            <div className={'presale-receipt-row'}>

                                <p>
                                    LGT price
                                </p>

                                <div className={'presale-dotted-line'}>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                </div>

                                <p>
                                    {formCurrency ? 0.03 : ((0.03 / ethRate).toFixed(10))} {formCurrency ? 'USDT' : 'ETH'}
                                </p>

                            </div>

                            <div className={'presale-receipt-row'}>

                                <p>
                                    Your Investment
                                </p>

                                <div className={'presale-dotted-line'}>

                                </div>

                                <p>
                                    {formCurrency
                                        ?
                                        Number(quantity)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Number(ethQuantity)?.toFixed(6)
                                    } {formCurrency ? 'USDT' : 'ETH'}
                                </p>

                            </div>

                            <div className={'presale-receipt-row'}>

                                <p>
                                    Your Tokens
                                </p>

                                <div className={'presale-dotted-line'}>

                                </div>

                                <p>
                                    {lgtQuantity?.toFixed()?.toLocaleString()} LGT
                                </p>

                            </div>


                            <div className={'presale-receipt-row'}>

                                <p>
                                    Bonus
                                </p>

                                <div className={'presale-dotted-line'}>

                                </div>

                                <p>
                                    {(lgtQuantity * bonus / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} LGT
                                </p>

                            </div>



                            <div className={'presale-receipt-row'}>

                                <h3>
                                    Total amount
                                </h3>

                                <div className={'presale-dotted-line'}>

                                </div>

                                <h3>
                                    {(lgtQuantity + (lgtQuantity * bonus / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} LGT
                                    {/*{lgtQuantity} LGT*/}
                                </h3>

                            </div>



                        </div>

                    </div>




                </div>

            </div>







        </div>
    );
};

export default PresaleForm;