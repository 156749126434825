import React from 'react';
import {Link} from "react-router-dom";
import document from '../assets/document.svg'
import arrowrightblue from "../assets/arrowrightblue.svg";

const TermsOfUse = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '10px', marginTop: '-20px'}} className={'info-page-header-img'} src={document} />
                    <h1>
                        Terms of Use
                    </h1>
                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text terms-of-use-container'}>


                        <h2 className={'info-page-h2'}>
                            I. Terms of Use
                        </h2>


                        <ul className={'ul-decimal info-page-ul-mt'}>
                            <li>
                                Use of this Liqui.Global website (“Website”) and the service offered on the Website (“Service”) are governed by the terms contained on this Terms and Conditions page (“Terms”). This agreement entirely constitutes the agreement between the parties. In the case of any contradiction or discrepancy between the other information provided on the Website and this terms, this terms shall prevail.
                            </li>

                            <li>
                                Please carefully read through this terms before using any Liqui Global service and you may consult Liqui Global if you have any question with regard to this terms. You shall be deemed as having accepted this terms and bound by this terms as long as you use Liqui Global services. By using Liqui Global services, you hereby promise to accept and observe this agreement. If you do not agree to this terms, you shall immediately stop registration/activation or stop using Liqui Global services.
                            </li>

                            <li>
                                By accessing, viewing or downloading information from the Liqui Global Website and using the Service provided by Liqui Global, you acknowledge that you have read, understood and unconditionally agreed to be bound by these Terms. Liqui Global may at any time without notice amend these Terms. You agree to continue to be bound by any amended terms and conditions and that Liqui Global has no obligation to notify you of such amendments. You acknowledge that it is your responsibility to check these Terms periodically for changes and that your continued use of the Liqui Global Website and services offered by Liqui Global following the posting of any changes to the Terms indicates your acceptance of any such changes.
                            </li>

                            <li>
                                Please read the terms carefully as they govern your use of Liqui Global services.  As with any asset, the values of cryptocurrencies may fluctuate significantly and there is a substantial risk of economic losses when purchasing, selling, holding or investing in cryptocurrencies and their derivatives. by making use of Liqui Global services, you acknowledge and agree that
                            </li>

                            <ul className={'ul-dot'}>
                                <li style={{paddingLeft: '10px'}}>
                                    You are aware of the risks associated with transactions of cryptocurrencies and their derivatives;
                                </li>

                                <li style={{paddingLeft: '10px'}}>
                                    You shall assume all risks related to the use of Liqui Global services and transactions of cryptocurrencies;
                                </li>

                                <li style={{paddingLeft: '10px'}}>
                                    Liqui Global shall not be liable for any such risks or adverse outcomes.
                                </li>

                            </ul>

                        </ul>






                        <h2 className={'info-page-h2'}>
                            II. Registration and Account
                        </h2>




                        <h5 className={'info-page-h3'}>
                            1. Eligibility of Registrants
                        </h5>

                        <ul className={'ul-lower-latin'}>
                            <li>
                                You hereby confirm that you are an individual, legal person or other organization with full capacity for civil rights and civil conducts when you complete the registration or actually use Liqui Global services in any other way allowed by Liqui Global. If you do not have the said capacity, you and your guardian shall undertake all the consequences resulted therefrom, and Liqui Global shall have the right to cancel or permanently freeze your account.
                            </li>

                            <li>
                                By registering to use a Liqui Global account, you represent and warrant that
                            </li>

                            <ul className={'ul-nested ul-dot'}>
                                <li>
                                    As an individual, you are at least 18 or are of legal age to form a binding contract under applicable laws;
                                </li>

                                <li>
                                    As an individual, legal person, or other organization, you have full legal capacity and sufficient authorizations to enter into these Terms;
                                </li>

                                <li>
                                    You have not been previously suspended or removed from using Liqui Global services;
                                </li>

                                <li>
                                    You are neither a United States user, a Mainland China user, a Singapore user or other users who are not allowed to register on this platform according to your local laws; nor are you acting on behalf of one of these above countries.
                                </li>

                                <li>
                                    Your use of Liqui Global services will not violate any and all laws and regulations applicable to you, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.
                                </li>

                            </ul>

                            <li>
                                Please note Liqui Global reserves the right to change, modify or impose additional restrictions at its discretion at any time.
                            </li>

                        </ul>




                        <h5 className={'info-page-h3'}>
                            2. Registration and Account
                        </h5>

                        <ul className={"ul-lower-latin"}>
                            <li>
                                You shall be bound by this agreement once you have filled in information, read and agreed to this agreement and completed the registration process following the instructions on the registration page or you have filled information, read and agreed to this agreement and completed the activation process following the instructions on the activation page, or upon your actual use of Liqui Global services in a way permitted by Liqui Global.
                            </li>

                            <li>
                                You may log in Liqui Global by your email address or any other means permitted by Liqui Global. При прохождение верификации И УВЕЛИЧЕНИЯ ЛИМИТОВ НА ВЫВОД СРЕДСТВ You must provide your real name, ID type, ID number and other information required by the laws and regulations. The users have responsibility to make sure the information for registration is accurate and complete and Liqui Global has no duty to verify the users’ real identity. If any information you have provided during the registration is inaccurate or incomplete, Liqui Global will not take any responsibility and any loss, direct or indirect, and adverse consequence resulted therefrom will be borne by you.
                            </li>

                            <li>
                                Liqui Global accounts can only be used by the person whose name they are registered under. Liqui Global reserves the right to suspend, freeze, or cancel accounts that are used by persons other than the persons whose names the accounts are registered under.
                            </li>
                        </ul>



                        <h5 className={'info-page-h3'}>
                            3. User’s Information
                        </h5>

                        <ul className={'ul-lower-latin'}>
                            <li>
                                During the registration or activation, you shall accurately provide and timely update your information by following the instructions on the relevant page according to the laws and regulations in order to make it truthful, timely, complete and accurate. If there is any reasonable doubt that any information provided by you is wrong, untruthful, outdated or incomplete, Liqui Global shall have the right to send you a notice to make enquiry and demand corrections, remove relevant information directly and, as the case may be, terminate all or part of Liqui Global services to you. Liqui Global will not take any responsibility and any loss, direct or indirect, and adverse consequence resulted therefrom will be borne by you.
                            </li>

                            <li>
                                You shall accurately fill in and timely update your email address, telephone number and other contact information so that Liqui Global will be able to effectively contact you. You shall be solely and fully responsible for any loss or extra expenses incurred during the use of Liqui Global services by you if you cannot be contacted through these contact information.
                            </li>
                        </ul>



                        <h5 className={'info-page-h3'}>
                            4. Account Security
                        </h5>

                        <ul className={'ul-lower-latin'}>
                            <li>
                                You shall be solely responsible for the safekeeping of your Liqui Global account and password on your own, and you shall be responsible for all activities under your log-in email, Liqui Global account and password (including but not limited to information disclosure, information posting, consent to or submission of various rules and agreements by clicking on the website, online renewal of agreement or online purchase of services, etc.
                            </li>

                            <li>
                                You hereby agree that:
                            </li>

                            <ul className={'ul-nested ul-dot'}>
                                <li>
                                    You will notify Liqui Global immediately if you are aware of any unauthorized use of your Liqui Global account and password by any person or any other violations to the security rules;
                                </li>

                                <li>
                                    You will strictly observe the security, authentication, dealing, charging, withdrawal mechanism or procedures of the Liqui Global website/services;
                                </li>

                                <li>
                                    You will log out the website by taking proper steps at the end of every visit. Liqui Global shall not and will not be responsible for any loss caused by your failure to comply with this provision.
                                </li>

                            </ul>

                            <li>
                                You understand that Liqui Global is a venue for cryptocurrency transactions. Although Liqui Global has taken all necessary method to Improve the network security, it is your responsibility to protect your login information and safeguard the assets in your account. Liqui Global will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your passwords.
                            </li>

                            <li>
                                Liqui Global suggests you timely take actions regarding your assets in your account for safeguarding. Although Liqui Global will take any necessary actions that Liqui Global deems appropriate to help the user recovering the stolen funds (if any), Liqui Global is not responsible for the consequences (including but not limited to any of your loss) that have occurred by user’s reckless safekeeping information or malicious hacker’s attacking. You also understand that Liqui Global needs reasonable time to take actions upon your request, and Liqui Global will not undertake any responsibility for the consequences (including but not limited to any of your loss) that have occurred prior to such actions.
                            </li>

                        </ul>






                        <h2 className={'info-page-h2'}>
                            III. Liqui Global Services
                        </h2>



                        <ul className={'ul-decimal info-page-ul-mt'}>

                            <li>
                                Through Liqui Global services, members may post deal information, access to the pricing and dealing information of a deal and carry out the deal, participate in activities organized by Liqui Global and enjoy other information services and technical services. By using this service you accept that Liqui Global reserves the right to liquidate any trades at any time regardless of the profit or loss position.
                            </li>

                            <li>
                                All the applicable taxes and all the expenses in relation to hardware, software, service and etc. arising during your use of the Liqui Global services shall be solely borne by you.
                            </li>

                            <li>
                                If you have any dispute with other members arising from any transaction on Liqui Global, once such dispute is submitted by one or both of you and the other member to Liqui Global for dispute resolution, Liqui Global shall have the right to make decision at its sole discretion. You hereby acknowledge and accept the discretion and decision of Liqui Global.
                            </li>

                            <li>
                                Вы признаете и соглашаетесь с тем, что Liqui Global может использовать любые пользовательский данные, записи транзакций и любую другую необходимую информацию.
                            </li>

                            <li>
                                Если вы предположительно нарушаете чьи-либо интеллектуальные права или другие законные интересы, Liqui Global может временно заморозить ваш аккаунт для прохождения процесса проверки.
                            </li>

                        </ul>






                        <h2 className={'info-page-h2'}>
                            IV. Important Reminder
                        </h2>





                        <h5 className={'info-page-h3'}>
                            You hereby promise to observe the following covenants during your use of Liqui Global services:
                        </h5>

                        <ul className={'ul-decimal'}>

                            <li>
                                Digital asset transactions are highly risky, due to the fact that they are traded throughout 24-hours a day without limits on the rise or fall in price, and market makers and global government policies may cause major fluctuations in their prices.
                            </li>

                            <li>
                                Digital asset transactions may be suspended or prohibited at any time due to the enactment or modification of national laws, regulations and regulatory documents. It is your solely and fully responsibility to be compliant with their local laws and regulations concerning cryptocurrency trading. The users from the countries or regions that prohibit cryptocurrency trading, or have very strict legal requirements to cryptocurrency trading, shall consciously consider giving up using Liqui Global platform. Any loss, direct or indirect and adverse consequence resulted therefrom shall be borne by the user. Any loss to Liqui Global resulted therefrom shall be compensated by the user.
                            </li>

                            <li>
                                You understand that this website is only intended to serve as a venue for you to obtain digital asset information, find trading counterparties, hold negotiations on and effect transactions of digital assets. You shall, at your sole discretion, carefully assess the authenticity, legality and validity of relevant digital assets and/or information, and solely bear the responsibilities and losses that may arise therefore.
                            </li>

                            <li>
                                All opinions, information, discussions, analyses, prices, advice and other information on this website are general market reviews and do not constitute any investment advice. We do not bear any loss arising directly or indirectly from reliance on the above-mentioned information, including but not limited to, any loss of profits.
                            </li>

                            <li>
                                It is prohibited to use this website for any illegal activities, such as money-laundering, smuggling and commercial bribery, theft, malicious manipulating price leading to market collapse. Upon uncovering any of such illegal activities, this website will adopt all available measures, including but not limited to freezing accounts. And in this case, we shall not assume any of the responsibilities arising therefrom, and reserve the right to hold the relevant persons accountable.
                            </li>

                            <li>
                                All the activities that you carry out during the use of Liqui Global Services will be in compliance with the various rules of Liqui Global, will not be in violation of public interests, public ethnics or other’s legitimate interests, will not constitute evasion of payable taxes or fees and will not violate this agreement or relevant rules. If you violate the foregoing promises and thereby cause any legal consequence, you shall independently undertake all of the legal liabilities in your own name and hold Liqui Global harmless from any loss resulted from such violation.
                            </li>

                            <li>
                                During any transaction with other members, you will be in good faith, will not take any acts of unfair competition, will not disturb the normal order of online transactions.
                            </li>

                            <li>
                                You will not use any data on Liqui Global without prior written consent of Liqui Global, for any purposes, including but not limited to using any data displayed on Liqui Global through copy, dissemination or any other means.
                            </li>

                            <li>
                                You will not use any device, software or subroutine to intervene or attempt to intervene the normal operation of Liqui Global or any ongoing transaction or activities on Liqui Global. You will not adopt any action that will induce unreasonable size of data loading on the network equipments of Liqui Global.
                            </li>

                            <li>
                                Liqui Global shall have the right to unilaterally determine whether you have violated any of the covenants above and, according to such unilateral determination, apply relevant rules and take actions thereunder or terminate services to you, without your consent or prior notice to you.
                            </li>

                            <li>
                                As required to maintain the order and security of transactions on Liqui Global, Liqui Global shall have the right to close relevant orders and take other actions in case of any malicious sale or purchase or any other events disturbing the normal transaction of the market.
                            </li>

                            <li>
                                Liqui Global  имеет право по своему усмотрению определять, что вы нарушили условия и правила настоящего соглашения.
                            </li>

                            <li>
                                Liqui Global имеет право использовать любую предоставленную информацию и наложить наказание в соответствии с правилами.
                            </li>

                            <li>
                                As to any act you may have carried out on Liqui Global, including those you have not carried out on Liqui Global but have had impacts on Liqui Global and its users, Liqui Global shall have the right to unilaterally determine its nature and whether it constitutes violation of this agreement or any rules, and impose punishments accordingly.
                            </li>

                            <li>
                                For any disputes between users such as one user claims recovering from another user on Liqui Global in name of being stolen, with preliminary evidence accepted by Liqui Global, Liqui Global will take necessary actions such as freezing the cryptocurrency in the related account before the disputes between the users are solved.
                            </li>

                            <li>
                                In the event that the issuers of cryptocurrency swap or migrate its cryptocurrency to new smart contract or new chain, you understand that it is the users’ duty to inform Liqui Global timely, or you could withdraw it from Liqui Global to swap cryptocurrency by the yourselves. Liqui Global has no promise to the users for successfully swapping or migrating any cryptocurrency, and Liqui Global takes no responsibility to the loss caused if you do not timely inform Liqui Global for the cryptocurrency swap or migration.
                            </li>

                            <li>
                                If your alleged violation to your promises causes any losses to any third party, you shall solely undertake all liabilities in your own name and hold Liqui Global harmless from any loss or extra expenses. Если из-за любого предполагаемого нарушения настоящего соглашения Liqui Global понесет какие-либо убытки, ваша учетная запись подвергнется наказанию в одностороннем порядке.
                            </li>

                        </ul>





                        <h2 className={'info-page-h2'}>
                            V. Indemnity and Limitation of Liability
                        </h2>

                        <ul className={'ul-decimal info-page-ul-mt'}>

                            <li>
                                Liqui Global will provide Liqui Global services at an “as is” and “commercially available” condition. Liqui Global disclaims any express or implied warranty with regards to Liqui Global services, including but not limited to applicability, free from error or omission, continuity, accuracy, reliability or fitness for a particular purpose. Meanwhile, Liqui Global disclaims any promise or warranty with regards to the effectiveness, accuracy, correctness, reliability, quality, stability, completeness and timeliness of the technology and information involved by Liqui Global services.
                            </li>

                            <li>
                                Based on the special nature of the Internet, Liqui Global does not guarantee that the service will not be interrupted, and the timeliness of the service, security are also not guaranteed, and Liqui Global does not bear the responsibility which is not caused by Liqui Global. Liqui Global tries to provide a safety network environment to the users. However, Liqui Global does not guarantee that the site or its servers are free of viruses or other potentially harmful factors; therefore, the user should use the recognized software to check any virus in the files downloaded from Liqui Global.
                            </li>

                            <li>
                                You are fully aware that Liqui Global serves as a venue of transaction where you acquire coin related information, search for counterparties of transactions and negotiate and conduct transactions. Liqui Global does not control, guarantee or take responsibility of the quality, security or legality of the coin involved in any transaction, truthfulness or accuracy of the transaction information, or capacity of the parties to any transaction to perform its obligations under the transaction documents. You shall cautiously make judgment on your own on the truthfulness, legality and effectiveness of the coin and information in question, and undertake any liabilities and losses that may be caused thereby.
                            </li>

                            <li>
                                Liqui Global shall not have any duty to conduct preliminary review on information data, transaction activity and any other transaction related issues. Liqui Global has right to take necessary actions such as reporting to the authorities or closure of accounts if Liqui Global has reasonable cause to suspect that a member or a particular transaction may materially violate the laws, regulations or this agreement.
                            </li>

                            <li>
                                You acknowledge and agree, Liqui Global shall not be liable for any of your losses caused by any of the following events, including but not limited to losses of profits, goodwill, usage or data or any other intangible losses (regardless whether Liqui Global has been advised of the possibility of such losses):
                            </li>


                            <ul className={'ul-nested ul-dot'}>

                                <li>
                                    Use or failure to use Liqui Global services.
                                </li>

                                <li>
                                    Unauthorized use of your account or unauthorized alternation of your data by any third parties.
                                </li>

                                <li>
                                    Expenses and losses incurred from purchase or acquisition of any data or information or engagement in transaction through Liqui Global services, or any alternatives of the same.
                                </li>

                                <li>
                                    Your misunderstanding on Liqui Global services.
                                </li>

                                <li>
                                    Any other losses related to Liqui Global services which are not attributable to Liqui Global.
                                </li>

                            </ul>

                            <li>
                                Liqui Global does not bear any liabilities for any failure or delay of service resulted from regular equipment maintenance of the information network, connection error of information network, error of computers, communication failure or error, hacking incident, labor disputes, natural disasters, riots, wars or judicial orders.
                            </li>

                            <li>
                                Liqui Global may, in its discretion, update the fees at any time. Any updated fees will apply to any sales or other Transactions that occur following the effective date of the updated fees. You authorize Liqui Global to deduct from your account any applicable fees that you owe under these Terms.
                            </li>

                            <li>
                                Please be aware that official announcements, news, promotions, competitions and airdrops will be listed on ТУТ ССЫЛКТ НА РЕСУРСЫ С НОВОСТЯМИ. users undertake to refer to these materials regularly and promptly. Liqui Global will not be held liable or responsible in any manner of compensation should users incur personal losses arising from ignorance or negligence of the announcements.
                            </li>

                        </ul>











                        <h2 className={'info-page-h2'}>
                            VI. Termination of Agreement
                        </h2>




                        <ul className={'ul-decimal info-page-ul-mt'}>

                            <li>
                                You hereby agree that, with probable cause, at Liqui Global’s sole discretion, Liqui Global shall have the right to terminate all or part of Liqui Global services to you, temporarily freeze or permanently freeze/cancel the authorizations of your account on Liqui Global, and Liqui Global shall not be liable to you for the actions. However, Liqui Global shall have the right to keep and use the transaction data, records and other information that is related to such account.
                            </li>

                            <li>
                                In case of any of the following events, Liqui Global shall have the right to directly terminate this agreement by canceling your account, and shall have the right to permanently freeze/cancel the authorizations of your account on Liqui Global and withdraw the corresponding Liqui Global account thereof:
                            </li>


                            <ul className={'ul-nested ul-dot'}>

                                <li>
                                    After Liqui Global terminates services to you, you allegedly register or register in any other person’s name as Liqui Global user again, directly or indirectly;
                                </li>

                                <li>
                                    The main content of user’s information that you have provided is untruthful, inaccurate, outdated or incomplete;
                                </li>

                                <li>
                                    When this agreement (including the rules) is amended, you expressly state and notify Liqui Global of your unwillingness to accept the amended service agreement;
                                </li>

                                <li>
                                    Abnormal account activities, illegal behavior, malicious manipulation of the market;
                                </li>

                                <li>
                                    Any other circumstances where Liqui Global deems it should terminate the services.
                                </li>

                            </ul>


                            <li>
                                After the account service is terminated or the authorizations of your account on Liqui Global is permanently froze/cancelled, Liqui Global shall not have any duty to keep or disclose to you any information in your account or forward any information to you or any third party. You agree that, after the termination of agreement between you and Liqui Global, Liqui Global shall still have the rights to:
                            </li>

                            <ul className={'ul-nested ul-dot'}>

                                <li>
                                    Keep your user’s information and all the transaction information during your use of Liqui Global services.
                                </li>

                                <li>
                                    Ask for compensation for any loss caused to Liqui Global according to this agreement if you have violated any laws, regulations, this agreement or the rules during your use of Liqui Global services.
                                </li>

                            </ul>

                            <li>
                                After Liqui Global suspends or terminates Liqui Global services to you, your transaction activities prior to such suspension or termination will be dealt with according to the following principles, and you shall fully undertake any disputes, losses or extra expenses caused thereby and keep Liqui Global harmless from any losses or expenses:
                            </li>


                            <ul className={'ul-nested ul-dot'}>

                                <li>
                                    Upon suspension or termination of services, Liqui Global shall have the right to delete information related to any un-traded coin tokens that you have uploaded to Liqui Global prior to the suspension or termination.
                                </li>

                                <li>
                                    If you have reached any purchase agreement with any other member prior to the suspension or termination, and such agreement has not been actually performed, Liqui Global shall have the right to delete information related to such purchase agreement and the coins in question.
                                </li>

                                <li>
                                    If you have reached any purchase agreement with any other member prior to the suspension or termination, and such agreement has been partially performed, Liqui Global may elect not to delete the transaction; provided, however, Liqui Global shall have the right to notify your counterparty of the situation.
                                </li>

                            </ul>

                        </ul>


                    </div>

                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>


        </div>
    );
};

export default TermsOfUse;