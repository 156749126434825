import React, {useEffect, useRef, useState} from 'react';
import lgt_giftbox_vidget from "../../assets/float_right/lgt_giftbox_vidget.svg";
import support from "../../assets/float_right/support.svg";
import faq from "../../assets/float_right/faq.svg";
import './FloatingGiftbox.css'
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

const FloatingGiftbox = () => {

    const [cookieExists, setCookieExists] = useState(true)
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    return (
        <div className={'floating-presale'}>

            <div className={`floating-plates-container ${!cookies.cookieConsent ? 'up' : ''}`}>

                <Link to={'/token'} className={`floating-presale-wrapper`}>
                    <div className={`floating-presale-img-container`}>
                        <img className={'anim floating-presale-img'} src={lgt_giftbox_vidget} />
                    </div>
                    <div className={`floating-presale-hidden`}>
                        <h5 className={'whitewhite'}>
                            NFT GiftBox
                        </h5>
                        <p className={'white'}>
                            Get random NFT piece with <br/>every LGT contribution.
                        </p>
                    </div>
                </Link>

                <Link to={'/submit-request'} className={`floating-presale-wrapper`}>
                    <div className={`white floating-presale-img-container`}>
                        <img className={'floating-presale-img'} src={support} />
                    </div>
                    <div className={`white floating-presale-hidden`}>
                        <h5 className={''}>
                            Need Help?
                        </h5>
                        <p className={''}>
                            Leave request and our<br/>
                            manager will contact you.
                        </p>
                    </div>
                </Link>

                <Link to={'/faq'} className={`floating-presale-wrapper`}>
                    <div className={`white floating-presale-img-container`}>
                        <img className={'floating-presale-img'} src={faq} />
                    </div>
                    <div className={`white floating-presale-hidden`}>
                        <h5 className={''}>
                            FAQ
                        </h5>
                        <p className={''}>
                            Find answers or<br/>
                            ask questions.
                        </p>
                    </div>
                </Link>

            </div>
        </div>
    );
};

export default FloatingGiftbox;