import React, {useState} from 'react';
import './Blog.css'
import rupor from "../../assets/rupor.svg";
import {Link, Route, Routes} from "react-router-dom";
import giftbox from '../../assets/posts/giftbox.png'
import Home from "../components/Home";
import JoinCommunity from "../components/JoinCommunity";

const Blog = () => {

    const [activeArt, setActiveArt] = useState()



    return (
        <div className={'blog-wrapper'}>

            <div className={'blog-header'}>
                <img style={{height: '115px', marginRight: '15px'}} className={'info-page-header-img'} src={rupor}/>

                <h1>
                    Blog
                    <h5 style={{margin: '0', paddingTop: '10px'}}>
                        Keep up with the latest news
                    </h5>
                </h1>
            </div>

            <div className={"blog-list"}>

                <Link className={'blog-link'} to={'/blog/art1'}>

                    <img className={'blog-link-img'} src={giftbox} />
                    <span className={'blog-link-date'}>
                        22.10.23
                    </span>
                    <h3 className={'blog-link-h3'}>
                        NFT GiftBox with every LGT contribution
                    </h3>

                    <p className={'blog-link-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at atque deserunt exercitationem ipsum nesciunt sapiente similique voluptate...
                    </p>

                </Link>

                <Link className={'blog-link'} to={'/blog/art2'}>

                    <img className={'blog-link-img'} src={''} />
                    <span className={'blog-link-date'}>
                        20.10.23
                    </span>
                    <h3 className={'blog-link-h3'}>
                        Join bounty program and earn LGT
                    </h3>

                    <p className={'blog-link-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque eveniet iste natus praesentium quo repellendus rerum saepe sit tempora veritatis...
                    </p>

                </Link>

                <Link className={'blog-link'} to={'/blog/art3'}>

                    <img className={'blog-link-img'} src={''} />
                    <span className={'blog-link-date'}>
                        30.09.23
                    </span>
                    <h3 className={'blog-link-h3'}>
                        What is NFT Workshop and why i need to try it?
                    </h3>

                    <p className={'blog-link-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab at atque deserunt exercitationem ipsum nesciunt sapiente similique voluptate...
                    </p>

                </Link>

                <Link className={'blog-link'} to={'/blog/art4'}>

                    <img className={'blog-link-img'} src={''} />
                    <span className={'blog-link-date'}>
                        15.09.23
                    </span>
                    <h3 className={'blog-link-h3'}>
                        CEO of Liqui Global about security
                    </h3>

                    <p className={'blog-link-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur corporis et ipsa laboriosam, omnis possimus quibusdam quo, sed tempora velit veniam voluptatem...
                    </p>

                </Link>


            </div>
            <JoinCommunity />

        </div>
    );
};

export default Blog;