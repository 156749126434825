import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import LgtPresale from "./components/LgtPresale";
import arrowrightblue from "../assets/arrowrightblue.svg";
import './InfoPage.css'
import './Security.css'
import './SociadMediaBounty.css'
import security_icon from "../assets/security_icon.svg";
import safe1 from "../assets/safe1.svg";
import safe2 from "../assets/safe2.svg";
import safe3 from "../assets/safe3.svg";
import securityweb from "../assets/securityweb.svg";
import request from "../assets/privacy.svg";
import Reliability from "./components/Reliability";

const Security = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{height: '115px', marginRight: '10px'}} className={'info-page-header-img'} src={security_icon}/>

                    <h1>
                        Security and Safety
                        <h5 style={{margin: '0', paddingTop: '10px'}}>
                            Ваша безопасность – наша забота
                        </h5>
                    </h1>

                </div>

                <div className={'security-container info-page-container'}>

                        <div className={'security-2-container'}>

                            <div className={'security-1-left info-page-1-left'}>

                                <h2 className={'info-page-h2'}>
                                    Safety comes first
                                </h2>

                                <img className={'security-text-img-in info-page-text-img-in'} src={securityweb} />

                                <div className={'info-page-bsect'} style={{padding: '25px'}}>
                                    <h3>
                                        Data storage transparency
                                    </h3>

                                    <p className={'info-page-p'}>
                                        Мы не собирем и не храним скрытую информацию о наших пользователях. Вы всегда в курсе, какими данными делитесь при работе с платформой
                                    </p>
                                    <br/>
                                    <h3 className={'info-page-h3'}>
                                        Technical stability
                                    </h3>

                                    <p className={'info-page-p'}>
                                        Максимальное быстродействие при работе с платформой. Постоянные технические улучшения и обновления гарантируют надежность соединения и стабильную торговую среду
                                    </p>
                                </div>

                            </div>

                            <div className={'security-1-right info-page-1-right'}>
                                <img className={'security-text-img'} src={securityweb} />
                            </div>

                        </div>




                        <div className={'info-page-2limg-container info-page-2limg-container-first'}>

                            <div className={'info-page-2limg-left'}>

                                <img className={'info-page-2limg-img'} src={safe1} />

                            </div>

                            <div className={'info-page-2limg-right'}>

                                <h2 className={'info-page-h2'}>
                                    Cutting edge security measures
                                </h2>

                                <img className={'info-page-2limg-img-in'} src={safe1} />

                                <p className={'info-page-p'}>
                                    Мы используем инновационные технические разработки и строго соблюдаем правила безопасности.
                                    Все данные строго шифруются во время хранения и передачи, что гарантирует приватность доступа к вашему акаунту.
                                </p>

                            </div>

                        </div>




                        <div className={'info-page-2limg-container'}>

                            <div className={'info-page-2limg-left'}>

                                <img className={'info-page-2limg-img'} src={safe2} />

                            </div>

                            <div className={'info-page-2limg-right'}>

                                <h2 className={'info-page-h2'}>
                                    AI Real-Time Monitoring System
                                </h2>

                                <img className={'info-page-2limg-img-in'} src={safe2} />

                                <p className={'info-page-p'}>
                                    AI Система мониторинга анализирует поведение пользователей в режиме реального времени, моментально реагируя на подозрительную активность и блокируя потенциальные угрозы безопасности.
                                </p>

                            </div>

                        </div>




                        <div className={'info-page-2limg-container'}>

                            <div className={'info-page-2limg-left'}>

                                <img className={'info-page-2limg-img'} src={safe3} />

                            </div>

                            <div className={'info-page-2limg-right'}>

                                <h2 className={'info-page-h2'}>
                                    Secure Asset Storage
                                </h2>

                                <img className={'info-page-2limg-img-in'} src={safe3} />

                                <p className={'info-page-p'}>
                                    Используя холодные кошельки для хранения средств пользователей, мы гарантируем максимальную надежность и полную сохранность ваших цифровых активов.
                                </p>

                            </div>

                        </div>


                    <div className={'security-reliability-container'}>
                        <Reliability isInSecurity={true} />
                    </div>



                    <div className={'security-1-container info-page-1-container'}>

                        <div className={'security-1-left'}>

                            <h2 className={'info-page-h2'}>
                                Facing the issue?
                            </h2>

                            <img className={'security-text-img-in info-page-text-img-in'} src={request} />

                            {/*<div className={'info-page-bsect'} style={{padding: '25px'}}>*/}
                                <h3 className={'security-request-text'}>
                                    Contact us and we will help you as soon as possible
                                </h3>

                                <ul className={'ul-dot'} style={{paddingBottom: '0'}}>
                                    <li>
                                        Write us about your problem at <a className={'info-page-underlined-a'} href="mailto:support@liqui.global">support@liqui.global</a>
                                    </li>
                                    <li style={{paddingBottom: '0'}}>
                                        Or <Link className={'info-page-underlined-a'} to={'/submit-request'}>Submit a request</Link> and a member of our support staff will respond within 24 hours
                                    </li>
                                </ul>

                            {/*</div>*/}

                        </div>

                        <div className={'security-1-left'}>
                            <img className={'security-text-img'} src={request} />
                        </div>

                    </div>


                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>


                    </div>

            </div>

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default Security;