import React from 'react';
import './NFTGiftBoxes.css'
import lgt_giftboxes from '../assets/lgt_giftboxes.svg'
import {Link} from "react-router-dom";

const NftGiftBoxes = () => {
    return (
        <div className={'gift-boxes-wrapper'}>
            <div className={'gift-boxes-container'}>
                <div className={'gift-boxes-info'}>
                    <div className={'gift-boxes-text'}>
                        <h1 className={'gift-boxes-header'}>
                            NFT GiftBox
                        </h1>
                        <p className={'gift-boxes-info-p'}>
                            Get random NFT piece from GiftBox that comes with every LGT contribution. GiftBox can contain NFT valued from <span className={'bold'}>30$</span> to <span className={'bold'}>3000$</span>.
                        </p>
                    </div>

                    <Link to={'/token'} className={'gift-boxes-info-img-container-in'}>
                        <img src={lgt_giftboxes} className={'gift-boxes-info-img-in'}/>
                    </Link>

                    <Link to={'/token'} className={'btn dark gift-boxes-claim-btn'}>
                        Claim
                        {/*<svg style={{transform: 'rotate(90deg)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                                    <path
                                        fill={'#fff'}
                                        fillRule="evenodd"
                                        d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                    ></path>
                                </svg>*/}
                    </Link>

                </div>
                <Link to={'/token'} className={'gift-boxes-info-img-container'}>
                    <img src={lgt_giftboxes} className={'gift-boxes-info-img'}/>
                </Link>
            </div>
        </div>
    );
};

export default NftGiftBoxes;