import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Main";
import Verify from "./Verify";
import Token from "./Token/Token";
import Feedback from "./Feedback";
import Request from "./Request";
import ListingApplication from "./ListingApplication";
import GiftboxBanner from "./pages/components/GiftboxBanner";
import EarnBox from "./pages/EarnBox";
import TradingBots from "./pages/TradingBots";

function App() {

    // const promptValue = prompt('')

    // if (promptValue === 'hui123') {


        return (

            <BrowserRouter>
                <Routes>
                    <Route path={'/*'} element={<Main/>}/>
                    <Route path={'/verify'} element={<Verify/>}/>
                    <Route path={'/listing-application'} element={<ListingApplication/>}/>
                    <Route path={'/feedback'} element={<Feedback/>}/>
                    <Route path={'/submit-request'} element={<Request/>}/>

                    <Route path={'/token'} element={<Token scrollTo={''} />}/>
                    <Route path={'/token/overview'} element={<Token scrollTo={'overview'} />}/>
                    <Route path={'/token/buy'} element={<Token scrollTo={'buy'} />}/>
                    <Route path={'/token/giftbox'} element={<Token scrollTo={'giftbox'} />}/>
                    <Route path={'/token/roadmap'} element={<Token scrollTo={'roadmap'} />}/>

                    <Route path={'/earnbox'} element={ <EarnBox /> }/>
                    <Route path={'/trading-bots'} element={ <TradingBots /> }/>
                </Routes>
            </BrowserRouter>
        );
    // } else {
    //     alert('x')
    //     return <></>
    // }
}
export default App;
