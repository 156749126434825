import React, {useCallback, useEffect, useState} from 'react'
import './PostsCarousel.css'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import giftbox from "../../assets/posts/giftbox.png";
import introducinglgt from "../../assets/posts/introducinglgt.png";
import listing from "../../assets/posts/listing.png";
import cryptoacademy from "../../assets/posts/cryptoacademy.png";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Link} from "react-router-dom";
import arrowright from "../../assets/arrowrightblue.svg";

const PostsCarousel = () => {

    const url = ''

    const news = [
        'LGT Pre-sale round has started',
        'Preliminary Listing Application is now open',
        'Join Bounty Program and receive LGT tokens'
    ]

    const links = [
        '/token',
        '/listing-application',
        '/social-media-bounty-program'
    ]

    const [newsState, setNewsState] = useState(0);

    const [newsOnHover, setNewsOnHover] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setNewsState((counter) => counter + 1);
        }, 7000);
    }, []);

    const autoplayOptions = {
        delay: 13000,
    }

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true , align: 'start', inViewThreshold: 1 }, [Autoplay(autoplayOptions)])

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    )
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    )


    return (

        <div className={'posts-wrapper'}>

            <div className={'block1-news-letter-wrapper'}>

                <div className={'block1-news-letter-container'}>

                    {/*<svg className={'block1-news-letter-icon'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                        <path fill={'#000'} d="M19 0H3C1.3 0 0 1.3 0 3v16c0 1.7 1.3 3 3 3h16c1.7 0 3-1.3 3-3V3c0-1.7-1.3-3-3-3zm1 19a.94.94 0 01-1 1H3a.94.94 0 01-1-1V3a.94.94 0 011-1h16a.94.94 0 011 1v16zM16 5H6a.94.94 0 00-1 1 .94.94 0 001 1h10a.94.94 0 001-1 .94.94 0 00-1-1zm-4 4H6a.94.94 0 00-1 1 .94.94 0 001 1h6a.94.94 0 001-1 .94.94 0 00-1-1z"></path>
                    </svg>*/}

                    <p  className={'block1-news-letter-icon'}>
                        HOT!
                    </p>

                    <Link
                        to={links[newsState%3]}
                        className={`block1-news-letter-text`}
                        onMouseOver={() => setNewsOnHover(true)}
                        onMouseLeave={() => setNewsOnHover(false)}
                    >
                        <TransitionGroup>

                            <CSSTransition
                                key={newsState%3}
                                timeout={0}
                                classNames="newsanimation"
                            >


                                <div>{news[newsState%3]}</div>


                            </CSSTransition>

                        </TransitionGroup>
                    </Link>
                </div>

                <Link to={'/blog'} className={'home-blog-link'}>
                    <p className={'home-blog-link-p'}>More Events in Blog</p>
                    <img src={arrowright} className={'home-blog-link-arrow'} />
                </Link>

            </div>



            <div className={'posts-carousel-wrapper'}>

                <div className={'posts-btns'}>
                    <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev posts-btn'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn posts-btn-left'}>
                            <path
                                fill={'#1b78ff'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>
                    </button>

                    <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next posts-btn'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn posts-btn-right'}>
                            <path
                                fill={'#1b78ff'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>
                    </button>
                </div>


                <div className="embla posts" ref={emblaRef}>

                    <div className="embla__container posts-container">
                        <div className="embla__slide posts-slide">
                            <Link to={`/token`}>
                                <img className={'posts-slide-img'} src={giftbox} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/token`}>
                                <img className={'posts-slide-img'} src={introducinglgt} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/listing-application`}>
                                <img className={'posts-slide-img'} src={listing} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/crypto-academy`}>
                                <img className={'posts-slide-img'} src={cryptoacademy} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/token`}>
                                <img className={'posts-slide-img'} src={giftbox} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/token`}>
                                <img className={'posts-slide-img'} src={introducinglgt} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/listing-application`}>
                                <img className={'posts-slide-img'} src={listing} />
                            </Link>
                        </div>
                        <div className="embla__slide posts-slide">
                            <Link to={`/crypto-academy`}>
                                <img className={'posts-slide-img'} src={cryptoacademy} />
                            </Link>
                        </div>
                    </div>
                </div>


            </div>

        </div>


    );
};

export default PostsCarousel;