import React from 'react';
import './Timeline.css'
import {Link} from "react-router-dom";

const Timeline = () => {
    return (


                <div className="presale-page-illustration">

                    <div className={'presale-page-rounds'}>

                        <h3>
                            <div to={'/'} className={'presale-page-rounds-link presale-page-rounds-link-1'}>
                                Pre-Seed Round
                            </div>

                            <p className={'light bold'}>
                                Completed
                            </p>
                        </h3>

                        <h3>
                            <div to={'/'} className={'presale-page-rounds-link presale-page-rounds-link-2'}>
                                Pre-Sale Round
                            </div>
                            <p className={'white bold'}>
                                Ongoing
                            </p>
                        </h3>

                        <h3>
                            <div to={'/'} className={'presale-page-rounds-link presale-page-rounds-link-3'}>
                                TGE Round
                            </div>
                            <p className={'light bold'}>
                                Anticipation
                            </p>
                        </h3>

                        <h3>
                            <div to={'/'} className={'presale-page-rounds-link presale-page-rounds-link-4'}>
                                Launch
                            </div>
                            <p className={'light bold'}>
                                Anticipation
                            </p>
                        </h3>

                    </div>

                    <div className="presale-page-illustration-balls">
                        <div className="presale-page-ball-container">
                            <div  style={{background: 'var(--primary4light)', borderColor: 'var(--primary4light)'}} className="presale-page-illustration-ball"/>
                        </div>
                        <div className="presale-page-ball-container">
                            <div style={{background: 'var(--primary2)'}} className="presale-page-illustration-ball"></div>
                        </div>
                        <div className="presale-page-ball-container">
                            <div className="presale-page-illustration-ball"></div>
                        </div>
                        <div className="presale-page-ball-container">
                            <div className="presale-page-illustration-ball"></div>
                        </div>
                    </div>
                    <div className={'presale-page-illustration-vert'}></div>

                    <div className={'presale-page-p'}>

                        <div style={{textAlign: 'center'}}>
                            <p className={'presale-page-p-1 light'}>
                                Sold 100.000.000 LGT
                            </p>

                            <span className={`timeline hot mybots-bot-top-type`}>
                                0.01 USDT
                            </span>
                        </div>

                        <div style={{textAlign: 'center'}}>
                            <p className={'presale-page-p-2 white light'}>
                                Allocated 100.000.000 LGT
                            </p>

                            <span className={`timeline top mybots-bot-top-type`}>
                                0.03 USDT
                            </span>
                        </div>


                        <div style={{textAlign: 'center'}}>
                            <p className={'presale-page-p-3 light'}>
                                Allocated 100.000.000 LGT
                            </p>

                            <span className={`timeline hot mybots-bot-top-type`}>
                                0.1 USDT
                            </span>
                        </div>



                        <div style={{textAlign: 'center'}}>

                            <p className={'presale-page-p-4 light'}>
                                Liqui Global Exchange launch
                            </p>

                            <span className={`timeline hot mybots-bot-top-type`}>
                                Progress
                            </span>

                        </div>


                    </div>

                </div>

    );
};

export default Timeline;