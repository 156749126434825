import React, {useEffect} from 'react';
import {useState} from "react";
import {Link} from "react-router-dom";
import crypto_academy from "../assets/crypto_academy.svg";
import cert from "../assets/crypto_academy_cert.svg";
import crypto_academy_idea from "../assets/crypto_academy_idea.svg";
import crypto_academy_experts from "../assets/crypto_academy_experts.svg";
import crypto_academy_individual2 from "../assets/crypto_academy_individual2.svg";
import crypto_academy_monitor from "../assets/crypto_academy_monitor.svg";
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import './CryptoAcademy.css'
import MightBeInteresting from "../MightBeInteresting";


import play from "../assets/crypto_academy_lessons/play.svg";
import time from "../assets/time.svg";
import level from "../assets/level.svg";

import trading_basics from "../assets/crypto_academy_lessons/trading_basics.svg";
import investing from "../assets/crypto_academy_lessons/investing.svg";
import webthree from "../assets/crypto_academy_lessons/web3.svg";


import trading_bots from "../assets/crypto_academy_lessons/trading_bots.svg";
import staking from "../assets/crypto_academy_lessons/staking.svg";
import liquidation_shield from "../assets/crypto_academy_lessons/liquidation_shield.svg";


import glossary from "../assets/crypto_academy_lessons/glossary.svg";
import word from "../assets/crypto_academy_lessons/word_of_the_day.svg";
import JoinCommunity from "./components/JoinCommunity";
import abstract_primary from "../assets/abstract_primary.svg";



const CryptoAcademy = () => {


        document.body.scrollTo({
            top: 0,
        })


    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }



    const allArticles = [
        {
            img: trading_bots,
            name: 'Trading Bots Overview',
            desc: 'Liqui Global offers users a set of trading strategies that save time and increase trading efficiency...',
            tags: [
                {
                    name: 'Trading',
                },
                {
                    name: 'Automation',
                },
            ],
            lvl: 2,
            time: '12m',
        },

        {
            img: liquidation_shield,
            name: 'Trading Bots Overview',
            desc: 'Create p2p requests, provide assistance to other market participants and earn money together...',
            tags: [
                {
                    name: 'Margin',
                },
                {
                    name: 'Liquidation',
                },
            ],
            lvl: 2,
            time: '13m',
        },

        {
            img: staking,
            name: 'How To Stake Your Crypto',
            desc: 'Liqui Global offers users a set of trading strategies that save time and increase trading efficiency...',
            tags: [
                {
                    name: 'Passive',
                },
                {
                    name: 'Earnbox',
                },
            ],
            lvl: 1,
            time: '7m',
        },
    ]





    return (
        <div className={'academy-page info-page'} style={{marginTop: '0'}}>

            <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
            </div>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header-container academy-header-container'}>
                    <div className={'filled info-page-header academy-header'}>


                        <div className={'academy-header-h1'}>

                            <h1 className={'white'}>
                                Crypto Academy
                            </h1>

                            <h5 className={'white'}>
                                {/*Стань экспертом криптовалютной торговли вместе с предодавателями нашей крипто академии*/}
                                Become a cryptocurrency trading expert with our Crypto Academy
                            </h5>


                            <button onClick={() => featureNotAvailableActiveHandler()} className={'earn-box-header-btn btn light'}>
                                Start Learning
                            </button>

                        </div>

                        <div className={'academy-img-container'}>
                            <img className={'academy-header-img'} src={crypto_academy} />
                            {/*<div className={'academy-header-img-circle'}></div>*/}
                        </div>

                    </div>

                    <img className={'inverted academy-header-img-abstract info-page-header-img-abstract'} src={abstract_primary} />

                </div>



                <div className={'info-page-container'}>





                    <div className={'academy-articles-wrapper'}>

                        <div className={'h1-btn'}>

                            <h1>
                                Top Articles
                            </h1>

                            <button className={'btn primary flexible'}>
                                View All (27)
                            </button>

                        </div>

                        <div className={'academy-articles-container'}>

                            {
                                allArticles.map((item, i) =>
                                    <div className={'academy-article-item'}>

                                        <div className={'academy-article-item-img small academy-makeway-item-img-container'}>

                                            <div className={'academy-makeway-item-img-overlay'}></div>
                                            <img src={play} className={'academy-makeway-item-img-play'} />

                                            <img
                                                src={item.img}
                                                className={'academy-makeway-item-img'}
                                            />
                                        </div>


                                        <div className={'small academy-makeway-item-name-container'}>

                                            <h3 className={'academy-makeway-item-name'}>
                                                {item.name}
                                            </h3>

                                            <div className={'nav-item-expanded-item-text-top-in'}>
                                                {
                                                    item.tags.map((tag, i) =>
                                                        <p className={'right small plate-text-chapters-item white'}>
                                                            {tag.name}
                                                        </p>
                                                    )
                                                }
                                            </div>

                                        </div>

                                        <p>
                                            {item.desc}
                                        </p>


                                        <div className={'academy-article-params'}>

                                            <div className={'academy-article-param'}>

                                                <img src={level} />

                                                <h3>
                                                    {item.lvl === 1 ?
                                                        'Beginner'
                                                        :
                                                        item.lvl === 2 ? 'Intermediate'
                                                            :
                                                            'Advanced'
                                                    }
                                                </h3>

                                            </div>

                                            <div className={'academy-article-param'}>

                                                <img src={time} />

                                                <h3>
                                                    {item.time}
                                                </h3>

                                            </div>

                                        </div>


                                    </div>
                                )
                            }

                        </div>


                        <div className={'academy-articles-tutorials-wrapper'}>

                            <div className={'academy-articles-tutorials-container'}>

                                <div className={'academy-articles-tutorials-container-small'}>
                                    <h3>
                                        Glossary
                                    </h3>

                                    <div className={'academy-articles-tutorials-item'}>

                                        <div className={'academy-articles-tutorials-item-text'}>
                                            <h2>
                                                Time To Understand The Language
                                            </h2>

                                            <p>
                                                Learn all of the most important blockchain and cryptocurrency terms and jargon here.
                                            </p>
                                        </div>

                                        <div>
                                            <button className={'flexible primary btn'}>
                                                Explore Glossary
                                            </button>
                                        </div>

                                        <img src={glossary} />

                                    </div>

                                </div>

                                <div className={'academy-articles-tutorials-container-small'}>
                                    <h3>
                                        Term Of The Day
                                    </h3>

                                    <div className={'second academy-articles-tutorials-item'}>

                                        <div className={'academy-articles-tutorials-item-text'}>
                                            <h2 className={'white'}>
                                                Moving Average Envelopes
                                            </h2>

                                            <p className={'whitewhite'}>
                                                A TA tool that consists of two bands placed around a core moving average.
                                            </p>
                                        </div>

                                        <div>
                                            <button className={'flexible light btn'}>
                                                Full Definition
                                            </button>
                                        </div>

                                        <img src={word} />

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>





                    <div className={'academy-makeway-container'}>

                        <h3 className={'academy-makeway-h3'}>
                            Go Through Journey
                        </h3>

                        <h1 className={'academy-makeway-h1'}>
                            {/*Пройди путь от новичка до профессионала!*/}
                            From Beginner To Professional
                        </h1>

                        <div className={'academy-makeway-desc'}>

                            <p className={'academy-makeway-p'}>
                                {/*Мы подготовили для вас уникальную систему пошагового обучения, которая научит вас  эффективно зарабатывать и увеличивать ваш капитал.*/}
                                We have prepared for you a unique step-by-step training system that will teach you how to effectively earn money and increase your capital.
                            </p>

                            <div className={'academy-makeway-desc-right'}>

                                    <div className={'academy-article-param'}>

                                        <img src={level} />

                                        <h3>
                                            Beginner
                                        </h3>

                                    </div>

                                    <div className={'academy-article-param'}>

                                        <img src={time} />

                                        <h3>
                                            3h 27m
                                        </h3>

                                    </div>


                            </div>

                        </div>


                        <div className={'academy-makeway'}>

                            <div className={'academy-makeway-top'}>

                                <div className={'academy-makeway-left'}>

                                    <h5 className={'first academy-makeway-h5'}>
                                        Get Started
                                    </h5>

                                    <div className={'academy-makeway-item-first-container'}>

                                        <div className={'academy-makeway-item'}>

                                            <div className={'academy-makeway-item-img-container'}>

                                                <div className={'academy-makeway-item-img-overlay'}></div>
                                                <img src={play} className={'academy-makeway-item-img-play'} />

                                                <img
                                                    src={trading_basics}
                                                    className={'academy-makeway-item-img'}
                                                />

                                            </div>

                                        </div>

                                        <div className={'academy-makeway-item-name-container'}>

                                            <h2 className={'academy-makeway-item-name'}>
                                                Trading Basics
                                            </h2>

                                            <div className={'nav-item-expanded-item-text-top-in'}>
                                                <p className={'right plate-text-chapters-item dark'}>
                                                    Technical Analysis
                                                </p>

                                                <p className={'right plate-text-chapters-item dark'}>
                                                    Trading
                                                </p>
                                            </div>

                                        </div>



                                        <p className={'academy-makeway-item-p'}>
                                            There are countless ways to profit off of trading cryptocurrency. Trading strategies help you organize those techniques into a coherent framework that you can follow. This way, you can continually monitor and optimize your cryptocurrency trading strategy by...
                                        </p>


                                        <button className={'btn primary'} style={{marginTop: '32px'}}>
                                            Start
                                        </button>

                                    </div>



                                    <h5 className={'academy-makeway-h5'}>
                                        Featured Lessons
                                    </h5>

                                    <div className={'academy-makeway-featured-container'}>

                                        <div className={'right academy-makeway-item'}>

                                            <div className={'small academy-makeway-item-img-container'}>

                                                <div className={'academy-makeway-item-img-overlay'}></div>
                                                <img src={play} className={'academy-makeway-item-img-play'} />

                                                <img
                                                    src={webthree}
                                                    className={'academy-makeway-item-img'}
                                                />
                                            </div>


                                            <div className={'small academy-makeway-item-name-container'}>

                                                <h3 className={'academy-makeway-item-name'}>
                                                    Managing Digital Assets
                                                </h3>

                                                <div className={'nav-item-expanded-item-text-top-in'}>
                                                    <p className={'right small plate-text-chapters-item dark'}>
                                                        Portfolio
                                                    </p>

                                                    <p className={'right small plate-text-chapters-item dark'}>
                                                        Management
                                                    </p>
                                                </div>

                                            </div>


                                            <p>
                                                {/*A portfolio is a collection of assets and investments. The key to determining your portfolio’s strategy lies in how you want to manage your risk. This lesson will provide some basic understandings of portfolio and risk management.*/}
                                                A portfolio is a collection of assets and investments. The key to determining your portfolio’s tactic is...
                                            </p>

                                        </div>

                                        <div className={'right academy-makeway-item'}>

                                            <div className={'small academy-makeway-item-img-container'}>

                                                <div className={'academy-makeway-item-img-overlay'}></div>
                                                <img src={play} className={'academy-makeway-item-img-play'} />

                                                <img
                                                    src={investing}
                                                    className={'academy-makeway-item-img'}
                                                />
                                            </div>

                                            <div className={'small academy-makeway-item-name-container'}>

                                                <h3 className={'academy-makeway-item-name'}>
                                                    Investing Fundamentals
                                                </h3>

                                                <div className={'nav-item-expanded-item-text-top-in'}>
                                                    <p className={'right small plate-text-chapters-item dark'}>
                                                        Economics
                                                    </p>
                                                    <p className={'right small plate-text-chapters-item dark'}>
                                                        Personal Finance
                                                    </p>
                                                </div>

                                            </div>


                                            <p>
                                                {/*Practical approach to the fundamental analysis of crypto assets and token economics*/}
                                                Practical approach to the fundamental analysis of crypto assets, token economics and...
                                            </p>

                                        </div>

                                    </div>


                                    <button className={'full primary shadow nohover btn'}>
                                        See All Lessons
                                    </button>


                                </div>



                                <div className={'academy-makeway-right'}>


                                    <div className={'academy-makeway-right-top'}>

                                        <div>
                                            <h5 className={'first academy-makeway-h5'}>
                                                {/*Чему вы научитесь?*/}
                                                What will you learn?
                                            </h5>

                                            <div className={'academy-makeway-features-container'}>

                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'top4'}>
                                                        {/*Пониманию динамики рынков*/}
                                                        Understanding market dynamics
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Психологии трейдера и принципам принятия решений*/}
                                                        Trader psychology and decision-making principles
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Использованию торговых индикаторов и торговых инструментов*/}
                                                        Using trading indicators and tools
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Созданию торговой стратегии*/}
                                                        Creating a trading strategy
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Поиску точек входа и выхода из сделок*/}
                                                        Finding entry and exit points for trades
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Защите и безопасности ваших активов*/}
                                                        Protect and secure your assets
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Многоуровневому анализу рынка криптовалют*/}
                                                        Multi-level analysis of the cryptocurrency market
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Управлению капиталом и финансовой грамотности*/}
                                                        Wealth management and financial literacy
                                                    </h3>

                                                </div>


                                            </div>
                                        </div>



                                        <div>
                                            <h5 className={'second academy-makeway-h5'}>
                                                {/*Как построено обучение?*/}
                                                How is the Course structured?
                                            </h5>

                                            <div className={'academy-makeway-features-container'}>

                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'round academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'top4'}>
                                                        {/*Постоянно обновляющаяся база авторских статей*/}
                                                        Constantly updated database of original articles
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'round academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Ежедневные онлайн лекции*/}
                                                        Daily online lectures
                                                    </h3>

                                                </div>


                                                <div className={'academy-makeway-features-item'}>

                                                    <div className={'round academy-makeway-features-item-bar'}></div>

                                                    <h3 className={'coin-block-h3'}>
                                                        {/*Торговые идеи от наших экспертов*/}
                                                        Trading ideas from our experts
                                                    </h3>

                                                </div>


                                            </div>
                                        </div>






                                        <div className={'academy-makeway-right-bott'}>

                                            <h3>
                                                Receive NFT Certificate upon completing full Course
                                            </h3>

                                            <img src={cert} />

                                            <div>
                                                <p>
                                                    Claim your Certificate of Graduation as proof of knowledge
                                                </p>

                                                {/*<button className={'full btn primary'}>
                                                    Claim
                                                </button>*/}
                                            </div>

                                        </div>



                                    </div>
                            </div>

                            </div>





                        </div>


                    </div>






                    <Link to={'/'} className={'info-page-home'}>
                        {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                        <button className={'btn black2'} style={{minWidth: '150px'}}>
                            Go Home
                        </button>
                    </Link>

                </div>

            </div>

            <MightBeInteresting current={7}/>

            <JoinCommunity  mt0={true} />

        </div>
    );
};

export default CryptoAcademy;