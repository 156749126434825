import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';

export const ChartComponent = props => {

    const {
        data,
        colors: {
            backgroundColor = '#ffffff',
            lineColor = '#1b78ff',
            textColor = '#292a33',
            areaTopColor = '#ccdeff',
            areaBottomColor = 'rgba(204,222,255,0)',
        } = {},
    } = props;

    const chartContainerRef = useRef();

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const currentLocale = window.navigator.languages[0];
            const myPriceFormatter = Intl.NumberFormat(currentLocale, {
                style: 'currency',
                currency: 'ETH',
            }).format;

            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 200,
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    fontFamily: "'Inter Tight', 'Inter', 'Helvetica', sans-serif",
                },
                grid: {
                    horzLines: {
                        color: '#f1f1f1',
                        style: 0,
                        visible: false,
                    },
                    vertLines: {
                        color: '#f1f1f1',
                        style: 0,
                        visible: false,
                    },
                },
                localization: {
                    priceFormatter: myPriceFormatter,
                    dateFormat: 'yyyy-MM-dd',
                },
                handleScroll: {
                    mouseWheel: false,
                    pressedMouseMove: false,
                    horzTouchDrag: false,
                    vertTouchDrag: false
                },
                handleScale: {
                    axisPressedMouseMove: false,
                    mouseWheel: false,
                    pinch: false,
                },
            });
            chart.timeScale().applyOptions({
                borderColor: '#f1f1f1',
            });
            chart.priceScale('right').applyOptions({
                borderColor: '#f1f1f1',
            })
            chart.timeScale().fitContent();

            const newSeries = chart.addAreaSeries({ lineColor, lineWidth: 1, topColor: areaTopColor, bottomColor: areaBottomColor });
            newSeries.setData(data);

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef}
        />
    );
};