import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import './LaunchpadField.css'
import MightBeInteresting from "../MightBeInteresting";
import JoinCommunity from "./components/JoinCommunity";
import nft_page_back from "../assets/launchpad_field/lf_page_back.svg";


import launchpad_spaceship from "../assets/launchpad_field/launchpad_spaceship.svg";


import coming_launchpad from "../assets/launchpad_field/coming_launchpad.svg";

import whitepaper_icon from "../assets/launchpad_field/whitepaper.svg";
import website_icon from "../assets/launchpad_field/website.svg";
import research_icon from "../assets/launchpad_field/research.svg";
import participate_icon from "../assets/launchpad_field/participate.svg";
import PreliminaryListing from "./components/PreliminaryListing";



const LaunchpadField = () => {

    document.body.scrollTo({
        top: 0,
    })


    const launchpadRef = useRef()



    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }



    return (

        <div className={'lf-page info-page'}>



            <div className={'info-page-wrapper'}>

                <div className={'lf-header nft-header'}>

                    <img className={'nft-page-header-img-in'} src={launchpad_spaceship} />

                    <div className={'lf-header-container nft-header-container'}>

                        <div className={'nft-header-text'}>
                            <h1 className={'nft-page-h1'}>
                                Launchpad Field
                                <h5 style={{margin: '0', paddingTop: '10px'}}>
                                    {/*Платформа для помощи в привлечении инвестиций для молодных проектов*/}
                                    Platform for assistance in attracting investments for young projects
                                </h5>
                            </h1>

                            <button onClick={() => {launchpadRef.current?.scrollIntoView()}} className={'btn primary explore-btn'}>
                                Explore
                            </button>
                        </div>


                        <img className={'lfield-header-img'} src={launchpad_spaceship} />

                    </div>

                    <img className={'nft-page-header-img-back'} src={nft_page_back} />
                </div>






                <div className={'lpfield nft-page-highlights-container'}>

                    <button onClick={() => {launchpadRef.current?.scrollIntoView()}} className={'lpfield nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            №1
                        </h2>
                        <p className={'whitewhite'}>
                            Launchpad Platform
                        </p>
                    </button>

                    <button onClick={() => {launchpadRef.current?.scrollIntoView({block: "center"})}} className={'lpfield nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            30+
                        </h2>
                        <p className={'whitewhite'}>
                            Crypto Projects
                        </p>
                    </button>

                    <button onClick={() => {launchpadRef.current?.scrollIntoView({block: "center"})}} className={'lpfield nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            $150K
                        </h2>
                        <p className={'whitewhite'}>
                            Currently Staked
                        </p>
                    </button>

                    <button onClick={() => {launchpadRef.current?.scrollIntoView({block: "center"})}} className={'lpfield nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            Unique
                        </h2>
                        <p className={'whitewhite'}>
                            Analysis Methods
                        </p>
                    </button>

                </div>





                <div className={'info-page-container lf-page-container'}>
                    <div className={'info-page-text lf-page-text'}>

                        <div className={'lf-content'}>

                            <div className={'lf-marketplace-text'}>


                                <div className={'h1-btn'}>

                                    <h1>
                                        {/*Платформа проведения токенсейлов*/}
                                        Token Sale Platform
                                    </h1>

                                    <button className={'btn primary flexible'}>
                                        View All (38)
                                    </button>

                                </div>

                                <p className={'aimonitor-content-p'}>
                                    {/*Это место, позволяющее стартапам привлечь первоначальное финансирование для развития, а инвесторам — выбрать привлекательный продукт для вложения своих средств.*/}
                                    This is a place that allows startups to raise initial funding for development, and investors to choose an attractive product to invest their funds.
                                </p>




                                <div className={'lpfield-coming-wrapper'}>

                                    <h3>
                                        Coming Launchpad
                                    </h3>

                                    <div className={'lpfield-coming-container'}>

                                        <div className={'lpfield-coming-top'}>

                                            <div className={'lpfield-coming-left'}>

                                                <img src={coming_launchpad} className={'lpfield-coming-img'} />

                                            </div>


                                            <div className={'lpfield-coming-right'}>

                                                <div className={'lpfield-coming-tags'}>
                                                    <p className={'plate-text-chapters-item black'}>
                                                        DePIN
                                                    </p>

                                                    <p className={'plate-text-chapters-item black'}>
                                                        DeSci
                                                    </p>

                                                    <p className={'plate-text-chapters-item black'}>
                                                        PoUW
                                                    </p>
                                                </div>

                                                <div>
                                                    <h2 className={'white'}>
                                                        Tasha Supercomputer
                                                    </h2>

                                                    <p className={'lpfield-coming-desc white'}>
                                                        Tasha (TASH) is a DePIN platform for neuromorphic quantum computing, based on an innovative new blockchain protocol.
                                                    </p>
                                                </div>


                                                <div className={'lpfield-coming-price-container'}>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            Tokens Offered
                                                        </p>
                                                        <h3 className={'white'}>
                                                            20,000,000.00 TASH
                                                        </h3>
                                                    </div>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            Participants
                                                        </p>
                                                        <h3 className={'white'}>
                                                            139,027
                                                        </h3>
                                                    </div>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            Single Initial Investment
                                                        </p>
                                                        <h3 className={'white'}>
                                                            5.00 LGT
                                                        </h3>
                                                    </div>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            Sale Price
                                                        </p>
                                                        <h3 className={'white'}>
                                                            1 TASH = 0.8 LGT
                                                        </h3>
                                                    </div>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            Total Committed
                                                        </p>
                                                        <h3 className={'white'}>
                                                            8,173,940.00 LGT
                                                        </h3>
                                                    </div>

                                                    <div className={'lpfield-coming-price-item'}>
                                                        <p className={'white'}>
                                                            End Time
                                                        </p>
                                                        <h3 className={'white'}>
                                                            2024-07-01
                                                        </h3>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className={'lpfield-coming-bottom'}>

                                            <div className={'lpfield-coming-bottom-item'}>
                                                <img src={website_icon} />

                                                <div>
                                                    <h3 className={'white'}>
                                                        WebSite
                                                    </h3>

                                                    <p className={'white'}>
                                                        Check out
                                                        <br/>
                                                        Tasha's WebSite
                                                    </p>
                                                </div>
                                            </div>



                                            <div className={'lpfield-coming-bottom-item'}>
                                                <img src={whitepaper_icon} />

                                                <div>
                                                    <h3 className={'white'}>
                                                        WhitePaper
                                                    </h3>

                                                    <p className={'white'}>
                                                        Explore Tasha's WhitePaper
                                                    </p>
                                                </div>
                                            </div>


                                            <div className={'lpfield-coming-bottom-item'}>
                                                <img src={research_icon} />

                                                <div>
                                                    <h3 className={'white'}>
                                                        Research Report
                                                    </h3>

                                                    <p className={'white'}>
                                                        Discover Tasha's Research Report
                                                    </p>
                                                </div>
                                            </div>


                                            <div className={'lpfield-coming-bottom-item'}>
                                                <img src={participate_icon} />

                                                <div>
                                                    <h3 className={'white'}>
                                                        Participate
                                                    </h3>

                                                    <p className={'white'}>
                                                        Tale part in Tasha's Launchpad
                                                    </p>
                                                </div>
                                            </div>



                                        </div>


                                    </div>

                                </div>






                                <div className={'lpfield-howto-container'}>

                                    <h3>
                                        How To Participate?
                                    </h3>

                                    <div className={'lpfield-howto-item'}>
                                        <div className={'lpfield-howto-step-container'}>
                                            <h2 style={{marginTop: '3px', marginRight: '3px'}} className={'white lpfield-howto-step'}>
                                                1
                                            </h2>
                                        </div>
                                        <h3 className={'lpfield-howto-desc'}>
                                            {/*Обычно цена токена на токенсейле очень привлекательна для инвесторов и мы используем лотерейную систему для максимально справедливого распределения токенов.*/}
                                            Typically, the token price at the token sale is very attractive to investors and we use a lottery system to distribute tokens as fairly as possible.
                                        </h3>
                                    </div>

                                    <div className={'lpfield-howto-item'}>
                                        <div className={'lpfield-howto-step-container'}>
                                            <h2 style={{marginTop: '1px', marginRight: '1px'}} className={'white lpfield-howto-step'}>
                                                2
                                            </h2>
                                        </div>
                                        <h3 className={'lpfield-howto-desc'}>
                                            {/*Для участия в лаунчпаде вам необходимо иметь на балансе определенную сумму LGT как гарантию своего участия.*/}
                                            To participate in the launchpad, you need to have a certain amount of LGT on your balance as a guarantee of your participation.
                                        </h3>
                                    </div>

                                    <div className={'lpfield-howto-item'}>
                                        <div className={'lpfield-howto-step-container'}>
                                            <h2 style={{marginTop: '3px', marginRight: '1px'}} className={'white lpfield-howto-step'}>
                                                3
                                            </h2>
                                        </div>
                                        <h3 className={'lpfield-howto-desc'}>
                                            {/*Сумма LGT будет заморожена до проведения лотерии, после чего сумма за выйгранные квоты будет списана, а вам предоставлены токены проекта по заранее известной цене. Количество возможных квот зависит от вашего VIP статуса.*/}
                                            The LGT amount will be frozen until the lottery is held, after which the amount for the won quotas will be written off, and you will be provided with project tokens at a predetermined price. The number of possible quotas depends on your VIP status.
                                        </h3>
                                    </div>

                                    <p className={'lpfield-howto-item'}>
                                        {/*Наша платформа оказывает содействие новым перспективным криптовалютным проектам, нуждающимся в поддержке на начальном этапе развития.*/}
                                        Our platform provides assistance to new promising cryptocurrency projects that need support at the initial stage of development.
                                    </p>

                                </div>







                                <div className={'lpfield-features-wrapper'}>

                                    <div className={'one lpfield-features-container'}>
                                        <h3>
                                            {/*Быстрый и эффективный старт для вашего проекта*/}
                                            A quick and effective start for your project
                                        </h3>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'top4'}>
                                                {/*Календарь проведения токенсейлов*/}
                                                Token sale calendar
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'top4'}>
                                                {/*Обзор и аналитика представленных проектов*/}
                                                Review and analysis of presented projects
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'top4'}>
                                                {/*Организация сборов средств для молодых стартапов*/}
                                                Organizing fundraisers for young startups
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'top4'}>
                                                {/*Маркетинговое сопровождение*/}
                                                Marketing support
                                            </h3>
                                        </div>


                                        <h2 style={{marginTop: '50px', color: 'var(--primary)'}}>
                                            Launch Your Project
                                        </h2>

                                    </div>



                                    <div className={'two lpfield-features-container'}>
                                        <h3 className={'white'}>
                                            {/*Особенности Launchpad Field*/}
                                            Features of Launchpad Field
                                        </h3>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'dark round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'white top4'}>
                                                {/*Автоматизированное проведение токенсейлов*/}
                                                Automated token sales
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'dark round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'white top4'}>
                                                {/*Максимально справедливое распределение токенов*/}
                                                Maximum fair distribution of tokens
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'dark round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'white top4'}>
                                                {/*Тщательный анализ и отбор проектов нашими экспертами*/}
                                                Careful analysis and selection of projects by our experts
                                            </h3>
                                        </div>

                                        <div className={'academy-makeway-features-item'}>
                                            <div className={'dark round academy-makeway-features-item-bar'}></div>

                                            <h3 className={'white top4'}>
                                                {/*Поддержка в будущем*/}
                                                Future synergy
                                            </h3>
                                        </div>

                                        <button style={{marginTop: '46px'}} className={'primary2 btn'}>
                                            Participate
                                        </button>

                                    </div>

                                </div>






                                {/*<h3 className={'info-page-h3'}>
                                    Быстрый и эффективный старт для вашего проекта
                                </h3>

                                <ul className={'ul-dot'}>
                                    <li>
                                        Календарь проведения токенсейлов
                                    </li>
                                    <li>
                                        Обзор и аналитика представленных проектов
                                    </li>
                                    <li>
                                        Организация сборов средств для молодых стартапов
                                    </li>
                                    <li>
                                        Маркетинговое сопровождение
                                    </li>
                                </ul>

                                <h3 className={'info-page-h3'}>
                                    Особенности Launchpad Field
                                </h3>

                                <ul  className={'ul-dot'}>
                                    <li>
                                        Автоматизированное проведение токенсейлов
                                    </li>
                                    <li>
                                        Максимально справедливое распределение токенов
                                    </li>
                                    <li>
                                        Тщательный анализ и отбор проектов нашими экспертами
                                    </li>
                                </ul>

                                <h3 className={'info-page-h3'}>
                                    Как участвовать в лаунчпаде?
                                </h3>

                                <p  className={'info-page-p'}>
                                    Обычно цена токена на токенсейле очень привлекательна для инвесторов и мы используем лотерейную систему для максимально справедливого распределения токенов.
                                </p>

                                <ul  className={'ul-dot'}>
                                    <li>
                                        Для участия в лаунчпаде вам необходимо иметь на балансе определенную сумму LGT как гарантию своего участия
                                    </li>
                                    <li>
                                        Сумма LGT будет заморожена до проведения лотерии, после чего сумма за выйгранные квоты будет списана, а вам предоставлены токены проекта по заранее известной цене. Количество возможных квот зависит от вашего VIP статуса.
                                    </li>
                                </ul>

                                <div className={'info-page-bsect lf-bsect'}>
                                    <p style={{padding: '0', textAlign: 'center'}}>
                                        Станьте инвестором вместе с платформой Launchpad Field.
                                    </p>
                                </div>*/}

                            </div>


                                {/*<div className={'info-page-lbsect lf-lbsect'}>
                                    <h3 style={{paddingTop: 0}}>
                                        Как подать заявку на проведение токенсейла
                                    </h3>

                                    <div className={'nft-mb-img'}>
                                        <img src={nft_mysterybox}/>
                                    </div>

                                    <ul className={'ul-decimal'}>
                                        <li>
                                            Заполните форму, предоставив всю необходимую информацию о вашем проекте
                                        </li>
                                        <li>
                                            Ожидайте одобрения на электронной почте
                                        </li>
                                        <li style={{paddingBottom: 0}}>
                                            Согласуйте детали с нашими экспертами и следуйте предоставленным инструкциям
                                        </li>
                                    </ul>
                                </div>*/}



                        </div>


                        <PreliminaryListing />


                        <Link to={'/'} className={'info-page-home'}>
                            {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                            <button className={'btn black2'} style={{minWidth: '150px'}}>
                                Go Home
                            </button>
                        </Link>


                    </div>

                </div>


            </div>


            <MightBeInteresting current={2}/>

            <JoinCommunity  mt0={true} />

        </div>

    );
};

export default LaunchpadField;