import React, {useState} from 'react';

const CryptoTableToggle = ({i}) => {


    const [monitorToggleState, setMonitorToggleState] = useState(
        i === 2 ||
        i === 3 ||
        i === 7
    )


    return (
        <div className={'full nft-marketplace-features-item-toggle'}>

            <button
                onClick={() => setMonitorToggleState(true)}
                className={`btn nft-marketplace-features-item-toggle-button full ${monitorToggleState ? 'active' : ''}`}
            >
                Off
            </button>

            <button
                onClick={() => setMonitorToggleState(false)}
                className={`btn nft-marketplace-features-item-toggle-button full ${!monitorToggleState ? 'active' : ''}`}
            >
                On
            </button>

        </div>
    );
};

export default CryptoTableToggle;