import React from 'react';
import './Loader.css'

const Loader = () => {
    return (
        <div className={'loader-wrapper'} id={'loader-wrapper'} autoFocus>
            <svg className={'loader-svg'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.6 263">
                <path
                    fill="#76aeff"
                    d="M138.3 0l32.6 100.4 20.2 62.2L223.7 263l-85.4-62.1L52.9 263l32.6-100.4 20.2-62.2z"
                ></path>
                <path
                    fill="#1b78ff"
                    d="M170.9 100.4h105.7l-85.5 62.2-52.8 38.3L52.9 263l32.6-100.4L0 100.4h105.7z"
                ></path>
            </svg>
        </div>
    );
};

export default Loader;