import React from 'react';
import './MobileApp.css'
import Phone from "../../assets/phone.svg";

import qr from "../../assets/mobile/qr.svg";
import appstore from "../../assets/mobile/appstore.svg";
import googleplay from "../../assets/mobile/googleplay.svg";
import android from "../../assets/mobile/android.svg";

const MobileApp = () => {
    return (
        <div className={'mobile-app-wrapper'}>

            <div className={'mobile-app-container'}>

                <div className={'mobile-app-content'}>

                    <div className={'mobile-app-text'}>

                        <h1>
                            Trade Anytime, Everywhere
                        </h1>
                        <p>
                            {/*Всегда оставайтесь на рынке и получайте доступ к нашим торговым инструментам, используя наши специализированные торговые приложения для iPhone или Android.*/}
                            Stay connected to the market. Access our trading tools using our dedicated trading apps for iPhone or Android.
                        </p>

                    </div>

                    <div className={'mobile-app-graphic-in'}>
                        <img src={Phone}/>
                    </div>


                    <div className={'mobile-app-download-container'}>

                        <div className={'mobile-app-download-qr-wrapper'}>

                            <h5 className={'light nav-mobile-h5'}>
                                All Devices
                            </h5>

                            <div className={'mobile-app-download-qr-container'}>

                                <img
                                    src={qr}
                                    className={'small mobile-app-download-qr-img'}
                                />

                            </div>

                        </div>


                        <div className={'mobile-app-download-bar'}>

                        </div>


                        <div className={'vert mobile-app-buttons'}>

                            <h5 className={'light nav-mobile-h5'}>
                                iOS
                            </h5>

                            <a
                                className={'mobile-app-button'}
                                onClick={() => window.open('https://www.apple.com/app-store/', "_blank", "noreferrer")}
                            >

                                <img
                                    src={appstore}
                                    className={'mobile-app-button-img'}
                                />

                                <div className={'mobile-app-button-text'}>

                                    <p>
                                        Download on the
                                    </p>

                                    <h3>
                                        App Store
                                    </h3>

                                </div>

                            </a>

                            <h5 className={'light nav-mobile-h5'}>
                                Android
                            </h5>

                            <a
                                className={'mobile-app-button'}
                                onClick={() => window.open('https://play.google.com/store/apps', "_blank", "noreferrer")}
                            >

                                <img
                                    src={googleplay}
                                    className={'mobile-app-button-img'}
                                />

                                <div className={'mobile-app-button-text'}>

                                    <p>
                                        Get it on
                                    </p>

                                    <h3>
                                        Google Play
                                    </h3>

                                </div>

                            </a>

                            <a
                                className={'mobile-app-button'}
                                /*onClick={() => window.open('https://www.apple.com/app-store/', "_blank", "noreferrer")}*/
                            >

                                <img
                                    src={android}
                                    className={'mobile-app-button-img'}
                                />

                                <div className={'mobile-app-button-text'}>

                                    <p>
                                        Download
                                    </p>

                                    <h3>
                                        APK
                                    </h3>

                                </div>

                            </a>

                        </div>
                    </div>

                </div>



                <div className={'mobile-app-graphic'}>
                    <img src={Phone}/>
                </div>

            </div>

        </div>
    );
};

export default MobileApp;