import React from 'react';
import './DiscoverProducts.css'
import {Link} from "react-router-dom";
import nft_workshop from "../../assets/nft_workshop.svg";
import nft_workshop_ape from "../../assets/nft_workshop_ape.svg";
import launchpad_field from "../../assets/launchpad_field.svg";
import ai_gem_monitor from "../../assets/ai_gem_monitor.svg";
import liquidation_shield from "../../assets/liquidation_shield.svg";
import earn_box from "../../assets/earn_box.svg";
import earnbox_pig from "../../assets/earnbox-pig.svg";
import pig from "../../assets/pig.svg";
import leveraged_etf from "../../assets/leveraged_etf.svg";
import crypto_academy from "../../assets/crypto_academy.svg";
import trading_bots from "../../assets/trading_bots.svg";
import clone_trading from "../../assets/clone_trading.svg";
import IntroducingLgt from "./IntroducingLgt";

const DiscoverProducts = () => {
    return (
        <div className={'block2-wrapper'}>
            <div className={'block2'}>
                <h1>Discover Our Products</h1>
                <h5 className={'block2-h5'}>Experiment with innovative services and trading tools</h5>
                <div className={'plates'}>

                    <div className={'plates-left'}>

                        <Link to={'/nft-workshop'} className={'plate nft-plate'}>
                            <img src={nft_workshop_ape} className={'plate-img nft-plate-img'}/>
                            <div className={'plate-text nft-plate-text'}>
                                <div className={'nft-plate-text-header plate-text-header'}>
                                    <h3>NFT Workshop</h3>
                                    <p>
                                        {/*Уникальная платформа для конструирования и торговли NFT*/}
                                        A unique platform for designing and trading NFTs
                                    </p>
                                </div>

                                <div className={'plate-text-chapters col'}>
                                    <p className={'plate-text-chapters-item whitewhite'}>
                                        Marketplace
                                    </p>
                                    <p className={'plate-text-chapters-item whitewhite'}>
                                        Builder
                                    </p>
                                </div>

                            </div>

                        </Link>

                        <Link to={'/ai-gem-monitor'} className={'plate gem-monitor-plate'}>
                            <img src={ai_gem_monitor} className={'plate-img gem-monitor-plate-img'}/>
                            <div className={'plate-text gem-monitor-plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3 className={'white'}>AI Gem Monitor</h3>
                                    <p className={'whitewhite'}>
                                        Find the next crypto Gem and create your perfect cryptocurrency portfolio
                                    </p>
                                </div>



                            </div>

                        </Link>

                        <Link to={'/crypto-academy'} className={'plate crypto-academy-plate'}>
                            <img src={crypto_academy} className={'plate-img crypto-academy-plate-img'}/>
                            <div className={'crypto-academy-plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3  className={'white'}>Crypto Academy</h3>
                                    <p  className={'whitewhite'}>
                                        {/*Стань экспертом криптовалютной торговли вместе с предодавателями нашей крипто академии*/}
                                        Become a cryptocurrency trading expert with help from our crypto academy instructors
                                    </p>
                                </div>

                                <div className={'plate-text-chapters col'}>
                                    <p className={'plate-text-chapters-item black'}>
                                        Learn To Earn
                                    </p>
                                    <p className={'plate-text-chapters-item black'}>
                                        Individual Course
                                    </p>
                                </div>

                            </div>

                        </Link>

                    </div>

                    <div className={'plates-right'}>

                        <Link to={'/leveraged-etfs'} className={'plate'}>
                            <img src={leveraged_etf} className={'plate-img'}/>
                            <div className={'plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3>Leveraged ETFs</h3>
                                    <p>
                                        {/*Торгуйте токенами с кредитным плечом без риска ликвидации позиций*/}
                                        Trade tokens with leverage without the risk of position liquidation
                                    </p>
                                </div>

                            </div>

                        </Link>

                        <Link to={'/liquidation-shield'} className={'plate'}>
                            <img src={liquidation_shield} className={'plate-img'}/>
                            <div className={'plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3>Liquidation Shield</h3>
                                    <p>
                                        {/*Защитите свои маржинальные позиции от ликвидации*/}
                                        Protect your margin positions from liquidation
                                    </p>
                                </div>

                            </div>
                        </Link>

                        <Link to={'/earnbox'} className={'earnbox-plate plate'}>
                            <div className={'earnbox-plate-text plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3>EarneBox</h3>
                                    <p>
                                        {/*Инвестируйте и получайте пассивный доход без рисков*/}
                                        Invest and receive risk‑free passive income
                                    </p>
                                </div>

                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Staking
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Liquidity Pools
                                    </p>
                                </div>

                            </div>

                            <img src={earnbox_pig} className={'earnbox-plate-img'}/>

                        </Link>

                        <Link to={'/trading-bots'} className={'plate trading-bots-plate'}>
                            <img src={trading_bots} className={'plate-img trading-bots-plate-img'}/>
                            <div className={'plate-text trading-bots-plate-text'}>

                                <div className={'plate-text-header'}>
                                    <h3>Trading Bots</h3>
                                    <p>
                                        {/*Создавайте алгоритмы и автоматизируйте вашу торговую стратегию*/}
                                        Create algorithms and automate your trading strategy
                                    </p>
                                </div>


                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        DCA
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Grid
                                    </p>
                                </div>
                            </div>

                        </Link>

                        <Link to={'/clone-trading'} className={'plate'}>
                            <img src={clone_trading} className={'plate-img'}/>
                            <div className={'plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3>Clone Trading</h3>
                                    <p>
                                        Earn money using the experience and knowledge of professionals
                                    </p>
                                </div>

                            </div>

                        </Link>

                        <Link to={'/launchpad-field'} className={'plate'}>
                            <img src={launchpad_field} className={'plate-img'}/>
                            <div className={'plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h3>Launchpad Field</h3>
                                    <p>
                                        {/*Платформа для помощи в привлечении инвестиций для молодых проектов*/}
                                        Platform to help attract investment for new projects
                                    </p>
                                </div>

                            </div>

                        </Link>

                    </div>

                </div>


                <IntroducingLgt />

            </div>
        </div>
    );
};

export default DiscoverProducts;