import React from 'react';
import './LgtPresale.css'
import lgt from '../../assets/lgt.svg'
import {Link} from "react-router-dom";

const LgtPresale = () => {
    return (
        <div className={'lgt-presale-wrapper'}>
            {/*<div className={'lgt-presale-container'}>

                <img src={lgt} className={'lgt-presale-img'} />

                <div className={'lgt-presale-text'}>

                    <div>
                        <h2 className={'lgt-presale-h2'}>
                            LGT pre-sale launched
                        </h2>

                        <h5 className={'lgt-presale-h5'}>
                            Don't miss the opportunity to become one of the first contributors
                        </h5>
                    </div>

                    <img src={lgt} className={'lgt-presale-img-in'} />

                    <div className={'lgt-presale-block'}>

                        <div className={'lgt-presale-block-text'}>
                            <h3 style={{padding: 0}} className={'lgt-presale-block-h3'}>
                                Participate in the presale round to receive LGT
                            </h3>
                            <p className={'presale-p'}>
                                Hurry up to buy Liqui Global Token at a special price
                            </p>
                        </div>



                        <Link to={'/token#presale'} className={'btn participate-btn'}>
                            Participate
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                                <path
                                    fill={'#fff'}
                                    fillRule="evenodd"
                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                ></path>
                            </svg>
                        </Link>
                    </div>

                </div>

            </div>*/}
        </div>
    );
};

export default LgtPresale;