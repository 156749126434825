import React from 'react';
import './InfoPage.css'
import './InfluencerVipBounty.css'
import {Link} from "react-router-dom";
import LgtPresale from "./components/LgtPresale";
import influencer from "../assets/influencer.svg";
import influencerVIP_bounty from "../assets/influencerVIP_bounty.svg";
import arrowrightblue from "../assets/arrowrightblue.svg";
import arrowRightWhite from "../assets/arrowrightwhite.svg";
import messages from "../assets/social.svg";

const InfluencerVipBounty = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{height: '115px', marginRight: '15px'}} className={'info-page-header-img'} src={influencer}/>

                    <h1>
                        <h1>Influencer VIP Bounty</h1>
                        <h5 style={{marginTop: '10px'}}>
                            Станьте инфлюенсером Liqui Global
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>


                            <div className={'info-page-1-container'}>

                                <div className={'info-page-1-left'}>

                                    <h2 className={'info-page-h2'}>
                                        Станьте инфлюенсером Liqui Global
                                    </h2>

                                    <p className={'info-page-p'}>
                                        Вы популярный в сфере криптовалют? У вас есть значительная аудитория или вы владелец криптовалютных соушел медиа ресурсов?
                                        <br/>
                                        Мы готовы щедро вознаградить инфлюенсеров за маркетинговый вклад и предложить индивидуальные условия вознаграждения.
                                    </p>

                                    <p className={'info-page-p'}>
                                        Пожалуйста свяжитесь с нами для сотрудничества по электронной почте&nbsp;
                                        <a
                                            className={'info-page-underlined-a'}
                                            href={'mailto:marketing@liqui.global'}
                                        >
                                            marketing@liqui.global
                                        </a>
                                    </p>

                                </div>

                                <div className={'info-page-1-right'}>

                                    <img src={influencerVIP_bounty} />

                                </div>

                                <Link to={'/social-media-bounty-program'} className={'influencer-2-inpage influencer-inpage'}>
                                    <div className={'influencer-content-inpage'}>
                                        <h3>
                                            Social Media Bounty
                                        </h3>
                                        <p>
                                            Мы сформировали пуллы вознаграждений за помощь в продвижении Liqui Global в социальных сетях. Любой пользователь может поучаствовать в баунти программе и разделить награду за помощь в продвижении нашей платформы.
                                        </p>

                                        <img src={messages} className={'influencer-img-inpage'} />

                                        <img src={arrowRightWhite} className={'influencer-arrow-inpage'} />

                                    </div>

                                </Link>

                            </div>




                    </div>


                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>


                </div>

            </div>


            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>


        </div>
    );
};

export default InfluencerVipBounty;