import React, {useEffect, useState, useCallback, useRef} from 'react';
import {Link} from "react-router-dom";
import nft_workshop from "../assets/nft_workshop.svg";
import nft_builder from "../assets/nft_builder.svg";
import nft_workshop_ape from "../assets/nft_workshop_ape.svg";
import nft_workshop_ape2 from "../assets/nft_workshop_ape2.svg";
import nft_workshop_ape3 from "../assets/nft_workshop_ape3.svg";
import header1 from "../assets/nft_workshop_assets/header1.png";
import header2 from "../assets/nft_workshop_assets/header2.png";
import nft_mysterybox from "../assets/nft_mysterybox.svg";
import arrowrightblue from "../assets/arrowrightblue.svg";
import tick from "../assets/tick.svg";
import tick_inverted from "../assets/tick_inverted.svg";
import LgtPresale from "./components/LgtPresale";
import './NFTWorkshop.css'
import MightBeInteresting from "../MightBeInteresting";

import nft_page_back from "../assets/nft_workshop_assets/nft_page_back.svg";
import accessibility from "../assets/nft_workshop_assets/accessibility.svg";
import youdecide from "../assets/nft_workshop_assets/youdecide.svg";
import fixedprice from "../assets/nft_workshop_assets/fixedprice.svg";


import moonbirds_img from "../assets/nft_workshop_assets/moonbirds_img.png";
import moonbirds_logo from "../assets/nft_workshop_assets/moonbirds_logo.png";

import crypto_punks from "../assets/nft_workshop_assets/crypto_punks.png";
import crypto_punks_logo from "../assets/nft_workshop_assets/crypto_punks_logo.png";

import azuki_img from "../assets/nft_workshop_assets/azuki_banner.png";
import azuki_logo from "../assets/nft_workshop_assets/azuki_logo.png";

import bayc_img from "../assets/nft_workshop_assets/bayc_img.png";
import bayc_logo from "../assets/nft_workshop_assets/bayc_logo.png";

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import axios from "axios";
import {ColorType, createChart} from 'lightweight-charts';


import one from "../assets/nft_workshop_assets/trandingnow/1.png";
import two from "../assets/nft_workshop_assets/trandingnow/2.png";
import three from "../assets/nft_workshop_assets/trandingnow/3.png";
import four from "../assets/nft_workshop_assets/trandingnow/4.png";
import five from "../assets/nft_workshop_assets/trandingnow/5.png";


import art_img from "../assets/nft_workshop_assets/deepdive/art_img.png";
import pfps_img from "../assets/nft_workshop_assets/deepdive/pfps_img.png";
import gamefi_img from "../assets/nft_workshop_assets/deepdive/gamefi.png";
import audio_img from "../assets/nft_workshop_assets/deepdive/audio.svg";
import metaverse_img from "../assets/nft_workshop_assets/deepdive/metaverse.png";
import domains_img from "../assets/nft_workshop_assets/deepdive/domains.png";
import {ChartComponent} from "./components/ChartComponent";


import mysterybox_1 from "../assets/nft_workshop_assets/mysterybox_1.svg";
import mysterybox_2 from "../assets/nft_workshop_assets/mysterybox_2.svg";
import NFTGiftBoxes from "../Token/NFTGiftBoxes";
import NFTBuilder from "./NFTBuilder";
import JoinCommunity from "./components/JoinCommunity";




const NftWorkshop = ({scrollTo}) => {

    useEffect(() => {

        if (scrollTo === 'marketplace') {
            nftMarketplaceRef.current?.scrollIntoView()
        }
        else if (scrollTo === 'builder') {
            nftBuilderRef.current?.scrollIntoView({block: "start", inline: "nearest"})
        }
        else {
            document.body.scrollTo({
                top: 0,
            })
        }

    }, [])


    const nftMarketplaceRef = useRef()
    const nftCollectionsRef = useRef()
    const nftItemsRef = useRef()
    const nftBuilderRef = useRef()
    const nftMysteryBoxesRef = useRef()



    const collections = [
        {
            img: crypto_punks,
            logo: crypto_punks_logo,
            h1: 'Crypto' +
                'Punks',
            h12: 'C. Punks',
            floor: '49.2 ETH',
            id_img: 'carousel-img-2',
            id_bott: 'carousel-bott-2',
        },
        {
            img: azuki_img,
            logo: azuki_logo,
            h1: 'Azuki',
            h12: 'Azuki',
            floor: '6.94 ETH',
            id_img: 'carousel-img-3',
            id_bott: 'carousel-bott-3',
        },
        {
            img: bayc_img,
            logo: bayc_logo,
            h1: 'Bored' +
                'Ape' +
                'Yacht' +
                'Club',
            h12: 'BAYC',
            floor: '23.6 ETH',
            id_img: 'carousel-img-1',
            id_bott: 'carousel-bott-1',
        },
        {
            img: moonbirds_img,
            logo: moonbirds_logo,
            h1: 'Moonbirds',
            h12: 'Moonbirds',
            floor: '1.79 ETH',
            id_img: 'carousel-img-4',
            id_bott: 'carousel-bott-4',
        },
    ]


    Autoplay.globalOptions = { delay: 10000, stopOnInteraction: false }
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' }, [Autoplay()])

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    )
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    )


    const url = 'http://82.146.58.251'
    const [dataNft, setDataNft] = useState([])


    useEffect(() => {
        axios.get(`${url}/api/nft_coin`)
            .then(response => setDataNft(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])


    const trending_now = [
        {
            img: one,
            name: '#3022',
            collection: 'Moonbirds',
            chain: 'Ethereum',
            price: '1.6 ETH',
        },
        {
            img: two,
            name: '#6594',
            collection: 'Mutant Ape Yacht Club',
            chain: 'Ethereum',
            price: '3.8 ETH',
        },
        {
            img: three,
            name: '#18840',
            collection: 'Azuki Elementals',
            chain: 'Ethereum',
            price: '0.55 ETH',
        },
        {
            img: four,
            name: '#5874',
            collection: 'Pillheads NFT',
            chain: 'Ethereum',
            price: '0.1 ETH',
        },
        {
            img: five,
            name: '#161',
            collection: 'Art Blocks',
            chain: 'Ethereum',
            price: '0.44 ETH',
        },
    ]



    const [categoryState, setCategoryState] = useState(1)
    const deep_dive = [
            {
                img: art_img,
                name: 'Slobber Saw',
                collection: 'JOYWORLD JOYs',
                author: 'JOYWORLD',
                chain: 'Ethereum',
                author_earnings: '10%',
                can_buy: true,
                current_price: '99 ETH',
                price_to_dollar: '$277,831',
                best_offer: '1.5 WETH',
                offer_to_dollar: '$4,240',
                have_about: true,
                about: 'Slobber Saw – the only tool ever needed for cutting through the thick materials separating all dimensions.',
                have_traits: true,
                traits: [
                    {
                        name: 'MAGIC',
                        value: 'Cutting Dimensions',
                    },
                    {
                        name: 'SLOBBERY',
                        value: 'Profuse',
                    },
                    {
                        name: 'BACKGROUND',
                        value: 'Gradient',
                    },
                ],
            },
            {
                img: pfps_img,
                name: '#5506',
                collection: 'Mutant Ape Yacht Club',
                author: 'YugaLabs',
                chain: 'Ethereum',
                author_earnings: '2.5%',
                can_buy: true,
                current_price: '57 ETH',
                price_to_dollar: '$164,333',
                best_offer: '4 WETH',
                offer_to_dollar: '$11,635',
                have_about: true,
                about: 'The Mutant Ape Yacht Club is a collection of up to 20,000 Mutant Apes that can only be created by exposing an existing Bored Ape to a vial of MUTANT SERUM or by minting a Mutant Ape in the public sale.',
                have_traits: true,
                traits: [
                    {
                        name: 'BACKGROUND',
                        value: 'M2 Purple',
                    },
                    {
                        name: 'CLOTHES',
                        value: 'M2 Sleeveless Logo T',
                    },
                    {
                        name: 'EYES',
                        value: 'M2 Holographic',
                    },
                    {
                        name: 'FUR',
                        value: 'M2 DMT',
                    },
                    {
                        name: 'HAT',
                        value: 'M2 Commie Hat',
                    },
                    {
                        name: 'MOUTH',
                        value: 'M2 Bored Pipe',
                    },
                ],
            },
            {
                img: gamefi_img,
                name: 'Eye of Galli',
                collection: 'Parallel Alpha',
                author: 'Parallel',
                chain: 'Ethereum',
                author_earnings: '10%',
                can_buy: false,
                current_price: 0,
                price_to_dollar: 0,
                best_offer: '1.2 WETH',
                offer_to_dollar: '$3,636',
                have_about: true,
                about: 'Sci-fi collectable card game with NFTs.',
                have_traits: true,
                traits: [
                    {
                        name: 'ARTIST',
                        value: 'Oscar Mar',
                    },
                    {
                        name: 'CLASS',
                        value: 'Masterpiece',
                    },
                    {
                        name: 'PARALLEL',
                        value: 'Kathari',
                    },
                    {
                        name: 'RARITY',
                        value: 'Prime',
                    },
                ],
            },
            {
                img: audio_img,
                name: 'Faronoreb',
                collection: 'Audioglyphs',
                author: '0x1b9',
                chain: 'Ethereum',
                author_earnings: '5%',
                can_buy: true,
                current_price: '1.7 ETH',
                price_to_dollar: '$4,900',
                best_offer: '0.05 WETH',
                offer_to_dollar: '$140',
                have_about: true,
                about: 'Each Audioglyph synthesizes a unique, infinite stream of music directly in your browser at lossless quality.',
                have_traits: true,
                traits: [
                    {
                        name: 'CLOCK',
                        value: '64.7',
                    },
                    {
                        name: 'MOIRE',
                        value: '397.7',
                    },
                    {
                        name: 'RANGE',
                        value: '1.9',
                    },
                    {
                        name: 'SCALE',
                        value: '0 4 5 7 9',
                    },
                    {
                        name: 'SPACE',
                        value: '0.53',
                    },
                    {
                        name: 'VOICE',
                        value: 'Triangle',
                    },
                ],
            },
            {
                img: metaverse_img,
                name: '#78060',
                collection: 'Otherdeed for Otherside',
                author: 'OthersideMeta',
                chain: 'Ethereum',
                author_earnings: '5%',
                can_buy: false,
                current_price: '0',
                price_to_dollar: '0',
                best_offer: '0.3 WETH',
                offer_to_dollar: '$1,054',
                have_about: true,
                about: `Otherdeed is the key to claiming land in Otherside. Each have a unique blend of environment and sediment — some with resources, some home to powerful artifacts. And on a very few, a Koda roams.`,
                have_traits: true,
                traits: [
                    {
                        name: 'ARTIFACT',
                        value: 'No',
                    },
                    {
                        name: 'CATEGORY',
                        value: 'Spirit',
                    },
                    {
                        name: 'ENVIRONMENT',
                        value: 'Sands',
                    },
                    {
                        name: 'KODA',
                        value: 'No',
                    },
                    {
                        name: 'NORTHERN RESOURCE',
                        value: 'Luster',
                    },
                    {
                        name: 'SOUTHERN RESOURCE',
                        value: 'Oblivion',
                    },
                    {
                        name: 'SEDIMENT',
                        value: 'Rainbow Atmos',
                    },
                ],
            },
            {
                img: domains_img,
                name: 'crypto.eth',
                collection: 'Ethereum Name Service',
                author: 'ENSDeployer',
                chain: 'Ethereum',
                author_earnings: '0%',
                can_buy: false,
                current_price: '0',
                price_to_dollar: '0',
                best_offer: '22 WETH',
                offer_to_dollar: '$63,980',
                have_about: false,
                about: '',
                have_traits: true,
                traits: [
                    {
                        name: 'CHARACTER SET',
                        value: 'Letter',
                    },
                ],
            },
    ]
    const price_data = [
        [
            {
                time: '2019-12-28',
                value: 3.00
            },
            {
                time: '2020-07-23',
                value: 5.50
            },
            {
                time: '2021-01-06',
                value: 7.20
            },
            {
                time: '2021-01-29',
                value: 21.00
            },
        ],
        [
            {
                time: '2021-08-29',
                value: 12.5,
            },
            {
                time: '2022-01-31',
                value: 35,
            },
            {
                time: '2022-02-12',
                value: 35,
            },
            {
                time: '2022-04-24',
                value: 43.9,
            },
        ],
        [
            {
                time: '2023-08-05',
                value: 0.02,
            },
            {
                time: '2023-08-08',
                value: 5.7,
            },
            {
                time: '2023-08-14',
                value: 87,
            },
        ],
        [
            {
                time: '2021-08-19',
                value: 0.1,
            },
            {
                time: '2021-10-12',
                value: 0.13,
            },
            {
                time: '2021-11-03',
                value: 0.15,
            },
        ],
        [
            {
                time: '2023-05-01',
                value: 7.4,
            },
            {
                time: '2023-05-03',
                value: 4.2,
            },
            {
                time: '2023-05-04',
                value: 4.5,
            },
        ],
        [
            {
                time: '2019-10-09',
                value: 28,
            },
            {
                time: '2020-04-29',
                value: 60,
            },
        ],
    ]



    const [formCurrency, setFormCurrency] = useState(true)


    const [fullscreenImg, setFullscreenImg] = useState(false)

    return (
        <div className={'nft-page'}>

            <div className={`nft-marketplace-deep-dive-img-fullscreen-container ${fullscreenImg ? '' : 'hidden'}`}>
                <div onClick={() => setFullscreenImg(false)} className={`nft-marketplace-deep-dive-img-fullscreen-back ${fullscreenImg ? '' : 'hidden'}`}></div>
                <img src={deep_dive[categoryState-1].img} className={`nft-marketplace-deep-dive-img-fullscreen ${fullscreenImg ? '' : 'hidden'}`}/>
            </div>

            <div className={'nft-wrapper'}>

                <div className={'nft-header'}>

                    <img className={'nft-page-header-img-in'} src={nft_workshop_ape} />

                    <div className={'nft-header-container'}>

                        <div className={'nft-header-text'}>
                            <h1 className={'nft-page-h1'}>
                                NFT Workshop
                                <h5 style={{margin: '0', paddingTop: '10px'}}>
                                    {/*Уникальная платформа для конструирования и торговли NFT*/}
                                    A unique platform for designing and trading NFTs
                                </h5>
                            </h1>

                            <button onClick={() => {nftMarketplaceRef.current?.scrollIntoView()}} className={'btn primary explore-btn'}>
                                Explore
                            </button>
                        </div>


                        <div className={'nft-page-header-column'}>

                            <div className={'nft-page-header-img-container side'}>
                                <img className={'nft-page-header-img'} src={nft_workshop_ape3} />
                            </div>

                            <div className={'nft-page-header-img-container'}>
                                <img className={'nft-page-header-img'} src={nft_workshop_ape} />
                            </div>

                            <div className={'nft-page-header-img-container side'}>
                                <img className={'nft-page-header-img'} src={nft_workshop_ape2} />
                            </div>

                        </div>


                    </div>

                    <img className={'nft-page-header-img-back'} src={nft_page_back} />
                </div>

                <div className={'nft-page-highlights-container'}>

                    <button onClick={() => {nftMarketplaceRef.current?.scrollIntoView()}} className={'nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            №1
                        </h2>
                        <p className={'whitewhite'}>
                            Marketplace
                        </p>
                    </button>

                    <button onClick={() => {nftCollectionsRef.current?.scrollIntoView({block: "center"})}} className={'nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            50K
                        </h2>
                        <p className={'whitewhite'}>
                            Collections
                        </p>
                    </button>

                    <button onClick={() => {nftItemsRef.current?.scrollIntoView({block: "center"})}} className={'nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            1M
                        </h2>
                        <p className={'whitewhite'}>
                            Unique Pieces
                        </p>
                    </button>

                    <div onClick={() => {nftBuilderRef.current?.scrollIntoView({block: "start", inline: "nearest"})}} className={'nft-page-highlight-item'}>
                        <h2 className={'white'}>
                            AI
                        </h2>
                        <p className={'whitewhite'}>
                            Based Builder
                        </p>
                    </div>

                </div>

                <div className={'info-page-container'}>
                    <div ref={nftMarketplaceRef} className={'nft-page-text'}>

                        <h1 className={'nft-marketplace-h2'}>
                            NFT Marketplace
                        </h1>

                        <p className={'nft-marketplace-p'}>
                            Доступ к современным NFT коллекциям стал еще доступнее.<br/>
                            Площадка, предоставляющая широкоформатный доступ к современным NFT коллекциям от авторов со всего мира.
                            <span className={'bold'}> Станьте обладателем уникальных и редких NFT и зарабатывайте на этом.</span>
                        </p>

                        <div className={'nft-marketplace'}>


                            <div className={'nft-marketplace-features'}>

                                <div className={'black2 nft-marketplace-features-item'}>
                                    <div className={'nft-marketplace-features-item-text'}>

                                        <div className={'nft-marketplace-features-item-text-top'}>
                                            <h3 className={'nft-marketplace-features-item-h3'}>
                                                Accessibility
                                            </h3>
                                            <p className={'nft-marketplace-features-item-p'}>
                                                Принимайте в качестве оплаты за NFT удобную для вас криптовалюту
                                            </p>
                                        </div>

                                        <img className={'nft-marketplace-features-item-img-in'} src={accessibility} />

                                        <div className={'nft-marketplace-features-item-text-bot'}>
                                            <h2 className={'nft-marketplace-features-item-text-bot-h2'}>
                                                <span style={{color: '#1b78ff'}}>200+ Currencies</span>
                                            </h2>
                                        </div>

                                    </div>

                                    <img className={'nft-marketplace-features-item-img'} src={accessibility} />
                                </div>

                                <div className={'black2 nft-marketplace-features-item'}>
                                    <div className={'nft-marketplace-features-item-text'}>

                                        <div className={'nft-marketplace-features-item-text-top'}>
                                            <h3 className={'nft-marketplace-features-item-h3'}>
                                                Choose Your Strategy
                                            </h3>
                                            <p className={'nft-marketplace-features-item-p'}>
                                                Выбирайте и настраивайте свою систему торговли
                                            </p>
                                        </div>

                                        <img className={'nft-marketplace-features-item-img-in'} src={formCurrency ? youdecide : fixedprice} />

                                        <div className={'nft-marketplace-features-item-text-bot'}>
                                            <div className={'nft-marketplace-features-item-toggle'}>

                                                <button
                                                    onClick={() => setFormCurrency(true)}
                                                    className={`btn nft-marketplace-features-item-toggle-button ${formCurrency ? 'active' : ''}`}
                                                >
                                                    Auction
                                                </button>

                                                <button
                                                    onClick={() => setFormCurrency(false)}
                                                    className={`btn nft-marketplace-features-item-toggle-button ${!formCurrency ? 'active' : ''}`}
                                                >
                                                    Fixed Price
                                                </button>

                                            </div>
                                        </div>

                                    </div>

                                    <img className={'nft-marketplace-features-item-img'} src={formCurrency ? youdecide : fixedprice} />

                                </div>

                            </div>


                            <div ref={nftCollectionsRef}>

                                <div className={'nft-page-collections-top'}>
                                    <h2 className={'nft-page-collections-h2'}>
                                        Top Collections
                                    </h2>

                                    <div className={'coins-market-btns'}>
                                        <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev coins-market-btn'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn carousel-btn-left'}>
                                                <path
                                                    fill={'#1b78ff'}
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </button>

                                        <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next coins-market-btn'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn carousel-btn-right'}>
                                                <path
                                                    fill={'#1b78ff'}
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>


                                <div className={'nft-marketplace-left'}>

                                    {/*<div className={'posts-btns'}>
                                        <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev posts-btn'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn posts-btn-left'}>
                                                <path
                                                    fill={'#1b78ff'}
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </button>

                                        <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next posts-btn'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn posts-btn-right'}>
                                                <path
                                                    fill={'#1b78ff'}
                                                    fillRule="evenodd"
                                                    d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>*/}

                                    <div className={'embla nft-page-collections-slider'} ref={emblaRef}>

                                        <div id={'slider-container'} className={'embla__container nft-page-collections-container'}>

                                            {collections.map((item,i) =>
                                                <div key={i} className={'embla__slide nft-page-collection-item'}>
                                                    <img id={item.id_img} src={item.img} className={'nft-page-collection-item-img'}/>

                                                    <div id={item.id_bott} className={'nft-page-collection-item-bottom'}>
                                                        <h1 className={'nft-page-collection-item-h1 white'}>
                                                            <img className={'nft-page-collection-item-logo'} src={item.logo} />
                                                            <span>{item.h1}</span>
                                                            <h2 className={'nft-page-collection-item-h2 white'}>{item.h12}</h2>
                                                            <img className={'nft-page-collection-item-tick'} src={tick}/>
                                                        </h1>

                                                        <div className={'nft-page-collection-item-right'}>
                                                            <p className={''}>
                                                                Floor
                                                            </p>
                                                            <h3 className={''}>
                                                                {item.floor}
                                                            </h3>
                                                        </div>

                                                    </div>

                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>


                                <div className={'nft-marketplace-right'}>

                                    {/*<h3 className={'nft-marketplace-coins-h3'}>
                                        Top Currencies
                                    </h3>*/}

                                    <div className={'nft-marketplace-coins'}>

                                        {dataNft.slice(0, 4).map((i, index) =>
                                            <div className={'workshop-block-coin coin-block-coin'}>
                                                <div className={'coin-block-left'}>
                                                    <img className={'coin-block-img'} src={i.image} />
                                                    <h4 className={'coin-block-h4'} style={{color: '#505050'}}>{i.symbol.toUpperCase()}</h4>
                                                </div>

                                                <div className={'coin-block-right'}>
                                                    {i.price_change_percentage_24h >= 0 ?
                                                        <h4 className={'coin-block-h4 green'}>+{i.price_change_percentage_24h.toFixed(2)}%</h4> :
                                                        <h4 className={'coin-block-h4 red'}>{i.price_change_percentage_24h.toFixed(2)}%</h4>
                                                    }
                                                    <h3 className={'coin-block-h3'}>${i.current_price.toLocaleString()}</h3>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>


                        </div>


                        <div ref={nftItemsRef} className={'nft-marketplace-trending'}>

                            <h2 className={'nft-marketplace-trending-h2'}>
                                Trending Now
                            </h2>

                            <div className={'nft-marketplace-trending-container'}>
                                {trending_now.map((item, i) =>
                                    <a className={'nft-marketplace-trending-item'}>
                                        <div className={'nft-marketplace-trending-item-img-container'}>
                                            <img className={'nft-marketplace-trending-item-img'} src={item.img} />
                                        </div>

                                        <div className={'nft-marketplace-trending-item-text'}>
                                            <div className={'nft-marketplace-trending-item-top'}>
                                                <h3>{item.name}</h3>
                                                <p className={'bold'}>
                                                    {item.collection}
                                                    <img className={'nft-marketplace-trending-item-tick'} src={tick}/>
                                                </p>
                                            </div>

                                            <div className={'nft-marketplace-trending-item-bott'}>
                                                <p>Price</p>
                                                <h5 className={''}>
                                                    {item.price}
                                                </h5>
                                            </div>
                                        </div>



                                    </a>
                                )}
                            </div>

                        </div>



                        <div className={'nft-marketplace-categories-wrapper'}>

                            <div className={'nft-marketplace-categories-left'}>

                                <h3 className={'nft-marketplace-categories-h3'}>
                                    Categories
                                </h3>

                                <div className={'nft-marketplace-categories-left-content'}>
                                    <div onClick={() => setCategoryState(1)} className={`bold nft-marketplace-categories-item ${categoryState === 1 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            Art
                                        </p>
                                    </div>

                                    <div onClick={() => setCategoryState(2)} className={`bold nft-marketplace-categories-item ${categoryState === 2 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            PFPs
                                        </p>
                                    </div>

                                    <div onClick={() => setCategoryState(3)} className={`bold nft-marketplace-categories-item ${categoryState === 3 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            GameFi
                                        </p>
                                    </div>

                                    <div onClick={() => setCategoryState(4)} className={`bold nft-marketplace-categories-item ${categoryState === 4 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            Audio
                                        </p>
                                    </div>

                                    <div onClick={() => setCategoryState(5)} className={`bold nft-marketplace-categories-item ${categoryState === 5 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            Metaverse
                                        </p>
                                    </div>

                                    <div onClick={() => setCategoryState(6)} className={`bold nft-marketplace-categories-item ${categoryState === 6 ? 'active' : ''}`}>
                                        <p className={'bold'}>
                                            Domains
                                        </p>
                                    </div>

                                    <div onClick={() => {nftMysteryBoxesRef.current?.scrollIntoView({block: "start", inline: "nearest"})}} className={`nft-marketplace-categories-item mb`}>
                                        <p className={'bold whitewhite'}>
                                            MysteryBox
                                        </p>
                                    </div>

                                    {/*<div onClick={() => {nftMysteryBoxesRef.current?.scrollIntoView({block: "start", inline: "nearest"})}} className={`nft-marketplace-categories-item gb`}>
                                        <p className={'bold whitewhite'}>
                                            GiftBox
                                        </p>
                                    </div>*/}
                                </div>



                            </div>

                            <div className={'nft-marketplace-categories-right'}>

                                {/*<h3 className={'nft-marketplace-deep-dive-h3'}>
                                    Deep Dive
                                </h3>*/}

                                <div className={'nft-marketplace-deep-dive'}>
                                    <div className={'nft-marketplace-deep-dive-top'}>
                                        <img onClick={() => setFullscreenImg(true)} src={deep_dive[categoryState-1].img}  className={'nft-marketplace-deep-dive-img'}/>

                                        <div className={'nft-marketplace-deep-dive-top-right'}>
                                            <div className={'nft-marketplace-deep-dive-top-text'}>
                                                <h1>{deep_dive[categoryState-1].name}</h1>
                                                <div className={'nft-marketplace-deep-dive-top-text-items'}>
                                                    <div className={'nft-marketplace-deep-dive-top-text-item'}>
                                                        <p className={'nft-marketplace-deep-dive-top-text-collection-p'}>Collection</p>
                                                        <h5 className={'nft-marketplace-deep-dive-top-text-collection hover'}>
                                                            {deep_dive[categoryState-1].collection}
                                                            <img className={'nft-marketplace-deep-dive-tick'} src={tick}/>
                                                        </h5>
                                                    </div>

                                                    <div className={'nft-marketplace-deep-dive-top-text-item'}>
                                                        <p className={'nft-marketplace-deep-dive-top-text-collection-p'}>Author</p>
                                                        <h5 className={'nft-marketplace-deep-dive-top-text-collection hover'}>
                                                            {deep_dive[categoryState-1].author}
                                                            <img className={'nft-marketplace-deep-dive-tick'} src={tick}/>
                                                        </h5>
                                                    </div>

                                                    <div className={'nft-marketplace-deep-dive-top-text-item'}>
                                                        <p className={'nft-marketplace-deep-dive-top-text-collection-p'}>Chain</p>
                                                        <h5 className={'nft-marketplace-deep-dive-top-text-collection hover'}>{deep_dive[categoryState-1].chain}</h5>
                                                    </div>

                                                    <div className={'nft-marketplace-deep-dive-top-text-item'}>
                                                        <p className={'nft-marketplace-deep-dive-top-text-collection-p'}>Author's Earnings</p>
                                                        <h5 className={'nft-marketplace-deep-dive-top-text-collection'}>{deep_dive[categoryState-1].author_earnings}</h5>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className={'nft-marketplace-deep-dive-top-price'}>
                                                <div className={'nft-marketplace-deep-dive-top-price-top'}>

                                                    <div className={'nft-marketplace-deep-dive-top-price-top-item'}>
                                                        <p>Best Offer</p>
                                                        <div className={'nft-marketplace-deep-dive-top-price-top-h2'}>
                                                            <h2>
                                                                {deep_dive[categoryState-1].best_offer}
                                                            </h2>
                                                            <p>({deep_dive[categoryState-1].offer_to_dollar})</p>
                                                        </div>
                                                    </div>

                                                    {deep_dive[categoryState-1].can_buy ?
                                                        <div className={'nft-marketplace-deep-dive-top-price-top-item'}>
                                                            <p>Current Price</p>
                                                            <div className={'nft-marketplace-deep-dive-top-price-top-h2'}>
                                                                <h2>
                                                                    {deep_dive[categoryState-1].current_price}
                                                                </h2>
                                                                <p>({deep_dive[categoryState-1].price_to_dollar})</p>
                                                            </div>
                                                        </div>
                                                        : <></>
                                                    }

                                                </div>

                                                <div className={'nft-marketplace-deep-dive-top-price-bott'}>
                                                    <a className={'btn dark'}>
                                                        Make Offer
                                                    </a>

                                                    {deep_dive[categoryState-1].can_buy ?
                                                        <a className={'btn primary btn-blue'}>
                                                            Buy Now
                                                        </a>
                                                        : <></>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className={'nft-marketplace-deep-dive-about-wrapper'}>
                                        <div className={'nft-marketplace-deep-dive-about-container'}>
                                            {deep_dive[categoryState-1].have_about ?
                                                <h3 className={'nft-marketplace-deep-dive-about-h3'}>
                                                    About
                                                </h3>
                                                : <></>
                                            }

                                            {deep_dive[categoryState-1].have_about ?
                                                <p className={'nft-marketplace-deep-dive-about-p'}>
                                                    {deep_dive[categoryState-1].about}
                                                </p>
                                                : <></>
                                            }
                                        </div>

                                        <div className={'nft-marketplace-deep-dive-price-container'}>
                                            <h3 className={'nft-marketplace-deep-dive-price-h3'}>
                                                Last Sales
                                            </h3>
                                            <div className={'nft-marketplace-deep-dive-price'}>
                                                <ChartComponent data={
                                                    price_data[categoryState-1]
                                                }></ChartComponent>
                                            </div>
                                        </div>
                                    </div>





                                    {deep_dive[categoryState-1].have_traits ?
                                        <h3 className={'nft-marketplace-deep-dive-traits-h3'}>
                                            Traits
                                        </h3>
                                        : <></>
                                    }

                                    {deep_dive[categoryState-1].have_traits ?
                                        <div className={'nft-marketplace-deep-dive-traits-container'}>

                                            {deep_dive[categoryState-1].traits.map((item, i) =>
                                                <div className={'nft-marketplace-deep-dive-traits-item'}>
                                                    <p key={i} className={'nft-marketplace-deep-dive-traits-item-p'}>
                                                        {item.name}
                                                    </p>
                                                    <h5 key={i} className={'nft-marketplace-deep-dive-traits-item-h5'}>
                                                        {item.value}
                                                    </h5>
                                                </div>
                                            )}

                                        </div>
                                        : <></>
                                    }




                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div ref={nftBuilderRef}></div>

            <NFTBuilder />

            <div className={'nft-wrapper'}>
                <div className={'nft-page-container'}>

                    <div>

                        <div ref={nftMysteryBoxesRef}></div>
                        <div className={'nft-page-mysteryboxes-wrapper'}>

                            <div className={'nft-page-mysteryboxes-header'}>

                                <h2 className={'nft-page-mysteryboxes-h2'}>
                                    MysteryBoxes
                                </h2>
                                <p>Приобретайте для себя или в подарок мистери-боксы по фиксированной цене.</p>

                            </div>

                            <div className={'nft-page-mysteryboxes-container'}>

                                <div className={'nft-page-mysteryboxes-item mysterybox-1'}>

                                    <div className={'nft-page-mysteryboxes-item-img-container'}>
                                        <img className={'nft-page-mysteryboxes-item-img'} src={mysterybox_1} />
                                    </div>

                                    <div className={'nft-page-mysteryboxes-item-text'}>
                                        <div className={'nft-page-mysteryboxes-item-top'}>
                                            <h3>Rare MysteryBox</h3>
                                            <p className={'nft-page-mysteryboxes-item-top-p-collection fst bold'}>
                                                Liqui Global
                                                <img className={'nft-page-mysteryboxes-item-tick'} src={tick}/>
                                            </p>
                                            <p className={'nft-page-mysteryboxes-item-top-p'}>Contains NFT worth from <span className={'bold'}>$100</span> to <span className={'bold'}>$1000</span></p>
                                        </div>

                                        <div className={'nft-page-mysteryboxes-item-bott'}>
                                            <p>Price</p>
                                            <h3>0,0003 ETH</h3>
                                        </div>
                                    </div>

                                </div>

                                <div className={'nft-page-mysteryboxes-item mysterybox-2'}>


                                    <div className={'nft-page-mysteryboxes-item-img-container'}>
                                        <img className={'nft-page-mysteryboxes-item-img'} src={mysterybox_2} />
                                    </div>

                                    <div className={'nft-page-mysteryboxes-item-text'}>
                                        <div className={'nft-page-mysteryboxes-item-top'}>
                                            <h3 className={'white'}>Legendary MysteryBox</h3>
                                            <p className={'nft-page-mysteryboxes-item-top-p-collection whitewhite bold'}>
                                                Liqui Global
                                                <img className={'nft-marketplace-trending-item-tick'} src={tick_inverted}/>
                                            </p>
                                            <p className={'nft-page-mysteryboxes-item-top-p whitewhite'}>Contains NFT worth from <span className={'bold'}>$1000</span> to <span className={'bold'}>$10000</span></p>
                                        </div>

                                        <div className={'nft-page-mysteryboxes-item-bott'}>
                                            <p className={'whitewhite'}>Price</p>
                                            <h3 className={'white'}>0,003 ETH</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <NFTGiftBoxes />


                        </div>


                        <div className={'final-phrase-container'}>
                            <p className={'final-phrase'}>
                                Discover the digital world of contemporary art in a new way.
                            </p>
                        </div>



                    </div>

                    <Link to={'/'} className={'info-page-home'}>
                        {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                        <button className={'btn black2'} style={{minWidth: '150px'}}>
                            Go Home
                        </button>
                    </Link>

                </div>


            </div>



            <MightBeInteresting current={1}/>

            <JoinCommunity  mt0={true} />

        </div>
    );
};

export default NftWorkshop;