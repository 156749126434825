import React from 'react';
import './InfluencerBounty.css'
import influencer_bounty from '../../assets/influencer_bounty_new.svg'
import {Link} from "react-router-dom";
import arrowRightWhite from '../../assets/arrowrightwhite.svg'
import arrowRightBlue from '../../assets/arrowrightblue.svg'
import influencer from '../../assets/influencer.svg'
import social from '../../assets/social.svg'

const InfluencerBounty = () => {
    return (
        <div className={'influencer-wrapper'}>

            <div className="influencer-container">
                <h2>
                    Join Bounty Program
                </h2>

                <div className={'influencer-container-content'}>
                    <div className={'influencer-text'}>

                        <Link to={'/social-media-bounty-program'} className={'influencer-2 influencer'}>
                            <div className={'influencer-content'}>
                                <h3 className={'white'}>
                                    Social Media Bounty
                                </h3>
                                <p className={'white'}>
                                    {/*Мы сформировали пуллы вознаграждений за помощь в продвижении Liqui Global в социальных сетях. Любой пользователь может поучаствовать в баунти программе и разделить награду за помощь в продвижении нашей платформы.*/}
                                    We have created a pool of rewards to help promote Liqui Global on social networks. Any user can participate in the bounty program and receive the reward for help in promoting our platform.
                                </p>

                                {/*<div className={'influencer-horisontal-line2'}></div>*/}

                                </div>

                                <img src={social} className={'influencer-img'} />

                                <img src={arrowRightWhite} className={'influencer-arrow'} />
                        </Link>

                        <Link to={'/influencer-vip-bounty-program'} className={'influencer-1 influencer'}>

                            <div className={'influencer-content'}>
                                <h3>
                                    Influencer VIP Bounty
                                </h3>
                                <p>
                                    {/*Вы популярный в сфере криптовалют? У вас есть значительная аудитория или вы владелец криптовалютных соушел медиа ресурсов? Мы готовы щедро вознаградить инфлюенсеров за маркетинговый вклад и предложить индивидуальные условия вознаграждения.*/}
                                    If you have a large audience or manage your own cryptocurrency social media channels, we are ready to generously reward influencers for marketing contributions and offer unique remuneration conditions.
                                </p>

                                {/*<div className={'influencer-horisontal-line1'}/>*/}

                                <img src={influencer} className={'influencer-img'}></img>

                                <img src={arrowRightBlue} className={'influencer-arrow'} />

                            </div>

                        </Link>

                    </div>

                    {/*<img className={'influencer-bounty-img'} src={influencer_bounty} />*/}

                </div>

            </div>


        </div>
    );
};

export default InfluencerBounty;