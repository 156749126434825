import React, {useEffect, useState} from 'react';
import './CryptoTable.css'
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";
import {dataGainerHard} from "./CoinDataHard";
import {logos} from "../TradingBotsData";
import axios from "axios";
import CryptoTableToggle from "../CryptoTableToggle";

const CryptoTable = ({page, array, minusFirst, length, action, leverages, monitorToggle}) => {


    useEffect(() => {

        /*if (minusFirst) {
            array.shift()
        }*/

    }, [])


    const gemMonitorSelection = [
        'BTC',
        'BTC',

        'NEAR',
        'FTM',
        'JUP',
        'WLD',
        'ATOM',
        'ARB',
        'DOT',
        'ADA',
    ]

    let arrayFiltered = []

    if (page === 'Ai Gem Monitor') {

        for (let i = 0; i < gemMonitorSelection.length; i = i + 1) {

            let arrayItem = {}

            for (let c = 0; c < array.length; c = c + 1) {

                arrayItem = array.find((item) => item?.symbol === gemMonitorSelection[i].toLowerCase())

            }

            arrayFiltered.push(
                arrayItem
            )

        }

    } else {
        arrayFiltered = array
    }



    const url = 'http://82.146.58.251'

    const [dataGainer, setDataGainer] = useState(dataGainerHard)
    const [gainerToggle, setGainerToggle] = useState(1)

    const urlGainer = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h%2C24h%2C7d&locale=en'

    /*useEffect(() => {
        axios.get(`${url}/api/gainer_coin`)
            .then(response => {
                setDataGainer(JSON.parse(response.data))
            })
            .catch(error => console.log(error))
    }, [])*/


    dataGainer.sort((a, b) => (a.total_volume < b.total_volume) ? 1 : -1)

    /*useEffect(() => {
        dataGainer.shift()
    }, [])*/




    if (array.length > 1) return (
        <div className={'crypto-table-wrapper'}>

            <div className={'crypto-table-container'}>

                <div className={`${leverages ? 'leverages' : ''} crypto-table-marks`}>

                    <p>
                        Cruptocurrency
                    </p>

                    {
                        leverages ?
                            <p className={'crypto-table-mark-24-centered'}>
                                24h Change
                            </p>
                            :
                            <p>
                                Current Price
                            </p>
                    }

                    {
                        leverages ?
                            <p>
                                Net Asset Value
                            </p>
                            :
                            <p>
                                24h Change
                            </p>
                    }

                    {
                        leverages ?
                            <p>
                                Last Price
                            </p>
                            :
                            <p>
                                7D Change
                            </p>
                    }
                    {
                        leverages ?
                            <p>
                                Leverage
                            </p>
                            :
                            <p>
                                Markets
                            </p>
                    }
                    {
                        monitorToggle ?
                            <p>
                                Monitor
                            </p>
                            :
                            <p>
                                Action
                            </p>
                    }

                </div>

                {
                    arrayFiltered?.slice(minusFirst ? 2 : 0, length).map((item, i) =>
                        <div className={`${leverages ? 'leverages' : ''} crypto-table-item`}>

                            <div className={'crypto-table-item-logo-container'}>

                                <div className={`${leverages ? item.dir === 1 ? 'green leverages' : 'red leverages' : ''} crypto-table-item-logo-img-container`}>
                                    <img src={
                                        leverages ?
                                            logos.find((logo) => logo.currency === item?.symbol)?.logo
                                            :
                                            item?.image
                                    } className={`${leverages ? 'leverages' : ''} crypto-table-item-logo-img`} />
                                </div>


                                {
                                    leverages ?
                                        <h3>
                                            {item.dir === 1 ? item?.symbol.toUpperCase() + 'UP' : item?.symbol.toUpperCase() + 'DOWN'}
                                            <p className={item.dir === 1 ? 'green bold' : 'red bold'}>
                                                {item.leverage}x {item?.dir === 1 ? 'Long' : 'Short'} {item?.symbol.toUpperCase()}
                                            </p>
                                        </h3>
                                        :
                                        <h3>
                                            {item?.symbol.toUpperCase()}
                                            <p>
                                                {item?.name}
                                            </p>
                                        </h3>
                                }
                            </div>


                            <div className={'crypto-table-current-price'}>
                                    {
                                        leverages ?

                                            <div className={'crypto-table-double-change-container'}>

                                                <div className={'crypto-table-double-change-left'}>
                                                    <h3 className={item.change_24h >= 0 ? '' : ''}>

                                                        {dataGainer.find((gainer) => (gainer?.symbol).toLowerCase() === (item?.symbol).toLowerCase())?.price_change_percentage_24h?.toFixed(2)}%

                                                    </h3>

                                                    <p>
                                                        {item?.symbol.toUpperCase()}
                                                    </p>
                                                </div>


                                                <div className={'crypto-table-double-change-bar'}></div>


                                                <div className={'crypto-table-double-change-right'}>
                                                    <h3 className={item.change_24h >= 0 ? 'green' : 'red'}>
                                                        {item.dir === 1 ? '+' : '-'}
                                                        {(dataGainer.find((gainer) => gainer?.symbol === item?.symbol.toLowerCase())?.price_change_percentage_24h?.toFixed(2) * item.leverage + (Math.random() * 7.9243872))?.toFixed(2) * ((dataGainer.find((gainer) => (gainer.symbol).toLowerCase() === (item.symbol).toLowerCase())?.price_change_percentage_24h?.toFixed(2) * item.leverage) >= 0 ? 1 : -1)}%
                                                    </h3>

                                                    <p>
                                                        {item.dir === 1 ? item?.symbol.toUpperCase() + 'UP' : item?.symbol.toUpperCase() + 'DOWN'}
                                                    </p>
                                                </div>

                                            </div>

                                            :
                                            <h3>
                                                ${item?.current_price}
                                            </h3>
                                    }
                            </div>


                            <div className={'crypto-table-change'}>
                                <h3>
                                    {
                                        leverages ?
                                            `${item.nav} USDT`
                                            :
                                            item?.price_change_percentage_24h?.toFixed(2) >= 0 ?
                                                    <h3 className={'green'}>+{item?.price_change_percentage_24h?.toFixed(2)}%</h3> :
                                                    <h3 className={'red'}>{item?.price_change_percentage_24h?.toFixed(2)}%</h3>

                                    }

                                </h3>
                            </div>


                            <div className={'crypto-table-change'}>
                                <h3>

                                    {
                                        leverages ?
                                            `${item.last_price} USDT`
                                            :
                                            item?.price_change_percentage_7d_in_currency >= 0 ?
                                                <h3 className={'green'}>+{item?.price_change_percentage_7d_in_currency?.toFixed(2)}%</h3> :
                                                <h3 className={'red'}>{item?.price_change_percentage_7d_in_currency?.toFixed(2)}%</h3>
                                    }

                                </h3>
                            </div>


                            {
                                leverages ?

                                    <h3>
                                        {item.dir === 1 ? '+' : '-'}
                                        {(item.leverage + (item.leverage * Math.random() * 0.1) - 0.2)?.toFixed(3)}x
                                    </h3>

                                    :

                                    <div className={'crypto-table-spark-container'}>
                                        <div className={'crypto-table-spark-top sparkline'}>
                                            <Sparklines className={'tabs-sparkline'} data={item?.sparkline_in_7d.price}>
                                                <SparklinesCurve className={'tabs-sparkline-top'} style={{fill: 'none', strokeWidth: '1.8'}}   color={item?.price_change_percentage_7d_in_currency >= 0 ? '#0bd084' : '#ff5151'}/>
                                            </Sparklines>
                                        </div>
                                        <div className={'crypto-table-spark-bottom sparkline-bott'}>
                                            <Sparklines data={item?.sparkline_in_7d.price}>
                                                <SparklinesCurve style={{fill: item?.price_change_percentage_7d_in_currency >= 0 ? '#0bd084' : '#ff5151', strokeWidth: ''}} color={item?.price_change_percentage_7d_in_currency >= 0 ? '#0bd084' : '#ff5151'}/>
                                            </Sparklines>
                                        </div>
                                    </div>

                            }


                            {
                                monitorToggle ?

                                    <div className={'crypto-table-onoff-container'}>
                                        <CryptoTableToggle i={i} />
                                    </div>

                                    :

                                    <button className={'btn full primary'}>
                                        {action}
                                    </button>
                            }


                        </div>
                    )
                }

                <div className={'crypto-table-viewmore'}>

                    <h3>
                        View More
                    </h3>

                </div>

            </div>

        </div>
    );
};

export default CryptoTable;