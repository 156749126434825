import React from 'react';
import {Link} from "react-router-dom";
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import faq from '../assets/faq.svg'

const FAQ = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '15px', marginTop: '0px'}} className={'info-page-header-img'} src={faq}/>
                    <h1>
                        FAQ
                        <h5 style={{margin: '0', paddingTop: '10px'}}>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>

                        <div className={'info-page-1-container'}>

                            <div className={'info-page-1-left'}>

                                <h2 className={'info-page-h2'}>
                                    What is Liqui Global?
                                </h2>

                                <p className={'info-page-p'}>
                                    <br/>
                                    Криптовалютная платформа, предлагающая пользователям уникальные инструменты и сервисы высочайшего уровня.
                                    <br/>
                                    Некогда популярная криптовлютная биржа Liqui.io была перезапущена, чтобы вновь стать лучшей криптовалютной платформой

                                    <Link to={'/legacy'} className={'faq-accordion-item-btn-container'}>
                                        <button className={'btn primary faq-accordion-item-btn'}>
                                            Legacy
                                        </button>
                                    </Link>
                                </p>




                                <h2 className={'info-page-h2'}>
                                    What is LGT price?
                                </h2>

                                <p className={'info-page-p'}>
                                    <br/>
                                    В настоящий момент, цена LGT является фиксированной и зависит от активного инвестиционного раунда. После проведения инвестиционных раундов, цена LGT будет определена рынком

                                    <Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                                        <button className={'btn dark faq-accordion-item-btn'}>
                                            Tokenomics
                                        </button>
                                    </Link>
                                </p>




                                <h2 className={'info-page-h2'}>
                                    How do i get LGT?
                                </h2>

                                <p className={'info-page-p'}>

                                    <br/>
                                    После деплоя LGT смарт-контракта, клейм будет будет доступен на указанный вами кошелек в соответствии с условиями<span className={'bold'}> Claim/Vesting</span>

                                    <Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                                        <button className={'btn dark faq-accordion-item-btn'}>
                                            Vesting Rules
                                        </button>
                                    </Link>

                                </p>

                            </div>

                            <div style={{marginTop: '0'}} className={'info-page-1-right'}>


                                <h2 className={'info-page-h2'}>
                                    What is LGT?
                                </h2>

                                <p className={'info-page-p'}>
                                    <br/>
                                    Liqui Global Token (LGT) - официальный токен, выпущенный Liqui Global. Является центрой всей экосистемы и используется в различных биржевых механниках.
                                    <span className={'bold'}> Всего будет выпущенно 550.000.000 токенов LGT</span>

                                    <Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                                        <button className={'btn dark faq-accordion-item-btn'}>
                                            Features
                                        </button>
                                    </Link>
                                </p>




                                <h2 className={'info-page-h2'}>
                                    How to buy LGT?
                                </h2>

                                <p className={'info-page-p'}>

                                    <br/>
                                    Вы можете купить LGT в рамкам проведения инвестиционных раундов.  В настоящий момент активным является <Link className={'faq-accordion-presale-link'} to={'/token'}>Pre-sale round</Link>

                                    <Link to={'/token'} className={'faq-accordion-item-btn-container'}>
                                        <button className={'btn dark faq-accordion-item-btn'}>
                                            Buy LGT
                                        </button>
                                    </Link>

                                </p>




                            </div>


                        </div>


                        <div className={'info-page-lbsect'} style={{marginTop: '80px'}}>

                            <h3>
                                Still have questions?
                            </h3>

                            <p className={'info-page-p'}>
                                We'll answer everything you are interested in.
                                <br/>
                                Contact us at
                                <br/>
                                <a className={'info-page-underlined-a'} href="mailto:service@liqui.global">service@liqui.global</a>
                            </p>


                        </div>



                    </div>

                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default FAQ;