import React from 'react';
import BlogNav from "./BlogNav";
import JoinCommunity from "../components/JoinCommunity";

const BlogArt3 = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (

        <div className={'blog-container-wrapper'}>
            <div className={'blog-container'}>
                <BlogNav active={3}/>

                <div className={'blog-content'}>

                    <span className={'blog-content-date'}>
                        30.09.23
                    </span>

                    <h2 className={'blog-content-h2'}>
                        What is NFT Workshop and why i need to try it?
                    </h2>

                    <img className={'blog-content-img'} src={''} />

                    <h3 className={'blog-content-h3'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit
                    </h3>

                    <p className={'blog-content-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, obcaecati, totam! Alias beatae blanditiis, cum delectus, dolor eius est iusto labore nobis non quasi saepe tempora, voluptas. Ab accusamus, aliquid, amet, asperiores at blanditiis dicta distinctio dolorum est et facilis harum illum nostrum numquam omnis praesentium qui temporibus velit voluptatem voluptatum! Adipisci aut commodi dignissimos dolorem facilis illum nihil nulla odit sint voluptas! Aliquam architecto facere molestiae quaerat quisquam quos. Dolore odio placeat quaerat! At autem earum eius fugit quo ullam. Accusantium dicta dolores suscipit. Culpa mollitia ratione voluptatibus! Aut beatae consequuntur dicta eveniet exercitationem fugiat ipsum maiores necessitatibus nesciunt non, nulla officia perspiciatis voluptates? Accusamus accusantium aperiam aspernatur, consequatur deserunt dicta dolore dolorem excepturi fugit hic id inventore, iure laborum magnam nihil nobis officia optio praesentium provident quaerat quas quasi quibusdam sed sint suscipit vero voluptas voluptate! Accusamus facere illo ipsam minus nam natus quia quibusdam quo repellat veniam.
                    </p>

                    <h3 className={'blog-content-h3'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit
                    </h3>

                    <p className={'blog-content-p'}>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, obcaecati, totam! Alias beatae blanditiis, cum delectus, dolor eius est iusto labore nobis non quasi saepe tempora, voluptas. Ab accusamus, aliquid, amet, asperiores at blanditiis dicta distinctio dolorum est et facilis harum illum nostrum numquam omnis praesentium qui temporibus velit voluptatem voluptatum! Adipisci aut commodi dignissimos dolorem facilis illum nihil nulla odit sint voluptas! Aliquam architecto facere molestiae quaerat quisquam quos. Dolore odio placeat quaerat! At autem earum eius fugit quo ullam. Accusantium dicta dolores suscipit. Culpa mollitia ratione voluptatibus! Aut beatae consequuntur dicta eveniet exercitationem fugiat ipsum maiores necessitatibus nesciunt non, nulla officia perspiciatis voluptates? Accusamus accusantium aperiam aspernatur, consequatur deserunt dicta dolore dolorem excepturi fugit hic id inventore, iure laborum magnam nihil nobis officia optio praesentium provident quaerat quas quasi quibusdam sed sint suscipit vero voluptas voluptate! Accusamus facere illo ipsam minus nam natus quia quibusdam quo repellat veniam.
                    </p>

                </div>
            </div>
            <JoinCommunity />
        </div>

    );
};

export default BlogArt3;