import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import abstract_bots from "../assets/abstract_bots.svg";
import trading_bots from "../assets/trading_bots.svg";
import arrowrightbluedark from "../assets/arrowrightbluedark.svg";
import staking from "../assets/earnbox/staking.svg";
import lpools from "../assets/earnbox/lpools.svg";
import add_dark from "../assets/add_dark.svg";
import add_primary2 from "../assets/add_primary2.svg";
import LgtPresale from "./components/LgtPresale";
import MightBeInteresting from "../MightBeInteresting";
import crypto_academy_experts from "../assets/crypto_academy_experts.svg";
import './TradingBots.css'
import './EarnBox.css'
import NavbarBlack from "../NavbarBlack";
import Footer from "./components/Footer";
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";
import earn_box from '../assets/earnbox-pig.svg'
import abstract_2 from '../assets/abstract_2.svg'


import {
    mybots_data,
    myearn_data,
    earnProducts,
    earnStakingTypes,
    marketplace_data,
    dataCoins,
    sort_options,
    sparklines,
    grid_ai_data,
    dca_ai_data,
    leverage_options,
    dataTopCoin, logos,
} from "./TradingBotsData";
import Select from "react-select";
import axios from "axios";
import InputRange from "react-input-range";
import TradingBotsLearn from "./TradingBotsLearn";
import earnbox_pig from "../assets/earnbox-pig.svg";
import VerificationWidget from "./components/VerificationWidget";
import abstract_verify from "../assets/abstract_verify.svg";
import EarnBoxProduct from "./EarnBoxProduct";
import earnBoxProduct from "./EarnBoxProduct";
import Navbar from "./components/Navbar";


const EarnBox = () => {

    /*useEffect(() => {

        document.body.scrollTo({
            top: 0,
        })

    }, [])*/


    const select_styles = {
        input: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        noOptionsMessage: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        container: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),

        control: (baseStyles, state) => ({
            border: '1px solid #292a33',
            background: '#0e0e12',
            "&:hover": {
                border: '1px solid #575866',
                transition: '0.2s ease-in-out',
            },
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 12,
            height: '48px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            color: '#ffffff',
            paddingLeft: '5px',
            zIndex: '100',
            transition: '0.2s ease-in-out',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 0,
            height: '42px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            background: (state.isFocused || state.isSelected) ? '#292a33' : '#1f2026',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            border: '1px solid var(--primary4)',
            paddingBottom: '0px',
            marginTop: '5px',
            width: '90%',
            height: 'auto',
            boxShadow: 'none',
            zIndex: '100',
            overflow: 'hidden',
            background: '#1f2026',
            color: '#ffffff',
        }),
        menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            width: '',
            height: 'auto',
            boxShadow: 'none',
            color: '#ffffff',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            height: 'auto',
            boxShadow: 'none',
            borderRadius: 10,
            color: '#ffffff',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#ffffff',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.isFocused) ? '#ccdeff' : '#ffffff',
            "&:hover": {
                color: '#ccdeff'
            }
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
        }),
    }

    const select_styles_light = {
        input: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        noOptionsMessage: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),

        container: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),

        control: (baseStyles, state) => ({
            border: '1px solid #ffffff',
            background: '#ffffff',
            "&:hover": {
                border: '1px solid #ffffff',
                transition: '0.2s ease-in-out',
            },
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 12,
            height: '48px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            color: '#0e0e12',
            paddingLeft: '5px',
            zIndex: '100',
            transition: '0.2s ease-in-out',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 0,
            height: '42px',
            width: '100%',
            margin: '0',
            fontFamily: 'var(--font)',
            fontSize: '18px',
            fontWeight: '400',
            display: 'flex',
            alignItems: 'center',
            color: '#0e0e12',
            background: (state.isFocused || state.isSelected) ? '#fafafa' : '#ffffff',
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 12,
            border: '1px solid #f1f1f1',
            paddingBottom: '0px',
            marginTop: '5px',
            width: '90%',
            height: 'auto',
            boxShadow: 'none',
            zIndex: '100',
            overflow: 'hidden',
            background: '#ffffff',
            color: '#ffffff',
        }),
        menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            width: '',
            height: 'auto',
            boxShadow: 'none',
            color: '#0e0e12',
        }),
        menuList: (baseStyles, state) => ({
            ...baseStyles,
            padding: '0',
            height: 'auto',
            boxShadow: 'none',
            borderRadius: 10,
            color: '#0e0e12',
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: '#0e0e12',
        }),
        dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.isFocused) ? '#0e0e12' : '#292a33',
            "&:hover": {
                color: '#0e0e12'
            }
        }),
        indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: 'none',
        }),
    }


    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }


    const now = Date.now()


    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now())

        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);








    const totalPnl = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].pnl;
        }
        return total / arr.length;
    }

    const totalPnlUsdt = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + (arr[i].aum * arr[i].pnl / 100);
        }
        return total;
    }

    const totalAum = function(arr){
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            total = total + arr[i].aum;
        }
        return total;
    }




    useEffect(() => {

        setProductsIsSorting(true)

        setTimeout(() => {
            setStakingOutput1(earnProducts.sort((a, b) => (a.apr < b.apr) ? 1 : -1))
            setStakingOutput2(earnProducts.sort((a, b) => (a.apr < b.apr) ? 1 : -1))
        }, 310)



        setTimeout(() => {
            setProductsIsSorting(false)
        }, 3000)

    }, [])












    const [earnProductsType, setEarnProductsType] = useState(1)


    const handleEarnProductsTypeChange = (e) => {
        setEarnProductsType(e)
    }


    const [stakingOutput1, setStakingOutput1] = useState(earnProducts)
    const [stakingOutput2, setStakingOutput2] = useState(earnProducts)
    const [stakingFinal, setStakingFinal] = useState(earnProducts)

    const [productsInSight, setProductsInSight] = useState(6)



    let earnStakingCoins = [{value: 'All Crypto', label: 'All Crypto'}]
    const [stakingSelectedCoin, setStakingSelectedCoin] = useState({value: 'All Crypto', label: 'All Crypto'})

    for (let i = 0; i < earnProducts.length; i = i + 1) {
        earnStakingCoins.push(
            {
                value: (earnProducts[i]?.currency),
                label: (earnProducts[i]?.currency)
            }
        )
    }



    const handleStakingCoinChange = (e) => {
        setStakingSelectedCoin(e)
        setProductsIsSorting(true)

        if (e?.value === 'All Crypto') {

            setTimeout(() => {
                setStakingOutput1(earnProducts)
                setStakingFinal(earnProducts.filter(value => stakingOutput2.includes(value)))
            }, 310)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)

        } else {

            setTimeout(() => {
                setStakingOutput1(earnProducts.filter((item) => item.currency === e.value))
                setStakingFinal(earnProducts.filter((item) => item.currency === e.value).filter(value => stakingOutput2.includes(value)))
            }, 310)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)

        }


    }





    const [productsIsSorting, setProductsIsSorting] = useState(false)
    const [productsStakingType, setProductsStakingType] = useState({value: 'All Types'})

    /*useEffect(() => {
        setStakingOutput(earnProducts.sort((a, b) => (a.apr < b.apr) ? 1 : -1))
    }, [productsStakingType])*/


    const handleStakingTypesChange = (e) => {
        setProductsStakingType(e)

        setProductsIsSorting(true)

        if (e?.value === 'All Types') {

            setTimeout(() => {
                setStakingOutput2(earnProducts)
                setStakingFinal(stakingOutput1.filter(value => earnProducts.includes(value)))
            }, 1010)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)

        } else if (e?.value === 'Fixed') {

            setTimeout(() => {
                setStakingOutput2(earnProducts.filter((item) => (item.fifteen) || (item.thirty) || (item.sixty) || (item.ninety) || (item.hundredtwenty)))
                setStakingFinal(stakingOutput1.filter(value => earnProducts.filter((item) => (item.fifteen) || (item.thirty) || (item.sixty) || (item.ninety) || (item.hundredtwenty)).includes(value)))
            }, 1010)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)
        } else if (e?.value === 'ETH 2.0') {

            setTimeout(() => {
                setStakingOutput2(earnProducts.filter((item) => item.eth === true))
                setStakingFinal(stakingOutput1.filter(value => earnProducts.filter((item) => item.eth === true).includes(value)))
            }, 1010)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)
        } else if (e?.value === 'DeFi') {

            setTimeout(() => {
                setStakingOutput2(earnProducts.filter((item) => item.defi === true))
                setStakingFinal(stakingOutput1.filter(value => earnProducts.filter((item) => item.defi === true).includes(value)))
            }, 1010)

            setTimeout(() => {
                setProductsIsSorting(false)
            }, 2300)
        }



    }




    useEffect(() => {

        setTimeout(() => {
            setStakingFinal(stakingOutput1.filter(value => stakingOutput2.includes(value)))
        }, 1500)



        setProductsInSight(6)


    }, [/*productsStakingType, stakingSelectedCoin*/])







    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)


    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)

        setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 3000)
    }





    const [durationState, setDurationState] = useState({})


    return (
        <>
            <Navbar isBlack={true} />
            <div className={'trading-bots-page'}>

                <div className={'trading-bots-wrapper info-page-wrapper'}>

                    <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                        <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                        {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
                    </div>

                    <div className={'earn-box-header-container trading-bots-header-container'}>
                        <div className={'earn-box-header trading-bots-header'}>


                            <div className={'earn-box-header-h1'}>
                                <h1>
                                    EarnBox
                                </h1>

                                <h5>
                                    Invest and receive passive income without risk
                                </h5>


                                {/*<h3 className={'earn-box-header-h3'}>
                                    Don’t let your funds sit idle!
                                </h3>

                                <p>
                                    Увеличивайте свои сбережения, зарабатывайте и получайте пассивный доход от криптовалют просто оставаясь в экосистеме Liqui Global.
                                </p>*/}

                                <button onClick={() => featureNotAvailableActiveHandler()} className={'earn-box-header-btn btn light'}>
                                    Enable Auto-Earn
                                </button>
                            </div>

                            <img className={'earn-box-page-header-img'} src={earn_box}/>

                            <img className={'earn-box-page-header-img-abstract trading-bots-page-header-img-abstract'} src={abstract_2}/>


                        </div>

                    </div>

                    <div className={'trading-bots-container'}>


                        <h3 className={'mybots-h3'}>
                            Spotlight
                        </h3>

                        <div className={'mybots-wrapper'}>

                            <div className={'mybots-header'}>
                                <div className={'mybots-header-left'}>
                                    <h2 className={'mybots-h2 white'}>
                                        My Earn
                                    </h2>
                                </div>

                                <div className={'mybots-header-right'}>
                                    <button onClick={() => featureNotAvailableActiveHandler()} className={'btn light'}>
                                        Manage
                                    </button>
                                </div>

                            </div>


                            <p className={'mybots-p white'}>
                                Increase your savings, earn and receive passive income from cryptocurrencies simply by staying in the Liqui Global Ecosystem.
                            </p>


                            <div className={'mybots-container-big'}>
                                <div className={'mybots-container'}>

                                    {myearn_data.map((item, i) =>
                                        <div className={'myearn-item mybots-bot'}>

                                            <div className={`${item.type === 'Pool' ? 'double' : ''} mybots-bot-img-wrapper`}>

                                                <div className={`${item.type === 'Pool' ? 'fst' : ''} mybots-bot-img-container`}>
                                                    <img
                                                        className={'mybots-bot-img'}
                                                        src={logos.find((logo) => logo.currency === item.currency)?.logo}
                                                    />
                                                </div>

                                                {item.type === 'Pool' ?
                                                    <div className={`scd mybots-bot-img-container`}>
                                                        <img
                                                            className={'mybots-bot-img'}
                                                            src={logos.find((logo) => logo.currency === item.currency2)?.logo}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }

                                            </div>



                                            <div className={'myearn-item-top mybots-bot-top'}>

                                                <h3 className={'mybots-bot-top-h3 white'}>
                                                    {item.currency}{item.type === 'Pool' ? `/${item.currency2}` : ''} {item.type}
                                                    {/*<p className={'whitewhite'}>
                                                        Status: <span className={'bold'}>{item.status}</span>
                                                    </p>*/}
                                                </h3>

                                                <div className={'mybots-bot-top-type-container'}>
                                                    <p className={'mybots-bot-top-type'}>
                                                        {item.type_type}
                                                    </p>
                                                    <br/>
                                                    {/*<p className={'mybots-bot-top-type long'} style={item.type === 'DCA' ? {} : {display: 'none'}}>
                                                        {item.strategy}
                                                    </p>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.long ? 'Long' : 'Short'}
                                                    </p>
                                                    <p className={'mybots-bot-top-type long'} style={item.futures ? {} : {display: 'none'}}>
                                                        {item.leverage.toFixed(2)}x
                                                    </p>*/}
                                                </div>


                                            </div>

                                            <div className={'mybots-bot-mid-row'}>


                                                <div className={'mybots-bot-mid-row-left'}>
                                                    <p className={'mybots-bot-pnl-p white'}>
                                                        APR
                                                    </p>

                                                    <h3 className={'mybots-bot-pnl-h2 white'}>
                                                        {item.pnl.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}%
                                                    </h3>
                                                </div>

                                                <div className={'mybots-bot-mid-row-right'}>
                                                    <p className={'mybots-bot-pnl-p white'}>
                                                        AuM
                                                    </p>

                                                    <h3 className={'mybots-bot-pnl-h2 white'}>
                                                        {item.aum.toLocaleString()} USDT
                                                    </h3>
                                                </div>


                                            </div>

                                            <div className={'mybots-bot-mid-row'}>

                                                <div className={'mybots-bot-mid-row-left'}>
                                                    <p className={'mybots-bot-aum-p white'}>
                                                        Runtime
                                                    </p>

                                                    <h3 className={'mybots-bot-aum-h3 white'}>
                                                        {item.runtime}D {((now - item.date) / (1000*60*60) % 24).toFixed(0)}H {((now - item.date) / (1000*60) % 60).toFixed(0)}M
                                                    </h3>
                                                </div>

                                                <div className={'mybots-bot-mid-row-right'}>
                                                    <p className={'mybots-bot-aum-p white'}>
                                                        Duration
                                                    </p>

                                                    <h3 className={'mybots-bot-aum-h3 white'}>
                                                        {item.duration !== 0 ? `${item.duration}D` : 'Flexible'}
                                                    </h3>
                                                </div>

                                            </div>

                                            <div
                                                className={`myearn-duration-line ${item.duration !== 0 ? '' : 'full'}`}
                                                style={
                                                    {background: item.duration !== 0 ?
                                                        `linear-gradient(90deg, var(--primary2) 0%, var(--primary2) ${item.runtime / item.duration * 100}%, var(--primary4lightlight) ${item.runtime / item.duration * 100}%, var(--primary4lightlight) 100%)`
                                                        :
                                                        `var(--black0)`
                                                    }
                                            }
                                            >

                                            </div>


                                        </div>
                                    )}

                                </div>

                                <div className={'myearn-stats mybots-stats'}>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total AuM
                                        </p>
                                        <h2>
                                            {totalAum(myearn_data).toLocaleString()} USDT
                                        </h2>
                                        <h3>
                                            {(150682.7601 / totalAum(myearn_data)).toFixed(2)}% of Total Net Assets
                                        </h3>
                                    </div>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total Products
                                        </p>
                                        <h2>
                                            {myearn_data.length} Products
                                        </h2>
                                        <h3>
                                            Staking – 2, Pools – 1
                                        </h3>
                                    </div>

                                    <div className={'mybots-stats-item'}>
                                        <p>
                                            Total APR
                                        </p>
                                        <h2>
                                            +{totalPnl(myearn_data).toFixed(2)}%
                                        </h2>
                                        <h3>
                                            +{totalPnlUsdt(myearn_data).toLocaleString(undefined, {maximumFractionDigits: 2})} USDT
                                        </h3>
                                    </div>

                                </div>
                            </div>




                            <div className={'spotlight-add-wrapper'}>

                                <div className={'mybots-header'}>
                                    <div className={'mybots-header-left'}>
                                        <h2 className={'mybots-h2 white'}>
                                            Add More
                                        </h2>
                                    </div>

                                    <div className={'mybots-header-right'}>
                                        <button onClick={{/*() => {botMarketplaceRef.current?.scrollIntoView({block: "center"})}*/}} className={'btn light'}>
                                            Products
                                        </button>
                                    </div>

                                </div>

                                <p className={'mybots-p white'}>
                                    Choose your investment tool and Earn stable profits with professional asset management.
                                </p>



                                <div className={'spotlight-add-container'}>

                                    <div onClick={{/*() => {gridBotRef.current?.scrollIntoView({block: "center"})}*/}} className={'spotlight-add-item add-grid'}>

                                        <div className={'spotlight-add-item-text'}>

                                            <h3 className={''}>
                                                Staking
                                            </h3>

                                            <p className={''}>
                                                The simple way to Deposit & Earn. Stake assets at any time.
                                            </p>

                                        </div>

                                        <img className={'add-img-staking spotlight-add-item-img'} src={staking} />

                                        <img className={'spotlight-add-item-img-plus dark'} src={add_dark} />

                                    </div>

                                    <div onClick={{/*() => {dcaBotRef.current?.scrollIntoView({block: "center"})}*/}} className={'spotlight-add-item add-dca'}>

                                        <div className={'spotlight-add-item-text'}>

                                            <h3 className={'white'}>
                                                Liquidity Pools
                                            </h3>

                                            <p className={'white'}>
                                                Add liquidity to the pool and receive guaranteed rewards
                                            </p>

                                        </div>

                                        <img className={'add-img-lpools spotlight-add-item-img'} src={lpools} />

                                        <img className={'spotlight-add-item-img-plus primary2'} src={add_primary2} />

                                    </div>

                                </div>



                            </div>




                        </div>



                        <div className={'trading-bots-text'}>



                        </div>



                        <div className={'trading-bots-marketplace-wrapper'}>

                            <h1 className={'white'}>
                                All Products
                            </h1>

                            <p className={'trading-bots-marketplace-p white'}>
                                Automated tools for beginners with stable guaranteed returns, as well as complex investment tools for experienced users will make your income as profitable as possible.
                            </p>

                            <div className={'trading-bots-marketplace-types'}>

                                <div className={'dca grid trading-bots-marketplace-types-left-top'}>
                                    <p
                                        onClick={() => handleEarnProductsTypeChange(1)}
                                        className={`dca grid trading-bots-marketplace-types-item bold ${earnProductsType === 1 ? 'active' : ''}`}
                                    >
                                        Staking
                                    </p>
                                    <p
                                        onClick={() => handleEarnProductsTypeChange(2)}
                                        className={`dca grid trading-bots-marketplace-types-item bold ${earnProductsType === 2 ? 'active' : ''}`}
                                    >
                                        Liquidity Pools
                                    </p>
                                </div>

                                <div className={'grid trading-bots-marketplace-types-right'}>

                                    <div className={'grid trading-bots-marketplace-types-right-select-container'}>

                                        <Select
                                            className={`grid trading-bots-marketplace-types-right-select`}
                                            options={earnStakingTypes}
                                            onChange={handleStakingTypesChange}
                                            placeholder={`All Types`}
                                            styles={select_styles_light}
                                            isSearchable={false}
                                        />

                                        <Select
                                            className={`grid trading-bots-marketplace-types-right-select`}
                                            options={earnStakingCoins}
                                            onChange={handleStakingCoinChange}
                                            placeholder={`All Crypto`}
                                            styles={select_styles_light}
                                        />
                                    </div>

                                </div>

                            </div>





                            <div className={`earnproducts-wrapper ${stakingFinal.length === 0 ? 'nothing' : ''}`}>


                                <div className={`earnproducts-guide ${stakingFinal.length === 0 ? 'nothing' : ''}`}>
                                     <p className={'white'}>
                                         Currency
                                     </p>

                                    <p className={'white'}>
                                        APR
                                    </p>

                                    <p className={'white'}>
                                        Duration (Days)
                                    </p>

                                    <p className={'white'}>

                                    </p>

                                </div>


                                {
                                    stakingFinal.slice(0, productsInSight).map((item, i) =>
                                        <EarnBoxProduct item={item} productsIsSorting={productsIsSorting} />
                                    )

                                }

                                {stakingFinal.length === 0 ?
                                    <div className={`earnproducts-nothing`}>
                                        <h3 className={'white'}>
                                            Nothing Found Under Selected Filters
                                        </h3>

                                        {/*<button
                                            className={'btn light'}
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            Reset
                                        </button>*/}
                                    </div>
                                    :
                                    ''
                                }

                                {stakingFinal.length > productsInSight ?
                                    <div
                                        className={`viewmore earnproducts-item ${productsIsSorting ? 'sorting' : ''}`}
                                        onClick={() => setProductsInSight(productsInSight + 6)}
                                    >
                                        <h3 className={'white'}>
                                            View More
                                        </h3>
                                    </div>
                                    :
                                    ''
                                }


                            </div>

                            <div className={'trading-bots-marketplace-place'}>

                                <h3 className={''}>
                                    What Is Auto-Earn And How To Use It?
                                </h3>

                                <img className={'verify-widget-abstract2'} src={abstract_2} />

                                <button onClick={() => featureNotAvailableActiveHandler()} className={'btn dark'}>
                                    Learn More
                                </button>

                            </div>

                        </div>


                    </div>




                    <div className={'trading-bots-container'}>
                        <Link to={'/'} className={'info-page-home'}>
                            <img src={arrowrightbluedark} className={'info-paige-arrow-home dark'} />
                            <p className={'info-paige-p-home dark'}>Home</p>
                        </Link>


                        <h3 className={'white'} style={{marginTop: 'var(--blockmargin)'}}>
                            Might Be Interesting
                        </h3>

                        <Link to={'/trading-bots'} className={'tb plate trading-bots-plate'}>
                            <img src={trading_bots} className={'tb-in plate-img trading-bots-plate-img'}/>
                            <div className={'plate-text trading-bots-plate-text'}>

                                <div className={'plate-text-header'}>
                                    <h2>Trading Bots</h2>
                                    <p>
                                        Создавайте алгоритмы и автоматизируйте вашу торговую стратегию
                                    </p>
                                </div>


                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        DCA
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Grid
                                    </p>
                                </div>
                            </div>

                        </Link>
                        <VerificationWidget mt={20} />
                    </div>


                </div>

                {/*<MightBeInteresting current={8} dark={true}/>*/}

                <div className={'info-page-presale'}>
                    <LgtPresale />
                </div>

            </div>
            <Footer/>
        </>

    );
};

export default EarnBox;