import React from 'react';
import {Link} from "react-router-dom";
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import document from '../assets/document.svg'
import './Legacy.css'

const Legacy = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '10px', marginTop: '0px'}} className={'info-page-header-img'} src={document}/>
                    <h1>
                        Legacy
                        <h5 style={{margin: '0', paddingTop: '10px'}}>
                            «Every Ending is a New Beggining»
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>

                        <div className={'info-page-1-container'}>

                            <div className={'info-page-1-left'}>

                                <h2 className={'info-page-h2'}>
                                    <p style={{paddingBottom: '0', marginBottom: '-10px'}}>2016 July</p>
                                    CHAPTER I
                                </h2>

                                <p className={'info-page-p'}>
                                    Криптовалютная биржа Liqui была основанна в 2016 году группой разработчиков из украины и других европейских стран. На этапе создания, нашей основной задачей являлось создание торговой платформы, максимально простой для использования новичками рынка и предоставление широкого спектра торговых сервисов для уже опытных пользователей.
                                    <br/>
                                    <br/>
                                    Мы стремились предоставить безопасные, удобные и инновационные торговые инструменты. Мы были нацелены на удовлетворение всех потребности пользователей, включая торговлю, инвестирование, покупку, размещение, заимствование и многое другое. У нас получилось быстро вырасти из экспериментальной трейдинговой платформы в самый популярный альткоин маркет и обрести огромную популярность среди криптовалютных инвесторов и трейдеров.
                                    <br/>
                                    <br/>
                                    Возможность выбора из огромного количества криптовалют, особый узнаваемый дизайн, безупречный сервис смогли привлечь на платформу огромную аудиторию. К концу 2018 года, наша платформа насчитывала более 400 тысяч активных пользователей и возможность торговли более 600 криптовалютами.
                                </p>

                                <h2 className={'info-page-h2'}>
                                    <p style={{paddingBottom: '0', marginBottom: '-10px'}}>2018 January</p>
                                    CHAPTER II
                                </h2>

                                <p className={'info-page-p'}>
                                    После анализа рисков из за негативных рыночных факторов, вызвавших проблемы с ликвидностью, мы приняли тяжелое решение об окончании возможности предоставления услуг и уведомили пользователей об инициировании процесса вывода средств. Мы понимаем, что потеряли доверие части аудитории, но остались честны.
                                    <br/>
                                    <br/>
                                    Хотим выразить вам благодарность за огромную поддержку в то сложное время.
                                </p>


                                <h2 className={'info-page-h2'}>
                                    <p style={{paddingBottom: '0', marginBottom: '-10px'}}>2023</p>
                                    NEW CHAPTER
                                </h2>

                                <p className={'info-page-p'}>
                                    Мы всегда являлись противниками рыночных манипуляций для получения "агрессивной" выгоды за счет пользователей. Мы разработали и готовы предоставить широчайший спектр инновационных инструментов и высочайший уровень предоставления услуг и безопасности. Благодаря огромному практическому опыту, мы уверенны что несомненно сможем достичь успеха и установить новый уровень среди криптовалютных платформ.
                                    <br/>
                                    <br/>
                                    Мы долго оставались в тени и сейчас готовы вернуться и стать трейдинговой платформой номер один.
                                </p>

                            </div>

                            <div className={'info-page-1-right'}>

                                <div className={'info-page-lbsect'} style={{marginTop: '20px'}}>

                                    <h3>
                                        PATIENCE IS KEY
                                    </h3>

                                    <p className={'info-page-p'}>
                                        На рынке всегда кто то выигрывает, кто то проигрывает, но мы убеждены, что у всех участников рынка равные возможности. Благодаря нашим сервисам, любой новичок может стать опытным и создавать и использовать свои уникальные торговые стретегии.
                                    </p>

                                </div>

                            </div>

                        </div>



                    </div>

                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default Legacy;