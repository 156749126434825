import React, {useEffect, useRef, useState} from 'react';
import block1 from "../../assets/block1.svg";
import block1In from "../../assets/block1-in.svg";
import block1_back from "../../assets/block1_back.svg";
import plane from "../../assets/plane.svg";
import block1_front from "../../assets/block1_front.svg";
import lgt from '../../assets/lgt.svg'
import lgt_giftbox_vidget from '../../assets/float_right/lgt_giftbox_vidget.svg'
import planet from '../../assets/planet.svg'
import crator from '../../assets/crator.svg'
import emailIllustration from "../../assets/email.svg";
import './FirstBlock.css'
import axios from "axios";
import PostsCarousel from "./PostsCarousel";
import {Link} from "react-router-dom";



const FirstBlock = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [nameDirty, setNameDirty] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [formValid, setFormValid] = useState(false)

    const url = 'http://82.146.58.251'

    const blurHandler = (e) => {
        switch (e.target.value) {
            case 'email':
                setEmailDirty(true)
                break
            case 'name':
                setNameDirty(true)
                break
        }
    }

    useEffect(() => {
        if (emailError || nameError || name === '' || email === '') {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[email, name])

    const nameHandler = (e) => {
        setName(e.target.value)
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
    }

    const emailErrorHandler = (e) => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Please enter a valid email address')
        } else {
            setEmailError('')
        }

        if (e.target.value === '') {
            setEmailError('')
        }

    }

    const nameErrorHandler = (e) => {
        if (nameDirty && e.target.value.trim().length === 0) {
            setNameError('Please enter your name')
        } else {
            setNameError('')
        }

    }

    const inputRef = useRef()

    const handleKeypress = e => {
        if (e.keyCode === 13 && formValid) {
            sendData();
            inputRef.current.blur()
        }
    };

    const [successNotificationShowing, setSuccessNotificationShowing] = useState(false)
    const [containsNotificationShowing, setContainsNotificationShowing] = useState(false)
    const [errorNotificationShowing, setErrorNotificationShowing] = useState(false)

    const successNotification = () => {
        setSuccessNotificationShowing(true)
        setTimeout(() => {
            setSuccessNotificationShowing(false)
            setFormValid(true)
        }, 5000)
    }

    const containsNotification = () => {
        setContainsNotificationShowing(true)
        setTimeout(() => {
            setContainsNotificationShowing(false)
            setFormValid(true)
        }, 5000)
    }

    const errorNotification = () => {
        setErrorNotificationShowing(true)
        setTimeout(() => {
            setErrorNotificationShowing(false)
            setFormValid(true)
        }, 5000)
    }

    const sendData = () => {

        setFormValid(false)

        const options = {
            method: 'GET',
            url: `${url}/api/email`,
            params: {
                name: name,
                email: email
            }
        }

        axios.request(options)
            .then(function (response) {

                // alert(response.data)

                // if ((response.status > 199) && (response.status < 300)) {
                if (response.data === 200) {
                    successNotification()
                }
                if (response.data === 500) {
                    errorNotification()
                }
                if (response.data === 100) {
                    containsNotification()
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }




    return (

        <div className={'firstblock-wrapper'}>

            <div className={successNotificationShowing ? 'email-notification email-notification-active' : 'email-notification'}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3'}>
                        Hey, {name}. Thank you for subscribing.
                    </h3>

                    <p>
                        Please, check your inbox.
                    </p>
                </div>
            </div>

            <div className={containsNotificationShowing ? 'email-notification email-notification-active message' : 'email-notification message'}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3 message'}>
                        Hey, {name}
                    </h3>

                    <p>
                        You're already subscribed.
                    </p>
                </div>
            </div>

            <div className={errorNotificationShowing ? 'email-notification email-notification-active error' : 'email-notification error'}>
                <div className={'email-notification-text'}>
                    <h3 className={'email-notification-h3'}>
                        Sorry, {name}. Something went wrong.
                    </h3>

                    <p>
                        Try again later.
                    </p>
                </div>
            </div>


            <img className={'block1-back'} src={block1_back} />
            <img className={'block1-plane1'} src={plane} />
            <img className={'block1-plane2'} src={plane} />
            <img className={'block1-plane3'} src={plane} />
            <div className={'block1-gradient'}></div>
            <div className={'block1-inset-shadow'}></div>

            <div className={'body-team'}>

                <div className={'body-team-text'}>

                    <h1>Enjoy the Experience<br/> with <span className={'span-lg'}>Liqui Global</span></h1>


                    <img className={'block1-front'} src={block1_front} />
                    <img className={'block1-front-in'} src={block1_front} />




                    <h3 className={'body-team-text-h3'}>
                        Keep Up with the Latest News
                    </h3>

                    <div className={'body-team-text-input'}>
                        <p className={'input-p'}>
                            Your Name<span className={'form-page-important-star'}>*</span>
                        </p>
                        <input
                            onBlur={() => setNameDirty(true)}
                            onKeyDown={handleKeypress}
                            onChange={e => {nameHandler(e); nameErrorHandler(e)}}
                            className={'input light body-team-text-input1'}
                            ref={inputRef}
                            value={name}
                            name={'name'}
                            type={'text'}
                            placeholder={'Satoshi Nakamoto'}
                            autoComplete={'off'}
                        />

                        <p className={'input-p'}>
                            E-mail<span className={'form-page-important-star'}>*</span>
                        </p>
                        <input
                            onBlur={(e) => emailErrorHandler(e)}
                            onKeyDown={handleKeypress}
                            onChange={e => {emailHandler(e); emailErrorHandler(e)}}
                            className={'input light body-team-text-input1'}
                            ref={inputRef}
                            value={email}
                            name={'email'}
                            type={'email'}
                            placeholder={'satoshi@nakamoto.com'}
                            autoComplete={'off'}
                        />
                        <div className={'body-team-text-button-container'}>

                            <p>{!emailError ? nameError : emailError}&nbsp;</p>

                            <button
                                onClick={() => {sendData()}}
                                disabled={!formValid}
                                onKeyDown={handleKeypress}
                                className={'btn primary body-team-text-button'}
                                type={'submit'}
                            >
                                Subscribe
                            </button>
                        </div>

                    </div>
                </div>

            </div>


        </div>


    );
};

export default FirstBlock;