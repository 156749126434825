import React from 'react';
import './Reliability.css'
import clock from '../../assets/clock.svg'
import deposit from '../../assets/deposit.svg'


const Reliability = ({isInSecurity}) => {
    return (
        <div className={'reliability-wrapper'}>
            <div className={isInSecurity === true ? 'reliability-container-security' : 'reliability-container'}>

                <div className={'reliability-item reliability-item-1'}>
                    <img className={'reliability-item-img'} src={deposit} />
                    <div className={'reliability-text'}>
                        <h3>
                            Quick Deposit and Withdrawal
                        </h3>

                        <p>
                            {/*Для нас важно удобство и комфорт в работе с вашими крипто активами. Мы обеспечили максимально быструю и надёжную обработку внесения депозитов и выводов средств.*/}
                            Convenience and comfort in working with your crypto assets is important to us. We ensure the fastest and most reliable processing of deposits and withdrawals.
                        </p>
                    </div>
                </div>

                <div className={'reliability-item reliability-item-2'}>
                    <img className={'reliability-item-img'} src={clock} />
                    <div className={'reliability-text'}>
                        <h3  className={'white'}>
                            24/7 Customer Service
                        </h3>

                        <p className={'whitewhite'}>
                            {/*Наша служба поддержки всегда на связи и готова ответить на ваши вопросы. Свяжитесь с нами наиболее удобным для вас способом, мы оперативно и вежливо обработаем вашу заявку.*/}
                            Our support team is always online and ready to answer your questions. Contact us in a convenient way for you and we will process your application promptly and politely.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Reliability;