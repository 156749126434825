import React from 'react';
import './JoinCommunity.css'
import community from "../../assets/community.svg";

const JoinCommunity = ({mt0}) => {
    return (
        <div className={'join-community-wrapper'} style={mt0 ? {marginTop: '0'} : {}}>

            <div className={'join-community-container'}>

                <div className={"join-community"}>

                    <div className={'join-community-text'}>
                        <h2 className={'white'}>
                            Join Liqui Global's Community
                        </h2>
                        <h5  className={'whitewhite'}>
                            {/*Embrace Collaboration, Multiply Gains!*/}
                            Be Where it All Happens
                        </h5>
                    </div>

                    <img className={'join-community-img-in'} src={community} alt=""/>

                    <div className="join-community-buttons">
                        <a href={'https://t.me/LiquiGlobal_Announcements'} className={'btn'}>
                            Telegram
                        </a>

                        <a href={'https://twitter.com/LiquiGlobal'} className={'btn'}>
                            Twitter
                        </a>
                    </div>
                </div>

                <img className={'join-community-img'} src={community} alt=""/>

            </div>

        </div>
    );
};

export default JoinCommunity;