import React, {useEffect, useRef, useState} from 'react';
import './NFTBuilder.css'
import console from '../assets/nft_workshop_assets/console.svg'


import eth_logo from '../assets/nft_workshop_assets/nftbuilder/eth_logo.svg'
import sol_logo from '../assets/nft_workshop_assets/nftbuilder/sol_logo.svg'
import matic_logo from '../assets/nft_workshop_assets/nftbuilder/matic_logo.svg'
import imx_logo from '../assets/nft_workshop_assets/nftbuilder/imx_logo.svg'
import arb_logo from '../assets/nft_workshop_assets/nftbuilder/arb_logo.svg'
import avax_logo from '../assets/nft_workshop_assets/nftbuilder/avax_logo.svg'


import illustration from '../assets/nft_workshop_assets/nftbuilder/illustration.jpg';
import vector_art from '../assets/nft_workshop_assets/nftbuilder/vector_art.png';
import anime_movie from '../assets/nft_workshop_assets/nftbuilder/anime_movie.png';
import twonhalfD from '../assets/nft_workshop_assets/nftbuilder/25d.jpg';
import scifi from '../assets/nft_workshop_assets/nftbuilder/scifi.png';
import mecha from '../assets/nft_workshop_assets/nftbuilder/mecha.jpg';
import threed from '../assets/nft_workshop_assets/nftbuilder/threed.png';
import cartoon from '../assets/nft_workshop_assets/nftbuilder/cartoon.png';
import FeatureNotAvailable from "./components/FeatureNotAvailable";
import {Link} from "react-router-dom";
import cross from "../assets/cross_inv_fat.svg";



const NftBuilder = () => {

    const [chainState, setChainState] = useState(1)
    const [styleState, setStyleState] = useState(6)


    const [prompt, setPrompt] = useState('')
    const promptHandler = (e) => {
        setPrompt(e.target.value)
    }


    const [fileName, setFileName] = useState("");

    const handleFile = (file) => {
        if (file?.size > (1048576*20)) {
            setFileName("This File is Too Big");
        } else {
            setFileName(file?.name);
        }
    };

    const hiddenFileInput = useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    }





    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }


    return (
        <div className={'nft-page-builder-wrapper'}>

            <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
            </div>

            <div className={'nft-page-builder-container'}>

                <div className={'nft-page-builder-top'}>
                    <div className={'nft-page-builder-text'}>
                        <h1 className={'nft-page-builder-text-h2 white'}>
                            NFT Builder
                        </h1>
                        <p className={'nft-page-builder-text-p white'}>
                            Попробуйте созданный нами NFT конструктор и создайте цифровой шедевр. Реализовывайте свой творческий потенциал, создавайте уникальные авторские коллекции и зарабатывайте на этом.
                        </p>
                    </div>

                    <img src={console} className={'nft-page-builder-img'} />
                </div>


                <h2 className={'nft-page-builder-text-h5 white'}>
                    Try yourself as an artist
                </h2>

                <div className={'nft-page-builder-guide'}>

                    {/*<div className={'nft-page-builder-left-line'}>

                    </div>*/}

                    <div className={'nft-page-builder-guide-item'}>
                        {/*<div className={'nft-page-builder-left-ball'}>

                        </div>*/}
                        <h3 className={'nft-page-builder-guide-item-h3 white'}>
                            1. Select Network
                        </h3>
                        <div className={'nft-page-builder-guide-item-option-container chain'}>
                            <div
                                onClick={() => setChainState(1)}
                                className={(chainState === 1) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={eth_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Ethereum
                                </h5>
                            </div>
                            <div
                                onClick={() => setChainState(2)}
                                className={(chainState === 2) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={sol_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Solana
                                </h5>
                            </div>
                            <div
                                onClick={() => setChainState(3)}
                                className={(chainState === 3) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={matic_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Polygon
                                </h5>
                            </div>
                            <div
                                onClick={() => setChainState(4)}
                                className={(chainState === 4) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={imx_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Immutable X
                                </h5>
                            </div>
                            <div
                                onClick={() => setChainState(5)}
                                className={(chainState === 5) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={arb_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Arbitrum
                                </h5>
                            </div>
                            <div
                                onClick={() => setChainState(6)}
                                className={(chainState === 6) ? 'nft-page-builder-guide-item-option network active' : 'nft-page-builder-guide-item-option network'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container logo'}>
                                    <img className={'nft-page-builder-guide-item-option-img logo'} src={avax_logo} />
                                </div>
                                <h5 className={'black'}>
                                    Avalanche
                                </h5>
                            </div>
                        </div>
                    </div>


                    <div className={'nft-page-builder-guide-item'}>
                        {/*<div className={'nft-page-builder-left-ball'}>

                        </div>*/}
                        <h3 className={'nft-page-builder-guide-item-h3 white'}>
                            2. Choose Style
                        </h3>
                        <div className={'nft-page-builder-guide-item-option-container style'}>
                            <div
                                onClick={() => setStyleState(1)}
                                className={(styleState === 1) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={illustration} />
                                </div>
                                <h5 className={'black'}>
                                    Illustration
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(2)}
                                className={(styleState === 2) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={vector_art} />
                                </div>
                                <h5 className={'black'}>
                                    Vector Art
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(3)}
                                className={(styleState === 3) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={anime_movie} />
                                </div>
                                <h5 className={'black'}>
                                    Anime
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(4)}
                                className={(styleState === 4) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={twonhalfD} />
                                </div>
                                <h5 className={'black'}>
                                    2.5D
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(5)}
                                className={(styleState === 5) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={scifi} />
                                </div>
                                <h5 className={'black'}>
                                    Sci-Fi
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(6)}
                                className={(styleState === 6) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={mecha} />
                                </div>
                                <h5 className={'black'}>
                                    Mecha
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(7)}
                                className={(styleState === 7) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={threed} />
                                </div>
                                <h5 className={'black'}>
                                    3D
                                </h5>
                            </div>
                            <div
                                onClick={() => setStyleState(8)}
                                className={(styleState === 8) ? 'nft-page-builder-guide-item-option style active' : 'nft-page-builder-guide-item-option style'}
                            >
                                <div className={'nft-page-builder-guide-item-option-img-container style'}>
                                    <img className={'nft-page-builder-guide-item-option-img style'} src={cartoon} />
                                </div>
                                <h5 className={'black'}>
                                    Comics
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className={'nft-page-builder-guide-item'}>
                        {/*<div className={'nft-page-builder-left-ball'}>

                        </div>*/}
                        <h3 className={'nft-page-builder-guide-item-h3 white'}>
                            3. Describe Your Idea
                        </h3>
                        <div className={'nft-page-builder-guide-item-input-wrapper'}>
                            <textarea
                                className={`nft-page-builder-guide-item-input ${prompt === '' ? '' : 'active'}`}
                                name={'name'}
                                placeholder={'Enter Prompt'}
                                autoComplete={'off'}
                                rows={5}
                                value={prompt}
                                onChange={(e) => promptHandler(e)}
                            />

                            <div className={`nft-page-builder-guide-item-input-file ${fileName == "" || fileName == 0 || fileName == null ? '' : 'active'}`}>
                                <input
                                    type="file"
                                    name="image"
                                    accept=".gif, .jpeg, .jpg, .png, .svg, .eps"
                                    onChange={handleChange}
                                    ref={hiddenFileInput}
                                />
                                <div onClick={handleClick} className="nft-page-builder-guide-item-input-file-btn">Add Reference Image</div>
                                <div>
                                    {fileName == "" || fileName == 0 || fileName == null ? <div className={'nft-page-builder-guide-item-input-file-text'}>Max Size 20mb</div> :
                                        <div className="nft-page-builder-guide-item-input-file-text">
                                            <div className={'nft-page-builder-guide-item-input-file-text-text'}>{fileName}</div>
                                            <div
                                                onClick={() => {
                                                    setFileName()
                                                }}
                                                className={'nft-page-builder-guide-item-input-file-text-cross'}
                                            >
                                                +
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>

                    {/*<div className={'nft-page-builder-left-ball-end'}/>*/}

                    <button
                        onClick={() => featureNotAvailableActiveHandler()}
                        className={'btn nft-page-builder-guide-item-btn'}
                    >
                        Generate
                    </button>

                </div>


            </div>

        </div>
    );
};

export default NftBuilder;