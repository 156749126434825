import React from 'react';
import './Tokenomics.css'
import tokenomics from '../assets/tokenomics.svg'
import download from "../assets/download.svg";
import fees from "../assets/fees.svg";
import whitepaper from "../assets/lgt_whitepaper.svg";
import {Link} from "react-router-dom";
import BurningMechanism from "./BurningMechanism";

import fileDownload from 'js-file-download'
import axios from 'axios'


const Tokenomics = () => {


    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }


    return (
        <div className={'tokenomics-wrapper'}>

            <div className={'tokenomics-container'}>

                <img className={'tokenomics-img'} src={tokenomics} />


                <BurningMechanism />


                <a
                    className={'wpdl-wrapper'}
                    onClick={() => {
                        handleDownload('http://82.146.58.251/api/lg_assets/lgt.png', 'LGT_WhitePaper.png')
                    }}
                >

                    <div className={'wpdl-container'}>

                        <div className={'wpdl-text'}>

                            <div className={'wpdl-text-top'}>
                                <h3 className={''}>
                                    Download Full LGT WhitePaper Document
                                </h3>

                                <p className={''}>
                                    Study in detail tokenomics, features and utilities of Liqui Global Token in the full WhitePaper PDF file
                                </p>
                            </div>


                            {/*<Link to={'/fees-and-limits'}>
                        <button className={'btn primary feeslimitshome-btn'}>
                            Discover
                        </button>
                    </Link>*/}

                            {/*<img src={download} className={'wpdl influencer-arrow'} />*/}

                            <svg className={'wpdl influencer-arrow'} xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 30 30">
                                <path d="m15 21.3 7.1-7.1M15 21.3l-7.1-7.1M15 1.3v20" className="wpdl-arrow-move"/>
                                <path d="M2 16.6v9.1c0 1.7 1.4 3.1 3.1 3.1h19.8c1.7 0 3.1-1.4 3.1-3.1v-9.1" />
                            </svg>

                        </div>

                        <img src={whitepaper} className={'wpdl-img'} />

                    </div>

                </a>


            </div>

        </div>
    );
};

export default Tokenomics;