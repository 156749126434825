import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './InfoPage.css'
import './LeveragedETFs.css'
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";
import MightBeInteresting from "../MightBeInteresting";
import {dataEtfHard, dataGainerHard} from "./components/CoinDataHard";
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";
import CryptoTable from "./components/CryptoTable";
import MobileApp from "./components/MobileApp";
import JoinCommunity from "./components/JoinCommunity";
import {logos, sparklines} from "./TradingBotsData";
import axios from "axios";

import leveraged_etf from "../assets/leveraged_etf.svg";
import wallet from "../assets/deposit.svg";
import dynamic from "../assets/dynamic_primary.svg";
import abstract_header from "../assets/abstract_header.svg";

const LeveragedETFs = () => {

        document.body.scrollTo({
            top: 0,
        })

    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)
    const [featureNotAvailableClose, setFeatureNotAvailableClose] = useState(false)

    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)
        const featureNotAvailableTO = setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 7000)
    }





    const url = 'http://82.146.58.251'

    const [dataGainer, setDataGainer] = useState([])
    const [gainerToggle, setGainerToggle] = useState(1)

    const urlGainer = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h%2C24h%2C7d&locale=en'

    useEffect(() => {
        axios.get(`${url}/api/gainer_coin`)
            .then(response => {
                setDataGainer(JSON.parse(response.data))
            })
            .catch(error => console.log(error))
    }, [])


    dataGainer.sort((a, b) => (a.total_volume < b.total_volume) ? 1 : -1)

    /*useEffect(() => {
        dataGainer.shift()
    }, [])*/




    const [levFilter, setLevFilter] = useState(5)

    const [dataEtfHardFiltered, setDataEtfHardFiltered] = useState([])

    useEffect(() => {
        setDataEtfHardFiltered(dataEtfHard.filter((item) => item.leverage === levFilter))
    }, [levFilter])


    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header-container black2'}>
                    <div className={'filled info-page-header'}>


                        <div className={'academy-header-h1'}>

                            <h1 className={''}>
                                Leveraged ETFs
                            </h1>

                            <h5 className={''}>
                                {/*Торгуйте токенами с кредитным плечом без риска ликвидации позиций*/}
                                Trade tokens with leverage without the risk of liquidation
                            </h5>


                            <button onClick={() => featureNotAvailableActiveHandler()} className={'letf-header-btn earn-box-header-btn btn primary'}>
                                Trade ETFs
                            </button>

                        </div>


                        <img className={'info-page-header-img'} src={leveraged_etf} />

                    </div>

                    <img className={'aimonitor-header-img-abstract info-page-header-img-abstract'} src={abstract_header} />

                </div>




                <div className={'info-page-container'}>

                    <div className={'h1-btn'}>

                        <h1>
                            {/*Специальные навыки использования и платные подписки на индикаторы больше не нужны*/}
                            {/*Special usage skills and paid subscriptions to indicators are no longer needed*/}
                            Margin Without Loan
                        </h1>

                        <button className={'btn primary flexible'}>
                            View All (160)
                        </button>

                    </div>

                    <p className={'letf-content-p'}>
                        {/*Торговля токенами ETF происходит на спотовом рынке, и приносит прибыль, эквивалентную торговле контрактами. В отличие от маржинальной торговли, вы никогда не столкнетесь с проблемой ликвидации, даже при сильной волатильности рынка что значительно снижает риски для новичков рынка.*/}
                        ETF tokens are traded on the spot market and generate profits equivalent to contract trading. Unlike margin trading, you will never face the problem of liquidation, even with strong market volatility, which significantly reduces the risks for market newcomers.
                    </p>





                    <div className={'nft-marketplace-features'}>


                        <div className={'primary nft-marketplace-features-item'}>
                            <div className={'nft-marketplace-features-item-text'}>

                                <div className={'nft-marketplace-features-item-text-top'}>
                                    <h3 className={'white nft-marketplace-features-item-h3'}>
                                        Leveraged ETF Token
                                    </h3>
                                    <p className={'white nft-marketplace-features-item-p'}>
                                        {/*это торговый инструмент, дающий возможность получать увеличенную прибыль за счет кредитного плеча но без риска ликвидации.*/}
                                        is a trading tool that makes it possible to receive increased profits due to leverage but without the risk of liquidation.
                                    </p>
                                </div>

                                <img className={'nft-marketplace-features-item-img-in'} src={dynamic} />


                                <div className={'nft-marketplace-features-item-text-bot'}>
                                    <button className={`btn light`}>
                                        Trade
                                    </button>
                                </div>


                            </div>

                            <img className={'nft-marketplace-features-item-img'} src={dynamic} />
                        </div>


                        <div className={'black2 nft-marketplace-features-item'}>
                            <div className={'nft-marketplace-features-item-text'}>

                                <div className={'nft-marketplace-features-item-text-top'}>
                                    <h3 className={'nft-marketplace-features-item-h3'}>
                                        Create Portfolio
                                    </h3>
                                    <p className={'nft-marketplace-features-item-p'}>
                                        {/*Анализируйте и оценивайте перспективность криптовалют*/}
                                        Build your own leverage portfolios directly on spot trading.
                                    </p>
                                </div>

                                <img className={'nft-marketplace-features-item-img-in'} src={wallet} />


                                <div className={'nft-marketplace-features-item-text-bot'}>
                                    <h2 className={'nft-marketplace-features-item-text-bot-h2'}>
                                        <span style={{color: '#1b78ff'}}>160 Tokens To Choose</span>
                                    </h2>
                                </div>


                            </div>

                            <img className={'nft-marketplace-features-item-img'} src={wallet} />

                        </div>


                    </div>





                    <div className={'aimonitor-suggested-container'}>

                                <h3 className={'aimonitor-suggested-h3'}>
                                    Profitable Now
                                </h3>

                            <div className={`coins-market-gainer ${gainerToggle === 1 ? '' : 'loser'}`} style={{background: '#fafafa', position: 'relative'}}>

                                <div className={'coins-market-gainer-container-left'}>

                                    <div className={'letf-primary-logo-container coins-market-gainer-container-left-img-container'}>
                                        <img className={`letf-primary-logo coins-market-gainer-container-left-img ${gainerToggle === 1 ? '' : 'none'}`} src={logos.find((logo) => logo.currency === 'LGT')?.logo} />
                                    </div>


                                    <div className={'coins-market-gainer-container-left-text'}>
                                        <div className={'coins-market-gainer-container-left-text-top'}>
                                            <p className={'coins-market-gainer-name'}>Liqui Global Token</p>
                                            <h2 style={{color: 'var(--black100)'}}>LGTUP</h2>
                                        </div>



                                        <div className={'coins-market-gainer-container-left-text-mc'}>
                                            <p>Net Asset Value</p>
                                            <h3>
                                                2.10926 USDT
                                            </h3>
                                        </div>

                                        <div className={'coins-market-gainer-container-left-text-mc cp'}>
                                            <p className={''}>
                                                Last Price
                                            </p>
                                            <h2 className={''}>
                                                2.11327 USDT
                                            </h2>
                                        </div>

                                    </div>

                                </div>

                                <div className={'coins-market-gainer-container-right'}>
                                    <div
                                        className={`coins-market-gainer-container-right-time`}
                                    >

                                        <div className={'coins-market-gainer-container-right-time-item'}>
                                            <p>Leverage</p>
                                            <h3 className={``}>9.385x</h3>
                                        </div>

                                        <div className={'coins-market-gainer-container-right-time-item'}>
                                            <p>LGT 24h</p>
                                            <h3 className={`blue`}>+1.49%</h3>
                                        </div>

                                        <div className={'coins-market-gainer-container-right-time-item'}>
                                            <p>LGTUP 24h</p>
                                            <h3 className={`blue`}>+13.98%</h3>
                                        </div>


                                    </div>

                                    <div>
                                        <p className={'coins-market-gainer-container-right-price'}>Last Price</p>
                                        <h2 className={'coins-market-gainer-container-right-h2'}>2.11327 USDT</h2>
                                    </div>
                                </div>

                                <div className={'gainer-sparkline-container'}>
                                    <p>7D</p>
                                    <div className={'gainer-sparkline'}>
                                        <Sparklines className={'gainer-sparkline'} data={sparklines[15]}>
                                            <SparklinesCurve className={'gainer-sparkline-top'} style={{fill: 'none', strokeWidth: '0.6'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                        </Sparklines>
                                    </div>
                                    <div className={`${gainerToggle === 1 ? '' : 'red'} gainer-sparkline-bott`}>
                                        <Sparklines data={sparklines[15]}>
                                            <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                        </Sparklines>
                                    </div>
                                </div>


                            </div>





                        <div className={'letf-filter-container'}>

                                <p className={'letf-content-p-ratio'}>
                                    {/*ETF токены на Liqui Global привязаны к доступным криптовалютам и отслеживают их цены в соотношении:*/}
                                    ETF tokens on Liqui Global are tied to available cryptocurrencies and track their prices in the ratio:
                                </p>


                                <div className={`grid trading-bots-marketplace-types-left-bott ${levFilter === 1 ? 'disabled' : ''}`}>

                                    <p
                                        onClick={() => setLevFilter(3)}
                                        className={`letf-black2 grid trading-bots-marketplace-types-item bold ${levFilter === 3 ? 'active' : ''}`}
                                    >
                                        3x
                                    </p>
                                    <p
                                        onClick={() => setLevFilter(5)}
                                        className={`letf-black2 grid trading-bots-marketplace-types-item bold ${levFilter === 5 ? 'active' : ''}`}
                                    >
                                        5x
                                    </p>
                                    <p
                                        onClick={() => setLevFilter(10)}
                                        className={`letf-black2 grid trading-bots-marketplace-types-item bold ${levFilter === 10 ? 'active' : ''}`}
                                    >
                                        10x
                                    </p>

                                </div>

                        </div>


                        <CryptoTable array={dataEtfHardFiltered} minusFirst={false} length={8} action={'Trade'} leverages />


                    </div>



                    <Link to={'/'} className={'info-page-home'}>
                        {/*<img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>*/}
                        <button className={'btn black2'} style={{minWidth: '150px'}}>
                            Go Home
                        </button>
                    </Link>




                    <MobileApp />



                </div>

            </div>

            <MightBeInteresting current={5} mt={true} />

            <JoinCommunity  mt0={true} />

        </div>
    );
};

export default LeveragedETFs;