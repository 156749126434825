import one from '../assets/clone_trading_assets/1.jpg'
import two from '../assets/clone_trading_assets/2.jpg'
import three from '../assets/clone_trading_assets/3.png'




export const myClonesData = [
    {
        userName: 'Alexander Romero',
        userImg: one,
        userProfitShare: 20,
        userIsExpert: true,
        userMode: 'All Trades',
        userCurrentClones: 147,
        userMaximumClones: 200,
    },
    {
        userName: 'blockchainHero',
        userImg: two,
        userProfitShare: 25,
        userIsExpert: false,
        userMode: 'All Trades',
        userCurrentClones: 172,
        userMaximumClones: 200,
    },
    {
        userName: 'Crypto Ellis',
        userImg: three,
        userProfitShare: 25,
        userIsExpert: true,
        userMode: '20 Trades/Day',
        userCurrentClones: 38,
        userMaximumClones: 200,
    },
]