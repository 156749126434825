import React from 'react';
import {Link} from "react-router-dom";
import './MightBeInteresting.css'
import nft_workshop from "./assets/nft_workshop.svg";
import launchpad_field from "./assets/launchpad_field.svg";
import ai_gem_monitor from "./assets/ai_gem_monitor.svg";
import liquidation_shield from "./assets/liquidation_shield.svg";
import earn_box from "./assets/earn_box.svg";
import leveraged_etf from "./assets/leveraged_etf.svg";
import crypto_academy from "./assets/crypto_academy.svg";
import trading_bots from "./assets/trading_bots.svg";
import earnbox_pig from "./assets/earnbox-pig.svg";
import IntroducingLgt from "./pages/components/IntroducingLgt";

const MightBeInteresting = ({current, dark, mt, fixed}) => {

    let mbiFirstItem = 1
    let mbiSecondItem = 3
    let mbiThirdItem = 1

    if (fixed) {
        mbiFirstItem = 1
        mbiSecondItem = 3
        mbiThirdItem = 1
    } else {
        let mbiFirstItem = Math.floor(Math.random()*5) + 1
        let mbiSecondItem = Math.floor(Math.random()*5) + 1
        let mbiThirdItem = Math.floor(Math.random()*2) + 1


        if (mbiFirstItem === current) {
            mbiFirstItem = current + 1
        }

        if (mbiSecondItem === current) {
            mbiSecondItem = current + 1
        }


        if (mbiFirstItem === mbiSecondItem) {
            mbiSecondItem = mbiSecondItem + 1
        }
    }

    return (
        <div className={`mbi ${mt ? 'mt' : ''}`}>

            <h1 className={dark ? 'white mbi-h1' : 'mbi-h1'}>Might be Interesting</h1>
            <div className={dark ? 'mbi-plates-dark' : 'mbi-plates'}>

                <Link to={'/nft-workshop'} className={dark ? 'mbi-plate-dark' : 'mbi-plate'} style={(mbiFirstItem === 1 || mbiSecondItem === 1 || mbiSecondItem === 9) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={nft_workshop} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>NFT Workshop</h3>
                        <p className={dark ? 'white' : ''}>
                            Уникальная платформа для конструирования и торговли NFT
                        </p>
                    </div>

                </Link>

                <Link to={'/launchpad-field'} className={dark ? 'mbi-plate-dark' : 'mbi-plate'} style={(mbiFirstItem === 2 || mbiSecondItem === 2) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={launchpad_field} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Launchpad Field</h3>
                        <p className={dark ? 'white' : ''}>
                            Платформа для помощи в привлечении инвестиций для молодых проектов
                        </p>
                    </div>

                </Link>

                <Link to={'/ai-gem-monitor'} className={dark ? 'mbi-plate-dark primary' : 'mbi-plate primary'} style={(mbiFirstItem === 3 || mbiSecondItem === 3) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={ai_gem_monitor} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>AI Gem monitor</h3>
                        <p className={dark ? 'white' : ''}>
                            Find the next crypto Gem and create your perfect cryptocurrency portfolio
                        </p>
                    </div>

                </Link>

                <Link to={'/liquidation-shield'} className={dark ? 'mbi-plate-dark' : 'mbi-plate'} style={(mbiFirstItem === 4 || mbiSecondItem === 4) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={liquidation_shield} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Liquidation Shield</h3>
                        <p className={dark ? 'white' : ''}>
                            Защитите свои маржинальные позиции от ликвидации
                        </p>
                    </div>

                </Link>

                <Link to={'/leveraged-etfs'} className={dark ? 'mbi-plate-dark' : 'mbi-plate'} style={(mbiFirstItem === 5 || mbiSecondItem === 5) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={leveraged_etf} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Leveraged ETFs</h3>
                        <p className={dark ? 'white' : ''}>
                            Торгуйте токенами с кредитным плечом без риска ликвидации позиций
                        </p>
                    </div>

                </Link>

                <Link to={'/crypto-academy'} className={dark ? 'mbi-plate-dark primary' : 'mbi-plate primary'} style={(mbiFirstItem === 6 || mbiFirstItem === 7 || mbiSecondItem === 6) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={crypto_academy} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Crypto Academy</h3>
                        <p className={dark ? 'white' : ''}>
                            Стань экспертом криптовалютной торговли вместе с предодавателями нашей крипто академии
                        </p>
                    </div>

                </Link>


                {/*<Link to={'/earnbox'} className={dark ? 'mbi-plate-dark primary2' : 'mbi-plate primary2'} style={(mbiFirstItem === 5 || mbiSecondItem === 5) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={earn_box} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Earn Box</h3>
                        <p className={dark ? 'white' : ''}>
                            Инвестируйте и получайте пассивный доход без рисков
                        </p>
                    </div>

                </Link>*/}

                {/*<Link to={'/ai-gem-monitor'} className={dark ? 'mbi-plate-dark primary' : 'mbi-plate primary'} style={(mbiFirstItem === 5 || mbiSecondItem === 5) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={ai_gem_monitor} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>AI Gem monitor</h3>
                        <p className={dark ? 'white' : ''}>
                            Find the next crypto Gem and create your perfect cryptocurrency portfolio
                        </p>
                    </div>

                </Link>*/}


                {/*<Link to={'/trading-bots'} className={dark ? 'mbi-plate-dark primary2' : 'mbi-plate primary2'} style={(mbiFirstItem === 8 || mbiSecondItem === 8) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={trading_bots} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Trading Bots</h3>
                        <p className={dark ? 'white' : ''}>
                            Создавайте алгоритмы и автоматизируйте вашу торговую стратегию
                        </p>
                    </div>

                </Link>*/}


                {/*<Link to={'/liquidation-shield'} className={dark ? 'mbi-plate-dark' : 'mbi-plate'} style={(mbiFirstItem === 8 || mbiSecondItem === 8) ? {display: 'flex'} : {display: 'none'}}>
                    <img src={liquidation_shield} className={'mbi-plate-img'}/>
                    <div className={'mbi-plate-text'}>
                        <h3 className={dark ? 'white' : ''}>Liquidation Shield</h3>
                        <p className={dark ? 'white' : ''}>
                            Защитите свои маржинальные позиции от ликвидации
                        </p>
                    </div>

                </Link>*/}


            </div>



            <div className={'mbi-lgt-container'}>
                <IntroducingLgt />
            </div>



            <div className={'mbi-bottom-container'}>

                {
                    mbiThirdItem === 1 ?

                        <Link to={'/earnbox'} className={'tb earnbox-plate plate'} style={{zIndex: '10', marginTop: '0'}}>
                            <div className={'earnbox-plate-text plate-text'}>
                                <div className={'plate-text-header'}>
                                    <h2>EarnBox</h2>
                                    <p>
                                        Инвестируйте и получайте пассивный доход без рисков
                                    </p>
                                </div>

                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Staking
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Liquidity Pools
                                    </p>
                                </div>

                            </div>

                            <img src={earnbox_pig} className={'tb earnbox-plate-img'}/>

                        </Link>


                        :


                        <Link to={'/trading-bots'} className={'tb plate trading-bots-plate'} style={{zIndex: '10', marginTop: '0'}}>
                            <img src={trading_bots} className={'tb-in plate-img trading-bots-plate-img'}/>
                            <div className={'plate-text trading-bots-plate-text'}>

                                <div className={'plate-text-header'}>
                                    <h2>Trading Bots</h2>
                                    <p>
                                        Создавайте алгоритмы и автоматизируйте вашу торговую стратегию
                                    </p>
                                </div>


                                <div className={'plate-text-chapters'}>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        DCA
                                    </p>
                                    <p className={'plate-text-chapters-item light whitewhite'}>
                                        Grid
                                    </p>
                                </div>
                            </div>

                        </Link>


                }

            </div>







            <div className={'mbi-back'}>

            </div>

        </div>
    );
};

export default MightBeInteresting;