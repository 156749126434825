import React, {useState} from 'react';
import './CookieConsent.css'
import cookie from "../../assets/cookie.svg";
import {Link} from "react-router-dom";
import FloatingGiftbox from "./FloatingGiftbox";
import {useCookies} from "react-cookie";

const CookieConsent = ({light=true}) => {

    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/", maxAge: 6000});
    };

    return (
        <div
            className={!cookies.cookieConsent ? (light ? 'cookie-consent-wrapper' : 'cookie-consent-wrapper dark') : (light ? 'cookie-consent-wrapper' : 'cookie-consent-wrapper dark hidden')}
        >

                <div className={'cookie-consent-content'}>
                    {/*<img
                        src={cookie}
                        className={'cookie-consent-img'}
                    />*/}

                    <div className={'cookie-consent-text'}>
                        <h5
                            className={'cookie-consent-h3'}
                        >
                            Cookie Consent
                        </h5>

                        <p
                            className={'cookie-consent-p'}
                        >
                            We use cookie settings to enhance your user experience. Learn more about <Link to={'/privacy-policy'} className={'primary bold'}>Privacy Policy</Link>
                        </p>
                    </div>

                </div>

                <div className={'cookie-consent-buttons'}>
                    <button
                        className={!cookies.cookieConsent ? (light ? 'btn full primary cookie-consent-button' : 'btn full primary cookie-consent-button') : (light ? 'btn full primary2 cookie-consent-button' : 'btn full primary2 cookie-consent-button hidden')}
                        onClick={giveCookieConsent}
                    >
                        <div>
                            Accept All
                        </div>
                    </button>
                    <button
                        className={!cookies.cookieConsent ? (light ? 'btn full black2 cookie-consent-button' : 'btn full black2 cookie-consent-button') : (light ? 'btn full black2 cookie-consent-button' : 'btn full black2 cookie-consent-button hidden')}
                        onClick={giveCookieConsent}
                    >
                        <div>
                            Only Required
                        </div>
                    </button>
                </div>

        </div>
    );
};

export default CookieConsent;