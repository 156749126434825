import React from 'react';
import {Link} from "react-router-dom";
import document from '../assets/document.svg'
import arrowrightblue from "../assets/arrowrightblue.svg";

const PrivacyPolicy = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '10px', marginTop: '-20px'}} className={'info-page-header-img'} src={document} />
                    <h1>
                        Privacy Policy
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text terms-of-use-container'}>

                        <p className={'info-page-p'}>
                            This Privacy Policy explains how information about you is collected, used, and disclosed by Liqui Global ("Liqui Global", "we" or "us"). This Privacy Policy applies to information we collect when you use our website and other online products and services that link to this Privacy Policy (collectively, the "Services").
                        </p>

                        <p className={'info-page-p'}>
                            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the ways you can help protect your privacy.
                        </p>





                        <h2 className={'info-page-h2'}>
                            Collection of information
                        </h2>




                        <h5 className={'info-page-h3'}>
                            Information You Provide to Us：
                        </h5>

                        <p className={'info-page-p'}>
                            We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive feature of the Services, fill out a form, participate in a community or forum discussion, complete an exchange transaction, apply for a job, request customer support or otherwise communicate with us. The types of information we may collect include your name, social security number or other government ID number, date of birth, email address, postal address, phone number, certain virtual currency information, and any other information you choose to provide.
                        </p>

                        <h5 className={'info-page-h3'}>
                            When you access or use our Services, we automatically collect information about you, including:
                        </h5>

                        <p className={'info-page-p'}>
                            Log Information:
                        </p>
                        <br/>

                        <p>
                            We log information about your use of the Services, including the type of browser you use, access time, pages viewed, your IP address, and the page you visited before navigating to our Services.
                        </p>

                        <p className={'info-page-p'}>
                            Device Information:
                        </p>
                        <br/>

                        <p>
                            We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
                        </p>

                        <p className={'info-page-p'}>
                            Exchange information:
                        </p>
                        <br/>

                        <p>
                            We collect information about the exchange transactions you complete on the Liqui Global website, including the amount of virtual currency associated with an exchange transaction, the types of exchanges executed, and other transaction information.
                        </p>

                        <h5 className={'info-page-h3'}>
                            Information Collected by Cookies and Other Tracking Technologies:
                        </h5>

                        <p className={'info-page-p'}>
                            We and our service providers may use various technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular and count visits, manage the registration process for accounts, remember your site preferences, retain certain information to process orders for exchange transactions, and retain information to provide you with support. Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness.
                        </p>

                        <h5 className={'info-page-h3'}>
                            Information We Collect From Other Sources:
                        </h5>

                        <p className={'info-page-p'}>
                            We may also obtain information from other sources and combine that with information we collect through our Services. For example, we may collect information about you from third parties, including but not limited to social media platforms and publicly available sources.
                        </p>





                        <h2 className={'info-page-h2'}>
                            Use of information
                        </h2>




                        <h5 className={'info-page-h3'}>
                            We may use information about you for various purposes, including to:
                        </h5>

                        <ul className={'ul-dot'}>

                            <li>
                                Provide, maintain, and improve our Services;
                            </li>

                            <li>
                                Provide and deliver the products and services you request, process transactions, and send you related information, including confirmations and invoices;
                            </li>

                            <li>
                                Send you technical notices, updates, security alerts, and support and administrative messages;
                            </li>

                            <li>
                                Send you promotional offers and other marketing material;
                            </li>

                            <li>
                                Respond to your comments, questions and requests, and provide customer service;
                            </li>

                            <li>
                                Resolve disputes and troubleshoot problems;
                            </li>

                            <li>
                                Monitor and analyze trends, usage, and activities in connection with our Services;
                            </li>

                            <li>
                                Link or combine with information we get from others to help understand your needs and provide you with better service;
                            </li>

                            <li>
                                Carry out any other reasonable purpose within the LAW.
                            </li>

                        </ul>





                        <h2 className={'info-page-h2'}>
                            Sharing of information
                        </h2>





                        <h5 className={'info-page-h3'}>
                            We may share information about you as follows or as otherwise described in this Privacy Policy:
                        </h5>


                        <ul  className={'ul-dot'}>

                            <li>
                                With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;
                            </li>

                            <li>
                                In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation or legal process, or as otherwise required by any applicable law, rule or regulation;
                            </li>

                            <li>
                                If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of Liqui Global or others;
                            </li>

                            <li>
                                In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company and
                                with your consent or at your direction.
                            </li>

                            <li>
                                We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
                            </li>

                        </ul>





                        <h2 className={'info-page-h2'}>
                            Security
                        </h2>





                        <p className={'info-page-p'}>
                            We take the protection of your personal information seriously. We use industry-standard data encryption technology and have implemented restrictions related to the storage of and the ability to access your personal information.
                        </p>

                        <p className={'info-page-p'}>
                            Please note that no transmission over the Internet or method of electronic storage can be guaranteed to be 100% secure.
                        </p>





                        <h2 className={'info-page-h2'}>
                            Contact us
                        </h2>




                        <p className={'info-page-p'}>
                            If you have any questions about our Privacy Policy as outlined above, please contact us through our support center.
                        </p>


                    </div>

                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>



        </div>
    );
};

export default PrivacyPolicy;