import React from 'react';
import {Link} from "react-router-dom";
import career2 from "../assets/career2.svg";
import arrowrightblue from "../assets/arrowrightblue.svg";
import LgtPresale from "./components/LgtPresale";

const Career = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>

                <div className={'info-page-header'}>

                    <img style={{marginRight: '10px', marginTop: '-5px'}} className={'info-page-header-img'} src={career2}/>
                    <h1>
                        Career
                        <h5 style={{margin: '0', paddingTop: '10px'}}>
                            Станьте частью семьи Liqui Global
                        </h5>
                    </h1>

                </div>

                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>

                        <div className={'info-page-1-container'} style={{marginTop: '30px'}}>

                            <div className={'info-page-1-left'}>

                                <p className={'info-page-p'}>
                                    Мы верим, что можно многого добиться, дав возможность проявить себя умным и целеустремленным людям. Независимо от того, откуда мы родом, каждый обладает своим уникальным опытом и индивидуальностью.
                                </p>

                                <p className={'info-page-p'}>
                                    Мы создали глобальную инфраструктуру, состоящую из лучших специалистов своей сферы и предлагаем стать частью команды. Хотите стать частью семьи и строить децентразованное будущее вместе?
                                </p>

                                <h3 className={'info-page-h3'}>
                                    Работа в команде Liqui Global значит:
                                </h3>

                                <ul className={'ul-dot'}>
                                    <li>
                                        Взаимодействать и обмениваться опытом с лучшими экспертами по всему миру
                                    </li>
                                    <li>
                                        Постоянно развиваться и улучшать свои навыки
                                    </li>
                                    <li>
                                        Получать неограниченную заработную плату, бонусы и  вознаграждения
                                    </li>
                                    <li>
                                        Брать на себя ответственность за свои проекты и создавать глобальную ценность для пользовалетей
                                    </li>

                                </ul>

                            </div>

                            <div className={'info-page-1-right'} style={{marginTop: '20px'}}>

                                <div className={'info-page-lbsect'} style={{marginTop: '0'}}>
                                    <h3>
                                        Добро пожаловать в семью
                                    </h3>

                                    <p  className={'info-page-p'}>
                                        Список вакансий будет обновляться.
                                        <br/>
                                        Отправляйте нам свое резюме:
                                        <br/>
                                        <a className={'info-page-underlined-a'} href={'mailto:team@liqui.global'}>team@liqui.global</a>
                                    </p>
                                </div>

                            </div>

                        </div>


                    </div>



                    <Link to={'/'} className={'info-page-home'}>
                        <img src={arrowrightblue} className={'info-paige-arrow-home'} />
                        <p className={'info-paige-p-home'}>Home</p>
                    </Link>

                </div>

            </div>

            <div className={'info-page-presale'}>
                <LgtPresale />
            </div>

        </div>
    );
};

export default Career;