import React from 'react';
import crypto_academy from "../assets/crypto_academy_dark.svg";
import {Link} from "react-router-dom";

const TradingBotsLearn = () => {
    return (
        <div className={'preliminary-listing-wrapper'}>
            <div className={'wide preliminary-listing-container'}>

                <img src={crypto_academy} className={'preliminary-listing-img'} />

                <div className={'preliminary-listing-text'}>

                    <div>
                        <h2 className={'preliminary-listing-h2'}>
                            Learn Trading Bots In Crypto Academy
                        </h2>

                    </div>

                    <div className={'preliminary-listing-img-in-container'}>
                        <img src={crypto_academy} className={'preliminary-listing-img-in-in'} />
                    </div>


                    <p style={{padding: 0}} className={'preliminary-listing-block-p'}>
                        A unique step-by-step training system from our crypto academy will teach you how to effectively earn money and increase your capital.
                    </p>

                    <Link to={'/listing-application'} className={'btn light apply-btn'}>
                        Explore
                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                            <path
                                fill={'var(--primary4darkdark)'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>*/}
                    </Link>

                </div>

            </div>
        </div>
    );
};

export default TradingBotsLearn;