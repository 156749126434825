import React, {useEffect, useState, useCallback} from 'react';
import {Sparklines, SparklinesCurve} from "@jrwats/react-sparklines";
import './CoinsMarket.css'
import axios from "axios";
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import preliminary_listing from '../../assets/preliminary_listing.svg'
import arrow from '../../assets/arrowright.svg'
import {logDOM} from "@testing-library/react";
import {Link} from "react-router-dom";
import PreliminaryListing from "./PreliminaryListing";
import GainerJSON from '../../assets/gainer_coin.json'
import FeesLimitsHome from "./FeesLimitsHome";


const url = 'http://94.250.254.177'
// const url = 'http://localhost:8800'

const CoinsMarket = () => {

    const [dataTop, setDataTop] = useState([])

    const urlTop = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2C%20ethereum%2C%20ripple%2C%20litecoin&order=market_cap_desc&per_page=100&page=1&sparkline=true&locale=en'

    useEffect(() => {
        axios.get(`${url}/api/top_coin`)
            .then(response => setDataTop(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])



    const [dataGainer, setDataGainer] = useState([])
    const [dataLoser, setDataLoser] = useState([])

    const urlGainer = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=volume_desc&per_page=200&page=1&sparkline=true&price_change_percentage=1h%2C24h%2C7d&locale=en'

    useEffect(() => {
        axios.get(`${url}/api/gainer_coin`)
            .then(response => {
                setDataGainer(JSON.parse(response.data))
                setDataLoser(JSON.parse(response.data))
            })
            .catch(error => console.log(error))
    }, [])


    const [gainerToggle, setGainerToggle] = useState(1)


    dataGainer.sort((a, b) => (a.price_change_percentage_7d_in_currency < b.price_change_percentage_7d_in_currency) ? 1 : -1)
    dataLoser.sort((a, b) => (a.price_change_percentage_7d_in_currency > b.price_change_percentage_7d_in_currency) ? 1 : -1)


    const [dataDefi, setDataDefi] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/defi_coin`)
            .then(response => setDataDefi(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])








    const urlMeme = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=dogecoin%2C%20shiba-inu%2C%20pepe%2C%20bone-shibaswap%2C%20floki&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C%207d&locale=en'

    const [dataMeme, setDataMeme] = useState([])

    useEffect(() => {
        // axios.get(urlMeme)
        axios.get(`${url}/api/meme_coin`)
            .then(response => setDataMeme(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlNFT = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=the-sandbox%2C%20axie-infinity%2C%20blockstack%2C%20theta-token%2C%20apecoin&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataNft, setDataNft] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/nft_coin`)
        // axios.get(urlNFT)
            .then(response => setDataNft(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlL2 = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network%2C%20arbitrum%2C%20optimism%2C%20immutable-x%2C%20loopring&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataL2, setDataL2] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/l2_coin`)
        // axios.get(urlL2)
            .then(response => setDataL2(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlMetaverse = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=axie-infinity%2C%20decentraland%2C%20render-token%2C%20zilliqa%2C%20enjincoin&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataMetaverse, setDataMetaverse] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/metaverse_coin`)
        // axios.get(urlMetaverse)
            .then(response => setDataMetaverse(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlFantokens = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=fc-barcelona-fan-token%2C%20manchester-city-fan-token%2C%20paris-saint-germain-fan-token%2C%20lazio-fan-token%2C%20santos-fc-fan-token&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataFantokens, setDataFantokens] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/fantokens_coin`)
        // axios.get(urlFantokens)
            .then(response => setDataFantokens(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlPrivacy = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=monero%2C%20zcash%2C%20dash%2C%20decred%2C%20verge&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataPrivacy, setDataPrivacy] = useState([])

    useEffect(() => {
        axios.get(`${url}/api/privacy_coin`)
        // axios.get(urlPrivacy)
            .then(response => setDataPrivacy(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])

    const urlWeb3 = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=polkadot%2C%20chainlink%2C%20filecoin%2C%20the-graph%2C%20blockstack&order=volume_desc&per_page=5&page=1&sparkline=true&price_change_percentage=24h%2C7d&locale=en'

    const [dataWeb3, setDataWeb3] = useState([])

    useEffect(() => {
        axios.get('http://82.146.58.251/api/web3_coin')
        // axios.get(urlWeb3)
            .then(response => setDataWeb3(JSON.parse(response.data)))
            .catch(error => console.log(error))
    }, [])



    const [autoplayActive, setAutoplayActive] = useState(true)

    const autoplayOptions = {
        delay: 8000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
    }

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true , align: 'start' }, [Autoplay(autoplayOptions)])

    const scrollPrev = useCallback(
        () => emblaApi && emblaApi.scrollPrev(),
        [emblaApi]
    )
    const scrollNext = useCallback(
        () => emblaApi && emblaApi.scrollNext(),
        [emblaApi]
    )

    const allCoins = [
        {
            name: 'Defi',
            array: dataDefi
        },
        {
            name: 'Meme',
            array: dataMeme
        },
        {
            name: 'NFT',
            array: dataNft
        },
        {
            name: 'Layer 2',
            array: dataL2
        },
        {
            name: 'Metaverse',
            array: dataMetaverse
        },
        {
            name: 'Fan Tokens',
            array: dataFantokens
        },
        {
            name: 'Privacy',
            array: dataPrivacy
        },
        {
            name: 'Web-3',
            array: dataWeb3
        }
    ]


    return (
        <div className={'coins-market-wrapper'}>

            <h1 className={'coins-market-h1'}>
                Crypto Markets
            </h1>

            {/*<h3 className={'coins-market-h3'}>
                Classic
            </h3>*/}

            <div className={'top-coins'}>

                {dataTop.map((i, index) =>
                    <div className={'top-coin'}>
                        <div className={'top-coin-top'}>
                            <h4 style={{color: 'var(--primary4lightlight)'}}>{i.symbol.toUpperCase()}</h4>
                            {i.price_change_percentage_24h >= 0 ?
                                <h4 className={'green'}><span style={{color: '#f1f1f1'}}>24H  </span>+{i.price_change_percentage_24h.toFixed(2)}%</h4> :
                                <h4 className={'red'}><span style={{color: '#f1f1f1'}}>24H  </span>{i.price_change_percentage_24h.toFixed(2)}%</h4>
                            }

                        </div>

                        <div className={'top-coin-bottom'}>
                            <h3>${i.current_price.toLocaleString()}</h3>
                            {/*<p className={'top-coin-bottom-p'}>7d.</p>*/}
                            <div className={'sparkline'}>
                                <Sparklines className={'tabs-sparkline'} data={i.sparkline_in_7d.price}>
                                    <SparklinesCurve className={'tabs-sparkline-top'} style={{fill: 'none', strokeWidth: '1.8'}}   color={i.price_change_percentage_24h >= 0 ? '#0bd084' : '#ff5151'}/>
                                </Sparklines>
                            </div>
                            <div className={'sparkline-bott'}>
                                <Sparklines data={i.sparkline_in_7d.price}>
                                    <SparklinesCurve style={{fill: '', strokeWidth: ''}} color={i.price_change_percentage_24h >= 0 ? '#0bd084' : '#ff5151'}/>
                                </Sparklines>
                            </div>
                        </div>
                    </div>
                )}

            </div>







            {((dataGainer[0] === "") || (dataGainer[0] === null) || (dataGainer[0] === undefined) || (dataLoser[0] === "") || (dataLoser[0] == null) || (dataLoser[0] === undefined) ?
                    <></>
                    :
                    <div className={'coins-market-gainer-toggle'}>

                        <div onClick={() => setGainerToggle(1)} className={'coins-market-gainer-toggle-item'}>
                            <h3 className={`coins-market-gainer-h3 ${gainerToggle === 1 ? 'active' : ''}`}>
                                Top Gainer
                            </h3>
                            <div className={`coins-market-gainer-toggle-underline ${gainerToggle === 1 ? 'active' : ''}`}></div>
                        </div>

                        <div onClick={() => setGainerToggle(2)} className={'coins-market-gainer-toggle-item'}>
                            <h3 className={`coins-market-gainer-h3 ${gainerToggle === 2 ? 'active' : ''}`}>
                                Top Loser
                            </h3>
                            <div className={`coins-market-gainer-toggle-underline ${gainerToggle === 2 ? 'active' : ''}`}></div>
                        </div>

                    </div>

            )}


            {(gainerToggle === 1 ? dataGainer : dataLoser).map((data, i) =>

                <div className={`coins-market-gainer ${gainerToggle === 1 ? '' : 'loser'}`}>

                        <div className={'coins-market-gainer-container-left'}>

                            <div className={'coins-market-gainer-container-left-img-container'}>
                                <img className={`coins-market-gainer-container-left-img ${gainerToggle === 1 ? '' : 'none'}`} src={dataGainer[0].image} />
                                <img className={`coins-market-gainer-container-left-img ${gainerToggle === 1 ? 'none' : ''}`} src={dataLoser[0].image} />
                            </div>


                            <div className={'coins-market-gainer-container-left-text'}>
                                <div className={'coins-market-gainer-container-left-text-top'}>
                                    <p className={'coins-market-gainer-name'}>{data.name}</p>
                                    <h2 style={{color: 'var(--black100)'}}>{data.symbol.toUpperCase()}</h2>
                                </div>



                                <div className={'coins-market-gainer-container-left-text-mc'}>
                                    <p>Market Cap</p>
                                    <h3>
                                        ${data.market_cap?.toLocaleString()}
                                    </h3>
                                </div>

                                <div className={'coins-market-gainer-container-left-text-mc cp'}>
                                    <p className={''}>
                                        Current Price
                                    </p>
                                    <h2 className={''}>
                                        ${data.current_price}
                                    </h2>
                                </div>

                            </div>

                        </div>

                        <div className={'coins-market-gainer-container-right'}>
                            <div
                                className={`coins-market-gainer-container-right-time ${data.price_change_percentage_7d_in_currency ? '' : 'twocol'}`}
                            >

                                {data.price_change_percentage_7d_in_currency ?
                                    null
                                    :
                                    <div className={'coins-market-gainer-container-right-time-dummie'}></div>
                                }

                                <div className={'coins-market-gainer-container-right-time-item'}>
                                    <p>1H</p>
                                    {data.price_change_percentage_1h_in_currency >= 0 ?
                                        <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_1h_in_currency?.toFixed(1)}%</h3> :
                                        <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_1h_in_currency?.toFixed(2)}%</h3>
                                    }
                                </div>

                                <div className={'coins-market-gainer-container-right-time-item'}>
                                    <p>24H</p>
                                    {data.price_change_percentage_24h >= 0 ?
                                        <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_24h?.toFixed(1)}%</h3> :
                                        <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_24h?.toFixed(1)}%</h3>
                                    }
                                </div>

                                {data.price_change_percentage_7d_in_currency ?
                                    <div className={'coins-market-gainer-container-right-time-item'}>
                                        <p>7D</p>
                                        {data.price_change_percentage_7d_in_currency >= 0 ?
                                            <h3 className={`${gainerToggle === 1 ? 'blue' : 'green'}`}>+{data.price_change_percentage_7d_in_currency?.toFixed(1)}%</h3> :
                                            <h3 className={'coin-block-h4 red'}>{data.price_change_percentage_7d_in_currency?.toFixed(1)}%</h3>
                                        }
                                    </div>
                                    :
                                    null
                                }


                            </div>

                            <div>
                                <p className={'coins-market-gainer-container-right-price'}>Current Price</p>
                                <h2 className={'coins-market-gainer-container-right-h2'}>${data.current_price}</h2>
                            </div>
                        </div>

                        <div className={'gainer-sparkline-container'}>
                            <p>7D</p>
                            <div className={'gainer-sparkline'}>
                                <Sparklines className={'gainer-sparkline'} data={data.sparkline_in_7d.price}>
                                    <SparklinesCurve className={'gainer-sparkline-top'} style={{fill: 'none', strokeWidth: '0.6'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                </Sparklines>
                            </div>
                            <div className={`${gainerToggle === 1 ? '' : 'red'} gainer-sparkline-bott`}>
                                <Sparklines data={data.sparkline_in_7d.price}>
                                    <SparklinesCurve style={{fill: '', strokeWidth: '0'}} color={`${gainerToggle === 1 ? '#1b78ff' : '#ff5151'}`}/>
                                </Sparklines>
                            </div>
                        </div>


                    </div>
                )[0]}






            <FeesLimitsHome />


            <div className={'coins-market-header'}>

                <h2>Alt Zones</h2>

                <div className={'coins-market-btns'}>
                    <button onClick={() => {scrollPrev(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--prev coins-market-btn'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn carousel-btn-left'}>
                            <path
                                fill={'#1b78ff'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>
                    </button>

                    <button onClick={() => {scrollNext(); emblaApi.plugins().autoplay.reset()}} className={'embla__button embla__button--next coins-market-btn'}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'carousel-btn carousel-btn-right'}>
                            <path
                                fill={'#1b78ff'}
                                fillRule="evenodd"
                                d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                            ></path>
                        </svg>
                    </button>
                </div>

            </div>



            <div className={'embla coin-block-wrapper'} ref={emblaRef}>

                <div className={'embla__container coin-block-container'}>

                    {allCoins.map((i, index) =>

                    ((i.array == "") || (i.array == null) || (i.array == undefined)) ?
                        <></>
                        :
                        <div className={'embla__slide coin-block'}>

                            <div className={'coin-block-header'}>

                                <div className={'coin-block-vert-bar'}></div>

                                <h3 className={'coin-block-h3'}>
                                    {i.name}
                                </h3>

                            </div>

                            <div className={'coin-block-coins'}>


                                {i.array.map((i, index) =>
                                    <div className={'coin-block-coin'}>

                                        {/*<div className={'coin-block-sparkline'}>
                                            <Sparklines data={i.sparkline_in_7d.price}>
                                                <SparklinesCurve style={{fill: 'none'}} color={i.price_change_percentage_24h >= 0 ? '#0bd084' : '#ff5151'}/>
                                            </Sparklines>
                                        </div>*/}

                                        <div className={'coin-block-left'}>
                                            <img className={'coin-block-img'} src={i.image} />
                                            <h4 className={'coin-block-h4'} style={{color: 'var(--primary4lightlight)'}}>{i.symbol.toUpperCase()}</h4>
                                        </div>

                                        <div className={'coin-block-right'}>
                                            {i.price_change_percentage_24h >= 0 ?
                                                <h4 className={'coin-block-h4 green'}><span style={{color: '#f1f1f1'}}>24H </span>+{i.price_change_percentage_24h.toFixed(2)}%</h4> :
                                                <h4 className={'coin-block-h4 red'}><span style={{color: '#f1f1f1'}}>24H </span>{i.price_change_percentage_24h.toFixed(2)}%</h4>
                                            }
                                            <h3 className={'coin-block-h3'}>${i.current_price}</h3>
                                        </div>
                                    </div>
                                )}


                            </div>

                        </div>


                    )}



                </div>

            </div>

            <PreliminaryListing />

        </div>
    );
};

export default CoinsMarket;