import React, {useEffect, useState} from 'react';
import './GiftboxBanner.css'
import lgt_giftboxes from '../../assets/lgt_giftboxes.svg'
import cross from '../../assets/cross.svg'
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

const GiftboxBanner = () => {

    const [modal, setModal] = useState(false)

    const [giftBoxCookie, setGiftBoxCookie] = useCookies(["GiftBoxBanner"]);

    useEffect(() => {
        /*setTimeout(() => {
            if (giftBoxCookie.GiftBoxBanner) {
                setModal(false)
            } else {
                setModal(true)
                setGiftBoxCookie("GiftBoxBanner", true, { path: "/", maxAge: 60000});
            }
        }, 6000)*/
    }, [])


    return (
        <div className={`giftbox-banner-wrapper`}>
            <div onClick={() => setModal(false)} className={`giftbox-banner-back ${modal ? '' : 'hidden'}`}></div>

            <div className={`giftbox-banner-container ${modal ? '' : 'hidden'}`}>
                <img onClick={() => setModal(false)} src={cross} className={`giftbox-banner-cross ${modal ? '' : 'hidden'}`} />

                <h3 className={'giftbox-banner-h3 white'}>
                    NFT GiftBox
                </h3>
                <p className={'giftbox-banner-p white'}>
                    Get random NFT piece from GiftBox that comes with every LGT contribution.
                </p>

                <Link to={'/token'}>
                    <img src={lgt_giftboxes} className={'giftbox-banner-img'} />
                </Link>

                <Link
                    to={'/token'}
                    className={`btn light`}
                >
                    Claim
                </Link>
            </div>

        </div>
    );
};

export default GiftboxBanner;