import React, {useState} from 'react';
import rupor from "../../assets/sheet.svg";
import {Link} from "react-router-dom";
import arrowrightblue from "../../assets/arrowrightblue.svg";

const BlogNav = ({active}) => {

    return (
        <div className={'blog-nav'}>

            <Link to={'/blog'}>
                <p className={'back-to-blog-p'}>
                    <img src={arrowrightblue} className={'back-to-blog-arrow'} />
                    Blog
                </p>
            </Link>

            <img className={'blog-article-img'} src={rupor}/>

            <Link to={'/blog/art1'}>
                <div className={active === 1 ? 'blog-article-container active' : 'blog-article-container'}>
                    <h5 className={'blog-article-h5'}>
                        Exclusive NFT GiftBox with every LGT contribution
                    </h5>
                </div>
            </Link>

            <Link to={'/blog/art2'}>
                <div className={active === 2 ? 'blog-article-container active' : 'blog-article-container'}>
                    <h5 className={'blog-article-h5'}>
                        Join bounty program and earn LGT
                    </h5>
                </div>
            </Link>

            <Link to={'/blog/art3'}>
                <div className={active === 3 ? 'blog-article-container active' : 'blog-article-container'}>
                    <h5 className={'blog-article-h5'}>
                        What is NFT Workshop and why i have to try it?
                    </h5>
                </div>
            </Link>

            <Link to={'/blog/art4'}>
                <div className={active === 4 ? 'blog-article-container active' : 'blog-article-container'}>
                    <h5 className={'blog-article-h5'}>
                        CEO of Liqui Global about security
                    </h5>
                </div>
            </Link>


        </div>
    );
};

export default BlogNav;