import React from 'react';
import './SecurityPrivacy.css'
import safe1 from "../../assets/safe1.svg";
import safe2 from "../../assets/safe2.svg";
import safe3 from "../../assets/safe3.svg";
import {Link} from "react-router-dom";

const SecurityPrivacy = () => {
    return (
        <div className={'block3'}>

            <div className={'block3-container'}>

                <h2>Security and Safety</h2>

                <Link to={'/security'} className={'btn primary security-btn'}>
                    Learn More
                    {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 50" className={'btn-arrow'}>
                        <path
                            fill={'#fff'}
                            fillRule="evenodd"
                            d="M27.2 22.8L5.3.9C4-.3 2.1-.3.9.9a3.14 3.14 0 000 4.4L20.6 25 .9 44.7C-.3 46-.3 47.9.9 49.1s3.1 1.2 4.4 0l21.9-21.9a3.14 3.14 0 000-4.4z"
                        ></path>
                    </svg>*/}
                </Link>

                <div className={'security-wrapper'}>
                    {/*<img src={privacy} height={'400px'}/>*/}
                    <div className={'plates3'}>

                        <div className={'plate3'}>
                            <img src={safe1}/>
                            <h3>Cutting Edge Security Measures</h3>
                            <p>
                                {/*Мы используем инновационные технические разработки и строго соблюдаем правила безопасности. Все данные строго шифруются во время хранения и передачи, что гарантирует приватность доступа к вашему акаунту.*/}
                                We use innovative technology in security and follow strict safety rules. All data is encrypted during storage and transmission. {/*which guarantees the privacy of access to your account.*/}
                            </p>
                        </div>

                        <div className={'plate3'}>
                            <img src={safe2}/>
                            <h3>AI Real-Time Monitoring System</h3>
                            <p>
                                {/*AI Система мониторинга анализирует поведение пользователей в режиме реального времени, моментально реагируя на подозрительную активность и блокируя потенциальные угрозы безопасности.*/}
                                The AI monitoring system analyzes user behavior in real time, instantly responding to suspicious activity and blocking potential security threats.
                            </p>
                        </div>

                        <div className={'plate3'}>
                            <img src={safe3}/>
                            <h3>Secure Asset Storage</h3>
                            <p>
                                {/*Используя холодные кошельки для хранения средств пользователей, мы гарантируем максимальную надежность и полную сохранность ваших цифровых активов.*/}
                                Using cold wallets to store user funds, we guarantee maximum reliability and complete safety of your digital assets.
                            </p>
                        </div>

                        {/*<Link to={'/security'} className={'btn'}>
                            Learn more...
                        </Link>*/}

                    </div>
                </div>

            </div>


        </div>
    );
};

export default SecurityPrivacy;