import React, {useState} from 'react';
import './Roadmap.css'
import {Link} from "react-router-dom";

const Roadmap = () => {


    const [period, setPeriod] = useState(2)


    return (
        <div className={'roadmap-wrapper'}>

             <h1 className={'roadmap-header'}>
                 Roadmap
             </h1>

            <div className={'roadmap-container-big'}>


                <div className={'roadmap-24'}>

                    {/*<div className={'roadmap-period-container'}>

                        <div className={`${period === 1 ? 'active' : ''} roadmap-period-item`} onClick={() => setPeriod(1)}>
                            <h2 className={'roadmap-period'}>
                                2016
                            </h2>
                        </div>

                        <div className={`${period === 2 ? 'active' : ''} roadmap-period-item`} onClick={() => setPeriod(2)}>
                            <h2 className={'roadmap-period'}>
                                2024
                            </h2>
                        </div>

                        <div className={`${period === 3 ? 'active' : ''} roadmap-period-item`} onClick={() => setPeriod(3)}>
                            <h2 className={'roadmap-period'}>
                                2025+
                            </h2>
                        </div>

                    </div>*/}

                    <div className={'active roadmap-period-item'}>
                        <h2 className={'active roadmap-period'}>
                            2024
                        </h2>
                    </div>


                    {/*<div className={`${period === 1 ? 'active' : ''} r16 roadmap-container`}>

                        <div className={'r16 roadmap-q'}>

                            <div className={'roadmap-q-item'}>
                                <h5>
                                    About
                                </h5>
                            </div>

                            <div className={'roadmap-q-item'}>
                                <h5>
                                    Accomplishments
                                </h5>
                            </div>

                        </div>



                        <div className={`roadmap-24-points`}>

                            <div className={'roadmap-16-about r16 roadmap-24-points-col'}>

                                <p className={'whitewhite'}>
                                    Криптовалютная биржа Liqui была основанна в 2016 году группой разработчиков из украины и других европейских стран. На этапе создания, нашей основной задачей являлось создание торговой платформы, максимально простой для использования новичками рынка и предоставление широкого спектра торговых сервисов для уже опытных пользователей...
                                    <br/>
                                    <br/>
                                    Мы стремились предоставить безопасные, удобные и инновационные торговые инструменты. Мы были нацелены на удовлетворение всех потребности пользователей, включая торговлю, инвестирование, покупку, размещение, заимствование и многое другое. У нас получилось быстро вырасти из экспериментальной трейдинговой платформы в самый популярный альткоин маркет и обрести огромную популярность среди криптовалютных инвесторов и трейдеров.
                                    <br/>
                                    <br/>
                                    Возможность выбора из огромного количества криптовалют, особый узнаваемый дизайн, безупречный сервис смогли привлечь на платформу огромную аудиторию. К концу 2018 года, наша платформа насчитывала более 400 тысяч активных пользователей и возможность торговли более 600 криптовалютами.
                                </p>

                                <Link to={'/legacy'}>
                                    <button className={'btn primary2filled2'}>
                                        Learn More
                                    </button>
                                </Link>

                            </div>


                            <div className={'r16 roadmap-24-points-col'}>
                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Exchange
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Статус крупнейшего альткоин маркета
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Huge Liquidity
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Возможность выбора из 600+ криптовалют
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Best Community
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Более 400 тысяч активных пользоваетелей
                                        </p>

                                    </div>

                                </div>


                            </div>

                        </div>



                    </div>*/}


                    <div className={`${period === 2 ? 'active' : ''} r16 roadmap-container`}>

                        <div className={'r24 roadmap-q'}>

                            <div className={'roadmap-q-item'}>
                                <h5 style={{paddingLeft: '4px'}}>
                                    Q1
                                </h5>
                            </div>

                            <div className={'roadmap-q-item'}>
                                <h5>
                                    Q2
                                </h5>
                            </div>

                            <div className={'roadmap-q-item'}>
                                <h5>
                                    Q3
                                </h5>
                            </div>

                            <div className={'roadmap-q-item'}>
                                <h5>
                                    Q4
                                </h5>
                            </div>

                        </div>

                        <div className={`roadmap-24-points`}>

                            <div className={'roadmap-24-points-col'}>
                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Аналитика
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Анализ и прогнозирование рынка
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            New Beginning
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Формирование новой концепции
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Ребрендинг
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Перезапуск Liqui Global
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Seed Round
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Have raised $1.000.000
                                        </p>

                                    </div>

                                </div>


                            </div>


                            <div className={'roadmap-24-points-col'}>


                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Pre-sale Round
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Будет собранно $3.000.000
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Smart Contract
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск LGT смарт контракта
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Перезапуск
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Основной запуск Liqui Global
                                        </p>

                                    </div>

                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Mobile Apps
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Приложения для iOS и Andriod
                                        </p>

                                    </div>
                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            TGE Round
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Будет собранно $10.000.000
                                        </p>

                                    </div>
                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Derivatives
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Интеграция деривативов
                                        </p>

                                    </div>
                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global Wallet
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск мультивалютного кошелька
                                        </p>

                                    </div>
                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Burning Mechanism
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Активация механизма сжигания LGT
                                        </p>

                                    </div>
                                </div>


                            </div>


                            <div className={'roadmap-24-points-col'}>


                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global Pool
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Интеграция майнинг пулла
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global Shop
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Открытие магазина ценных вещей
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global DEX (Testnet)
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск децентрализованной биржи в тестовом режиме
                                        </p>

                                    </div>

                                </div>


                                <div className={'roadmap-24-point'}>
                                    <div className={'roadmap-24-vert-bar'}></div>
                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Streaming Platform
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск стриминговой платформы для трейдеров
                                        </p>

                                    </div>
                                </div>


                            </div>


                            <div className={'roadmap-24-points-col'}>


                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liquium Blockchain
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Переход на собственный блокчейн
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global DEX (Mainnet)
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск децентрализованной биржи в основном режиме
                                        </p>

                                    </div>

                                </div>

                                <div className={'roadmap-24-point'}>

                                    <div className={'roadmap-24-vert-bar'}></div>

                                    <div className={'roadmap-24-point-text'}>

                                        <h3 className={'roadmap-24-h3'}>
                                            Liqui Global Metaverse
                                        </h3>

                                        <p className={'roadmap-24-p'}>
                                            Запуск Liqui Global Arena
                                        </p>

                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>


                </div>

            </div>

            <p className={'roadmap-disclaimer-p'}>
                *This roadmap lists many, but not all checkpoints currently planned. While they are generally in the order in which we plan them to be released, some items may shift and be released earlier or later, and others will be subdivided into additional parts. Despite this, all specified checkpoints are guaranteed to be achieved in 2024.
            </p>

        </div>
    );
};

export default Roadmap;