import React from 'react';
import './InfoPage.css'
import {Link} from "react-router-dom";

const OurMission = () => {

    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })

    return (
        <div className={'info-page'}>

            <div className={'info-page-wrapper'}>
                <div className={'info-page-container'}>
                    <div className={'info-page-text'}>
                        <h1>
                            Our <span className={'span-invert'}>Mission</span>
                        </h1>
                        <p>
                            Нашей основной миссией является создание торговой экосистемы, максимально простой для использования новичками рынка и предоставление инновационных торговых инструментов для уже опытных пользователей.
                        </p>

                        <p>
                            На рынке всегда кто то выигрывает, кто то проигрывает, но мы убеждены, что у всех участников рынка равные возможности, и благодаря нашим сервисам, любой новичок может стать опытным и создавать и использовать свои уникальные торговые стретегии.
                        </p>

                    </div>
                    <Link to={'/'} className={'info-page-home'}><p style={{color: '#1b78ff', textDecoration: 'underline', paddingTop: '100px'}}>Go home</p></Link>
                </div>

                <div className={'info-page-img'}>
                    <img height={'400px'} width={'400px'}/>
                </div>
            </div>



        </div>
    );
};

export default OurMission;