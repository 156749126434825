import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const EarnBoxProduct = ({item = item, productsIsSorting = false}) => {


    const [featureNotAvailableActive, setFeatureNotAvailableActive] = useState(false)


    const featureNotAvailableActiveHandler = () => {
        setFeatureNotAvailableActive(true)

        setTimeout(() => {
            setFeatureNotAvailableActive(false)
        }, 3000)
    }

    const [durationState, setDurationState] = useState('flexible')
    const [durationStateNum, setDurationStateNum] = useState(0)

    useEffect(() => {

        setDurationState(
            item.defi === true ?
                'defi'
                :
                item.eth === true ? 'eth' : 'flexible'

        )

    }, [productsIsSorting])


    return (
        <div className={`earnproducts-item ${item.top ? 'top' : ''} ${productsIsSorting ? 'sorting' : ''}`}>

            <div className={`feature-not-available-wrapper ${featureNotAvailableActive ? 'active' : ''}`}>
                <p className={'bold'}>This feature will be available after the <Link className={'feature-not-available-link bold'} to={'/token'}>TGE Round</Link></p>
                {/*<img onClick={() => setFeatureNotAvailableClose(true)} className={'feature-not-available-cross'} src={cross} />*/}
            </div>


            <div className={'earnproducts-item-left'}>

                <div className={'earnproducts-item-logo-container'}>
                    <img src={item.logo} className={'earnproducts-item-logo'} />
                </div>

                <h3 className={'white'}>
                    {item.currency}
                </h3>

                {item.hot ?
                    <p className={` ${item.top ? 'top' : ''} earnproducts mybots-bot-top-type`}>
                        {item.top ? 'Top' : 'Hot'}
                    </p>
                    :
                    ''
                }

            </div>

            <div className={'earnproducts-item-center-left'}>

                <h3 className={'white'}>
                    {
                        durationState === 'flexible' ?
                            (item.apr)?.toFixed(2)
                            :
                            durationState === 'eth' ?
                                (item.eth_apr)?.toFixed(2)
                                :
                                durationState === 'defi' ?
                                    (item.defi_apr)?.toFixed(2)
                                    :
                                    ((item.fixed_apr) * ((durationStateNum * 0.001981) + 0.3) * 1.7931)?.toFixed(2)
                    }%

                    {(item.apr_sec > 0) && (durationState === 'flexible') ?
                        ` ~ ${item.apr_sec}%`
                        :
                        ''
                    }

                    {(item.eth_apr_sec > 0) && (durationState === 'eth') ?
                        ` ~ ${item.eth_apr_sec}%`
                        :
                        ''
                    }

                    {(item.defi_apr_sec > 0) && (durationState === 'defi') ?
                        ` ~ ${item.defi_apr_sec}%`
                        :
                        ''
                    }
                </h3>

            </div>

            <div className={'earnproducts-item-center-left'}>

                {item.flexible ?

                    <button
                        onClick={() => {
                            setDurationState('flexible')
                            setDurationStateNum(0)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'flexible' ? 'primary2filled2' : 'primary4'}`}
                    >
                        Flexible
                    </button>
                    :
                    ''
                }

                {item.defi ?
                    <button
                        onClick={() => {
                            setDurationState('defi')
                            setDurationStateNum(0)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'defi' ? 'primary2filled2' : 'primary4'}`}
                    >
                        DeFi
                    </button>
                    :
                    ''
                }

                {item.eth ?
                    <button
                        onClick={() => {
                            setDurationState('eth')
                            setDurationStateNum(0)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'eth' ? 'primary2filled2' : 'primary4'}`}
                    >
                        ETH 2.0
                    </button>
                    :
                    ''
                }

                {item.fifteen ?
                    <button
                        onClick={() => {
                            setDurationState('fifteen')
                            setDurationStateNum(15)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'fifteen' ? 'primary2filled2' : 'primary4'}`}
                    >
                        15
                    </button>
                    :
                    ''
                }

                {item.thirty ?
                    <button
                        onClick={() => {
                            setDurationState('thirty')
                            setDurationStateNum(30)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'thirty' ? 'primary2filled2' : 'primary4'}`}
                    >
                        30
                    </button>
                    :
                    ''
                }

                {item.sixty ?
                    <button
                        onClick={() => {
                            setDurationState('sixty')
                            setDurationStateNum(60)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'sixty' ? 'primary2filled2' : 'primary4'}`}
                    >
                        60
                    </button>
                    :
                    ''
                }

                {item.ninety ?
                    <button
                        onClick={() => {
                            setDurationState('ninety')
                            setDurationStateNum(90)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'ninety' ? 'primary2filled2' : 'primary4'}`}
                    >
                        90
                    </button>
                    :
                    ''
                }

                {item.hundredtwenty ?
                    <button
                        onClick={() => {
                            setDurationState('hundredtwenty')
                            setDurationStateNum(120)
                        }}
                        className={`earnproducts-duration-btn lowpadding flexible btn ${durationState === 'hundredtwenty' ? 'primary2filled2' : 'primary4'}`}
                    >
                        120
                    </button>
                    :
                    ''
                }

            </div>



            <div className={'earnproducts-item-btn-container'}>
                <button onClick={() => featureNotAvailableActiveHandler()} className={'earnproducts-item-btn btn light'}>
                    Subscribe
                </button>
            </div>

        </div>
    );
};

export default EarnBoxProduct;